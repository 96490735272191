import { Api, CustomApiResponse } from '@root/services/api';
import { FetchAdditionalProposalsAction, FetchProposalsAction } from '@root/redux/actions/types';
import { call, put } from 'redux-saga/effects';
import { OfferArray, ErrorResponse } from '@root/general/types';
import {
  CALL_COMMON_ERROR_HANDLER,
  CALL_UNEXPECTED_ERROR_HANDLER,
  FETCH_PROPOSALS_SUCCESS,
} from '@root/general/consts/actions';
import { isArray } from 'lodash';
import { getOffersFilterById } from '@root/utils/getOffersFilterById';
import { getOffersTypeByFilterId } from '@root/mobile/utils/getOffersTypeByFilterId';

const DEFAULT_PAGE = 1;

export function* fetchProposalsByState(api: Api, action: FetchProposalsAction) {
  const { proposalsFilterId, jobId, callback } = action.payload;

  try {
    const offersType = getOffersTypeByFilterId(proposalsFilterId);
    const { offerStatusId, name: tabName } = getOffersFilterById(proposalsFilterId);

    const response: CustomApiResponse<OfferArray, ErrorResponse> = yield call(
      api.fetchUserOffersRequest,
      offersType,
      { offerStatusId, jobId },
      DEFAULT_PAGE,
    );

    if (response.ok && isArray(response.data)) {
      yield put({
        type: FETCH_PROPOSALS_SUCCESS,
        payload: { tabName, proposals: response.data, lastPage: response.meta?.lastPage ?? DEFAULT_PAGE },
      });
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  } finally {
    yield callback && call(callback);
  }
}

export function* fetchAdditionalProposalsByState(api: Api, action: FetchAdditionalProposalsAction) {
  const { page, proposalsFilterId, jobId, callback } = action.payload;

  try {
    const offersType = getOffersTypeByFilterId(proposalsFilterId);
    const { offerStatusId } = getOffersFilterById(proposalsFilterId);

    const response: CustomApiResponse<OfferArray, ErrorResponse> = yield call(
      api.fetchUserOffersRequest,
      offersType,
      { offerStatusId, jobId },
      page,
    );

    if (response.ok && isArray(response.data)) {
      yield callback && call(callback, true, response.data);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { WelcomePage } from '@root/web/screens/WelcomeScreen';
import { LoginScreen } from '@root/web/screens/LoginScreen';
import { RegistrationScreen } from '@root/web/screens/RegistrationScreen';
import { ResetPasswordScreen } from '@root/web/screens/ResetPasswordScreen';
import { RoleScreen } from '@root/web/screens/RoleScreen';
import { RestorePasswordScreen } from '@root/web/screens/RestorePasswordScreen';
import { PrivateRoutes } from '@root/web/navigators/PrivateRoutes';
import { route } from '@root/web/consts';
import { RegistrationSuccessfulScreen } from '@root/web/screens/RegistrationSuccessfulScreen';
import { ProfileScreen } from '@root/web/screens/ProfileScreen';

type RootNavigatorProps = {
  isAuth: boolean;
};

export const RootNavigator = (props: RootNavigatorProps) => {
  const { isAuth } = props;

  return (
    <Router>
      <Switch>
        <Route
          path={route.RESET_PASSWORD}
          render={({ match }) => <ResetPasswordScreen token={match.params?.token ?? ''} />}
        />
        <Route
          path={route.CONFIRM_EMAIL}
          render={({ match }) => <ProfileScreen path={route.CHANGE_EMAIL} token={match.params?.token ?? ''} />}
        />
        <Route
          path={route.CONFIRM_DELETE_ACCOUNT}
          render={({ match }) => (
            <ProfileScreen path={route.DELETE_ACCOUNT} deleteAccountToken={match.params?.token ?? ''} />
          )}
        />
        <Route path={route.WELCOME}>
          <WelcomePage />
        </Route>
        <Route path={route.LOGIN}>
          <LoginScreen />
        </Route>
        <Route path={route.CHOOSE_ROLE}>
          <RoleScreen />
        </Route>
        <Route path={route.REGISTRATION}>
          <RegistrationScreen />
        </Route>
        <Route path={route.RESTORE_PASSWORD}>
          <RestorePasswordScreen />
        </Route>
        <Route path={route.SUCCESSFUL_REGISTRATION}>
          <RegistrationSuccessfulScreen />
        </Route>
        <PrivateRoutes isAuth={isAuth} />
      </Switch>
    </Router>
  );
};

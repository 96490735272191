import { useHistory, useLocation } from 'react-router-dom';
import { route } from '@root/web/consts';

type LocationState = {
  from: {
    pathname: string;
  };
};

export const useOnLoginSuccessWeb = () => {
  let location = useLocation<LocationState>();
  let history = useHistory();

  let from = location.state?.from ?? route.PROFILE;

  const onLoginSuccess = (token?: string) => {
    if (token) {
      history.replace(route.SUCCESSFUL_REGISTRATION, { ...location.state, token });
    } else {
      history.replace(from);
    }
  };
  return [onLoginSuccess];
};

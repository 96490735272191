import React, { Dispatch, SetStateAction, useState } from 'react';
import { ProfileAvatar } from '@root/web/screens/ProfileScreen/components/ProfileAvatar';
import { CustomSwitcher } from '@root/web/components/CustomSwitcher';
import { accountType, privacyPolicyScreenMode, SUPPORT_EMAIL } from '@root/general/consts';
import { useStyles } from './styles';
import { strings } from '@root/strings';
import { PrivacyPolicyModal } from '@root/web/components/PrivacyPolicyModal';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';

const { profileScreen: string } = strings;

type ProfileMainInfoProps = {
  name: string;
  email: string;
  avatar: string | null;
  avatarLoading: boolean;
  setAvatarLoading: Dispatch<SetStateAction<boolean>>;
  roleId: accountType | null;
  onPressRemovePhoto: () => void;
  onPressSwitchRole: () => void;
};

export const ProfileMainInfo = (props: ProfileMainInfoProps) => {
  const { name, email, avatar, avatarLoading, setAvatarLoading, roleId, onPressRemovePhoto, onPressSwitchRole } = props;
  const styles = useStyles();

  const [privacyPolicyModalMode, setPrivacyPolicyModalMode] = useState<privacyPolicyScreenMode | null>(null);

  const openPrivacyPolicyModal = (mode: privacyPolicyScreenMode) => () => {
    setPrivacyPolicyModalMode(mode);
  };

  return (
    <>
      <div className={styles.content}>
        <ProfileAvatar avatar={avatar} avatarLoading={avatarLoading} setAvatarLoading={setAvatarLoading} />
        {avatar && (
          <CustomButton
            type={customButtonType.WHISPER}
            title={string.removePhoto}
            onPress={onPressRemovePhoto}
            ellipse
            maxWidth={150}
            marginTop={10}
            className={styles.removePhotoContainer}
          />
        )}
        <span className={styles.userName}>{name}</span>
        <span className={styles.userEmail}>{email}</span>
        <CustomSwitcher className={styles.customSwitcher} accountTypeValue={roleId} onPress={onPressSwitchRole} />
      </div>
      <div className={styles.referencesContainer}>
        <CustomButton
          type={customButtonType.TEXT}
          title={string.privacyPolicy}
          onPress={openPrivacyPolicyModal(privacyPolicyScreenMode.privacyPolicy)}
        />
        <CustomButton
          type={customButtonType.TEXT}
          title={string.termsOfService}
          onPress={openPrivacyPolicyModal(privacyPolicyScreenMode.termsOfService)}
          className={styles.contactSupportBorderText}
        />
        <CustomButton
          type={customButtonType.TEXT}
          title={string.contactSupport}
          onPress={() => (window.location.href = `mailto:${SUPPORT_EMAIL}`)}
        />
      </div>
      {privacyPolicyModalMode && (
        <PrivacyPolicyModal mode={privacyPolicyModalMode} setModalMode={setPrivacyPolicyModalMode} />
      )}
    </>
  );
};

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    backgroundColor: colors.lightGrey,
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

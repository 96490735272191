import { applyMiddleware, createStore, Store } from 'redux';
import { rootReducer } from './reducers';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import { Persistor } from 'redux-persist/es/types';
import { webRootSaga } from '@root/redux/sagas/web';
import storage from 'redux-persist/lib/storage';

export const getStore = () => {
  const migrations = {
    0: (state: any) => {
      return {
        ...state,
        auth: {
          ...state.auth,
          fieldReps: [],
        },
      };
    },
  };

  const persistConfig = { key: 'root', version: 0, storage, migrate: createMigrate(migrations, { debug: false }) };
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const sagaMiddleware = createSagaMiddleware();

  const store: Store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

  sagaMiddleware.run(webRootSaga);

  const persistor: Persistor = persistStore(store);

  return { persistor, store };
};

import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useStyles } from './styles';
import { useDispatch } from 'react-redux';
import { editUserAvatarAction } from '@root/redux/actions';
import { CustomLoader } from '@root/web/components/CustomLoader';
import { BASE_URL } from '@root/web/consts';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Modal } from '@material-ui/core';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import Scrollbars from 'react-custom-scrollbars';
import { strings } from '@root/strings';
import { showToastAction, ToastTypes } from '@root/redux/actions/commonActions';
import { MAX_AVATAR_SIZE } from '@root/general/consts';

const { buttons, warnings } = strings;

export type ProfileAvatarProps = {
  avatar: string | null;
  avatarLoading: boolean;
  setAvatarLoading: Dispatch<SetStateAction<boolean>>;
};

export const ProfileAvatar = (props: ProfileAvatarProps) => {
  const { avatar, avatarLoading, setAvatarLoading } = props;
  const styles = useStyles(props);

  const userAvatar = `${BASE_URL}storage/${avatar}`;

  const imageRef = useRef(null);
  const [crop, setCrop] = useState<Crop>({ unit: 'px', width: 100, aspect: 1 });
  const [src, setSrc] = useState<string>('');
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const formRef = useRef(null);
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const onPressEdit = () => {
    //@ts-ignore
    inputRef.current?.click();
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const onChange = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new window.FileReader();
      // @ts-ignore
      reader.addEventListener('load', () => setSrc(reader.result));
      const image = e.target.files[0];

      if (image.size > MAX_AVATAR_SIZE) {
        dispatch(showToastAction({ message: warnings.largeImage, type: ToastTypes.Notification }));
        return;
      }

      reader.readAsDataURL(image);
      setModalOpen(true);
    }
  };

  const onCropChange = (crop: any) => {
    setCrop(crop);
  };

  const onCropComplete = (crop: any) => {
    const image: any = imageRef.current;
    if (image && crop.width && crop.height) {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');

      ctx &&
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height,
        );

      const dataUrl = canvas.toDataURL();
      const startString = dataUrl.indexOf(',');
      const result = dataUrl.slice(startString + 1);
      setCroppedImage(result);
    }
  };

  const savePhoto = () => {
    //@ts-ignore
    const imageData = new FormData(formRef.current);

    if (croppedImage) {
      imageData.append('avatar', croppedImage);

      closeModal();

      setAvatarLoading(true);
      dispatch(editUserAvatarAction({ avatar: imageData }));
    }
  };

  return avatarLoading ? (
    <div className={styles.loaderContainer}>
      <CustomLoader />
    </div>
  ) : (
    <form ref={formRef} className={styles.photoContainer}>
      {avatar ? (
        <img className={styles.photo} src={userAvatar} alt='' />
      ) : (
        <img className={styles.defaultPhoto} src={require('@root/web/assets/icons/profile.svg')} alt='' />
      )}
      <div className={styles.editPhoto} onClick={onPressEdit}>
        <input hidden type={'file'} name={'photo'} accept={'image/*'} ref={inputRef} onChange={onChange} />
        <img src={require('@root/web/assets/icons/edit.svg')} alt='' />
      </div>
      <Modal open={isModalOpen} onClose={closeModal} className={styles.modalContainer}>
        <div className={styles.cropPhotoModal}>
          <Scrollbars>
            <div className={styles.reactCropContainer}>
              <ReactCrop
                src={src}
                crop={crop}
                ruleOfThirds
                onChange={onCropChange}
                onComplete={onCropComplete}
                onImageLoaded={(image: any) => {
                  imageRef.current = image;
                }}
              />
            </div>
          </Scrollbars>
          <CustomButton type={customButtonType.RED} title={buttons.save} disabledTopBorder onPress={savePhoto} />
        </div>
      </Modal>
    </form>
  );
};

import { useSelector } from 'react-redux';
import { getIndustries, getPostedJobsByStateId } from '@root/selectors';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { getJobStatusByStatusId, JOB_STATUS_ALL } from '@root/mobile/utils/getJobStatusByStatusId';
import { ArrayIndustries, Job } from '@root/general/types';
import { ActionType, AdditionActionType } from '@root/hooks/useFlatListPagination';
import { fetchAdditionalPostedJobsAction, fetchPostedJobsAction, refreshPostedJobsAction } from '@root/redux/actions';

type usePostedJobsScreenReturnType = [
  Array<Job>,
  number,
  ActionType,
  ActionType,
  AdditionActionType<Job>,
  Dispatch<SetStateAction<number>>,
  string,
  ArrayIndustries,
  Array<Job>,
  number,
];

export const usePostedJobsScreen = (): usePostedJobsScreenReturnType => {
  const { allIndustries } = useSelector(getIndustries);

  const [jobStatusFilterId, setJobStateFilterId] = useState(JOB_STATUS_ALL.id);

  const { lastPage, postedJobs } = useSelector(getPostedJobsByStateId(jobStatusFilterId));
  const { postedJobs: allJobs } = useSelector(getPostedJobsByStateId(JOB_STATUS_ALL.id));

  const { name: jobStatusFilterName } = getJobStatusByStatusId(jobStatusFilterId);

  const fetchAction = useCallback(
    (fetchCallback: () => void) => {
      return fetchPostedJobsAction({ jobStatus: jobStatusFilterId, callback: fetchCallback });
    },
    [jobStatusFilterId],
  );

  const refreshAction = useCallback(
    (refreshCallback: () => void) => {
      return refreshPostedJobsAction({ jobStatus: jobStatusFilterId, callback: refreshCallback });
    },
    [jobStatusFilterId],
  );

  const fetchAdditionAction = useCallback(
    (page: number, fetchAdditionCallback: (isSuccess: boolean, additionalItems: any) => void) => {
      return fetchAdditionalPostedJobsAction({
        jobStatus: jobStatusFilterId,
        page,
        callback: fetchAdditionCallback,
      });
    },
    [jobStatusFilterId],
  );

  return [
    postedJobs,
    lastPage,
    fetchAction,
    refreshAction,
    fetchAdditionAction,
    setJobStateFilterId,
    jobStatusFilterName,
    allIndustries,
    allJobs,
    jobStatusFilterId,
  ];
};

import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useStyles } from './styles';
import { profileTabsMode } from '@root/web/screens/ProfileScreen/types';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import { CustomTabHeader } from '@root/web/components/CustomTabHeader';
import { strings } from '@root/strings';
import Scrollbars from 'react-custom-scrollbars';
import { FormikHandleChangeType, FormikSetFieldTouchedType, FormikValuesType } from '@root/mobile/types/inputTypes';
import { CustomInputAdapter } from '@root/web/components/CustomInput/customInputAdapter';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { CustomLoader } from '@root/web/components/CustomLoader';
import { WarningModal } from '@root/web/components/WarningModal';
import { useHistory } from 'react-router-dom';
import { route } from '@root/web/consts';
import { validationSchema } from '@root/mobile/utils/validationSchema';
import { farmActionModalMode } from '@root/mobile/screens/FarmActionModalScreen/types';
import { useFarmActionModalScreen } from '@root/mobile/hooks/useFarmActionModalScreen';
import { showToastAction, ToastTypes } from '@root/redux/actions/commonActions';
import { useDispatch } from 'react-redux';
import { ChooseStateModal } from '@root/web/components/ChooseStateModal';
import { ChooseCityModal } from '@root/web/components/ChooseCityModal';
import { FarmType } from '@root/general/types';
import { CustomTabContainer } from '@root/web/components/CustomTabContainer';

const { farmActionModalScreen: string, warnings, buttons } = strings;

type MyLocationsActionScreenProps = {
  mode: farmActionModalMode;
  setTabMode: Dispatch<SetStateAction<profileTabsMode | null>>;
  location?: FarmType;
};

export const MyLocationsActionScreen = (props: MyLocationsActionScreenProps) => {
  const { mode, setTabMode, location } = props;
  const styles = useStyles();
  const dispatch = useDispatch();
  const ref = useRef<any>();
  const history = useHistory();

  const [isStateModalVisible, setStateModalVisible] = useState(false);
  const [isCityModalVisible, setCityModalVisible] = useState(false);

  const isEditFarmModalScreen = mode === farmActionModalMode.EDIT;

  const onStatePress = () => () => {
    setStateModalVisible(true);
  };

  const onCityPress = (selectedStateId: number | null) => () => {
    if (selectedStateId) {
      setCityModalVisible(true);
    } else {
      dispatch(showToastAction({ message: warnings.selectState, type: ToastTypes.Error }));
    }
  };

  const goBack = () => {
    setTabMode(null);
    history.push(route.LOCATIONS);
  };

  const [
    initialValues,
    locationsFields,
    onSubmit,
    isLoading,
    fillInfo,
    isDeleteWarningVisible,
    setDeleteWarningModalVisible,
    onPressDeleteFarm,
    deleteFarmName,
    setSelectedStateId,
    setSelectedCityId,
    selectedStateId,
    keyFillGeneralInfo,
  ] = useFarmActionModalScreen(isEditFarmModalScreen, onStatePress, onCityPress, goBack, goBack, () => {}, location);

  const renderHeader = () => {
    if (isEditFarmModalScreen) {
      return (
        <CustomTabHeader
          title={string.editLocationScreenTitle}
          onTabHeaderClose={goBack}
          buttonIcon={require('@root/web/assets/icons/delete.svg')}
          buttonTitle={buttons.delete}
          buttonPress={() => setDeleteWarningModalVisible(true)}
        />
      );
    }

    return <CustomTabHeader title={string.addLocationTitle} onTabHeaderClose={goBack} />;
  };

  const renderInputForms = (
    handleChange: FormikHandleChangeType,
    values: FormikValuesType,
    errors: FormikErrors<FormikValuesType>,
    touched: FormikTouched<FormikValuesType>,
    setFieldTouched: FormikSetFieldTouchedType,
  ) => {
    return locationsFields.map((item) => {
      const key = item.valuesName === 'farmName' ? `${item.id}` : `${item.id}-${keyFillGeneralInfo}`;
      return (
        <CustomInputAdapter key={key} item={{ ...item, handleChange, values, errors, touched, setFieldTouched }} />
      );
    });
  };

  const renderFillInfo = (
    handleChange: FormikHandleChangeType,
    values: FormikValuesType,
    validateForm: (values?: any) => Promise<FormikErrors<FormikValuesType>>,
  ) => {
    if (isEditFarmModalScreen) {
      return null;
    }

    return (
      <div className={styles.fillGeneralInfoContainer}>
        <span className={styles.fillGeneralInfo} onClick={fillInfo(values, handleChange, validateForm)}>
          {string.fillInfo}
        </span>
      </div>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema(locationsFields)}
      validateOnMount
      validateOnChange
    >
      {({ handleChange, handleSubmit, values, errors, touched, setFieldTouched, isValid, validateForm }) => (
        <CustomTabContainer ref={ref} tabPositionFixed>
          {renderHeader()}
          <div className={styles.contentContainer}>
            <Scrollbars>
              <div className={styles.content}>
                {renderInputForms(handleChange, values, errors, touched, setFieldTouched)}
              </div>
            </Scrollbars>
          </div>
          <div className={styles.submitButtonContainer}>
            {renderFillInfo(handleChange, values, validateForm)}
            <CustomButton
              type={customButtonType.RED}
              title={isEditFarmModalScreen ? buttons.save : buttons.add}
              onPress={handleSubmit}
              disabled={isLoading || !isValid}
            />
          </div>
          {isStateModalVisible && (
            <ChooseStateModal
              isModalVisible={isStateModalVisible}
              setModalVisible={setStateModalVisible}
              setSelectedCityId={setSelectedCityId}
              setSelectedStateId={setSelectedStateId}
              setValue={handleChange('state')}
              setCityValue={handleChange('city')}
              ref={ref}
            />
          )}
          {isCityModalVisible && (
            <ChooseCityModal
              isModalVisible={isCityModalVisible}
              setModalVisible={setCityModalVisible}
              setSelectedCityId={setSelectedCityId}
              selectedStateId={selectedStateId}
              setValue={handleChange('city')}
              ref={ref}
            />
          )}
          {isLoading && <CustomLoader />}
          <WarningModal
            expression={`delete ${deleteFarmName}`}
            isModalVisible={isDeleteWarningVisible}
            setModalVisible={setDeleteWarningModalVisible}
            onApply={onPressDeleteFarm}
          />
        </CustomTabContainer>
      )}
    </Formik>
  );
};

import React, { useState } from 'react';
import { useStyles } from './styles';
import { useTransactionHistoryScreen } from '@root/hooks/useTransactionHistoryScreen';
import { useFlatListPagination } from '@root/hooks/useFlatListPagination';
import { transactionHistoryTab } from '@root/mobile/navigators/TransactionHistoryTopNavigator/types';
import { CustomStatusPicker } from '@root/web/components/CustomStatusPicker';
import { Grid } from '@material-ui/core';
import { paymentStatus } from '@root/general/consts';
import { PaymentType } from '@root/general/types';
import { TransactionCard } from './components/TransactionCard';
import { setSucceedPayment } from '@root/redux/actions';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { route } from '@root/web/consts';
import { ProfileScreensProps } from '@root/web/screens/ProfileScreen/types';
import { PaymentSystemScreen } from '@root/web/screens/ProfileScreen/components/PaymentSystemScreen';
import { CustomScrollComponent } from '@root/web/components/CustomScrollComponent';
import { strings } from '@root/strings';
import { PaymentListModal } from '@root/web/screens/ProfileScreen/components/TransactionHistory/components/PaymentListModal';

const { transactionHistoryScreen: string } = strings;

const transactionTabs = [
  { id: transactionHistoryTab.ALL, name: transactionHistoryTab.ALL },
  { id: transactionHistoryTab.OPEN, name: transactionHistoryTab.OPEN },
];

export const TransactionHistory = (props: ProfileScreensProps) => {
  const { tabMode, setTabMode } = props;
  const styles = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();

  const params = useParams<{ id?: string; profileParam?: string }>();

  const paymentId = Number(params?.id ?? '');

  const [transactionsTab, setTransactionsTab] = useState(transactionTabs[0].id);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedPayment, setSelectedPayment] = useState<PaymentType | null>(null);

  const [payments, lastPage, fetchAction, refreshAction, fetchAdditionAction] = useTransactionHistoryScreen(
    transactionsTab,
  );

  const [allPayments, isFlatListLoading, , , , onEndReached, setPayments, hasMore] = useFlatListPagination<PaymentType>(
    payments,
    lastPage,
    fetchAction,
    refreshAction,
    fetchAdditionAction,
  );

  const transactionHistorySuccessCallback = () => {
    const newPayments = payments.filter((payment) => {
      if (payment.id === paymentId) {
        dispatch(setSucceedPayment({ succeedPayment: { ...payment, status: paymentStatus.SUCCEEDED } }));
      }

      return payment.id !== paymentId;
    });
    setPayments(newPayments);
  };

  const paymentSystemGoBackCallback = () => {
    setTabMode(null);
    history.push(route.TRANSACTION_HISTORY);
  };

  const renderItem = (payment: PaymentType) => {
    const { id, status } = payment;

    const isFailed = status === paymentStatus.FAILED || status === paymentStatus.INCOMPLETE;

    const onCardPress = () => {
      if (transactionsTab === transactionHistoryTab.OPEN) {
        history.push(`${route.TRANSACTION_HISTORY}/${id}`);
      }

      const { jobs } = payment;
      const singleJob = jobs.length === 1;
      const isInstantPayment = singleJob && jobs[0].paymentType === 0;

      if (!isInstantPayment && transactionsTab === transactionHistoryTab.ALL) {
        setSelectedPayment(payment);
        setIsModalVisible(true);
      }
    };

    return (
      <Grid key={id || 0} item xs={12} md={tabMode ? 12 : 6} lg={tabMode ? 6 : 4} xl={tabMode ? 4 : 3}>
        <TransactionCard
          payment={payment}
          isSelected={id === Number(paymentId)}
          isFailed={isFailed}
          onPress={onCardPress}
        />
      </Grid>
    );
  };

  return (
    <>
      <CustomScrollComponent
        data={allPayments}
        renderItem={renderItem}
        isFlatListLoading={isFlatListLoading}
        onEndReached={onEndReached}
        hasMore={hasMore}
        defaultPrimaryText={string.sorryText}
        header={
          <CustomStatusPicker
            data={transactionTabs}
            setRespondedValue={setTransactionsTab}
            respondedValue={transactionsTab}
          />
        }
        containerStyle={styles.scrollComponent}
      />
      {!!tabMode && (
        <PaymentSystemScreen
          paymentId={Number(paymentId)}
          goBackCallback={paymentSystemGoBackCallback}
          transactionHistorySuccessCallback={transactionHistorySuccessCallback}
        />
      )}
      {isModalVisible && selectedPayment && (
        <PaymentListModal
          isModalVisible={isModalVisible}
          setModalVisible={setIsModalVisible}
          payment={selectedPayment}
        />
      )}
    </>
  );
};

import Tabs from '@material-ui/core/Tabs';
import React, { ChangeEvent, useEffect, useState } from 'react';
import Tab from '@material-ui/core/Tab';
import { useStyles } from './styles';
import { useHistory, useParams } from 'react-router-dom';
import { accountType } from '@root/general/consts';
import { useSelector } from 'react-redux';
import { getUser } from '@root/selectors';
import { ProfileInfo } from '@root/web/screens/ProfileScreen/components/ProfileInfo';
import { MyCards } from '@root/web/screens/ProfileScreen/components/MyCards';
import { TransactionHistory } from '@root/web/screens/ProfileScreen/components/TransactionHistory';
import { References } from '@root/web/screens/ProfileScreen/components/References';
import { ChangePassword } from '@root/web/screens/ProfileScreen/components/ChangePassword';
import { ProfileTopNavigatorProps } from '@root/web/navigators/ProfileTopNavigator/types';
import { MyLocations } from '@root/web/screens/ProfileScreen/components/MyLocations';
import { ChangeEmail } from '@root/web/screens/ProfileScreen/components/ChangeEmail';
import { DeleteAccount } from '@root/web/screens/ProfileScreen/components/DeleteAccount';

enum profileTab {
  info = 'INFORMATION',
  locations = 'MY LOCATIONS',
  cards = 'MY CARDS',
  transactions = 'TRANSACTION HISTORY',
  references = 'REFERENCES',
  changeEmail = 'CHANGE EMAIL',
  changePassword = 'CHANGE PASSWORD',
  deleteAccount = 'DELETE ACCOUNT',
}

export enum profileParam {
  info = '',
  locations = 'locations',
  cards = 'cards',
  transactions = 'transaction-history',
  references = 'references',
  changeEmail = 'change-email',
  changePassword = 'change-password',
  deleteAccount = 'delete-account',
}

const profileTabs = [
  { param: profileParam.info, name: profileTab.info, Component: ProfileInfo },
  { param: profileParam.locations, name: profileTab.locations, Component: MyLocations, role: accountType.EMPLOYER },
  { param: profileParam.cards, name: profileTab.cards, Component: MyCards, role: accountType.EMPLOYER },
  {
    param: profileParam.transactions,
    name: profileTab.transactions,
    Component: TransactionHistory,
    role: accountType.EMPLOYER,
  },
  { param: profileParam.references, name: profileTab.references, Component: References, role: accountType.LABORER },
  { param: profileParam.changeEmail, name: profileTab.changeEmail, Component: ChangeEmail },
  { param: profileParam.changePassword, name: profileTab.changePassword, Component: ChangePassword },
  { param: profileParam.deleteAccount, name: profileTab.deleteAccount, Component: DeleteAccount },
];

export const ProfileTopNavigator = (props: ProfileTopNavigatorProps) => {
  const { path = '', setTabMode } = props;

  const params: any = useParams();
  const profileParam = params?.profileParam ?? '';
  const styles = useStyles();
  const history = useHistory();
  const { roleId } = useSelector(getUser);

  const tabsData = profileTabs.filter((profileTab) => profileTab.role === roleId || !profileTab.role);
  const tabIndex = tabsData.findIndex((profileTab) => profileTab.param.toLowerCase() === profileParam?.toLowerCase());
  const initialTabIndex = tabIndex < 0 || !profileParam ? 0 : tabIndex;
  const [selectedTab, setSelectedTab] = useState(initialTabIndex);

  useEffect(() => {
    setSelectedTab(initialTabIndex);
  }, [setSelectedTab, initialTabIndex, roleId]);

  const handleChange = (event: ChangeEvent<{}>, tabIndex: number) => {
    setTabMode(null);
    setSelectedTab(tabIndex);
    history.push(tabIndex === 0 ? path : `${path}/${tabsData[tabIndex].param}`);
  };

  return (
    <div className={styles.container}>
      <Tabs
        value={selectedTab}
        variant='scrollable'
        onChange={handleChange}
        scrollButtons='auto'
        classes={{ indicator: styles.tabsIndicator }}
        className={styles.tabsContainer}
      >
        {tabsData.map(({ name }) => {
          return <Tab key={name} label={name} classes={{ root: styles.tabRoot }} />;
        })}
      </Tabs>
      {tabsData.map((profileTab, index) => {
        const { name, Component } = profileTab;
        return selectedTab === index && <Component key={name} {...props} />;
      })}
    </div>
  );
};

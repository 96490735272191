import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  confirmDeleteAccount,
  deleteAccount,
  fetchFieldReps,
  initialiseApp,
  resetPassword,
  restorePassword,
  setAuthHeader,
  signInUser,
  signOut,
  signUpUser,
} from '@root/redux/sagas/authSaga';
import { create } from '@root/services/api';
import { createFarm, deleteFarm, editFarm, fetchAdditionalFarms, fetchFarms } from '@root/redux/sagas/farmsSaga';
import { getCityById, getStates, searchCities } from '@root/redux/sagas/locationSaga';
import {
  addReviews,
  createJob,
  deleteJob,
  editJob,
  fetchAdditionalPostedJobs,
  fetchJob,
  fetchPostedJobs,
} from '@root/redux/sagas/postedJobsSaga';
import { fetchAdditionalJobListing, fetchJobListing } from '@root/redux/sagas/jobListingSaga';
import { createOffer, updateOffer } from '@root/redux/sagas/offerSaga';
import { fetchAdditionalLaborersByIndustry, fetchLaborersByIndustry } from '@root/redux/sagas/laborersSaga';
import {
  ADD_REFERENCE_REQUEST,
  ADD_REVIEWS_REQUEST,
  AUTH_INITIALISE,
  CHANGE_EMAIL_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  CONFIRM_CHANGE_EMAIL_REQUEST,
  CONFIRM_DELETE_ACCOUNT_REQUEST,
  CREATE_FARM_REQUEST,
  CREATE_NEW_JOB_REQUEST,
  CREATE_OFFER_REQUEST,
  DELETE_ACCOUNT_REQUEST,
  DELETE_FARM_REQUEST,
  DELETE_PAYMENT_CARD_REQUEST,
  DELETE_POSTED_JOB_REQUEST,
  DELETE_REFERENCE_REQUEST,
  EDIT_FARM_REQUEST,
  EDIT_NOTIFICATION_REQUEST,
  EDIT_POSTED_JOB_REQUEST,
  EDIT_REFERENCE_REQUEST,
  EDIT_USER_AVATAR_REQUEST,
  EDIT_USER_REQUEST,
  EDIT_USER_ROLE_REQUEST,
  FETCH_ADDITIONAL_FARMS_REQUEST,
  FETCH_ADDITIONAL_JOB_LISTING_REQUEST,
  FETCH_ADDITIONAL_JOB_REQUESTS_BY_INDUSTRY_REQUEST,
  FETCH_ADDITIONAL_LABORERS_BY_INDUSTRY_REQUEST,
  FETCH_ADDITIONAL_OFFERS_REQUEST,
  FETCH_ADDITIONAL_PAYMENTS_REQUEST,
  FETCH_ADDITIONAL_POSTED_JOBS_REQUEST,
  FETCH_ADDITIONAL_PROPOSALS_REQUEST,
  FETCH_ADDITIONAL_REFERENCES_REQUEST,
  FETCH_ADS_REQUEST,
  FETCH_CITIES_ON_MAP_REQUEST,
  FETCH_FARMS_REQUEST,
  FETCH_FIELD_REPS_REQUEST,
  FETCH_INDUSTRIES_REQUEST,
  FETCH_INDUSTRIES_WITH_SPECIFICATIONS_REQUEST,
  FETCH_JOB_LISTING_REQUEST,
  FETCH_JOB_REQUEST,
  FETCH_JOB_REQUESTS_BY_INDUSTRY_REQUEST,
  FETCH_JOBS_BY_OFFERS,
  FETCH_JOBS_ON_MAP_REQUEST,
  FETCH_LABORERS_BY_INDUSTRY_REQUEST,
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_OFFERS_REQUEST,
  FETCH_PAYMENT_CARDS_REQUEST,
  FETCH_PAYMENT_REQUEST,
  FETCH_PAYMENT_SECRET_REQUEST,
  FETCH_PAYMENTS_REQUEST,
  FETCH_POSTED_JOBS_REQUEST,
  FETCH_PROPOSALS_REQUEST,
  FETCH_REFERENCES_REQUEST,
  FETCH_SETUP_SECRET_REQUEST,
  FETCH_SPECIFICATIONS_REQUEST,
  FETCH_STATES_REQUEST,
  FETCH_USER_BY_ID_REQUEST,
  FETCH_USER_REQUEST,
  GET_CITY_BY_ID,
  PAY_FOR_JOB_REQUEST,
  REFRESH_FARMS_REQUEST,
  REFRESH_JOB_LISTING_REQUEST,
  REFRESH_JOB_REQUESTS_BY_INDUSTRY_REQUEST,
  REFRESH_LABORERS_BY_INDUSTRY_REQUEST,
  REFRESH_OFFERS_REQUEST,
  REFRESH_PAYMENTS_REQUEST,
  REFRESH_POSTED_JOBS_REQUEST,
  REFRESH_PROPOSALS_REQUEST,
  REFRESH_REFERENCES_REQUEST,
  RESET_PASSWORD_REQUEST,
  RESTORE_PASSWORD_REQUEST,
  SEARCH_CITIES,
  SET_AUTH_HEADER,
  SIGN_IN_REQUEST,
  SIGN_UP_REQUEST,
  UPDATE_DEFAULT_PAYMENT_CARD_REQUEST,
  UPDATE_OFFER_REQUEST,
  AUTH_SIGN_OUT,
} from '@root/general/consts/actions';
import { fetchAdditionalJobRequestsByIndustry, fetchJobRequestsByIndustry } from '@root/redux/sagas/jobRequestsSaga';
import { fetchAdditionalOffers, fetchOffers } from '@root/redux/sagas/jobOffersSaga';
import { fetchAdditionalProposalsByState, fetchProposalsByState } from '@root/redux/sagas/proposalsSaga';
import {
  changeEmail,
  changePassword,
  confirmChangeEmail,
  editAvatar,
  editUser,
  editUserRole,
  fetchUser,
  fetchUserById,
} from '@root/redux/sagas/userSaga';
import {
  fetchIndustries,
  fetchIndustriesWithSpecifications,
  fetchSpecifications,
} from '@root/redux/sagas/generalDataSaga';
import { fetchAds } from '@root/redux/sagas/adsSaga';
import { fetchJobsByOffers } from '@root/redux/sagas/jobsByOffersSaga';
import { fetchCitiesOnMap, fetchJobsOnMap } from '@root/redux/sagas/mapSaga';
import { editNotification, fetchNotifications } from '@root/redux/sagas/notificatonsSaga';
import {
  deletePaymentCard,
  fetchAdditionalPayments,
  fetchPayment,
  fetchPaymentCards,
  fetchPayments,
  fetchPaymentSecret,
  fetchSetupSecret,
  payForJob,
  setDefaultPaymentCard,
} from '@root/redux/sagas/paymentCardsSaga';
import {
  addReference,
  deleteReference,
  fetchAdditionalReferences,
  fetchReferences,
  updateReference,
} from '@root/redux/sagas/referencesSaga';

export const generalSagas = (baseUrl: string, appVersion = '') => {
  const api = create(baseUrl, appVersion);

  return [
    takeLatest(AUTH_INITIALISE, initialiseApp, api),
    takeLatest(SIGN_IN_REQUEST, signInUser, api),
    takeLatest(SIGN_UP_REQUEST, signUpUser, api),
    takeLatest(SET_AUTH_HEADER, setAuthHeader, api),
    takeLatest(RESET_PASSWORD_REQUEST, resetPassword, api),
    takeLatest(RESTORE_PASSWORD_REQUEST, restorePassword, api),
    takeLatest(AUTH_SIGN_OUT, signOut, api),

    takeLatest(FETCH_INDUSTRIES_REQUEST, fetchIndustries, api),
    takeLatest(FETCH_SPECIFICATIONS_REQUEST, fetchSpecifications, api),
    takeLatest(FETCH_INDUSTRIES_WITH_SPECIFICATIONS_REQUEST, fetchIndustriesWithSpecifications, api),

    takeLatest(FETCH_FARMS_REQUEST, fetchFarms, api),
    takeLatest(REFRESH_FARMS_REQUEST, fetchFarms, api),
    takeLatest(FETCH_ADDITIONAL_FARMS_REQUEST, fetchAdditionalFarms, api),

    takeLatest(CREATE_FARM_REQUEST, createFarm, api),
    takeLatest(DELETE_FARM_REQUEST, deleteFarm, api),
    takeLatest(EDIT_FARM_REQUEST, editFarm, api),

    takeLatest(FETCH_STATES_REQUEST, getStates, api),
    takeLatest(SEARCH_CITIES, searchCities, api),
    takeLatest(GET_CITY_BY_ID, getCityById, api),

    takeEvery(FETCH_POSTED_JOBS_REQUEST, fetchPostedJobs, api),
    takeLatest(REFRESH_POSTED_JOBS_REQUEST, fetchPostedJobs, api),
    takeLatest(FETCH_ADDITIONAL_POSTED_JOBS_REQUEST, fetchAdditionalPostedJobs, api),

    takeLatest(FETCH_JOB_REQUEST, fetchJob, api),
    takeLatest(CREATE_NEW_JOB_REQUEST, createJob, api),
    takeLatest(EDIT_POSTED_JOB_REQUEST, editJob, api),
    takeLatest(DELETE_POSTED_JOB_REQUEST, deleteJob, api),

    takeEvery(FETCH_JOB_LISTING_REQUEST, fetchJobListing, api),
    takeLatest(REFRESH_JOB_LISTING_REQUEST, fetchJobListing, api),
    takeLatest(FETCH_ADDITIONAL_JOB_LISTING_REQUEST, fetchAdditionalJobListing, api),

    takeLatest(FETCH_USER_REQUEST, fetchUser, api),
    takeLatest(FETCH_USER_BY_ID_REQUEST, fetchUserById, api),
    takeLatest(CHANGE_EMAIL_REQUEST, changeEmail, api),
    takeLatest(CONFIRM_CHANGE_EMAIL_REQUEST, confirmChangeEmail, api),
    takeLatest(CONFIRM_DELETE_ACCOUNT_REQUEST, confirmDeleteAccount, api),
    takeLatest(CHANGE_PASSWORD_REQUEST, changePassword, api),
    takeLatest(DELETE_ACCOUNT_REQUEST, deleteAccount, api),
    takeLatest(EDIT_USER_REQUEST, editUser, api),
    takeLatest(EDIT_USER_ROLE_REQUEST, editUserRole, api),
    takeLatest(EDIT_USER_AVATAR_REQUEST, editAvatar, api),
    takeLatest(FETCH_FIELD_REPS_REQUEST, fetchFieldReps, api),

    takeEvery(FETCH_LABORERS_BY_INDUSTRY_REQUEST, fetchLaborersByIndustry, api),
    takeLatest(REFRESH_LABORERS_BY_INDUSTRY_REQUEST, fetchLaborersByIndustry, api),
    takeLatest(FETCH_ADDITIONAL_LABORERS_BY_INDUSTRY_REQUEST, fetchAdditionalLaborersByIndustry, api),

    takeEvery(FETCH_JOB_REQUESTS_BY_INDUSTRY_REQUEST, fetchJobRequestsByIndustry, api),
    takeLatest(REFRESH_JOB_REQUESTS_BY_INDUSTRY_REQUEST, fetchJobRequestsByIndustry, api),
    takeLatest(FETCH_ADDITIONAL_JOB_REQUESTS_BY_INDUSTRY_REQUEST, fetchAdditionalJobRequestsByIndustry, api),

    takeEvery(FETCH_PROPOSALS_REQUEST, fetchProposalsByState, api),
    takeLatest(REFRESH_PROPOSALS_REQUEST, fetchProposalsByState, api),
    takeLatest(FETCH_ADDITIONAL_PROPOSALS_REQUEST, fetchAdditionalProposalsByState, api),

    takeEvery(FETCH_OFFERS_REQUEST, fetchOffers, api),
    takeLatest(REFRESH_OFFERS_REQUEST, fetchOffers, api),
    takeLatest(FETCH_ADDITIONAL_OFFERS_REQUEST, fetchAdditionalOffers, api),

    takeLatest(CREATE_OFFER_REQUEST, createOffer, api),
    takeLatest(UPDATE_OFFER_REQUEST, updateOffer, api),

    takeLatest(ADD_REVIEWS_REQUEST, addReviews, api),

    takeLatest(FETCH_ADS_REQUEST, fetchAds, api),

    takeLatest(FETCH_JOBS_BY_OFFERS, fetchJobsByOffers, api),

    takeLatest(FETCH_NOTIFICATIONS_REQUEST, fetchNotifications, api),
    takeLatest(EDIT_NOTIFICATION_REQUEST, editNotification, api),

    takeLatest(FETCH_CITIES_ON_MAP_REQUEST, fetchCitiesOnMap, api),
    takeLatest(FETCH_JOBS_ON_MAP_REQUEST, fetchJobsOnMap, api),

    takeLatest(FETCH_PAYMENT_CARDS_REQUEST, fetchPaymentCards, api),
    takeLatest(FETCH_PAYMENT_REQUEST, fetchPayment, api),
    takeLatest(UPDATE_DEFAULT_PAYMENT_CARD_REQUEST, setDefaultPaymentCard, api),
    takeLatest(DELETE_PAYMENT_CARD_REQUEST, deletePaymentCard, api),
    takeLatest(FETCH_SETUP_SECRET_REQUEST, fetchSetupSecret, api),
    takeLatest(FETCH_PAYMENT_SECRET_REQUEST, fetchPaymentSecret, api),
    takeLatest(PAY_FOR_JOB_REQUEST, payForJob, api),
    takeEvery(FETCH_PAYMENTS_REQUEST, fetchPayments, api),
    takeLatest(REFRESH_PAYMENTS_REQUEST, fetchPayments, api),
    takeLatest(FETCH_ADDITIONAL_PAYMENTS_REQUEST, fetchAdditionalPayments, api),

    takeLatest(ADD_REFERENCE_REQUEST, addReference, api),
    takeLatest(EDIT_REFERENCE_REQUEST, updateReference, api),
    takeLatest(DELETE_REFERENCE_REQUEST, deleteReference, api),
    takeLatest(FETCH_REFERENCES_REQUEST, fetchReferences, api),
    takeLatest(REFRESH_REFERENCES_REQUEST, fetchReferences, api),
    takeLatest(FETCH_ADDITIONAL_REFERENCES_REQUEST, fetchAdditionalReferences, api),
  ];
};

export const BASE_URL: string = process.env.API_HOST || '';
export const API_URL: string = `${BASE_URL}api/`;

export const GOOGLE_MAP_API_KEY = process.env.GOOGLE_MAP_API_KEY || '';
export const STRIPE_PUBLIC_KEY = process.env.STRIPE_PUBLIC_KEY || '';

export enum route {
  JOB_LISTING = '/joblisting',
  JOB_LISTING_MAP = '/joblisting-map',
  OFFERS = '/offers',
  REQUESTS = '/requests',
  JOBS = '/jobs',
  LABORERS = '/laborers',
  PROFILE = '/profile',
  CHANGE_EMAIL = '/profile/change-email',
  DELETE_ACCOUNT = '/profile/delete-account',
  RESET_PASSWORD = '/reset-password/:token',
  CONFIRM_DELETE_ACCOUNT = '/confirm-delete/:token',
  WELCOME = '/welcome-page',
  LOGIN = '/login',
  CHOOSE_ROLE = '/choose-role',
  REGISTRATION = '/registration',
  RESTORE_PASSWORD = '/restore-password',
  SUCCESSFUL_REGISTRATION = '/successful-registration',
  REFERENCES = '/profile/references',
  LOCATIONS = '/profile/locations',
  TRANSACTION_HISTORY = '/profile/transaction-history',
  CARDS = '/profile/cards',
  CONFIRM_EMAIL = '/confirm-email/:token',
}

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 4,
    justifyContent: 'center',
    backgroundColor: colors.athensGrayOpacity3,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  butlerImage: {
    height: '100vh',
    width: '80%',
    alignSelf: 'center',
  },
  logoImage: {
    width: '100%',
  },
}));

import React from 'react';
import { useStyles } from './styles';
import { LaborerCardProps } from '@root/general/types';
import { getRating } from '@root/utils/getRating';
import { OFFERS_BY_STATUS } from '@root/general/consts';
import { strings } from '@root/strings';
import { JobValue } from '@root/web/components/JobValue';
import { getLabelColorByOfferStatus } from '@root/mobile/utils/getLabelColorByOfferStatus';
import { colors } from '@root/general/colors';
import { CustomCardContainer } from '@root/web/components/CustomCardContainer';

const { laborerCard: string } = strings;

export type LaborerCardWebProps = { onPress: () => void; isSelected?: boolean } & LaborerCardProps;

export const LaborerCard = (props: LaborerCardWebProps) => {
  const styles = useStyles(props);

  const { name, jobName, rate, rating, offerStatus, isOfferFromUs, onPress, isSelected } = props;
  const { ratingValue, reviewsCount } = getRating(rating);

  const RenderStatusFlag = () => {
    const { name: statusName, color: statusColor } = getLabelColorByOfferStatus(offerStatus, isOfferFromUs, true);

    const [textColor, statusContainerBorder] =
      statusName === OFFERS_BY_STATUS.NEW.name || statusName === OFFERS_BY_STATUS.RECEIVED.name
        ? [colors.black, { borderColor: colors.black, borderWidth: 1 }]
        : [colors.white, {}];

    return (
      <span
        className={styles.statusText}
        style={{
          backgroundColor: statusColor,
          borderColor: textColor,
          color: textColor,
          ...statusContainerBorder,
        }}
      >
        {statusName}
      </span>
    );
  };
  return (
    <CustomCardContainer isSelected={isSelected} onPress={onPress}>
      <div className={styles.content}>
        <div className={styles.leftContainer}>
          <span className={styles.userName}>{name}</span>
          <div className={styles.ratingContainer}>
            <img src={require('@root/web/assets/icons/filledStar.svg')} alt='' />
            <span className={styles.ratingText}>{`${ratingValue}/5`}</span>
            <span className={styles.ratingCountText}>{` (${reviewsCount} ${string.ratings})`}</span>
          </div>
          <JobValue jobName={jobName} />
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.rateContainer}>
            <span className={styles.rateText}>{`$` + rate}</span>
            <span className={styles.perHour}>{string.perHour}</span>
          </div>
          {offerStatus && <RenderStatusFlag />}
        </div>
      </div>
    </CustomCardContainer>
  );
};

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles({
  container: {
    height: '100%',
    display: 'flex',
    flex: 1,
    backgroundColor: colors.lightGrey,
  },
});

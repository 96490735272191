import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '@root/selectors';
import { createOfferAction, editPostedJobAction, fetchJobAction, updateOfferAction } from '@root/redux/actions';
import { Job, Offer } from '@root/general/types';
import { JobDetailsCallbackProps } from '@root/mobile/screens/JobOffersScreen/types';
import { accountType, defaultJob, JOBS_STATUS, OFFER_STATUS } from '@root/general/consts';
import { EditJobData } from '@root/redux/reducers/types';

type UseJobDetailsModalScreenType = [
  Job,
  Dispatch<SetStateAction<Job>>,
  boolean,
  boolean,
  boolean,
  jobDetailsMode,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  (newJobStatusId: number) => void,
  (state: number, jobCallback?: (isSuccess: boolean) => void) => () => void,
];

export enum jobDetailsMode {
  init,
  apply,
  applied,
  offered,
  accepted,
  declined,
  cancelled,
}

export const useJobDetailsModalScreen = (
  jobId: number | null,
  goBackCallback: () => void,
  goToReviewLaborers: (setJob: Dispatch<SetStateAction<Job>>, job: Job) => () => void,
  jobDetailsCallback?: (jobCallbackProps: JobDetailsCallbackProps) => void,
  updateNotificationCallback?: () => void,
): UseJobDetailsModalScreenType => {
  const dispatch = useDispatch();

  const { id: userId, roleId } = useSelector(getUser);

  const isEmployer = roleId === accountType.EMPLOYER;

  const [isLoading, setLoading] = useState(false);
  const [jobStatusesModalVisible, setJobStatesModalVisible] = useState(false);

  const [job, setJob] = useState<Job>(defaultJob);
  const [offer, setOffer] = useState<Offer | null>(null);
  const [mode, setMode] = useState<jobDetailsMode>(jobDetailsMode.init);

  const { userId: jobUserId, reviewsCount, status: jobStatusId, isBlocked } = job;

  const isJobEditable =
    !isBlocked && (jobStatusId === JOBS_STATUS.OFFERED.id || jobStatusId === JOBS_STATUS.CONFIRMED.id);

  useEffect(() => {
    setLoading(true);

    dispatch(
      fetchJobAction({
        jobId,
        userId: !isEmployer ? userId : null, // for fetching offer
        callback: (isSuccess: boolean, job?: Job, offer?: Offer) => {
          if (isSuccess) {
            job && setJob(job);
            offer && setOffer(offer);
            updateNotificationCallback && updateNotificationCallback();
          }
          setLoading(false);
        },
      }),
    );
  }, [dispatch, jobId, userId, isEmployer, updateNotificationCallback]);

  useEffect(() => {
    if (jobUserId) {
      if (offer) {
        switch (offer.status) {
          case OFFER_STATUS.SENT.id:
            return setMode(offer.userId === jobUserId ? jobDetailsMode.applied : jobDetailsMode.offered);
          case OFFER_STATUS.ACCEPTED.id:
            return setMode(jobDetailsMode.accepted);
          case OFFER_STATUS.DECLINED.id:
            return setMode(jobDetailsMode.declined);
          case OFFER_STATUS.CANCELLED.id:
            return setMode(jobDetailsMode.cancelled);
        }
      } else {
        setMode(jobDetailsMode.apply);
      }
    }
  }, [jobUserId, offer]);

  const setNewJobStatusId = (newJobStatusId: number) => {
    setLoading(true);

    const editedJob: EditJobData = {
      id: job.id,
      userId: jobUserId,
      farmId: job.farmId,
      industryId: job.industryId,
      specificationId: job.specificationId,
      name: job.name,
      startDate: job.startDate,
      endDate: job.endDate,
      rate: job.rate,
      rateTypeId: job.rateTypeId,
      peopleAmount: job.peopleAmount,
      description: job.description,
      status: newJobStatusId,
      payment_type: job.paymentType,
    };

    dispatch(
      editPostedJobAction({
        data: editedJob,
        callback: (isSuccess: boolean, job?: Job) => {
          if (isSuccess && job) {
            setJob(job);
            if (newJobStatusId === JOBS_STATUS.COMPLETED.id && reviewsCount === 0) {
              goToReviewLaborers(setJob, job)();
            }
          }
          setLoading(false);
        },
      }),
    );
  };

  const onPressJobAction = (offerStatusId: number) => () => {
    const createOfferCallback = (isSuccess: boolean, createdOfferId?: number | null) => {
      if (jobDetailsCallback) {
        jobDetailsCallback({
          jobId,
          offerId: createdOfferId ? createdOfferId : offer?.id,
          offerStatusId,
          navigationCallback: goBackCallback,
          isSuccess,
        });
      } else {
        //appeared in the screen from push notifications
        isSuccess && goBackCallback();
      }
      setLoading(false);
    };

    const updateOfferCallback = (offerId: number) => (isSuccess: boolean) => {
      createOfferCallback(isSuccess, offerId);
    };

    setLoading(true);
    if (mode === jobDetailsMode.apply) {
      dispatch(createOfferAction({ jobId, userId: jobUserId, callback: createOfferCallback }));
    } else if (offer) {
      const { id: offerId } = offer;

      dispatch(
        updateOfferAction({
          offerId,
          offerStatusId,
          callback: updateOfferCallback(offerId),
        }),
      );
    }
  };

  return [
    job,
    setJob,
    isEmployer,
    isLoading,
    isJobEditable,
    mode,
    jobStatusesModalVisible,
    setJobStatesModalVisible,
    setNewJobStatusId,
    onPressJobAction,
  ];
};

import { call, put, select } from 'redux-saga/effects';
import { Api, CustomApiResponse } from '@root/services/api';
import { ErrorResponse, JobArray, OfferArray } from '@root/general/types';
import {
  CALL_COMMON_ERROR_HANDLER,
  CALL_UNEXPECTED_ERROR_HANDLER,
  FETCH_JOB_LISTING_SUCCESS,
} from '@root/general/consts/actions';
import { FetchAdditionalJobListingAction, FetchJobListingAction } from '@root/redux/actions/types';
import { isArray } from 'lodash';
import { getJobListingWithOfferState } from '@root/mobile/utils/getJobListingWithOfferState';
import { getUser } from '@root/selectors';

export function* fetchJobListing(api: Api, action: FetchJobListingAction) {
  const { id, ownerId, callback, filter } = action.payload;

  try {
    const { id: userId } = yield select(getUser);

    const response: CustomApiResponse<JobArray, ErrorResponse> = yield call(api.fetchJobListings, id, filter, 1);

    if (response.ok && isArray(response.data)) {
      const jobIdsArray = response.data.map((job) => job.id);

      const additionalResponse: CustomApiResponse<OfferArray, ErrorResponse> = yield call(
        api.fetchUserOffersByJobIds,
        ownerId,
        jobIdsArray,
      );

      if (additionalResponse.ok && additionalResponse.data) {
        yield put({
          type: FETCH_JOB_LISTING_SUCCESS,
          payload: {
            id,
            jobs: getJobListingWithOfferState(response.data, additionalResponse.data, userId),
            lastPage: response.meta?.lastPage ?? 1,
          },
        });
      }

      if (!additionalResponse.ok) {
        yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: additionalResponse });
      }
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  } finally {
    yield callback && call(callback);
  }
}

export function* fetchAdditionalJobListing(api: Api, action: FetchAdditionalJobListingAction) {
  const { id, ownerId, filter, page, callback } = action.payload;

  try {
    const { id: userId } = yield select(getUser);

    const response: CustomApiResponse<JobArray, ErrorResponse> = yield call(api.fetchJobListings, id, filter, page);

    if (response.ok && isArray(response.data)) {
      const jobIdsArray = response.data.map((job) => job.id);

      const additionalResponse: CustomApiResponse<OfferArray, ErrorResponse> = yield call(
        api.fetchUserOffersByJobIds,
        ownerId,
        jobIdsArray,
      );

      if (additionalResponse.ok && additionalResponse.data) {
        yield callback &&
          call(callback, response.ok, getJobListingWithOfferState(response.data, additionalResponse.data, userId));
      }

      if (!additionalResponse.ok) {
        yield callback && call(callback, false);
        yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: additionalResponse });
      }
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '@root/general/fonts';
import { colors } from '@root/general/colors';
import { PaymentCardProps } from '@root/web/screens/ProfileScreen/components/MyCards/components/PaymentCard/index';

export const useStyles = makeStyles(() => ({
  containerClasses: ({ isSelected }: PaymentCardProps) => {
    return {
      backgroundColor: colors.bananaMania,
      borderColor: colors.darkGreyOpacity5,
      borderWidth: isSelected ? 2 : 0,
    };
  },
  cardNumberContainer: {
    display: 'flex',
    flex: 1,
    marginBottom: 70,
  },
  dots: {
    fontSize: 18,
    fontFamily: fonts.IBMPlexMonoBold,
    lineHeight: '20px',
    marginRight: 12,
    whiteSpace: 'nowrap',
  },
  cardNumberText: {
    fontSize: 15,
    fontFamily: fonts.IBMPlexMonoMedium,
    lineHeight: '22px',
    letterSpacing: 3,
  },
  cardInformationContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  dateLabelText: {
    fontSize: 10,
    fontFamily: fonts.IBMPlexMonoMedium,
    textTransform: 'uppercase',
  },
  dateText: {
    fontSize: 13,
    fontFamily: fonts.IBMPlexMonoMedium,
    lineHeight: '22px',
    textTransform: 'uppercase',
  },
}));

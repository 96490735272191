import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    padding: '30px 20px',
    borderColor: colors.athensGray,
    borderWidth: '0px 1px 1px',
    borderStyle: 'solid',
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    backgroundColor: colors.white,
  },
  containerPadding: {
    paddingRight: 20,
  },
  warnText: {
    fontFamily: fonts.SFUiDisplayMedium,
    fontSize: 22,
    letterSpacing: -0.24,
    textAlign: 'center',
    marginBottom: 7,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
}));

import { JobDetailsCallbackProps } from '@root/mobile/screens/JobOffersScreen/types';
import { Offer } from '@root/general/types';
import { OFFERS_BY_STATUS } from '@root/general/consts';
import { Dispatch, SetStateAction } from 'react';

export const jobDetailsCallback = (
  jobOffers: Array<Offer>,
  offersFilterName: string,
  setOffers: Dispatch<SetStateAction<Array<Offer>>>,
) => (jobCallbackProps: JobDetailsCallbackProps) => {
  const { isSuccess, offerStatusId, offerId, navigationCallback } = jobCallbackProps;

  if (isSuccess) {
    const filteredOffers = jobOffers.filter((offer: Offer) => {
      if (offer.id === offerId) {
        offer.status = offerStatusId;
      }

      return offer.id !== offerId || offersFilterName === OFFERS_BY_STATUS.ALL.name;
    });

    setOffers(filteredOffers);
    navigationCallback();
  }
};

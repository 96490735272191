import React, { useEffect, useState } from 'react';
import { useUserProfileScreen } from '@root/hooks/useUserProfileScreen';
import { useOnLoginSuccessWeb } from '@root/web/hooks/useOnLoginSuccessWeb';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { CustomModal } from '@root/web/components/CustomModal/CustomModal';
import { CustomInputAdapter } from '@root/web/components/CustomInput/customInputAdapter';
import { CustomModalWithSections } from '@root/web/components/CustomModal/CustomModalWithSections';
import { validationSchema } from '@root/mobile/utils/validationSchema';
import { ToastContainer } from 'react-toastify';
import {
  FormikHandleChangeType,
  FormikHandleSubmit,
  FormikSetFieldTouchedType,
  FormikValuesType,
} from '@root/mobile/types/inputTypes';
import { strings } from '@root/strings';
import { accountType, privacyPolicyScreenMode, WEB_NOTIFICATION_TIMEOUT } from '@root/general/consts';
import 'react-toastify/dist/ReactToastify.css';
import { useStyles } from './styles';
import { AuthHeader } from '@root/web/components/AuthHeader';
import { AuthFooter } from '@root/web/components/AuthFooter';
import { RightImageContainer } from '@root/web/components/RightImageContainer';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { CustomSwitcher } from '@root/web/components/CustomSwitcher';
import { Grid } from '@material-ui/core';
import { ChooseStateModal } from '@root/web/components/ChooseStateModal';
import { ChooseCityModal } from '@root/web/components/ChooseCityModal';
import { showToastAction, ToastTypes } from '@root/redux/actions/commonActions';
import { useDispatch } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { CustomLoader } from '@root/web/components/CustomLoader';
import { route } from '@root/web/consts';
import { TermsAndConditions } from './components/TermsAndConditions';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { PrivacyPolicyModal } from '@root/web/components/PrivacyPolicyModal';
import { PickerMenu } from '@root/web/components/PickerMenu';

const { buttons: button, registrationScreen: string, userProfileActionsScreen, warnings } = strings;

export const RegistrationScreen = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  let history = useHistory();
  const styles = useStyles();

  //@ts-ignore
  const roleType = location.state?.accountType ?? accountType.EMPLOYER;

  const [isStateModalVisible, setStateModalVisible] = useState(false);
  const [isCityModalVisible, setCityModalVisible] = useState(false);
  const [webLoading, setWebLoading] = useState(true);

  const onStatePress = () => () => {
    setStateModalVisible(true);
  };

  const onCityPress = (selectedStateId: number | null) => () => {
    if (selectedStateId) {
      setCityModalVisible(true);
    } else {
      dispatch(showToastAction({ message: warnings.selectState, type: ToastTypes.Error }));
    }
  };

  const [
    formFields,
    formInitialValues,
    formHandleSubmit,
    selectedIndustryIds,
    setSelectedIndustryIds,
    selectedSpecificationIds,
    setSelectedSpecificationIds,
    industryModalVisible,
    setIndustryModalVisible,
    specificationsModalVisible,
    setSpecificationsModalVisible,
    allIndustries,
    specificationsByIndustries,
    specificationsValidation,
    formikRef,
    isLoading,
    setCurrentRole,
    termsAndConditions,
    setTermsAndConditions,
    currentRole,
    setSelectedStateId,
    setSelectedCityId,
    selectedStateId,
    privacyPolicy,
    setPrivacyPolicy,
    fieldRepModalVisible,
    setFieldRepModalVisible,
    fieldReps,
    handleSetFieldRepId,
    formFieldsValidation,
    getFieldRepId,
  ] = useUserProfileScreen(undefined, onStatePress, onCityPress);
  const [onLoginSuccess] = useOnLoginSuccessWeb();

  useEffect(() => {
    setCurrentRole(roleType);
    setWebLoading(false);
  }, [roleType, setCurrentRole]);

  const goToLogin = () => {
    history.push(route.LOGIN, location.state);
  };

  const onSwitchPress = () => {
    currentRole === accountType.LABORER ? setCurrentRole(accountType.EMPLOYER) : setCurrentRole(accountType.LABORER);
  };

  const toggleTermsAndConditions = () => {
    setTermsAndConditions(!termsAndConditions);
  };

  const togglePrivacyPolicy = () => {
    setPrivacyPolicy(!privacyPolicy);
  };

  const [privacyPolicyModalMode, setPrivacyPolicyModalMode] = useState<privacyPolicyScreenMode | null>(null);
  const navigateToPrivacyPolicy = (mode: privacyPolicyScreenMode) => {
    setPrivacyPolicyModalMode(mode);
  };

  const renderInputForms = (
    handleChange: FormikHandleChangeType,
    values: FormikValuesType,
    errors: FormikErrors<FormikValuesType>,
    touched: FormikTouched<FormikValuesType>,
    setFieldTouched: FormikSetFieldTouchedType,
  ) => {
    return (
      <Grid container>
        {formFields.map((item) => {
          const { valuesName } = item;

          switch (valuesName) {
            case 'fieldRep':
              return (
                <Grid item sm={item?.sm ?? 6} xs={12} key={item.id} className={styles.containerPadding}>
                  <PickerMenu
                    key={item.id}
                    title={item.title}
                    data={fieldReps}
                    value={getFieldRepId()}
                    handleChange={handleChange('fieldRep')}
                    setValue={handleSetFieldRepId}
                    isOpen={fieldRepModalVisible}
                    onOpen={item.onPress}
                    onClose={() => setFieldRepModalVisible(false)}
                    fullWidth={true}
                  />
                </Grid>
              );
            default:
              return (
                <Grid item sm={item?.sm ?? 6} xs={12} key={item.id} className={styles.containerPadding}>
                  <CustomInputAdapter item={{ ...item, handleChange, values, errors, touched, setFieldTouched }} />
                </Grid>
              );
          }
        })}
      </Grid>
    );
  };

  const renderFooter = (handleSubmit: FormikHandleSubmit, disabled: boolean) => {
    return (
      <Grid container justify={'space-between'} alignItems={'center'} className={styles.containerPadding}>
        <TermsAndConditions isChecked={termsAndConditions} toggleCheckBox={toggleTermsAndConditions} />
        <PrivacyPolicy
          toggleCheckBox={togglePrivacyPolicy}
          isChecked={privacyPolicy}
          navigateToPrivacyPolicy={navigateToPrivacyPolicy}
        />
        <Grid item>
          <span>{string.member}</span>
          <CustomButton type={customButtonType.TEXT} title={button.login} onPress={goToLogin} />
        </Grid>
        <Grid item xs={3} md={2}>
          <CustomButton
            type={customButtonType.RED}
            title={button.register}
            disabled={disabled}
            onPress={handleSubmit}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={styles.container}>
      <Scrollbars className={styles.scrollbar} autoHeight autoHeightMax={'100vh'}>
        {webLoading ? (
          <CustomLoader />
        ) : (
          <div className={styles.leftContainer}>
            <AuthHeader createNewAccountVisible={false} />
            <Formik
              initialValues={formInitialValues}
              onSubmit={(data) => formHandleSubmit(data, onLoginSuccess)}
              validationSchema={validationSchema(formFieldsValidation, specificationsValidation)}
              validateOnMount={true}
              innerRef={formikRef}
            >
              {({ handleChange, handleSubmit, values, errors, isValid, touched, setFieldTouched }) => (
                <div className={styles.main}>
                  <span className={styles.title}>{string.screenTitle}</span>
                  <CustomSwitcher accountTypeValue={currentRole} onPress={onSwitchPress} />
                  <div>
                    {renderInputForms(handleChange, values, errors, touched, setFieldTouched)}
                    {renderFooter(handleSubmit, isLoading || !isValid || !termsAndConditions || !privacyPolicy)}
                  </div>
                  <CustomModal
                    setValue={handleChange('industry')}
                    isModalVisible={industryModalVisible}
                    setModalVisible={setIndustryModalVisible}
                    data={allIndustries}
                    selectedItemIds={selectedIndustryIds}
                    setSelectedItemIds={setSelectedIndustryIds}
                    setDependentValue={handleChange('specifications')}
                    dependentItem={selectedSpecificationIds}
                    setDependentItem={setSelectedSpecificationIds}
                    headerTitle={userProfileActionsScreen.industry}
                  />
                  <CustomModalWithSections
                    setValue={handleChange('specifications')}
                    isModalVisible={specificationsModalVisible}
                    setModalVisible={setSpecificationsModalVisible}
                    sections={specificationsByIndustries}
                    selectedItemIds={selectedSpecificationIds}
                    setSelectedItemIds={setSelectedSpecificationIds}
                    headerTitle={userProfileActionsScreen.specifications}
                  />
                  {isStateModalVisible && (
                    <ChooseStateModal
                      isModalVisible={isStateModalVisible}
                      setModalVisible={setStateModalVisible}
                      setSelectedCityId={setSelectedCityId}
                      setSelectedStateId={setSelectedStateId}
                      setValue={handleChange('state')}
                      setCityValue={handleChange('city')}
                    />
                  )}
                  {isCityModalVisible && (
                    <ChooseCityModal
                      isModalVisible={isCityModalVisible}
                      setModalVisible={setCityModalVisible}
                      setSelectedCityId={setSelectedCityId}
                      selectedStateId={selectedStateId}
                      setValue={handleChange('city')}
                    />
                  )}
                  {privacyPolicyModalMode && (
                    <PrivacyPolicyModal mode={privacyPolicyModalMode} setModalMode={setPrivacyPolicyModalMode} />
                  )}
                </div>
              )}
            </Formik>
            <AuthFooter className={styles.containerPadding} />
          </div>
        )}
      </Scrollbars>
      <ToastContainer autoClose={WEB_NOTIFICATION_TIMEOUT} />
      <RightImageContainer />
    </div>
  );
};

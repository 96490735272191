import { combineReducers } from 'redux';
import { authReducer } from '@root/redux/reducers/authReducer';
import { locationReducer } from '@root/redux/reducers/locationReducer';
import { farmsReducer } from './farmsReducer';
import { postedJobsReducer } from './postedJobsReducer';
import { industriesReducer } from './industriesReducer';
import { jobListingReducer } from '@root/redux/reducers/jobListingReducer';
import { laborersReducer } from './laborersReducer';
import { mapFilterReducer } from '@root/redux/reducers/mapFilterReducer';
import { jobRequestsReducer } from '@root/redux/reducers/jobRequestsReducer';
import { jobOffersReducer } from './jobOffersReducer';
import { proposalsReducer } from './proposalsReducer';
import { paymentCardsReducer } from '@root/redux/reducers/paymentCardsReducer';
import { referencesReducer } from '@root/redux/reducers/referencesReducer';
import { navigationBarReducer } from '@root/redux/reducers/navigationBarReducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  farms: farmsReducer,
  location: locationReducer,
  postedJobs: postedJobsReducer,
  industries: industriesReducer,
  jobListing: jobListingReducer,
  laborers: laborersReducer,
  mapFilter: mapFilterReducer,
  jobRequests: jobRequestsReducer,
  jobOffers: jobOffersReducer,
  proposals: proposalsReducer,
  paymentCards: paymentCardsReducer,
  references: referencesReducer,
  navigationBar: navigationBarReducer,
});

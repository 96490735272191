import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import { useStyles } from './styles';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { strings } from '@root/strings';
import { useHistory } from 'react-router-dom';
import { MapOptions, Maps } from 'google-map-react';
import { colors } from '@root/general/colors';

const { buttons } = strings;

export const GOOGLE_MAP_ZOOM = 13;

export const getMapOptions: (maps: Maps) => MapOptions = () => {
  return {
    disableDefaultUI: true,
    gestureHandling: 'greedy',
    //@ts-ignore
    restriction: { latLngBounds: { north: 85, south: -85, west: -180, east: 180 } },
    backgroundColor: colors.lightGrey,
  };
};

type MapControls = {
  zoom: number;
  setZoom: (zoom: number) => void;
  onLocationPress: () => void;
};

export const MapControls = (props: MapControls) => {
  const styles = useStyles();
  const history = useHistory();

  const { zoom, setZoom, onLocationPress } = props;

  const onZoomIn = () => {
    setZoom(zoom + 1);
  };

  const onZoomOut = () => {
    setZoom(zoom - 1);
  };

  return (
    <>
      <div className={styles.mapControlsContainer}>
        <IconButton className={clsx(styles.mapControlButton, styles.mapCenterButton)} onClick={onLocationPress}>
          <img src={require('@root/web/assets/icons/mapCenter.svg')} alt={''} />
        </IconButton>
        <IconButton className={clsx(styles.mapControlButton, styles.mapZoomInButton)} onClick={onZoomIn}>
          <img src={require('@root/web/assets/icons/mapZoomIn.svg')} alt={''} />
        </IconButton>
        <IconButton className={styles.mapControlButton} onClick={onZoomOut}>
          <img src={require('@root/web/assets/icons/mapZoomOut.svg')} alt={''} />
        </IconButton>
      </div>
      <CustomButton
        type={customButtonType.RED}
        className={styles.mapGoBackButton}
        title={buttons.backToList}
        onPress={history.goBack}
        icon={require('@root/web/assets/icons/goBack.svg')}
        iconClassName={styles.mapGoBackIcon}
        maxWidth={180}
        ellipse
      />
    </>
  );
};

import { Api, CustomApiResponse } from '@root/services/api';
import { CreateOfferAction, UpdateOfferAction } from '@root/redux/actions/types';
import { ErrorResponse, MessagesResponse, Offer, PaymentsArray } from '@root/general/types';
import { call, put, select, delay } from 'redux-saga/effects';
import { commonNotificationHandler } from '@root/redux/sagas/helpers';
import { CALL_COMMON_ERROR_HANDLER, CALL_UNEXPECTED_ERROR_HANDLER } from '@root/general/consts/actions';
import { accountType, OFFER_STATUS } from '@root/general/consts';
import { getUser } from '@root/selectors';

const DEFAULT_PAGE = 1;

export function* createOffer(api: Api, action: CreateOfferAction) {
  const { jobId, userId, callback } = action.payload;

  try {
    const response: CustomApiResponse<Offer & MessagesResponse, ErrorResponse> = yield call(
      api.createOffer,
      jobId,
      userId,
    );

    if (response.ok && response.data) {
      yield call(callback, true, response.data.id);
      yield commonNotificationHandler(response.messages);
    }

    if (!response.ok) {
      yield call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

const DELAY_FOR_THE_PAYMENT_PROCESSING_MS = 4000;

export function* updateOffer(api: Api, action: UpdateOfferAction) {
  const { offerId, offerStatusId, callback } = action.payload;

  try {
    const response: CustomApiResponse<Offer, ErrorResponse> = yield call(api.updateOffer, offerId, offerStatusId);

    if (response.ok && response.data) {
      const { roleId } = yield select(getUser);

      if (offerStatusId === OFFER_STATUS.ACCEPTED.id && roleId === accountType.EMPLOYER) {
        yield delay(DELAY_FOR_THE_PAYMENT_PROCESSING_MS);

        const paymentsResponse: CustomApiResponse<PaymentsArray, ErrorResponse> = yield call(api.fetchPayments, {
          page: DEFAULT_PAGE,
          offerId,
        });

        if (paymentsResponse.ok && paymentsResponse.data) {
          const recentPayment = paymentsResponse.data?.find((paymentItem) => {
            return paymentItem.offers?.find((offer) => offer.id === offerId);
          });

          yield callback && call(callback, true, recentPayment);
        }

        if (!paymentsResponse.ok) {
          yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
          yield callback && call(callback, false);
        }
      } else {
        yield commonNotificationHandler(response.messages);
        yield callback && call(callback, true);
      }
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
      yield callback && call(callback, false);
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

import { Offer } from '@root/general/types';
import { ActionType, AdditionActionType } from '@root/hooks/useFlatListPagination';
import { useSelector } from 'react-redux';
import { getJobRequestsByIndustryId } from '@root/selectors';
import { useCallback } from 'react';
import {
  fetchAdditionalJobRequestsByIndustryAction,
  fetchJobRequestsByIndustryAction,
  refreshJobRequestsByIndustryAction,
} from '@root/redux/actions';

type UseJobRequestsScreenReturnType = [Array<Offer>, number, ActionType, ActionType, AdditionActionType<Offer>];

export const useJobRequestsScreen = (industryId: number): UseJobRequestsScreenReturnType => {
  const industryJobRequests = useSelector(getJobRequestsByIndustryId(industryId));
  const { jobRequests, lastPage } = industryJobRequests;

  const fetchAction = useCallback(
    (fetchCallback: () => void) => {
      return fetchJobRequestsByIndustryAction({ industryId, callback: fetchCallback });
    },
    [industryId],
  );

  const refreshAction = useCallback(
    (refreshCallback: () => void) => {
      return refreshJobRequestsByIndustryAction({ industryId, callback: refreshCallback });
    },
    [industryId],
  );

  const fetchAdditionAction = useCallback(
    (page: number, fetchAdditionCallback: (isSuccess: boolean, additionalItems: any) => void) => {
      return fetchAdditionalJobRequestsByIndustryAction({ industryId, page, callback: fetchAdditionCallback });
    },
    [industryId],
  );

  return [jobRequests, lastPage, fetchAction, refreshAction, fetchAdditionAction];
};

import { Api, CustomApiResponse } from '@root/services/api';
import { call, put } from 'redux-saga/effects';
import { ArrayAds, ErrorResponse } from '@root/general/types';
import { CALL_COMMON_ERROR_HANDLER, CALL_UNEXPECTED_ERROR_HANDLER } from '@root/general/consts/actions';
import { isArray } from 'lodash';
import { FetchAdsAction } from '@root/redux/actions/types';

export function* fetchAds(api: Api, action: FetchAdsAction) {
  const { screenType, callback } = action.payload;

  try {
    const response: CustomApiResponse<ArrayAds, ErrorResponse> = yield call(api.fetchAds, screenType);

    if (response.ok && isArray(response.data)) {
      yield callback && call(callback, response.ok, response.data);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

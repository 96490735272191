import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flex: 1,
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    markerIcon: {
      transform: 'translate(-50%, -50%)',
      marginTop: -20,
    },
  };
});

import React from 'react';
import { UserInfoProps } from '@root/general/types';
import { InfoContainer } from '@root/web/components/InfoContainer';
import { UserHeader } from './UserHeader';

type UserInfoWebProps = { inlineHeader?: boolean } & UserInfoProps;

export const UserInfo = (props: UserInfoWebProps) => {
  const { user, userInfoData, isAccepted, isLaborer, inlineHeader, isLoading } = props;
  const { name, avatar = null, rating, isApproved } = user;

  return (
    <div>
      <UserHeader
        name={name}
        avatar={avatar}
        rating={rating}
        isLaborer={isLaborer}
        isApproved={isApproved}
        isAccepted={isAccepted}
        inline={inlineHeader}
        isLoading={isLoading}
      />
      {userInfoData.map((userInfoField) => {
        const { id, label, value, valueElement, iconWeb, multiline, hiddenValue } = userInfoField;

        const valueText = value ? value : hiddenValue || '';

        return (
          <InfoContainer
            key={id}
            label={label}
            value={valueText}
            iconSource={iconWeb}
            multiline={multiline}
            valueElement={valueElement}
          />
        );
      })}
    </div>
  );
};

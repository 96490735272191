import { ArrayPaymentMethod, CityType, FarmType, GeneralUser, Job, MapFilter, StateType } from '@root/general/types';
import { colors } from '@root/general/colors';
import { strings } from '@root/strings';
import { CSSProperties } from 'react';

export const GENERAL_HEADERS: { [key: string]: string } = { 'content-type': 'application/json' };
export const TIMEOUT: number = 5000;
export const WEB_NOTIFICATION_TIMEOUT: number = 4000;
export const FETCH_THROTTLE_TIME = 5000;
export const FETCH_DEBOUNCE_TIME = 400;
export const modalStyle: CSSProperties = { position: 'absolute' };

export const MAX_AVATAR_SIZE = 1024 * 1024 * 8; // 8MB

export const TERMS_AND_PRIVACY_URL = 'https://www.ftc.gov/enforcement/statutes/childrens-online-privacy-protection-act';

export const SUPPORT_EMAIL = 'support@agbutlerapp.org';

export const PRIVACY_POLICY = 'storage/privacy-policy.pdf';
export const TERMS_OF_SERVICE = 'storage/terms-of-service.pdf';

export const WEB = 'web';

export enum deviceType {
  mobile,
  web,
}

export enum accountType {
  LABORER = 3,
  EMPLOYER = 2,
}

export const radius = {
  fiveMiles: '5 miles',
  tenMiles: '10 miles',
  fifteenMiles: '15 miles',
  thirtyMiles: '30 miles',
  fiftyMiles: '50 miles',
  oneHundredMiles: '100 miles',
  twoHundredAndFiftyMiles: '250 miles',
  fiveHundredMiles: '500 miles',
};

export const JOBS_STATUS = {
  OFFERED: { id: 1, name: 'OFFERED', color: colors.confetti },
  CONFIRMED: { id: 2, name: 'CONFIRMED', color: colors.celery },
  IN_PROGRESS: { id: 3, name: 'IN PROGRESS', color: colors.apricot },
  COMPLETED: { id: 4, name: 'COMPLETED', color: colors.athensGray },
  CANCELLED: { id: 5, name: 'CANCELLED', color: colors.darkRed },
};

export const OFFER_STATUS = {
  SENT: { id: 1, name: 'SENT' },
  ACCEPTED: { id: 3, name: 'ACCEPTED' },
  DECLINED: { id: 2, name: 'DECLINED' },
  CANCELLED: { id: 4, name: 'CANCELLED' }, // cancelled by Laborer or when EMPLOYER change job state to  CONFIRMED,IN_PROGRESS,COMPLETED,CANCELLED all offers in status SENT changed to CANCELLED by server
};

export enum offersType {
  offers = 'offers',
  incoming = 'incoming-offers',
  outgoing = 'outgoing-offers',
}

export const OFFERS_BY_STATUS = {
  ALL: { id: 0, name: 'ALL', offerStatusId: 0 },
  NEW: { id: 1, name: 'NEW', offerStatusId: OFFER_STATUS.SENT.id },
  RECEIVED: { id: 2, name: 'RECEIVED', offerStatusId: OFFER_STATUS.SENT.id },
  SENT: { id: 3, name: 'SENT', offerStatusId: OFFER_STATUS.SENT.id },
  APPLIED: { id: 4, name: 'APPLIED', offerStatusId: OFFER_STATUS.SENT.id },
  ACCEPTED: { id: 5, name: OFFER_STATUS.ACCEPTED.name, offerStatusId: OFFER_STATUS.ACCEPTED.id },
  DECLINED: { id: 6, name: OFFER_STATUS.DECLINED.name, offerStatusId: OFFER_STATUS.DECLINED.id },
  CANCELLED: { id: 7, name: OFFER_STATUS.CANCELLED.name, offerStatusId: OFFER_STATUS.CANCELLED.id },
};

export enum photoActionType {
  ADD,
  REMOVE,
}

export const defaultState: StateType = {
  id: 0,
  name: '',
  abbreviation: '',
  createdAt: '',
  updatedAt: '',
};

export const defaultCity: CityType = {
  id: null,
  name: '',
  stateId: null,
  longitude: null,
  latitude: null,
  updatedAt: '',
  createdAt: '',
  state: defaultState,
};

export const defaultGeneralUser: GeneralUser = {
  id: null,
  roleId: null,
  roles: [],
  name: '',
  email: '',
  phone: '',
  zipCode: '',
  rate: null,
  cityId: null,
  city: defaultCity,
  stateId: null,
  industries: [],
  specifications: [],
  state: defaultState,
  createdAt: '',
  updatedAt: '',
  emailVerifiedAt: '',
  avatar: null,
  rating: null,
  unreadNotificationsCount: null,
  isApproved: null,
  isBlocked: null,
  fieldRepId: null,
  fieldRep: null,
};

export const defaultFarm: FarmType = {
  id: null,
  name: '',
  email: '',
  phone: '',
  zipCode: '',
  contactName: '',
  userId: null,
  cityId: null,
  city: defaultCity,
  stateId: null,
  state: defaultState,
  createdAt: '',
  updatedAt: '',
};

export const defaultJob: Job = {
  id: null,
  name: '',
  status: null,
  rate: null,
  rateTypeId: null,
  startDate: '',
  endDate: '',
  userId: null,
  user: defaultGeneralUser,
  farmId: null,
  industryId: null,
  specificationId: null,
  peopleAmount: null,
  offersCount: null,
  offerStatus: null,
  farm: defaultFarm,
  state: defaultState,
  city: defaultCity,
  description: '',
  createdAt: '',
  updatedAt: '',
  reviewsCount: 0,
  payment: null,
  isBlocked: null,
  paymentType: null,
};

export const haventSeenYouNotificationId = '1';
export const haventSeenYouNotificationTime = 3 * 24 * 3600 * 1000; // in 3 days

export const defaultMapFilter: MapFilter = {
  filterSpecificationIds: [],
  filterCity: null,
};

export enum adScreenType {
  welcome = 'Welcome',
  login = 'Login',
  jobOffers = 'JobOffers',
  jobs = 'Jobs',
  jobIndustry = 'JobIndustry',
  jobListing = 'SpecificationJobs',
  laborers = 'Laborers',
}

export enum cardDefaultType {
  SELECTED = 1,
  NOT_SELECTED = 0,
}

export enum notificationTypes {
  // job_id
  LABORER_NEW_JOB_OFFER = 'laborerNewJobOffer',
  LABORER_ACCEPTED_JOB_REQUEST = 'laborerAcceptedJobRequest',
  LABORER_DECLINED_JOB_REQUEST = 'laborerDeclinedJobRequest',
  LABORER_NEW_JOB_IN_INDUSTRIES = 'laborerNewJobInIndustries',
  LABORER_NEW_JOB_IN_AREA = 'laborerNewJobInArea',
  // job_id, user_id
  EMPLOYER_NEW_JOB_REQUEST = 'employerNewJobRequest',
  EMPLOYER_ACCEPTED_JOB_OFFER = 'employerAcceptedJobOffer',
  EMPLOYER_DECLINED_JOB_OFFER = 'employerDeclinedJobOffer',
  // user_id
  EMPLOYER_NEW_LABORER_IN_INDUSTRY = 'employerNewLaborerInIndustry',
  EMPLOYER_NEW_LABORER_IN_AREA = 'employerNewLaborerInArea',
  // job_id
  EMPLOYER_EXPIRED_JOB = 'employerExpiredJob',
  EMPLOYER_UNBLOCKED_JOB = 'employerUnblockedJob',
  EMPLOYER_BLOCKED_JOB = 'employerBlockedJob',
}

export enum paymentStatus {
  CREATED = 0,
  PROCESSING = 1,
  SUCCEEDED = 2,
  FAILED = 3,
  INCOMPLETE = 4,
  CANCELLED = 5,
  REFUNDED = 6,
}

export enum referencesActionModalMode {
  ADD = 'REFERENCES_ADD',
  EDIT = 'REFERENCES_EDIT',
  REGISTRATION = 'REFERENCES_REGISTRATION',
}

export enum laborerCardType {
  INBOX = 'INBOX',
  OUTBOX = 'OUTBOX',
}

export enum cardBrandLogoType {
  small,
  default,
}

export enum userInfoModalMode {
  init,
  request,
  sent,
  accepted,
  info,
}

export const RATING = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' },
];

export const RADIUS = [
  { id: 5, name: radius.fiveMiles },
  { id: 10, name: radius.tenMiles },
  { id: 15, name: radius.fifteenMiles },
  { id: 30, name: radius.thirtyMiles },
  { id: 50, name: radius.fiftyMiles },
  { id: 100, name: radius.oneHundredMiles },
  { id: 250, name: radius.twoHundredAndFiftyMiles },
  { id: 500, name: radius.fiveHundredMiles },
];

export enum privacyPolicyScreenMode {
  privacyPolicy = 'privacyPolicy',
  termsOfService = 'termsOfService',
}

const { paymentTypes: string } = strings;

export const PAY_METHODS: ArrayPaymentMethod = [
  { id: 1, name: string.instantPayment },
  { id: 2, name: string.monthlyPayment },
];

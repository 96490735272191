import { JobDetailsCallbackProps } from '@root/mobile/screens/JobOffersScreen/types';
import { Job } from '@root/general/types';
import { Dispatch, SetStateAction } from 'react';

export const jobListingCallback = (jobs: Array<Job>, setJobs: Dispatch<SetStateAction<Array<Job>>>) => (
  jobDetailsCallbackProps: JobDetailsCallbackProps,
) => {
  const { jobId, isSuccess, navigationCallback, offerId, offerStatusId } = jobDetailsCallbackProps;

  if (isSuccess) {
    navigationCallback();

    const newJobs = jobs.map((job: Job) => {
      if (job.id === jobId) {
        job.offerId = offerId;
        job.offerStatus = offerStatusId;
        job.isOfferFromUs = true;
      }

      return job;
    });

    setJobs(newJobs);
  }
};

import { Button, withStyles } from '@material-ui/core';
import { colors } from 'src/general/colors';
import { fonts } from '@root/general/fonts';

export const MyButton = withStyles({
  root: {
    textTransform: 'capitalize',
    height: 48,
    fontSize: 17,
    fontFamily: fonts.SFUiDisplayBold,
    width: '100%',
    boxShadow: 'none',
    letterSpacing: -0.24,
  },
  disabled: {
    opacity: 0.4,
  },
  contained: {
    '&:hover': {
      boxShadow: 'none',
    },
  },
  label: {
    whiteSpace: 'nowrap',
  },
})(Button);

export const TextButton = withStyles({
  root: {
    color: colors.red,
    fontSize: '17px',
    fontFamily: fonts.SFUiDisplayBold,
    textTransform: 'none',
    '&:hover': {
      boxShadow: 'none',
      color: colors.darkRed,
      backgroundColor: 'transparent',
    },
  },
})(Button);

import React, { Dispatch, SetStateAction } from 'react';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { getStateFullName } from '@root/utils/getStateFullName';
import { Checkbox, TextField, ThemeProvider } from '@material-ui/core';
import { colors } from '@root/general/colors';
import { useSelector } from 'react-redux';
import { getStates } from '@root/selectors';
import { StateType } from '@root/general/types';
import { useStyles, stateFieldTheme } from '@root/web/components/StatesInput/styles';

type StatesInputType = {
  setFilterState: Dispatch<SetStateAction<StateType[] | null>>;
  defaultValue: StateType[] | null;
};

export const StatesInput = (props: StatesInputType) => {
  const { setFilterState, defaultValue } = props;
  const states = useSelector(getStates);
  const styles = useStyles();

  const renderOptions: AutocompleteProps<StateType>['renderOption'] = (option, { selected }) => (
    <div>
      <Checkbox checked={selected} style={{ color: colors.red }} />
      {getStateFullName(option)}
    </div>
  );

  return (
    <ThemeProvider theme={stateFieldTheme}>
      <Autocomplete
        multiple
        options={states}
        disableCloseOnSelect
        getOptionLabel={(option) => getStateFullName(option)}
        onChange={(e, value) => setFilterState(value)}
        classes={{ endAdornment: styles.endAdornment }}
        renderOption={renderOptions}
        value={defaultValue ?? []}
        className={styles.autocomplete}
        renderInput={(params) => (
          <TextField {...params} label='State' placeholder='States' variant={'outlined'} fullWidth />
        )}
      />
    </ThemeProvider>
  );
};

import { Api, CustomApiResponse } from '@root/services/api';
import { EditNotificationAction, FetchNotificationsAction } from '@root/redux/actions/types';
import { call, put } from 'redux-saga/effects';
import { ErrorResponse, NotificationsArray } from '@root/general/types';
import { CALL_COMMON_ERROR_HANDLER, CALL_UNEXPECTED_ERROR_HANDLER } from '@root/general/consts/actions';

export function* fetchNotifications(api: Api, action: FetchNotificationsAction) {
  const { page, callback } = action.payload;

  try {
    const response: CustomApiResponse<NotificationsArray, ErrorResponse> = yield call(api.fetchNotifications, page);

    if (response.ok && response.data) {
      yield callback && call(callback, true, response.data, response.meta?.lastPage ?? 1);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* editNotification(api: Api, action: EditNotificationAction) {
  const { notificationId, callback } = action.payload;

  try {
    const response: CustomApiResponse<Array<string>, ErrorResponse> = yield call(api.editNotification, notificationId);

    if (response.ok && response.data) {
      yield callback && call(callback, true);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

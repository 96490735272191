import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() => ({
  container: {
    outline: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.lightGrey,
    padding: '14px 23px',
    borderWidth: '0px 0px 1px',
    borderColor: colors.athensGray,
    borderStyle: 'solid',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  title: {
    color: colors.black,
    fontSize: 19,
    fontFamily: fonts.SFUiDisplayBold,
    letterSpacing: -0.24,
  },
  buttonText: {
    color: colors.red,
    fontSize: 17,
    fontFamily: fonts.SFUiDisplayBold,
    letterSpacing: -0.24,
    cursor: 'pointer',
    '&:hover': {
      color: colors.darkRed,
    },
  },
}));

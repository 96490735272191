import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles(() => ({
  myCardsContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderWidth: '0px 1px 1px',
    borderStyle: 'solid',
    borderColor: colors.athensGray,
    position: 'relative',
    height: '100%',
    padding: '20px 0px',
    backgroundColor: colors.white,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  myCardsAddButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px 30px 20px',
  },
  removeButtonStyle: {
    alignSelf: 'center',
  },
  scrollbarContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  horizontalScroll: {
    display: 'none',
  },
  gridContainer: {
    width: '100%',
    padding: '0px 30px',
    boxSizing: 'border-box',
    margin: 0,
  },
}));

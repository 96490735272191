import { IActionType } from '@root/redux/types';
import { defaultGeneralUser } from '@root/general/consts';
import { ArrayRoles, FieldReps, GeneralUser } from '@root/general/types';
import {
  FETCH_ROLES_SUCCESS,
  SET_AUTH_USER,
  EDIT_USER_SUCCESS,
  CLEAR_USER_DATA_STATE,
  FETCH_USER_SUCCESS,
  SET_TOKEN,
  EDIT_USER_ROLE_SUCCESS,
  AUTH_INITIALISE,
  REDUCE_UNREAD_NOTIFICATION,
  FETCH_FIELD_REPS_SUCCESS,
} from '@root/general/consts/actions';
import { omit } from 'lodash';

export type AuthReducer = {
  appVersionChecked: boolean;
  loading: boolean;
  user: GeneralUser;
  token: string;
  roles: ArrayRoles;
  fieldReps: FieldReps;
};

const INITIAL_STATE: AuthReducer = {
  appVersionChecked: true,
  loading: false,
  user: defaultGeneralUser,
  token: '',
  roles: [],
  fieldReps: [],
};

export const authReducer = (state = INITIAL_STATE, action: IActionType) => {
  switch (action.type) {
    case AUTH_INITIALISE:
      return { ...state, appVersionChecked: true };
    case SET_TOKEN:
      return { ...state, token: action.payload };
    case SET_AUTH_USER:
      return { ...state, user: action.payload };
    case FETCH_USER_SUCCESS:
      const userWithoutRole = omit(action.payload, 'roleId');
      return { ...state, user: { ...state.user, ...userWithoutRole } };
    case EDIT_USER_SUCCESS:
      return { ...state, user: { ...state.user, ...action.payload } };
    case EDIT_USER_ROLE_SUCCESS:
      return { ...state, user: { ...state.user, ...action.payload } };
    case FETCH_ROLES_SUCCESS:
      return { ...state, roles: action.payload };
    case FETCH_FIELD_REPS_SUCCESS: {
      return { ...state, fieldReps: action.payload };
    }
    case REDUCE_UNREAD_NOTIFICATION: {
      const newUnreadNotificationsCount = state.user.unreadNotificationsCount
        ? state.user.unreadNotificationsCount - 1
        : null;
      return { ...state, user: { ...state.user, unreadNotificationsCount: newUnreadNotificationsCount } };
    }
    case CLEAR_USER_DATA_STATE:
      return { ...state, ...INITIAL_STATE, ...action.payload };
    default:
      return state;
  }
};

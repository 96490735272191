import React, { CSSProperties } from 'react';
import { colors } from '@root/general/colors';

export const ICONS = {
  arrowRight: {
    viewBox: '0 0 10 12',
    path: 'M8 6L2.00019 12L2.00019 9.00007L5.00009 6L2 3.00007L2.00019 0L8 6Z',
  },
};

type IconProps = {
  icon: { viewBox: string; path: string };
  color: string;
  width?: number;
  height?: number;
  style?: CSSProperties;
};

export const Icon = (props: IconProps) => {
  const { icon, color = colors.grey, width = 24, height = 24, style = {} } = props;

  return (
    <div style={style}>
      <svg width={width} height={height} viewBox={icon.viewBox}>
        <path d={icon.path} fill={color} />
      </svg>
    </div>
  );
};

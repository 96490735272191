import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { CustomCardContainerProps } from '@root/web/components/CustomCardContainer/index';

export const useStyles = makeStyles(() => ({
  cardContainer: ({ isSelected }: CustomCardContainerProps) => {
    return {
      flex: 1,
      height: '100%',
      borderWidth: 1,
      borderColor: isSelected ? colors.red : colors.athensGray,
      borderStyle: 'solid',
      borderRadius: 5,
      boxShadow: 'none',
      boxSizing: 'border-box',
    };
  },
  cardActionArea: {
    height: '100%',
    padding: 0,
  },
  cardContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    padding: 20,
  },
}));

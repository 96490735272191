import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles({
  container: {
    backgroundColor: colors.white,
    borderBottomWidth: 1,
    borderBottomColor: colors.athensGray,
    borderBottomStyle: 'solid',
  },
  inlineContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  multilineContainer: {
    marginTop: -10,
    marginBottom: 16,
    display: 'flex',
    flex: 1,
    marginLeft: '30px',
  },
  labelContainer: {
    display: 'flex',
    flex: 1,
    padding: '16px 0px',
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    padding: '16px 0px',
    justifyContent: 'flex-end',
    width: '50%',
  },
  iconContainer: {
    display: 'flex',
    width: 24,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 6,
  },
  icon: {
    height: 20,
    width: 20,
  },
  labelText: {
    fontSize: 17,
    fontFamily: fonts.SFUiDisplaySemiBold,
    lineHeight: '22px',
    letterSpacing: -0.41,
  },
  valueText: {
    fontSize: 17,
    fontFamily: fonts.SFUiDisplaySemiBold,
    lineHeight: '22px',
    letterSpacing: -0.24,
    color: colors.darkGrey,
    textAlign: 'right',
    width: '100%',
    wordWrap: 'break-word',
  },
  descriptionText: {
    fontSize: 17,
    fontFamily: fonts.SFUiDisplaySemiBold,
    lineHeight: '20px',
    letterSpacing: -0.24,
    color: colors.darkGrey,
    width: '100%',
    wordWrap: 'break-word',
  },
});

import React, { useCallback, useEffect } from 'react';
import { useStyles } from './styles';
import { useAdBanner } from '@root/hooks/useAdBanner';
import { BASE_URL } from '@root/web/consts';
import { CustomLoader } from '@root/web/components/CustomLoader';
import { AdBannerProps } from '@root/general/types';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import firebase from 'firebase/app';

const getAdBannerSize = (width?: Breakpoint) => {
  //todo - add more banners
  switch (width) {
    case 'xs':
      return 'xs';
    case 'sm':
      return 'sm';
    default:
      return 'xl';
  }
};

export const AdBannerWithWidth = (props: AdBannerProps & Partial<WithWidth>) => {
  const { screenType, width } = props;

  const styles = useStyles();
  const [loading, banner, refreshAds, switchBanner] = useAdBanner(screenType);

  useEffect(
    useCallback(() => {
      const interval = switchBanner();
      return () => clearInterval(interval);
    }, [switchBanner]),
  );

  useEffect(() => {
    refreshAds();
  }, [refreshAds]);

  const showBanners = () => {
    if (banner) {
      const { link, params } = banner;
      const imageUrl = params?.images[getAdBannerSize(width)] ?? '';
      const uri = `${BASE_URL}storage/${imageUrl}`;
      const trackOpenAdd = () => {
        firebase.analytics().logEvent('open_ad', {
          url: link,
        });
      };

      return (
        <a className={styles.imageContainer} onClick={trackOpenAdd} href={link} target={'_blank'}>
          <img className={styles.image} src={uri} alt={''} />
        </a>
      );
    }
  };

  return <div className={styles.container}>{loading ? <CustomLoader /> : showBanners()}</div>;
};

export const AdBanner = withWidth()(AdBannerWithWidth);

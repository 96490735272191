import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthFooter } from '@root/web/components/AuthFooter';
import { strings } from '@root/strings';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { RightImageContainer } from '@root/web/components/RightImageContainer';
import { useStyles } from './styles';
import { AuthHeader } from '@root/web/components/AuthHeader';
import { accountType } from '@root/general/consts';
import { route } from '@root/web/consts';

const { roleScreen: string } = strings;

export const RoleScreen = () => {
  const styles = useStyles();
  let location = useLocation();
  let history = useHistory();

  const goToLaborerRegistration = () => {
    history.push(route.REGISTRATION, { ...location.state, accountType: accountType.LABORER });
  };

  const goToEmployerRegistration = () => {
    history.push(route.REGISTRATION, { ...location.state, accountType: accountType.EMPLOYER });
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <AuthHeader createNewAccountVisible={false} />
        <div className={styles.main}>
          <span className={styles.descriptionHeader}>{string.mainQuestion}</span>
          <CustomButton
            type={customButtonType.RED}
            title={string.laborer}
            onPress={goToLaborerRegistration}
            maxWidth={350}
          />
          <CustomButton
            type={customButtonType.RED}
            title={string.employer}
            onPress={goToEmployerRegistration}
            maxWidth={350}
            marginTop={10}
          />
        </div>
        <AuthFooter />
      </div>
      <RightImageContainer />
    </div>
  );
};

import React from 'react';
import { useStyles } from './styles';
import { radiusTypes } from '@root/hooks/useMapModalScreen';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import clsx from 'clsx';

type RadiusListProps = {
  onRadiusPress: (radius: number) => void;
  radius: number;
};

export const RadiusList = ({ onRadiusPress, radius }: RadiusListProps) => {
  const styles = useStyles();

  return (
    <div className={styles.radiusContainer}>
      {radiusTypes.map(({ name, value }) => {
        return (
          <CustomButton
            type={customButtonType.TEXT}
            key={value}
            title={name}
            onPress={() => onRadiusPress(value)}
            className={clsx(styles.radiusButton, radius === value && styles.radiusButtonSelected)}
          />
        );
      })}
    </div>
  );
};

import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { useStyles } from './styles';

export type CustomTabContainerProps = {
  isHidden?: boolean;
  tabPositionFixed?: boolean;
  isCarcass?: boolean;
  children?: React.ReactNode;
};

export const CustomTabContainerWithRef: ForwardRefRenderFunction<any, CustomTabContainerProps> = (
  props: CustomTabContainerProps,
  ref: any,
) => {
  const { isCarcass, children } = props;
  const styles = useStyles(props);

  if (isCarcass) {
    return <div className={styles.customTabContainer} />;
  }

  return (
    <div className={styles.customTabContainer}>
      <div className={styles.customTabContent} ref={ref}>
        {children && children}
      </div>
    </div>
  );
};

export const CustomTabContainer = forwardRef(CustomTabContainerWithRef);

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles({
  markerContainer: {
    backgroundColor: colors.red,
    width: 42,
    height: 42,
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  markerText: {
    color: colors.white,
    fontSize: 17,
    fontFamily: fonts.IBMPlexMonoMedium,
    lineHeight: '22px',
    letterSpacing: -0.41,
  },
});

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormikFieldFormDataType, FormikValuesType } from '@root/mobile/types/inputTypes';
import { ReferenceActionCallbackProps } from '@root/mobile/screens/ReferencesModalScreen/types';
import { Reference } from '@root/general/types';
import { addReferenceAction, deleteReferenceAction, editReferenceAction } from '@root/redux/actions';
import { strings } from '@root/strings';
import { referencesActionModalMode } from '@root/general/consts';

const { referencesActionModalScreen: string } = strings;

export enum referenceData {
  NAME = 12,
  EMAIL = 13,
  PHONE = 14,
}

type UseReferencesActionModalScreenReturnType = [
  FormikValuesType,
  FormikFieldFormDataType,
  (data: FormikValuesType) => void,
  boolean,
  () => void,
];

export const useReferencesActionModalScreen = (
  mode: referencesActionModalMode,
  goBack: () => void,
  goToReferences: () => void,
  referenceActionCallback?: (referenceActionCallbackProps: ReferenceActionCallbackProps) => void,
  reference?: Reference,
): UseReferencesActionModalScreenReturnType => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);

  const referenceName = reference?.name ?? '';
  const referenceEmail = reference?.email ?? '';
  const referencePhone = reference?.phone ?? '';

  const formInitialValues = {
    name: referenceName,
    referenceEmail: referenceEmail,
    referencePhone: referencePhone,
  } as FormikValuesType;

  const referenceFields: FormikFieldFormDataType = [
    {
      id: referenceData.NAME,
      title: string.nameTitle,
      valuesName: 'name',
      placeHolder: string.namePlaceholder,
    },
    {
      id: referenceData.EMAIL,
      title: string.emailTitle,
      valuesName: 'referenceEmail',
      placeHolder: string.emailPlaceholder,
      keyboardType: 'email-address',
    },
    {
      id: referenceData.PHONE,
      title: string.phoneTitle,
      valuesName: 'referencePhone',
      placeHolder: string.phonePlaceholder,
      mask: '[000]-[000]-[0000]',
      webMask: '999-999-9999',
      keyboardType: 'phone-pad',
    },
  ];

  const onDelete = () => {
    const callback = (isSuccess: boolean) => {
      if (isSuccess && referenceActionCallback) {
        referenceActionCallback({ isDelete: true, referenceId: reference?.id });
        goBack();
      }
    };

    dispatch(deleteReferenceAction({ referenceId: reference?.id ?? null, callback }));
  };

  const formHandleSubmit = (data: FormikValuesType) => {
    const { name, referenceEmail, referencePhone } = data;

    const newReference = {
      name,
      email: referenceEmail.length > 0 ? referenceEmail : undefined,
      phone: referencePhone.length > 0 ? referencePhone : undefined,
    };

    setLoading(true);

    if (mode === referencesActionModalMode.EDIT) {
      const callback = (isSuccess: boolean, updatedReference?: Reference) => {
        if (isSuccess && referenceActionCallback) {
          referenceActionCallback({ isDelete: false, updatedReference });
          goBack();
        }
        setLoading(false);
      };

      dispatch(editReferenceAction({ reference: newReference, referenceId: reference?.id ?? null, callback }));
    } else {
      let callback;
      if (mode === referencesActionModalMode.REGISTRATION) {
        callback = (isSuccess: boolean) => {
          isSuccess && goToReferences();
          setLoading(false);
        };
      } else {
        callback = (isSuccess: boolean) => {
          isSuccess && goBack();
          setLoading(false);
        };
      }

      dispatch(addReferenceAction({ reference: newReference, callback }));
    }
  };

  return [formInitialValues, referenceFields, formHandleSubmit, isLoading, onDelete];
};

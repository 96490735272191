import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { ArrayIndustries } from '@root/general/types';
import { useDispatch, useSelector } from 'react-redux';
import { getIndustries, getSpecificationByIndustryIdsWithTitle, getUser } from '@root/selectors';
import { showToastAction, ToastTypes } from '@root/redux/actions/commonActions';
import { editUserAction } from '@root/redux/actions';
import { FormikFieldFormDataType, FormikValuesType } from '@root/mobile/types/inputTypes';
import { accountType, WEB } from '@root/general/consts';
import { strings } from '@root/strings';

const { switchRoleModalScreen: switchRole, warnings } = strings;

type useSwitchRoleModalScreenReturnType = [
  FormikFieldFormDataType,
  FormikValuesType,
  (data: FormikValuesType, onSuccessCallback?: () => void) => void,
  number[],
  Dispatch<SetStateAction<number[]>>,
  number[],
  Dispatch<SetStateAction<number[]>>,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  ArrayIndustries,
  any,
  boolean,
  () => boolean,
  any,
];

export const useSwitchRoleModalScreen = (uniqueId: string = WEB): useSwitchRoleModalScreenReturnType => {
  const dispatch = useDispatch();

  const formikRef = useRef<any>();
  const { name, phone = '', stateId, cityId, zipCode, fieldRepId } = useSelector(getUser);
  const { allIndustries } = useSelector(getIndustries);

  const [isLoading, setLoading] = useState(false);
  const [industryModalVisible, setIndustryModalVisible] = useState<boolean>(false);
  const [selectedIndustryIds, setSelectedIndustryIds] = useState<Array<number>>([]);
  const [specificationsModalVisible, setSpecificationsModalVisible] = useState<boolean>(false);
  const [selectedSpecificationIds, setSelectedSpecificationIds] = useState<Array<number>>([]);

  const specificationsByIndustries = useSelector(getSpecificationByIndustryIdsWithTitle(selectedIndustryIds));

  const specificationsValidation = () => {
    return specificationsByIndustries.every(({ data }) => {
      return data.some((specification) => {
        return selectedSpecificationIds.includes(specification.id);
      });
    });
  };

  useEffect(() => {
    formikRef?.current?.validateForm();
  }, [selectedIndustryIds, selectedSpecificationIds]);

  const formInitialValues = { industry: '', specifications: '', rate: '' } as FormikValuesType;

  const formFields: FormikFieldFormDataType = [
    {
      id: 0,
      title: switchRole.industry,
      valuesName: 'industry',
      placeHolder: switchRole.industry,
      onPress: () => setIndustryModalVisible(!industryModalVisible),
      icon: require('@root/assets/icons/arrowRight.png'),
      selectable: true,
    },
    {
      id: 1,
      title: switchRole.specifications,
      valuesName: 'specifications',
      placeHolder: switchRole.specifications,
      onPress: () => {
        if (selectedIndustryIds.length) {
          setSpecificationsModalVisible(!specificationsModalVisible);
        } else {
          dispatch(showToastAction({ message: warnings.selectIndustry, type: ToastTypes.Error }));
        }
      },
      icon: require('@root/assets/icons/arrowRight.png'),
      selectable: true,
    },
    {
      id: 2,
      title: switchRole.salaryLabel,
      valuesName: 'rate',
      placeHolder: '0',
      keyboardType: 'number-pad',
      mask: '$[999990].[90]',
    },
    {
      id: 3,
      title: switchRole.descriptionLabel,
      valuesName: 'description',
      placeHolder: switchRole.descriptionPlaceholder,
      multiline: true,
    },
  ];

  const formHandleSubmit = (onlyLaborerFields: FormikValuesType, onSuccessCallback = () => {}) => {
    const callback = (isSuccess: boolean) => {
      if (isSuccess) {
        onSuccessCallback();
      }
      setLoading(false);
    };

    setLoading(true);

    dispatch(
      editUserAction({
        editUserData: {
          name,
          phone,
          stateId,
          cityId,
          zipCode,
          roleId: accountType.LABORER,
          industriesIds: selectedIndustryIds,
          specificationsIds: selectedSpecificationIds,
          rate: Number(onlyLaborerFields.rate.replace('$', '')),
          description: onlyLaborerFields.description,
          fieldRepId,
        },
        deviceId: uniqueId,
        callback,
      }),
    );
  };

  return [
    formFields,
    formInitialValues,
    formHandleSubmit,
    selectedIndustryIds,
    setSelectedIndustryIds,
    selectedSpecificationIds,
    setSelectedSpecificationIds,
    industryModalVisible,
    setIndustryModalVisible,
    specificationsModalVisible,
    setSpecificationsModalVisible,
    allIndustries,
    specificationsByIndustries,
    isLoading,
    specificationsValidation,
    formikRef,
  ];
};

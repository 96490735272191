import { Job, Offer, ReviewsArray } from '../general/types';
import { ActionType, AdditionActionType } from './useFlatListPagination';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OFFERS_BY_STATUS } from '../general/consts';
import { getOffersFilterById } from '@root/utils/getOffersFilterById';
import { getProposalsByFilterName } from '../selectors';
import {
  addReviewsAction,
  fetchAdditionalProposalsAction,
  fetchProposalsAction,
  refreshProposalsAction,
} from '../redux/actions';

export type UsersRatings = { [K in number]: number | null };

type UseReviewLaborersModalScreenReturnType = [
  Array<Offer>,
  number,
  ActionType,
  ActionType,
  AdditionActionType<Offer>,
  (
    reviewsArray: ReviewsArray,
    setJob: Dispatch<SetStateAction<Job>>,
    job: Job,
    onPressCancelButton: () => void,
  ) => void,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  UsersRatings,
  Dispatch<SetStateAction<UsersRatings>>,
];

export const useReviewLaborersModalScreen = (jobId: number | null): UseReviewLaborersModalScreenReturnType => {
  const dispatch = useDispatch();

  const [isWarningModalVisible, setWarningModalVisible] = useState(false);
  const [usersRatings, setUsersRatings] = useState<UsersRatings>({});

  const proposalsFilterId = OFFERS_BY_STATUS.ACCEPTED.id;

  const { name: proposalsFilterName } = getOffersFilterById(proposalsFilterId);

  const { proposals, lastPage } = useSelector(getProposalsByFilterName(proposalsFilterName));

  const fetchAction = useCallback(
    (fetchCallback: () => void) => {
      return fetchProposalsAction({ proposalsFilterId, jobId, callback: fetchCallback });
    },
    [proposalsFilterId, jobId],
  );

  const refreshAction = useCallback(
    (refreshCallback: () => void) => {
      return refreshProposalsAction({ proposalsFilterId, jobId, callback: refreshCallback });
    },
    [proposalsFilterId, jobId],
  );

  const fetchAdditionAction = useCallback(
    (page: number, fetchAdditionCallback: (isSuccess: boolean, additionalItems: any) => void) => {
      return fetchAdditionalProposalsAction({ proposalsFilterId, jobId, page, callback: fetchAdditionCallback });
    },
    [proposalsFilterId, jobId],
  );

  const handleAddReviews = useCallback(
    (reviewsArray: ReviewsArray, setJob: Dispatch<SetStateAction<Job>>, job: Job, onPressCancelButton: () => void) => {
      return dispatch(
        addReviewsAction({
          reviewsArray,
          callback: (isSuccess) => {
            if (isSuccess) {
              setJob({ ...job, reviewsCount: reviewsArray.length });
              onPressCancelButton();
            }
          },
        }),
      );
    },
    [dispatch],
  );

  return [
    proposals,
    lastPage,
    fetchAction,
    refreshAction,
    fetchAdditionAction,
    handleAddReviews,
    isWarningModalVisible,
    setWarningModalVisible,
    usersRatings,
    setUsersRatings,
  ];
};

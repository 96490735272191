import { SHOW_TOAST } from '@root/general/consts/actions';

export enum ToastTypes {
  Error = 'Error',
  Notification = 'Notification',
}

export type ShowToastData = {
  message: string;
  type: ToastTypes;
};

export type ShowToastAction = { type: typeof SHOW_TOAST; payload: ShowToastData };

export type ShowToast = (data: ShowToastData) => ShowToastAction;

export const showToastAction: ShowToast = (data) => ({ type: SHOW_TOAST, payload: data });

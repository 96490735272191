import {
  CREATE_FARM_REQUEST,
  DELETE_FARM_REQUEST,
  EDIT_FARM_REQUEST,
  FETCH_ADDITIONAL_FARMS_REQUEST,
  FETCH_FARMS_REQUEST,
  REFRESH_FARMS_REQUEST,
} from '@root/general/consts/actions';
import {
  CreateFarmPayload,
  DeleteFarmPayload,
  EditFarmPayload,
  FetchAdditionalFarmsPayload,
  FetchFarmsPayload,
  RefreshFarmsPayload,
} from '@root/redux/actions/types';

export const fetchFarmsRequestAction = (payload?: FetchFarmsPayload) => ({
  type: FETCH_FARMS_REQUEST,
  payload,
});

export const refreshFarmsRequestAction = (payload: RefreshFarmsPayload) => ({
  type: REFRESH_FARMS_REQUEST,
  payload,
});

export const fetchAdditionalFarmsRequestAction = (payload: FetchAdditionalFarmsPayload) => ({
  type: FETCH_ADDITIONAL_FARMS_REQUEST,
  payload,
});

export const createFarmAction = (payload: CreateFarmPayload) => ({
  type: CREATE_FARM_REQUEST,
  payload,
});

export const deleteFarmAction = (payload: DeleteFarmPayload) => ({
  type: DELETE_FARM_REQUEST,
  payload,
});

export const editFarmAction = (payload: EditFarmPayload) => ({
  type: EDIT_FARM_REQUEST,
  payload,
});

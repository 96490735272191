import { OfferArray } from '@root/general/types';
import { CLEAR_USER_DATA_STATE, FETCH_OFFERS_SUCCESS } from '@root/general/consts/actions';
import { IActionType } from '@root/redux/types';
import { OFFERS_BY_STATUS } from '@root/general/consts';

const defaultOffersByState = { offers: [], lastPage: 1, offerStatus: OFFERS_BY_STATUS.NEW.offerStatusId };

type OffersByStateType = {
  offers: OfferArray;
  lastPage: number;
  offerStatus: number;
};

export type JobOffersReducer = {
  offers: { [K in string]: OffersByStateType };
  currentFilter: string;
};

const INITIAL_STATE: JobOffersReducer = {
  offers: {
    [OFFERS_BY_STATUS.ALL.name]: defaultOffersByState,
    [OFFERS_BY_STATUS.NEW.name]: defaultOffersByState,
    [OFFERS_BY_STATUS.APPLIED.name]: defaultOffersByState,
    [OFFERS_BY_STATUS.ACCEPTED.name]: defaultOffersByState,
    [OFFERS_BY_STATUS.DECLINED.name]: defaultOffersByState,
    [OFFERS_BY_STATUS.CANCELLED.name]: defaultOffersByState,
  },
  currentFilter: OFFERS_BY_STATUS.NEW.name,
};

export const jobOffersReducer = (state = INITIAL_STATE, action: IActionType) => {
  switch (action.type) {
    case FETCH_OFFERS_SUCCESS: {
      const { tabName, offerStatusId, offers, lastPage } = action.payload;

      return {
        ...state,
        offers: { ...state.offers, [tabName]: { offers, offerStatus: offerStatusId, lastPage } },
        currentFilter: tabName,
      };
    }
    case CLEAR_USER_DATA_STATE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
};

import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { ArrayCityType, ArrayIndustries, CityType, Job, JobArray, MapCityType } from '@root/general/types';
import { useDispatch, useSelector } from 'react-redux';
import { getIndustries, getMapFilter } from '@root/selectors';
import { fetchCitiesOnMap, fetchJobsOnMap } from '@root/redux/actions/mapActions';
import { showToastAction, ToastTypes } from '@root/redux/actions/commonActions';
import { setMapFilter } from '@root/redux/actions';
import { strings } from '@root/strings';
import { fetchActionWithCallback } from '@root/hooks/useFlatListPaginationWithoutRedux';

const { warnings } = strings;

export const DEFAULT_RADIUS = 5;

export const radiusTypes = [
  { name: '5 MI', value: DEFAULT_RADIUS },
  { name: '10 MI', value: 10 },
  { name: '15 MI', value: 15 },
  { name: '30 MI', value: 30 },
  { name: '50 MI', value: 50 },
  { name: '100 MI', value: 100 },
  { name: '250 MI', value: 250 },
  { name: '500 MI', value: 500 },
];

type UseMapModalScreenReturnType = [
  Dispatch<SetStateAction<boolean>>,
  ArrayIndustries,
  Array<MapCityType>,
  Array<MapCityType>,
  Dispatch<SetStateAction<Array<MapCityType>>>,
  number,
  (radius: number) => void,
  () => void,
  fetchActionWithCallback<Job>,
  boolean,
];

export const useMapModalScreen = (
  userCity: CityType,
  onMapChangeRegion: (latitude: number | null, longitude: number | null, radius: number) => void,
): UseMapModalScreenReturnType => {
  const dispatch = useDispatch();

  const { allIndustries } = useSelector(getIndustries);
  const { filterSpecificationIds, filterCity } = useSelector(getMapFilter);
  const userMapCity = convertToMapCityType(userCity);

  const [radius, setRadius] = useState(DEFAULT_RADIUS);
  const [isMapReady, setMapReady] = useState(false);
  const [mapCities, setMapCities] = useState<Array<MapCityType>>([]);
  const [selectedCities, setSelectedCities] = useState<Array<MapCityType>>([]);

  const [initialised, setInitialised] = useState(false);

  const fetchAction = useCallback(
    (page: number, fetchCallback: (isSuccess: boolean, newItems?: JobArray, lastPage?: number) => void) => {
      return fetchJobsOnMap({
        filter: {
          specificationIds: filterSpecificationIds,
          cityIds: selectedCities.map((city) => city.id),
        },
        page,
        callback: fetchCallback,
      });
    },
    [filterSpecificationIds, selectedCities],
  );

  useEffect(() => {
    if (filterCity && isMapReady) {
      const { longitude, latitude } = filterCity;
      if (latitude && longitude) {
        dispatch(
          fetchCitiesOnMap({
            filter: { latitude, longitude, radius, specificationIds: filterSpecificationIds },
            callback: (isSuccess, cities: ArrayCityType = []) => {
              if (isSuccess) {
                const mapCities = cities
                  .filter((city) => city.id && city.jobsCount && city.latitude && city.longitude)
                  .map((city) => convertToMapCityType(city));
                setMapCities(mapCities);

                if (mapCities.length > 0) {
                  if (selectedCities.length === 0) {
                    const selectedCity = mapCities.find((mapCity) => mapCity.id === filterCity.id) ?? mapCities[0];
                    setSelectedCities([selectedCity]);
                  }
                } else {
                  setSelectedCities([]);
                }
                setInitialised(true);
              }
            },
          }),
        );
      } else {
        dispatch(showToastAction({ message: warnings.locationNotFound, type: ToastTypes.Error }));
      }
    }
  }, [dispatch, filterCity, selectedCities.length, isMapReady, filterSpecificationIds, radius]);

  useEffect(() => {
    isMapReady && onMapChangeRegion(filterCity.latitude, filterCity.longitude, radius);
  }, [isMapReady, onMapChangeRegion, filterCity, radius]);

  const onRadiusPress = (radiusValue: number) => {
    setRadius(radiusValue);
  };

  const onLocationPress = () => {
    setSelectedCities([userMapCity]);
    setRadius(DEFAULT_RADIUS);
    dispatch(setMapFilter({ mapFilter: { filterSpecificationIds: filterSpecificationIds, filterCity: null } }));
  };

  return [
    setMapReady,
    allIndustries,
    mapCities,
    selectedCities,
    setSelectedCities,
    radius,
    onRadiusPress,
    onLocationPress,
    fetchAction,
    initialised,
  ];
};

const convertToMapCityType = (city: CityType): MapCityType => {
  // validation done on fetchCitiesOnMap success callback
  return {
    id: city.id!,
    jobsCount: city?.jobsCount ?? 0,
    location: { latitude: city.latitude!, longitude: city.longitude! },
  };
};

import { OFFER_STATUS } from '@root/general/consts';
import { JobRequestFeedbackCallbackProps, Offer } from '@root/general/types';
import { Dispatch, SetStateAction } from 'react';

export const jobRequestFeedbackCallback = (
  jobRequestsByIndustry: Array<Offer>,
  setJobRequestsByIndustry: Dispatch<SetStateAction<Array<Offer>>>,
) => (jobRequestFeedbackCallbackProps: JobRequestFeedbackCallbackProps) => {
  const { isSuccess, offerStatusId, offerId, navigationCallback } = jobRequestFeedbackCallbackProps;

  if (isSuccess) {
    if (offerStatusId === OFFER_STATUS.DECLINED.id) {
      navigationCallback();
    }

    const newJobRequestsByIndustry = jobRequestsByIndustry.filter((jobRequest: Offer) => {
      return jobRequest?.id !== offerId;
    });

    setJobRequestsByIndustry(newJobRequestsByIndustry);
  }
};

import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '@root/general/fonts';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles(() => ({
  paymentSystemContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 30px',
    alignItems: 'center',
  },
  defaultCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: 20,
    alignItems: 'center',
  },
  warningText: {
    fontFamily: fonts.SFUiDisplayMedium,
    fontSize: 15,
    lineHeight: '22px',
    letterSpacing: -0.41,
    color: colors.grey,
    marginBottom: 15,
    padding: '0px 30px',
    textAlign: 'center',
  },
  warningCostText: {
    fontFamily: fonts.IBMPlexMonoRegular,
    fontSize: 21,
    lineHeight: '22px',
    letterSpacing: -0.24,
    color: colors.red,
    marginBottom: 9,
  },
  dontHaveAnyCardsWarning: {
    fontFamily: fonts.SFUiDisplayMedium,
    fontSize: 17,
    lineHeight: '22px',
    letterSpacing: -0.41,
    marginBottom: 14,
  },
  failedPaymentWarning: {
    fontFamily: fonts.SFUiDisplayMedium,
    fontSize: 17,
    lineHeight: '22px',
    letterSpacing: -0.41,
    color: colors.darkRed,
  },
  paymentSystemFooterContainer: {
    padding: '20px 30px',
    marginTop: 'auto',
  },
  paymentSystemButtonsContainer: {
    display: 'flex',
  },
}));

import React from 'react';
import { useStyles } from './styles';
import { FarmType } from '@root/general/types';
import { getLocationAddress } from '@root/utils/getLocationAddress';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { strings } from '@root/strings';
import { CustomCardContainer } from '@root/web/components/CustomCardContainer';

const { buttons } = strings;

export type LocationCardProps = {
  location: FarmType;
  onPress: () => void;
  isSelected?: boolean;
};

export const LocationCard = (props: LocationCardProps) => {
  const styles = useStyles(props);

  const { onPress, location, isSelected } = props;
  const { name, zipCode, contactName, email, phone, state, city } = location;

  return (
    <CustomCardContainer isSelected={isSelected}>
      <div className={styles.headerCard}>
        <div className={styles.titleContainer}>
          <span className={styles.title}>{name}</span>
        </div>
        <div className={styles.zipCodeContainer}>
          <span className={styles.zipCode}>{zipCode}</span>
        </div>
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.fieldContainer}>
          <div className={styles.imageContainer}>
            <img src={require('@root/web/assets/icons/hat.svg')} alt='' height={8} />
          </div>
          <span className={styles.fieldText}>{contactName}</span>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.imageContainer}>
            <img src={require('@root/web/assets/icons/email.svg')} alt='' />
          </div>
          <span className={styles.fieldText}>{email}</span>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.imageContainer}>
            <img src={require('@root/web/assets/icons/phone.svg')} alt='' />
          </div>
          <span className={styles.fieldText}>{phone}</span>
        </div>
      </div>
      <div className={styles.footerCard}>
        <div className={styles.locationContainer}>
          <div className={styles.imageContainer}>
            <img src={require('@root/web/assets/icons/location.svg')} alt='' />
          </div>
          <span className={styles.location}>{getLocationAddress(state, city)}</span>
        </div>
        <CustomButton
          type={customButtonType.GREY}
          title={buttons.edit}
          onPress={onPress}
          icon={require('@root/web/assets/icons/edit.svg')}
          iconClassName={styles.editIcon}
          maxWidth={90}
          ellipse
        />
      </div>
    </CustomCardContainer>
  );
};

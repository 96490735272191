import { fetchActionWithCallback } from '@root/hooks/useFlatListPaginationWithoutRedux';
import { Notification, NotificationsArray } from '@root/general/types';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { editNotificationAction, fetchNotificationsAction } from '@root/redux/actions';

type UseNotificationsReturnType = [
  fetchActionWithCallback<Notification>,
  (
    notificationId: number,
    readAt: null | string,
    notifications: NotificationsArray,
    setNotifications: Dispatch<SetStateAction<Notification[]>>,
  ) => () => void,
];

export const useNotificationsModalScreen = (): UseNotificationsReturnType => {
  const dispatch = useDispatch();

  const fetchAction = useCallback(
    (page: number, fetchCallback: (isSuccess: boolean, newItems?: NotificationsArray, lastPage?: number) => void) => {
      return fetchNotificationsAction({ page, callback: fetchCallback });
    },
    [],
  );

  const updateNotificationCallback = (
    notificationId: number,
    readAt: null | string,
    notifications: NotificationsArray,
    setNotifications: Dispatch<SetStateAction<Notification[]>>,
  ) => () => {
    !readAt &&
      dispatch(
        editNotificationAction({
          notificationId,
          callback: (isSuccess) => {
            if (isSuccess) {
              const updatedNotifications = notifications.map((notification) => {
                return notificationId === notification.id
                  ? { ...notification, readAt: new Date().toDateString() }
                  : notification;
              });
              setNotifications(updatedNotifications);
            }
          },
        }),
      );
  };
  return [fetchAction, updateNotificationCallback];
};

import React, { ChangeEvent, Dispatch, forwardRef, ForwardRefRenderFunction, SetStateAction, useState } from 'react';
import './react-datepicker-override.css';
import { Button, Modal } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import { useStyles } from '@root/web/components/DatePickerModal/styles';
import moment from 'moment';
import clsx from 'clsx';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';

import { strings } from '@root/strings';
import { Icon, ICONS } from '@root/web/assets/icons/Icon';
import { colors } from '@root/general/colors';
import { modalStyle } from '@root/general/consts';

const { buttons } = strings;

export type DatePickerModalProps = {
  setValue: (e: string | ChangeEvent<any>) => void;
  isModalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
};

export const DatePickerModalContainer: ForwardRefRenderFunction<any, DatePickerModalProps> = (
  props: DatePickerModalProps,
  ref: any,
) => {
  const styles = useStyles();

  const { isModalVisible, setModalVisible, setValue } = props;

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const onPressDone = () => {
    setModalVisible(!isModalVisible);
    if (startDate && !endDate) {
      setValue(`${moment(startDate).format('D MMMM YYYY')}`);
    } else if (startDate && endDate) {
      setValue(`${moment(startDate).format('D MMMM YYYY')} - ${moment(endDate).format('D MMMM YYYY')}`);
    }
  };

  const chooseDayClassName = (date: Date) => {
    const dat = moment(date).format('DD.MM.YYYY');

    if (startDate && !endDate && moment(startDate).format('DD.MM.YYYY') === dat) {
      return clsx(styles.day, styles.startDaySelected, styles.endDaySelected);
    }

    if (startDate && moment(startDate).format('DD.MM.YYYY') === dat) {
      return clsx(styles.day, styles.startDaySelected);
    } else if (endDate && moment(endDate).format('DD.MM.YYYY') === dat) {
      return clsx(styles.day, styles.endDaySelected);
    } else if (startDate && endDate && moment(date).isBetween(startDate, endDate)) {
      return clsx(styles.day, styles.middleDaySelected);
    } else if (dat === moment(new Date()).format('DD.MM.YYYY')) {
      return clsx(styles.day, styles.currentDay);
    } else {
      return styles.day;
    }
  };

  const renderCustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
  }: {
    date: Date;
    decreaseMonth(): void;
    increaseMonth(): void;
  }) => {
    const iconProps = { icon: ICONS.arrowRight, color: colors.white, height: 12, width: 12 };
    const iconStyles = { display: 'flex', width: '100%', justifyContent: 'center' };
    const leftIcon = { transform: 'rotate(180deg)' };

    return (
      <div className={styles.calendarHeaderContainer}>
        <Button onClick={decreaseMonth} variant='text' className={styles.calendarMonthContainer}>
          <Icon {...iconProps} style={{ ...iconStyles, ...leftIcon }} />
        </Button>
        <span className={styles.calendarHeaderText}>{moment(date).format('DD MMM, YYYY')}</span>
        <Button onClick={increaseMonth} variant='text' className={styles.calendarMonthContainer}>
          <Icon {...iconProps} style={iconStyles} />
        </Button>
      </div>
    );
  };

  const closeModal = () => setModalVisible(false);

  return (
    <Modal
      className={styles.modalContainer}
      style={modalStyle}
      open={isModalVisible}
      onClose={closeModal}
      container={ref?.current}
    >
      <div className={styles.contentContainer}>
        <DatePicker
          renderCustomHeader={renderCustomHeader}
          disabledKeyboardNavigation={true}
          calendarClassName={styles.datePicker}
          weekDayClassName={() => styles.weekdays}
          dayClassName={chooseDayClassName}
          minDate={new Date()}
          startDate={startDate}
          endDate={endDate}
          //@ts-ignore
          onChange={onChange}
          //@ts-ignore
          selectsRange
          inline
          dateFormatCalendar={'dd MMM, yyyy'}
          formatWeekDay={(formattedDate: string) => formattedDate.substr(0, 3)}
        />
        <CustomButton type={customButtonType.RED} title={buttons.done} onPress={onPressDone} disabledTopBorder />
      </div>
    </Modal>
  );
};

export const DatePickerModal = forwardRef(DatePickerModalContainer);

import { Rating } from '@root/general/types';

export const getRating = (rating?: Rating | null) => {
  if (rating) {
    const { counter: reviewsCount, average } = rating;
    if (average && reviewsCount) {
      return { ratingValue: average, reviewsCount };
    }
  }
  return { ratingValue: 0, reviewsCount: 0 };
};

import {
  DELETE_PAYMENT_CARD_REQUEST,
  FETCH_PAYMENT_CARDS_REQUEST,
  UPDATE_DEFAULT_PAYMENT_CARD_REQUEST,
  PAY_FOR_JOB_REQUEST,
  FETCH_SETUP_SECRET_REQUEST,
  FETCH_PAYMENT_SECRET_REQUEST,
  FETCH_PAYMENTS_REQUEST,
  REFRESH_PAYMENTS_REQUEST,
  FETCH_ADDITIONAL_PAYMENTS_REQUEST,
  SET_SUCCEED_PAYMENT,
  FETCH_PAYMENT_REQUEST,
} from '@root/general/consts/actions';
import {
  DeletePaymentCardPayload,
  SetDefaultPaymentCardPayload,
  PayForJobPayload,
  FetchPaymentSecretPayload,
  FetchSetupSecretPayload,
  FetchPaymentsPayload,
  FetchAdditionalPaymentsPayload,
  SetSucceedPaymentPayload,
  FetchPaymentPayload,
  FetchPaymentCardsPayload,
} from '@root/redux/actions/types';

export const setDefaultPaymentCardAction = (payload: SetDefaultPaymentCardPayload) => ({
  type: UPDATE_DEFAULT_PAYMENT_CARD_REQUEST,
  payload,
});

export const fetchPayment = (payload: FetchPaymentPayload) => ({
  type: FETCH_PAYMENT_REQUEST,
  payload,
});

export const fetchPaymentCardsAction = (payload: FetchPaymentCardsPayload) => ({
  type: FETCH_PAYMENT_CARDS_REQUEST,
  payload,
});

export const deletePaymentCardAction = (payload: DeletePaymentCardPayload) => ({
  type: DELETE_PAYMENT_CARD_REQUEST,
  payload,
});

export const payForJobAction = (payload: PayForJobPayload) => ({
  type: PAY_FOR_JOB_REQUEST,
  payload,
});

export const fetchSetupSecretAction = (payload: FetchSetupSecretPayload) => ({
  type: FETCH_SETUP_SECRET_REQUEST,
  payload,
});

export const fetchPaymentSecretAction = (payload: FetchPaymentSecretPayload) => ({
  type: FETCH_PAYMENT_SECRET_REQUEST,
  payload,
});

export const fetchPaymentsAction = (payload: FetchPaymentsPayload) => ({
  type: FETCH_PAYMENTS_REQUEST,
  payload,
});

export const refreshPaymentsRequestAction = (payload: FetchPaymentsPayload) => ({
  type: REFRESH_PAYMENTS_REQUEST,
  payload,
});

export const fetchAdditionalPaymentsRequestAction = (payload: FetchAdditionalPaymentsPayload) => ({
  type: FETCH_ADDITIONAL_PAYMENTS_REQUEST,
  payload,
});

export const setSucceedPayment = (payload: SetSucceedPaymentPayload) => ({
  type: SET_SUCCEED_PAYMENT,
  payload,
});

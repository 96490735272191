export const colors = {
  grey: '#B3BAC1',
  greyOpacity3: 'hsla(210, 10%, 73%, 0.3)',
  greyOpacity2: 'hsla(210, 10%, 73%, 0.2)',
  darkGrey: '#4E4E4E',
  darkGreyOpacity5: 'rgba(78,78,78,0.5)',
  darkGreyOpacity9: 'hsla(0, 0%, 31%, 0.9)',
  red: '#D0392A',
  redOpacity05: 'rgba(208,57,42,0.05)',
  lightRed: '#e62019',
  redOpacity: 'hsla(5, 66%, 49%, 0.1)',
  redOpacity2: 'hsla(5, 66%, 49%, 0.2)',
  darkRed: '#B72926',
  darkRedOpacity2: 'rgba(183, 41, 38, 0.2)',
  lightGrey: '#FBFBFB',
  white: '#ffffff',
  whiteOpacity: 'rgba(255, 255, 255, 0.01)',
  black: '#000000',
  blackOpacity5: 'hsla(0,0%, 0%, 0.5)',
  celery: '#9CBF5E',
  celeryOpacity5: 'rgba(156, 191, 94, 0.5)',
  tallPoppy: '#B72926',
  avocado: '#889C6D',
  tacha: '#DBC166',
  japonica: '#D9725E',
  whisper: '#F5F6FA',
  whiteLilac: '#E9EBF7',
  geyser: '#DCE2E8',
  geyserOpacity3: 'hsla(201, 21%, 89%, 0.3)',
  mineShaft: '#3D3D3D',
  apricot: '#E79863',
  athensGray: '#EBEBED',
  athensGrayOpacity3: 'rgba(235, 235, 237, 0.3)',
  confetti: '#EDCC67',
  roman: '#DD665B',
  mercury: '#E3E3E3',
  goldenRod: '#F8D56B',
  bananaMania: '#FBEAB5',
  //material ui hover color
  materialHover: 'rgba(0, 0, 0, 0.04)',
};

import { makeStyles } from '@material-ui/core/styles';
import { createStyles, Theme } from '@material-ui/core';
import { colors } from '@root/general/colors';
import { profileTabsMode } from '@root/web/screens/ProfileScreen/types';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flexGrow: 1,
      backgroundColor: colors.lightGrey,
      display: 'flex',
      alignItems: 'flex-start',
    },
    scrollbar: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    containerLeft: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    content: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'stretch',
    },
    profileMainInfoContainer: ({ tabMode }: { tabMode: profileTabsMode | null }) => {
      return {
        padding: '30px 0px 30px 30px',
        display: !tabMode ? 'flex' : 'none',
        flexDirection: 'column',
        alignSelf: 'flex-start',
        flex: 1,
        [theme.breakpoints.down('md')]: {
          width: '100%',
          boxSizing: 'border-box',
          padding: '15px 0px 0px 15px',
        },
      };
    },
    profileTabContainer: {
      padding: '30px 30px 0px',
      display: 'flex',
      flex: 3,
      height: 'auto',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        boxSizing: 'border-box',
        padding: '15px 15px 0px',
      },
    },
    footer: {
      padding: '30px 30px 20px',
      [theme.breakpoints.down('md')]: {
        padding: '10px 15px',
      },
    },
  }),
);

import React, { Dispatch, SetStateAction, useRef } from 'react';
import { profileTabsMode } from '@root/web/screens/ProfileScreen/types';
import { useSwitchRoleModalScreen } from '@root/hooks/useSwitchRoleModalScreen';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import { FormikHandleChangeType, FormikSetFieldTouchedType, FormikValuesType } from '@root/mobile/types/inputTypes';
import { validationSchema } from '@root/mobile/utils/validationSchema';
import { strings } from '@root/strings';
import { CustomModal } from '@root/web/components/CustomModal/CustomModal';
import { CustomModalWithSections } from '@root/web/components/CustomModal/CustomModalWithSections';
import { CustomInputAdapter } from '@root/web/components/CustomInput/customInputAdapter';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { CustomLoader } from '@root/web/components/CustomLoader';
import { clearJobListingFilterAction, clearLaborerFilterAction } from '@root/redux/actions';
import { useDispatch } from 'react-redux';
import { useStyles } from './styles';
import { CustomTabHeader } from '@root/web/components/CustomTabHeader';
import Scrollbars from 'react-custom-scrollbars';
import { useHistory } from 'react-router-dom';
import { route } from '@root/web/consts';
import { CustomTabContainer } from '@root/web/components/CustomTabContainer';

const { switchRoleModalScreen: switchRole, buttons } = strings;

type SwitchRoleScreenProps = {
  setTabMode: Dispatch<SetStateAction<profileTabsMode | null>>;
};

export const SwitchRoleScreen = (props: SwitchRoleScreenProps) => {
  const { setTabMode } = props;
  const styles = useStyles();
  let history = useHistory();

  const ref = useRef<any>();
  const dispatch = useDispatch();

  const [
    formFields,
    formInitialValues,
    formHandleSubmit,
    selectedIndustryIds,
    setSelectedIndustryIds,
    selectedSpecificationIds,
    setSelectedSpecificationIds,
    industryModalVisible,
    setIndustryModalVisible,
    specificationsModalVisible,
    setSpecificationsModalVisible,
    allIndustries,
    specificationsByIndustries,
    isLoading,
    specificationsValidation,
    formikRef,
  ] = useSwitchRoleModalScreen();

  const onCloseTab = () => {
    setTabMode(null);
  };

  const onSuccessCallback = () => {
    dispatch(clearLaborerFilterAction());
    dispatch(clearJobListingFilterAction());
    onCloseTab();
    history.push(route.PROFILE);
  };

  const renderInputForms = (
    handleChange: FormikHandleChangeType,
    values: FormikValuesType,
    errors: FormikErrors<FormikValuesType>,
    touched: FormikTouched<FormikValuesType>,
    setFieldTouched: FormikSetFieldTouchedType,
  ) => {
    return formFields.map((item) => {
      return (
        <CustomInputAdapter key={item.id} item={{ ...item, handleChange, values, errors, touched, setFieldTouched }} />
      );
    });
  };

  return (
    <Formik
      initialValues={formInitialValues}
      onSubmit={(data: FormikValuesType) => formHandleSubmit(data, onSuccessCallback)}
      validationSchema={validationSchema(formFields, specificationsValidation)}
      validateOnMount={true}
      innerRef={formikRef}
    >
      {({ handleChange, handleSubmit, values, errors, touched, setFieldTouched, isValid }) => (
        <CustomTabContainer ref={ref} tabPositionFixed>
          <CustomTabHeader title={switchRole.screenTitle} onTabHeaderClose={onCloseTab} />
          <div className={styles.contentContainer}>
            <Scrollbars>
              <div className={styles.content}>
                {renderInputForms(handleChange, values, errors, touched, setFieldTouched)}
              </div>
            </Scrollbars>
          </div>
          <div className={styles.submitButtonContainer}>
            <CustomButton
              type={customButtonType.RED}
              title={buttons.save}
              onPress={handleSubmit}
              disabled={isLoading || !isValid}
            />
          </div>
          {isLoading && <CustomLoader />}
          <CustomModal
            setValue={handleChange('industry')}
            isModalVisible={industryModalVisible}
            setModalVisible={setIndustryModalVisible}
            data={allIndustries}
            selectedItemIds={selectedIndustryIds}
            setSelectedItemIds={setSelectedIndustryIds}
            setDependentValue={handleChange('specifications')}
            dependentItem={selectedSpecificationIds}
            setDependentItem={setSelectedSpecificationIds}
            headerTitle={switchRole.industry}
            ref={ref}
          />
          <CustomModalWithSections
            setValue={handleChange('specifications')}
            isModalVisible={specificationsModalVisible}
            setModalVisible={setSpecificationsModalVisible}
            sections={specificationsByIndustries}
            selectedItemIds={selectedSpecificationIds}
            setSelectedItemIds={setSelectedSpecificationIds}
            headerTitle={switchRole.specifications}
            ref={ref}
          />
        </CustomTabContainer>
      )}
    </Formik>
  );
};

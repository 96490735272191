import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() => ({
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    height: '60%',
    outline: 0,
    backgroundColor: colors.white,
    minWidth: 320,
    width: 540,
    marginRight: 20,
    marginLeft: 20,
    borderRadius: 5,
    borderColor: colors.athensGray,
    borderWidth: 1,
    borderStyle: 'solid',
  },
  horizontalScroll: {
    display: 'none',
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    letterSpacing: -0.24,
    fontFamily: fonts.SFUiDisplayMedium,
    fontSize: 17,
    padding: '17px 20px',
    borderBottomColor: colors.athensGray,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    cursor: 'pointer',
  },
}));

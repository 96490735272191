import React, { useState } from 'react';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import { AuthFooter } from '@root/web/components/AuthFooter';
import { CustomInputAdapter } from '@root/web/components/CustomInput/customInputAdapter';
import { strings } from '@root/strings';
import { useResetPasswordScreen } from '@root/hooks/useResetPasswordModalScreen';
import { validationSchema } from '@root/mobile/utils/validationSchema';
import { FormikHandleChangeType, FormikSetFieldTouchedType, FormikValuesType } from '@root/mobile/types/inputTypes';
import { RightImageContainer } from '@root/web/components/RightImageContainer';
import { useStyles } from './styles';
import { AuthHeader } from '@root/web/components/AuthHeader';
import { ToastContainer } from 'react-toastify';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { WEB_NOTIFICATION_TIMEOUT } from '@root/general/consts';

const { resetPasswordModalScreen: string } = strings;

export const ResetPasswordScreen = (props: { token: string }) => {
  const styles = useStyles();
  const { token } = props;

  const [isSuccess, setIsSuccess] = useState(false);

  //TODO add navigate to login screen
  const goToLoginScreen = () => setIsSuccess(true);

  const [initialValues, resetPasswordData, onSubmit, loading] = useResetPasswordScreen(token, goToLoginScreen);

  const renderInputForms = (
    handleChange: FormikHandleChangeType,
    values: FormikValuesType,
    errors: FormikErrors<FormikValuesType>,
    touched: FormikTouched<FormikValuesType>,
    setFieldTouched: FormikSetFieldTouchedType,
  ) => {
    return resetPasswordData.map((item) => {
      return (
        <CustomInputAdapter key={item.id} item={{ ...item, handleChange, values, errors, touched, setFieldTouched }} />
      );
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <AuthHeader goBackVisible={false} createNewAccountVisible={false} />
        <div className={styles.main}>
          {isSuccess ? (
            <>
              <span className={styles.successText}>{string.successText}</span>
              <img className={styles.successImage} src={require('src/assets/images/passwordChanged.png')} alt='' />
            </>
          ) : (
            <>
              <span className={styles.title}>{string.screenName}</span>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema(resetPasswordData)}
                validateOnMount={true}
              >
                {({ handleChange, handleSubmit, values, errors, isValid, touched, setFieldTouched }) => (
                  <div className={styles.formContainer}>
                    {renderInputForms(handleChange, values, errors, touched, setFieldTouched)}
                    <CustomButton
                      type={customButtonType.RED}
                      title={string.buttonText}
                      onPress={handleSubmit}
                      maxWidth={350}
                      disabled={loading || !isValid}
                    />
                  </div>
                )}
              </Formik>
            </>
          )}
        </div>
        <AuthFooter />
      </div>
      <ToastContainer autoClose={WEB_NOTIFICATION_TIMEOUT} />
      <RightImageContainer />
    </div>
  );
};

import { cardBrandLogoType } from '@root/general/consts';

export const getCardBrandLogo = (brand: string, size: cardBrandLogoType) => {
  switch (brand) {
    case 'visa':
      return size === cardBrandLogoType.small
        ? require('@root/web/assets/icons/visaSmall.svg')
        : require('@root/web/assets/icons/visa.svg');
    case 'mastercard':
      return size === cardBrandLogoType.small
        ? require('@root/web/assets/icons/mastercardSmall.svg')
        : require('@root/web/assets/icons/mastercard.svg');
    case 'amex':
      return size === cardBrandLogoType.small
        ? require('@root/web/assets/icons/americanExpressSmall.svg')
        : require('@root/web/assets/icons/americanExpress.svg');
    case 'discover':
      return size === cardBrandLogoType.small
        ? require('@root/web/assets/icons/discoverSmall.svg')
        : require('@root/web/assets/icons/discover.svg');
    case 'diners':
      return size === cardBrandLogoType.small
        ? require('@root/web/assets/icons/dinersClubSmall.svg')
        : require('@root/web/assets/icons/dinersClub.svg');
    case 'jcb':
      return size === cardBrandLogoType.small
        ? require('@root/web/assets/icons/jcbSmall.svg')
        : require('@root/web/assets/icons/jcb.svg');
    case 'unionpay':
      return size === cardBrandLogoType.small
        ? require('@root/web/assets/icons/unionPaySmall.svg')
        : require('@root/web/assets/icons/unionPay.svg');
    default:
      require('@root/web/assets/icons/unknownCard.svg');
  }
};

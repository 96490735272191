import { FormikFieldFormDataType } from '@root/mobile/types/inputTypes';
import * as yup from 'yup';
import { temporaryAny } from '@root/general/types';

export const validationSchema = (data: FormikFieldFormDataType, specificationsValidation?: () => boolean) =>
  yup.object().shape(
    data.reduce((prev: temporaryAny, curr) => {
      const schema =
        curr.valuesName === 'description' ? yup.string() : yup.string().required(curr.title + ' is a required field');
      switch (curr.valuesName) {
        case 'email':
          return { ...prev, [curr.valuesName]: schema.email('This email is invalid') };
        case 'password':
          return { ...prev, [curr.valuesName]: schema.min(8, 'Password must be at least 8 characters') };
        case 'passwordConfirmation':
          return {
            ...prev,
            [curr.valuesName]: schema.oneOf([yup.ref('password'), null], 'Passwords must match'),
          };
        case 'newPassword':
          return {
            ...prev,
            [curr.valuesName]: schema
              .min(8, 'Password must be at least 8 characters')
              .notOneOf([yup.ref('password'), null], 'Your new password cannot be same as old password'),
          };
        case 'newPasswordConfirmation':
          return {
            ...prev,
            [curr.valuesName]: schema.oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
          };
        case 'specifications':
          return {
            ...prev,
            [curr.valuesName]: schema.test(
              'specificationsValidation',
              'It should be mandatory to select at least one specification for each industry',
              () => {
                return (specificationsValidation && specificationsValidation()) || false;
              },
            ),
          };
        case 'peopleAmount':
          return {
            ...prev,
            [curr.valuesName]: schema
              .min(1, curr.title + ' is a required field')
              .matches(/^\$*[1-9]\d{0,3}$/, curr.title + ' must be at least 1'),
          };
        case 'rate':
          return {
            ...prev,
            [curr.valuesName]: schema
              .min(1, curr.title + ' is a required field')
              .matches(/^\$*[1-9]\d{0,5}(\.\d{0,2})?$/, curr.title + ' has incorrect format'),
          };
        default:
          return { ...prev, [curr.valuesName]: schema };
      }
    }, []),
  );

export const referenceSchema = () => {
  return yup.object().shape(
    {
      name: yup.string().required('Name is a required field'),
      referenceEmail: yup.string().when(['referencePhone'], {
        is: (referencePhone) => {
          return !referencePhone;
        },
        then: yup
          .string()
          .required('Email is a required field')
          .email('This email is invalid'),
        otherwise: yup.string().email('This email is invalid'),
      }),
      referencePhone: yup.string().when(['referenceEmail'], {
        is: (referenceEmail) => {
          return !referenceEmail;
        },
        then: yup.string().required('Phone is a required field'),
      }),
    },
    // @ts-ignore
    ['referenceEmail', 'referencePhone'],
  );
};

import { IActionType } from '@root/redux/types';
import {
  SET_NEW_JOB,
  FETCH_POSTED_JOBS_SUCCESS,
  EDIT_POSTED_JOB_SUCCESS,
  DELETE_POSTED_JOB_SUCCESS,
  CLEAR_USER_DATA_STATE,
} from '@root/general/consts/actions';
import { Job } from '@root/general/types';
import { JOBS_STATUS } from '@root/general/consts';
import {
  getJobStatusByStatusId,
  getJobStatusByStatusName,
  JOB_STATUS_ALL,
} from '@root/mobile/utils/getJobStatusByStatusId';

type PostedJobByStateType = {
  postedJobs: Array<Job>;
  lastPage: number;
  jobStatus: number;
};

export type PostedJobsReducer = {
  postedJobs: { [K in string]: PostedJobByStateType };
  currentFilter: string;
};

const defaultPostedJobByState = { postedJobs: [], lastPage: 1, jobStatus: JOB_STATUS_ALL.id };

const INITIAL_STATE: PostedJobsReducer = {
  postedJobs: {
    [JOB_STATUS_ALL.name]: defaultPostedJobByState,
    [JOBS_STATUS.OFFERED.name]: defaultPostedJobByState,
    [JOBS_STATUS.CONFIRMED.name]: defaultPostedJobByState,
    [JOBS_STATUS.IN_PROGRESS.name]: defaultPostedJobByState,
    [JOBS_STATUS.COMPLETED.name]: defaultPostedJobByState,
    [JOBS_STATUS.CANCELLED.name]: defaultPostedJobByState,
  },
  currentFilter: JOB_STATUS_ALL.name,
};

export const postedJobsReducer = (state = INITIAL_STATE, action: IActionType) => {
  switch (action.type) {
    case FETCH_POSTED_JOBS_SUCCESS: {
      const { postedJobs, jobStatus, lastPage } = action.payload;

      const { name } = getJobStatusByStatusId(jobStatus);

      return {
        ...state,
        postedJobs: { ...state.postedJobs, [name]: { postedJobs, jobStatus, lastPage } },
        currentFilter: name,
      };
    }
    case SET_NEW_JOB: {
      const { postedJob } = action.payload;
      const name = state.currentFilter;

      if (name === JOB_STATUS_ALL.name || name === JOBS_STATUS.OFFERED.name) {
        return {
          ...state,
          postedJobs: {
            ...state.postedJobs,
            [name]: {
              ...state.postedJobs[name],
              postedJobs: [postedJob, ...(state.postedJobs[name]?.postedJobs ?? [])],
            },
          },
        };
      }

      return state;
    }
    case EDIT_POSTED_JOB_SUCCESS: {
      const name = state.currentFilter;
      const editedJob = action.payload;
      const { id: jobStatusId } = getJobStatusByStatusName(name);
      const jobs = state.postedJobs[name];

      const editedJobsFilter = () => {
        if (jobs?.postedJobs?.length) {
          return jobs.postedJobs.map((item) => (item.id === editedJob.id ? editedJob : item));
        }

        return [];
      };

      const newPostedJobsFilter = () => {
        if (jobs?.postedJobs?.length) {
          return jobs.postedJobs.filter((item) => item.id !== editedJob.id);
        }

        return [];
      };

      let newPostedJobs = [];

      // if job state has been changed
      if (name !== JOB_STATUS_ALL.name && editedJob.status !== jobStatusId) {
        newPostedJobs = newPostedJobsFilter();
      } else {
        newPostedJobs = editedJobsFilter();
      }

      return {
        ...state,
        postedJobs: {
          ...state.postedJobs,
          [name]: { ...jobs, postedJobs: newPostedJobs },
        },
      };
    }
    case DELETE_POSTED_JOB_SUCCESS: {
      const name = state.currentFilter;

      const jobsFilter = (state: PostedJobsReducer, name: string) => {
        if (state.postedJobs[name]?.postedJobs?.length) {
          return state.postedJobs[name].postedJobs.filter((item) => {
            return item.id !== action.payload;
          });
        }

        return [];
      };

      const filteredJobs = jobsFilter(state, name);

      return {
        ...state,
        postedJobs: {
          ...state.postedJobs,
          [name]: { ...state.postedJobs[name], postedJobs: filteredJobs },
        },
      };
    }
    case CLEAR_USER_DATA_STATE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
};

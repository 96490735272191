import React from 'react';
import { strings } from '@root/strings';
import { useStyles } from './styles';

const { approvedByAgButler: string } = strings;

export const ApprovedByAgButler = () => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <img src={require('@root/web/assets/icons/approvedSign.svg')} alt='' />
      <span className={styles.approvedText}>{string.approved}</span>
    </div>
  );
};

import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    height: '100vh',
  },
  leftContainer: {
    display: 'flex',
    flex: 6,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '24px 24px 12px',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    //todo so large fontSize
    fontSize: 42,
    fontFamily: fonts.SFUiDisplayBold,
    lineHeight: '32px',
    letterSpacing: -0.24,
    textAlign: 'center',
    marginBottom: 35,
  },
  formContainer: {
    maxWidth: 350,
  },
  devBtnContainer: {
    position: 'absolute',
    top: '20%',
    right: 200,
  },
  successText: {
    fontSize: 27,
    fontFamily: fonts.SFUiDisplayBold,
    lineHeight: '32px',
    letterSpacing: -0.24,
    textAlign: 'center',
    marginBottom: 20,
    width: 280,
  },
  successImage: {
    height: 300,
    width: 300,
  },
}));

import React from 'react';
import { SelectAllHeaderProps } from '@root/mobile/components/SelectAllHeader/types';
import { strings } from '@root/strings';
import { useStyles } from './styles';

const { buttons: string } = strings;

export const SelectAllHeader = (props: SelectAllHeaderProps) => {
  const { headerTitle, onPressAll } = props;
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <span className={styles.title}>{headerTitle}</span>
      <span className={styles.buttonText} onClick={onPressAll}>
        {string.selectAll}
      </span>
    </div>
  );
};

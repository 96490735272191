import React, { useEffect, useState } from 'react';
import { NavigationPaymentCallback, useUserInfoModalScreen } from '@root/hooks/useUserInfoModalScreen';
import { ArrayIndustries, Job, JobRequestFeedbackCallbackProps, RateValueProps } from '@root/general/types';
import { JobValue } from '@root/web/components/JobValue';
import { RateValue } from '@root/web/components/RateValue';
import { IndustryValue } from '@root/web/components/IndustryValue';
import { UserInfo } from './components/UserInfo';
import { OFFER_STATUS, userInfoModalMode } from '@root/general/consts';
import { CustomLoader } from '@root/web/components/CustomLoader';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { strings } from '@root/strings';
import Scrollbars from 'react-custom-scrollbars';
import { useStyles } from './styles';
import { CustomTabHeader } from '@root/web/components/CustomTabHeader';
import { Grid } from '@material-ui/core';
import { useOfferedJobListModalScreen } from '@root/hooks/useOfferedJobListModalScreen';
import { useFlatListPaginationWithoutRedux } from '@root/hooks/useFlatListPaginationWithoutRedux';
import { CustomInput } from '@root/web/components/CustomInput';
import { PickerScrollableModal } from '@root/web/components/PickerScrollableModal';
import { WarningModal } from '@root/web/components/WarningModal';
import { PaymentSystemScreen } from '@root/web/screens/ProfileScreen/components/PaymentSystemScreen';
import { PaymentSystemModalType } from '@root/mobile/navigators/types';
import { CustomTabContainer } from '@root/web/components/CustomTabContainer';

const { laborerInfoModalScreen: string, offeredJobListModalScreen } = strings;

export type UserInfoTabPropsType = {
  userId: number | null;
  goBackCallback: () => void;
  jobId?: number | null;
  jobRequestFeedbackCallback?: (jobRequestFeedbackCallbackProps: JobRequestFeedbackCallbackProps) => void;
  updateNotificationCallback?: () => void;
  accordionInfo?: boolean;
  tabPositionFixed?: boolean;
};

export const UserInfoTab = (props: UserInfoTabPropsType) => {
  const { jobId, userId, goBackCallback, jobRequestFeedbackCallback, accordionInfo, tabPositionFixed = false } = props;

  const styles = useStyles({});

  const [isWarningModalVisible, setWarningModalVisible] = useState<boolean>(false);

  const jobValueElement = (jobName: string | undefined) => {
    return <JobValue jobName={jobName} />;
  };
  const industryValueElement = (industries?: ArrayIndustries) => {
    return <IndustryValue industries={industries} />;
  };
  const rateValueElement = (rateValueProps: RateValueProps) => {
    const { rate } = rateValueProps;
    return <RateValue rate={rate} />;
  };

  const [paymentSystemProps, setPaymentSystemProps] = useState<PaymentSystemModalType | null>(null);

  const navigationPaymentCallback: NavigationPaymentCallback = (paymentId, setJobPaymentSucceed) => {
    setPaymentSystemProps({
      paymentId,
      setJobPaymentSucceed,
      goBackCallback: () => setPaymentSystemProps(null),
    });
  };

  const [user, userInfoData, isLoading, isLaborer, jobRequestFeedback, mode] = useUserInfoModalScreen(
    userId,
    goBackCallback,
    navigationPaymentCallback,
    jobValueElement,
    industryValueElement,
    rateValueElement,
    jobId,
    jobRequestFeedbackCallback,
  );

  const [
    isModalVisible,
    setModalVisible,
    selectedJob,
    onPressJob,
    onSendJobOffer,
    fetchAction,
    setSelectedJob,
  ] = useOfferedJobListModalScreen(userId, goBackCallback);

  useEffect(() => {
    setSelectedJob(null);
  }, [userId, setSelectedJob]);

  const [offeredPostedJobs, isFlatListLoading, , , , onEndReached, , hasMore] = useFlatListPaginationWithoutRedux<Job>(
    fetchAction,
    !jobId,
  );

  const getExpression = () => {
    const jobName = selectedJob?.name ?? '';
    return `${offeredJobListModalScreen.warning} ${jobName}`;
  };

  const renderButton = () => {
    if (mode === userInfoModalMode.init) {
      return null;
    }

    if (!jobId) {
      return (
        <div className={styles.sendJobOfferButton}>
          {!isLoading && !isFlatListLoading && (
            <>
              <span className={styles.selectJob}>{offeredJobListModalScreen.selectJob}</span>
              {!offeredPostedJobs.length ? (
                <span className={styles.offeredPostedJobsEmpty}>{offeredJobListModalScreen.thereAreNoJobs}</span>
              ) : (
                <CustomInput
                  item={{
                    id: 1,
                    title: offeredJobListModalScreen.selectJob,
                    placeHolder: offeredJobListModalScreen.selectJob,
                    defaultValue: selectedJob ? selectedJob.name : '',
                    onPress: () => setModalVisible(true),
                    icon: require('@root/web/assets/icons/arrowRight.svg'),
                  }}
                />
              )}
            </>
          )}
          <CustomButton
            type={customButtonType.RED}
            title={string.sendJobOffer}
            disabled={isLoading || isFlatListLoading || !selectedJob}
            onPress={() => setWarningModalVisible(true)}
          />
        </div>
      );
    }

    if (mode === userInfoModalMode.request) {
      return (
        <Grid container spacing={2} className={styles.buttonsContainer}>
          <Grid item xs={6}>
            <CustomButton
              type={customButtonType.GREY}
              title={string.decline}
              onPress={jobRequestFeedback(OFFER_STATUS.DECLINED.id)}
              disabled={isLoading}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomButton
              type={customButtonType.GREEN}
              title={string.accept}
              onPress={jobRequestFeedback(OFFER_STATUS.ACCEPTED.id)}
              disabled={isLoading}
            />
          </Grid>
        </Grid>
      );
    }
  };

  if (accordionInfo) {
    return (
      <div className={styles.scrollbarContent}>
        <UserInfo
          user={user}
          userInfoData={userInfoData}
          isAccepted={mode === userInfoModalMode.accepted}
          isLaborer={isLaborer}
          inlineHeader
          isLoading={isLoading}
        />
        {isLoading && <CustomLoader />}
      </div>
    );
  }

  return (
    <>
      <CustomTabContainer isHidden={!!paymentSystemProps} tabPositionFixed={tabPositionFixed}>
        <CustomTabHeader title={''} onTabHeaderClose={goBackCallback} />
        <Scrollbars>
          <div className={styles.scrollbarContent}>
            <UserInfo
              user={user}
              userInfoData={userInfoData}
              isAccepted={mode === userInfoModalMode.accepted}
              isLaborer={isLaborer}
              isLoading={isLoading}
            />
          </div>
        </Scrollbars>
        {!isLaborer && renderButton()}
        {(isLoading || isFlatListLoading) && <CustomLoader />}
        {isModalVisible && (
          <PickerScrollableModal
            items={offeredPostedJobs}
            onPressItem={onPressJob}
            isModalVisible={isModalVisible}
            setModalVisible={setModalVisible}
            onEndReached={onEndReached}
            hasMore={hasMore}
          />
        )}
        <WarningModal
          expression={getExpression()}
          isModalVisible={isWarningModalVisible}
          setModalVisible={setWarningModalVisible}
          onApply={onSendJobOffer}
        />
      </CustomTabContainer>
      {paymentSystemProps && <PaymentSystemScreen {...paymentSystemProps} />}
    </>
  );
};

import { CLEAR_USER_DATA_STATE, SET_MAP_FILTER } from '@root/general/consts/actions';
import { MapFilter } from '@root/general/types';
import { IActionType } from '@root/redux/types';
import { defaultMapFilter } from '@root/general/consts';

export type MapFilterReducer = {
  mapFilter: MapFilter;
};

const INITIAL_STATE: MapFilterReducer = {
  mapFilter: defaultMapFilter,
};

export const mapFilterReducer = (state = INITIAL_STATE, action: IActionType) => {
  switch (action.type) {
    case SET_MAP_FILTER:
      const { mapFilter } = action.payload;
      return { ...state, mapFilter: { ...state.mapFilter, ...mapFilter } };
    case CLEAR_USER_DATA_STATE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
};

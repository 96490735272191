import React, { useEffect } from 'react';
import { Chip, Grid } from '@material-ui/core';
import { IndustryTopNavigator } from '@root/web/navigators/IndustryTopNavigator';
import { useLaborersScreen } from '@root/hooks/useLaborersScreen';
import { useFlatListPagination } from '@root/hooks/useFlatListPagination';
import { GeneralUser, StateType } from '@root/general/types';
import { MainFooter } from '@root/web/components/MainFooter';
import { ToastContainer } from 'react-toastify';
import { useStyles } from './styles';
import { WEB_NOTIFICATION_TIMEOUT } from '@root/general/consts';
import { LaborerCard } from '@root/web/components/LaborerCard';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { MainHeader } from '@root/web/components/MainHeader';
import { route } from '@root/web/consts';
import { useDispatch, useSelector } from 'react-redux';
import { getIndustries, getIndustryById } from '@root/selectors';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { strings } from '@root/strings';
import { SearchInput } from '@root/web/components/SearchInput';
import { LaborersRightTab } from '@root/web/screens/LaborersScreen/components/LaborersRightTab';
import { setCurrentLaborersIndustryIdAction, setLaborerFilterAction } from '@root/redux/actions';
import { laborersFilterFields } from '@root/redux/reducers/laborersReducer';
import { CustomScrollComponent } from '@root/web/components/CustomScrollComponent';
import { CustomTabContainer } from '@root/web/components/CustomTabContainer';
import { useReadNotification } from '@root/web/hooks/useReadNotification';

const { laborersScreen: string, buttons } = strings;

type locationType = {
  isTabShown?: boolean;
  notificationId?: number | null;
};

export const LaborersScreen = (props: { path: string }) => {
  const styles = useStyles();

  const location = useLocation<locationType>();
  const isTabShown = location?.state?.isTabShown ?? false;
  const notificationId = location?.state?.notificationId ?? null;

  useReadNotification(notificationId);

  const { laborerId = '' } = useParams<{ laborerId?: string }>();
  const numberLaborerId = Number(laborerId);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.navigationContainer}>
        <MainHeader />
        <IndustryTopNavigator {...props}>
          <LaborersView industryId={1} numberLaborerId={numberLaborerId} />
        </IndustryTopNavigator>
      </div>
      {(isTabShown || !!numberLaborerId) && <CustomTabContainer isCarcass />}
    </div>
  );
};

type LaborersViewProps = {
  industryId: number;
  numberLaborerId: number;
};

const LaborersView = (props: LaborersViewProps) => {
  const { industryId, numberLaborerId } = props;
  const dispatch = useDispatch();

  const styles = useStyles();

  const history = useHistory();
  const location = useLocation<locationType>();

  const isTabShown = location?.state?.isTabShown ?? false;

  const { allIndustries } = useSelector(getIndustries);
  const currentIndustry = useSelector(getIndustryById(industryId)) || allIndustries[0];
  const currentIndustryName = currentIndustry.name.toLowerCase();

  useEffect(() => {
    dispatch(setCurrentLaborersIndustryIdAction({ id: industryId }));
  }, [dispatch, industryId]);

  const [
    specifications,
    filter,
    ,
    searchValue,
    onSearch,
    laborers,
    lastPage,
    fetchAction,
    refreshAction,
    fetchAdditionAction,
    debounceFetchAction,
  ] = useLaborersScreen(industryId);

  const [laborersByIndustry, isFlatListLoading, , , , onEndReached, , hasMore] = useFlatListPagination<GeneralUser>(
    laborers,
    lastPage,
    fetchAction,
    refreshAction,
    fetchAdditionAction,
    debounceFetchAction,
  );

  const onTabClose = () => {
    history.push(`${route.LABORERS}/${currentIndustryName}`, { isTabShown: false });
  };

  const toggleFilterTab = () => {
    history.push(`${route.LABORERS}/${currentIndustryName}`, { isTabShown: !isTabShown });
  };

  const goToLaborerInfo = (laborerId: number | null) => () => {
    history.push(`${route.LABORERS}/${currentIndustryName}/${laborerId}`);
  };

  const renderLaborer = (laborer: GeneralUser) => {
    const { id, name, rate, rating } = laborer;
    return (
      <Grid
        key={id || 0}
        item
        xs={12}
        md={numberLaborerId || isTabShown ? 12 : 6}
        lg={numberLaborerId || isTabShown ? 6 : 4}
        xl={numberLaborerId || isTabShown ? 4 : 3}
      >
        <LaborerCard
          onPress={goToLaborerInfo(id)}
          name={name}
          rate={rate}
          rating={rating}
          isSelected={id === numberLaborerId}
        />
      </Grid>
    );
  };

  const FilterValues = () => {
    const { specificationId, state: states = [], city, radius } = filter;
    const jobSpecification = specifications.find((item) => item.id === specificationId);

    const jobSpecificationName = jobSpecification?.name ?? '';
    // TODO AIMA-402 hide fields
    // const rateName = rate ? String(`$${rate}`) : '';
    // const ratingName = rating ? String(rating) : '';
    const radiusName = radius ? String(`${radius} miles`) : '';
    //const stateName = state?.name ?? '';
    const cityName = city?.name ?? '';

    const removeFilterValue = (valueName: laborersFilterFields, payload?: StateType) => () => {
      switch (valueName) {
        case laborersFilterFields.state: {
          const valueNameFilter = (states: StateType[] | null) => {
            return states?.length ? states.filter((state) => state.id !== payload?.id) : null;
          };

          return dispatch(
            setLaborerFilterAction({
              ...filter,
              [valueName]: valueNameFilter(states),
              [laborersFilterFields.city]: null,
              [laborersFilterFields.radius]: null,
            }),
          );
        }
        case laborersFilterFields.city: {
          return dispatch(
            setLaborerFilterAction({
              ...filter,
              [valueName]: null,
              [laborersFilterFields.radius]: null,
            }),
          );
        }
        default:
          return dispatch(setLaborerFilterAction({ ...filter, [valueName]: null }));
      }
    };

    const chipFilterValue = (textValue: string, valueName: laborersFilterFields, payload?: StateType) => {
      if (textValue) {
        return (
          <Chip
            key={textValue}
            className={styles.chip}
            deleteIcon={
              <img className={styles.chipDeleteIcon} src={require('@root/web/assets/icons/cross.svg')} alt='' />
            }
            label={textValue}
            onDelete={removeFilterValue(valueName, payload)}
          />
        );
      }

      return null;
    };

    // if (!jobSpecificationName && !rateName && !ratingName && !radiusName && !stateName && !cityName) {
    if (!jobSpecificationName && !radiusName && !states?.length && !cityName) {
      return null;
    }

    const chipJobSpecification = chipFilterValue(jobSpecificationName, laborersFilterFields.specificationId);
    // const chipRate = chipFilterValue(rateName, laborersFilterFields.rate);
    // const chipRating = chipFilterValue(ratingName, laborersFilterFields.rating);
    const chipState = (states: StateType[] | null) => {
      if (states?.length) {
        return states.map((state) => chipFilterValue(state.name, laborersFilterFields.state, state));
      }

      return null;
    };

    const chipCity = chipFilterValue(cityName, laborersFilterFields.city);
    const chipRadius = chipFilterValue(radiusName, laborersFilterFields.radius);

    return (
      <div className={styles.filterValues}>
        {chipJobSpecification}
        {/* TODO AIMA-402 hide fields*/}
        {/*{chipRate}*/}
        {/*{chipRating}*/}
        {chipState(states)}
        {chipCity}
        {chipRadius}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <CustomScrollComponent
        data={laborersByIndustry}
        renderItem={renderLaborer}
        isFlatListLoading={isFlatListLoading}
        onEndReached={onEndReached}
        hasMore={hasMore}
        defaultPrimaryText={string.defaultTextPrimary}
        header={
          <>
            <div className={styles.filterContainer}>
              <div className={styles.search}>
                <SearchInput value={searchValue} placeholder={string.search} onChange={onSearch} />
              </div>
              <CustomButton
                type={customButtonType.WHITE}
                title={buttons.filter}
                onPress={toggleFilterTab}
                maxWidth={150}
                ellipse
                icon={require('@root/web/assets/icons/filter.svg')}
              />
            </div>
            <FilterValues />
          </>
        }
        footer={<MainFooter />}
      />
      <LaborersRightTab isTabShown={isTabShown} laborerId={numberLaborerId} onTabClose={onTabClose} />
      <ToastContainer autoClose={WEB_NOTIFICATION_TIMEOUT} />
    </div>
  );
};

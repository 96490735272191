import React from 'react';
import { Button } from '@material-ui/core';
import { Icon, ICONS } from '@root/web/assets/icons/Icon';
import { getJobStatusByStatusId } from '@root/mobile/utils/getJobStatusByStatusId';
import { useStyles } from './styles';
import { capitalize } from '@root/utils/capitalize';

type JobStatusSwitcherProps = {
  jobStatusId: number | null;
  disabled: boolean;
  onSwitcherPress?: () => void;
};

export const JobStatusSwitcher = (props: JobStatusSwitcherProps) => {
  const { jobStatusId, disabled, onSwitcherPress } = props;

  const styles = useStyles();

  const { name, color } = getJobStatusByStatusId(jobStatusId);

  return (
    <Button
      onClick={onSwitcherPress}
      variant='text'
      className={styles.jobStatusSwitcherContainer}
      endIcon={onSwitcherPress && <Icon icon={ICONS.arrowRight} color={color} height={14} width={14} />}
      disabled={disabled}
      classes={{ text: styles.buttonText }}
    >
      <span style={{ color }}>{capitalize(name)}</span>
    </Button>
  );
};

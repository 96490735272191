import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { ProfileAvatarProps } from '@root/web/screens/ProfileScreen/components/ProfileAvatar/index';

export const useStyles = makeStyles(() => ({
  editPhoto: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    backgroundColor: colors.red,
    width: 32,
    height: 32,
    borderRadius: '50%',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: colors.white,
    bottom: 0,
    right: 0,
    cursor: 'pointer',
  },
  photoContainer: (props: ProfileAvatarProps) => {
    return {
      position: 'relative',
      width: 105,
      height: 105,
      borderRadius: '50%',
      backgroundColor: props.avatar ? 'none' : colors.redOpacity2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };
  },
  photo: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  defaultPhoto: {
    filter:
      'brightness(0) saturate(100%) invert(27%) sepia(71%) saturate(2791%) hue-rotate(348deg) brightness(86%) contrast(87%);',
  },
  loaderContainer: {
    position: 'relative',
    width: 105,
    height: 105,
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cropPhotoModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '60%',
    outline: 0,
    minWidth: 320,
    width: 540,
    marginRight: 20,
    marginLeft: 20,
    borderRadius: 5,
    borderColor: colors.athensGray,
    borderWidth: 1,
    borderStyle: 'solid',
    boxSizing: 'border-box',
    backgroundColor: colors.whisper,
  },
  reactCropContainer: {
    display: 'flex',
    flex: 1,
    minHeight: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    boxSizing: 'border-box',
  },
}));

import React from 'react';
import { Grid, Modal } from '@material-ui/core';
import { WarningModalProps } from '@root/mobile/components/WarningModal/types';
import { strings } from '@root/strings';
import { useStyles } from './styles';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';

const { warningModal: string } = strings;

export const WarningModal = (props: WarningModalProps) => {
  const {
    expression,
    isModalVisible,
    setModalVisible,
    onApply,
    changedExpression,
    closeButtonIsCancel = false,
  } = props;

  const styles = useStyles();

  const onPressDone = () => {
    onApply();
    setModalVisible(!isModalVisible);
  };

  const onCloseModal = () => setModalVisible(!isModalVisible);

  const modalExpression = changedExpression ? expression : `${string.warning} \n ${expression} ?`;

  const closeButtonLabel = closeButtonIsCancel ? string.cancel : string.close;

  const approveButtonLabel = changedExpression ? string.okay : string.yes;

  return (
    <Modal open={isModalVisible} onClose={onCloseModal} className={styles.modalContainer}>
      <div className={styles.container}>
        <p className={styles.modalExpressionText}>{modalExpression}</p>
        <Grid container direction={'row'} spacing={1}>
          <Grid item xs={6}>
            <CustomButton type={customButtonType.WHISPER} title={closeButtonLabel} onPress={onCloseModal} />
          </Grid>
          <Grid item xs={6}>
            <CustomButton type={customButtonType.GREEN} title={approveButtonLabel} onPress={onPressDone} />
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

import { radius, SUPPORT_EMAIL } from '@root/general/consts';

export const strings = {
  welcomeScreen: {
    createAccount: 'Create new account',
    descriptionHeader: 'Enabling Farm Productivity,\nOne Click at a Time',
    signIn: 'Sign in',
  },
  loginScreen: {
    emailTitle: 'Email',
    emailPlaceholder: 'agbutler@info.com',
    passwordTitle: 'Password',
    passwordPlaceholder: '********',
    screenTitle: 'Login',
    forgotPassword: 'Forgot your password?',
    restorePassword: 'Restore password',
  },
  changeEmailScreen: {
    screenTitle: 'CHANGE EMAIL',
    emailTitle: 'New email',
    emailPlaceHolder: 'agbutler@info.com',
    passwordTitle: 'Current password',
    passwordPlaceholder: '********',
  },
  roleScreen: {
    laborer: 'Laborer',
    employer: 'Employer',
    mainQuestion: 'What is your role or title?',
  },
  userProfileActionsScreen: {
    name: 'Name',
    emailTitle: 'Email',
    emailPlaceholder: 'agbutler@info.com',
    passwordTitle: 'Password',
    passwordConfirmation: 'Repeat Password',
    passwordPlaceholder: '********',
    phoneTitle: 'Cell phone',
    phonePlaceholder: '000-000-0000',
    state: 'State',
    cityTitle: 'City/Town',
    fieldRepTitle: 'Field Rep',
    fieldRepPlaceholder: 'Field Rep',
    cityPlaceholder: 'City',
    codeTitle: 'Zip code',
    codePlaceholder: '00000-00000',
    industry: 'Industry',
    specifications: 'Specifications',
    salaryLabel: 'Base rate',
    descriptionLabel: 'Description',
    descriptionPlaceholder: 'Write description here',
  },
  registrationScreen: {
    screenTitle: 'Registration',
    member: 'Already a member?',
    termsAndConditionsPart1: `AgButler's AgButler Platform and Services are not intended for individuals under the age of 18. Moreover, if we become aware that we have collected personal information (as defined by the `,
    termsAndConditionsPart2: `Children's Online Privacy Protection Act`,
    termsAndConditionsPart3: `) from children under the age of 18, we will take reasonable steps to delete it as soon as practicable.`,
    privacyPolicyPart1: 'I agree to the ',
    privacyPolicyPart2: 'Terms of Service ',
    privacyPolicyPart3: 'and acknowledge the ',
    privacyPolicyPart4: 'Privacy Policy',
  },
  registrationSuccessfulModalScreen: {
    titleText: 'Registration completed successfully',
    subTitleText:
      'To become an AgButler Approved laborer, supply references to earn star ratings and an AgButler Approved badge before you complete your first job. The badge will be visible to potential employers in your profile.',
    buttonText: 'Add references',
    skip: 'Skip',
  },
  privacyPolicyScreen: {
    privacyPolicyScreenTitle: 'Privacy Policy',
    termsOfServiceScreenTitle: 'Terms of Service',
  },
  editProfileModalScreen: {
    screenTitle: 'EDIT ACCOUNT',
    industry: 'Industry',
    specifications: 'Specifications',
  },
  laborersFilterModalScreen: {
    screenTitle: 'Filter',
    stateTitle: 'State',
    statePlaceholder: 'Washington',
    cityPlaceholder: 'Washington',
    cityTitle: 'City/Town',
    radiusTitle: 'Radius',
    radiusPlaceholder: '5 miles',
    jobSpecificationTitle: 'Job Specification',
    jobSpecificationPlaceholder: 'Cattle',
    rateTitle: 'Rate',
    ratePlaceholder: '$25',
    ratingTitle: 'Rating from',
    ratingPlaceholder: '5',
    resetFilter: 'Reset filter',
    save: 'Save',
  },
  jobListingFilterModalScreen: {
    screenTitle: 'FILTER',
    stateTitle: 'State',
    statePlaceholder: 'Washington',
    cityPlaceholder: 'Washington',
    cityTitle: 'City/Town',
    radiusTitle: 'Radius',
    radiusPlaceholder: '5 miles',
    resetFilter: 'Reset filter',
    save: 'Save',
  },
  laborersScreen: {
    search: 'Search',
    defaultTextPrimary: `Sorry, don't find any laborers`,
  },
  buttons: {
    register: 'Register',
    login: 'Login',
    reload: 'Restart App',
    done: 'Done',
    edit: 'Edit',
    delete: 'Delete',
    viewMap: 'View on the map',
    save: 'Save',
    selectAll: 'Select all',
    send: 'Send',
    filter: 'Filter',
    add: 'Add',
    backToList: 'Back to list',
    close: 'Close',
  },
  profileScreen: {
    deleteAccount: 'delete account',
    deleteAccountWarnPart1: 'Deactivating your account will delete all your data.',
    deleteAccountWarnPart2: 'Data from deactivated accounts cannot be restored.',
    emailLabel: 'Email',
    phoneLabel: 'Cell phone',
    cityLabel: 'City/Town',
    stateLabel: 'State',
    fieldRepLabel: 'Field Rep',
    zipCodeLabel: 'Zip code',
    industryLabel: 'Industry',
    specificationLabel: 'Specification',
    salaryLabel: 'Base rate',
    logout: 'log out',
    switchRole: 'switch role',
    cropperToolbarTitle: 'Choose a profile photo',
    newProfilePhoto: 'New profile photo',
    removeProfilePhoto: 'Remove profile photo',
    removePhoto: 'Remove photo',
    deletePhoto: 'delete profile photo',
    references: 'References',
    descriptionLabel: 'Description',
    descriptionPlaceholder: 'Write description here',
    contactSupport: 'Contact Support',
    privacyPolicy: 'Privacy Policy',
    termsOfService: 'Terms of Service',
    resetPassword: 'reset-password',
    confirmEmail: 'confirm-email',
    confirmDelete: 'confirm-delete',
  },
  warningModal: {
    warning: 'Are you sure you want to',
    close: 'Close',
    cancel: 'Cancel',
    yes: 'Yes',
    okay: 'Okay',
  },
  jobDetails: {
    nameLabel: 'Job Name',
    locationNameLabel: 'Location name',
    locationLabel: 'Location',
    industryLabel: 'Job Industry',
    specificationLabel: 'Job Specification',
    workPeriodLabel: 'Work Period',
    salaryLabel: 'Base rate',
    paymentType: 'Payment Type',
    howManyLaborersLabel: 'How many for this job',
    descriptionLabel: 'Description',
    employerLabel: 'Employer',
  },
  laborerCard: {
    perHour: 'per hour',
    ratings: 'ratings',
  },
  laborerInfoModalScreen: {
    requestJobLabel: 'Request to job',
    deletedUser: 'This user account has been deleted',
    deletedUserAccount: 'Deleted user account',
    emailLabel: 'Email',
    phoneLabel: 'Cell phone',
    cityLabel: 'City/Town',
    stateLabel: 'State',
    zipCodeLabel: 'Zip code',
    industryLabel: 'Industry',
    specificationLabel: 'Specification',
    salaryLabel: 'Base rate',
    hiddenValue: '**********',
    ratings: 'ratings',
    decline: 'Decline',
    accept: 'Accept',
    accepted: 'Accepted',
    sendJobOffer: 'Send job offer',
    description: 'Description',
  },
  radius,
  jobDetailsModalScreen: {
    screenTitle: 'JOB DETAILS',
    applied: 'Applied',
    applyForJob: 'Apply for job',
    accept: 'Accept',
    repostJob: 'Repost job',
    cancelJob: 'Cancel',
    declined: 'Declined',
    decline: 'Decline',
    cancelString: 'You can cancel until',
    submitReviewButton: 'Submit Review',
    jobAvailability: 'This job is no longer available',
    jobBlocked: 'Your job is blocked.\nPlease contact Support for details.',
  },
  jobActionsModalScreens: {
    postJobScreenTitle: 'POST JOB',
    editJobScreenTitle: 'EDIT JOB',
    jobNameTitle: 'Job Name',
    jobNamePlaceholder: 'Vaccinate Cattle',
    industryTitle: 'Industry',
    paymentTitle: 'Payment type',
    paymentPlaceholder: 'Monthly payment',
    industryPlaceholder: 'Livestock',
    specificationTitle: 'Specification',
    specificationPlaceholder: 'Specification',
    workPeriodTitle: 'Work period',
    workPeriodPlaceholder: '5 april',
    locationNameTitle: 'Location name',
    locationPlaceholder: 'Farm',
    rateTitle: 'Rate',
    ratePlaceholder: '5',
    rateTypeTitle: 'Rate Type',
    rateTypePlaceholder: 'per hour',
    peopleAmountTitle: 'How many for this job?',
    peopleAmountPlaceholder: '5',
    descriptionTitle: 'Description',
    descriptionPlaceholder: 'write description here',
    saveButton: 'Save',
    postJobLocationMessage: 'You have to add Location before posting a new job',
  },
  postedJobScreen: {
    defaultPrimaryText: `Sorry, you don't have any jobs`,
    additionPrimaryText: `Sorry, don't find any jobs`,
    defaultTextSecondary: 'But you can add your first job',
    addJob: 'Add Job',
  },
  proposalsScreen: {
    defaultPrimaryText: `Sorry, you don't have proposals`,
    screenName: 'PROPOSALS',
    filterLabel: 'Pending',
  },
  jobRequestsScreen: {
    defaultPrimaryText: `Sorry, you don't have any job requests`,
  },
  jobOffersScreen: {
    defaultPrimaryText: `Sorry, don't find any job offers`,
  },
  JobListingScreen: {
    defaultPrimaryText: `Sorry, don't find any jobs`,
  },
  myFarmsModalScreen: {
    screenTitle: 'MY LOCATIONS',
    addLocation: 'Add location',
    defaultTextPrimary: "Sorry, you don't have locations",
    defaultTextSecondary: 'But you can add your first location',
  },
  offeredJobListModalScreen: {
    screenTitle: 'Jobs',
    warning: 'send',
    defaultPrimaryText: `Please, create job first`,
    addJob: 'Create Job',
    selectJob: 'Select job',
    thereAreNoJobs: 'There are no jobs',
  },
  farmActionModalScreen: {
    addLocationTitle: 'Add Location',
    locationTitle: 'Location name',
    locationPlaceholder: 'AgButler',
    contactNameTitle: 'Contact name',
    contactNamePlaceholder: 'Juliet',
    emailTitle: 'Email',
    emailPlaceholder: 'agbutler@gmail.com',
    cellPhoneTitle: 'Cell phone',
    cellPhonePlaceholder: '+12345 673 65',
    stateTitle: 'State',
    statePlaceholder: 'State',
    cityTitle: 'City/Town',
    cityPlaceholder: 'City',
    zipCodeTitle: 'Zip code',
    zipCodePlaceholder: '0000 00000',
    fillInfo: 'Fill with general info',
    editLocationScreenTitle: 'Edit Location',
  },
  farmInfoModalScreen: {
    contactNameLabel: 'Contact Name',
    emailLabel: 'Email',
    cellPhoneLabel: 'Cell Phone',
    cityLabel: 'City/Town',
    stateLabel: 'State',
    zipCodeLabel: 'Zip code',
    editButton: 'Edit',
  },
  switchRoleModalScreen: {
    industry: 'Industry',
    specifications: 'Specifications',
    salaryLabel: 'Base rate',
    screenTitle: 'Additional fields',
    descriptionLabel: 'Description',
    descriptionPlaceholder: 'Write description here',
    switchRoleButton: 'Save and switch role',
  },
  reviewLaborersModalScreen: {
    reviewLaborersScreenTitle: 'RATING',
    tapToRateLaborers: 'Tap to Rate Laborers',
    cancelButton: 'Cancel',
    submitReviewButton: 'Submit Review',
    warningMessage: 'submit rating',
  },
  pushNotifications: {
    title: 'We haven’t seen you in a while!',
    message: 'We have some new job offers!',
  },
  restorePasswordModalScreen: {
    screenName: 'Restore password',
    emailTitle: 'Email',
    emailPlaceholder: 'agbutler@gmail.com',
    buttonText: 'Send',
  },
  resetPasswordModalScreen: {
    screenName: 'Reset password',
    newPasswordTitle: 'New password',
    repeatPasswordTitle: 'Repeat password',
    buttonText: 'Update',
    successText: 'Password successfully changed',
  },
  myCardsButton: {
    title: 'My Cards',
  },
  logoutButton: {
    title: 'Logout',
  },
  deleteAccountButton: {
    title: 'Delete account',
  },
  myCardsScreen: {
    myCardsScreenTitle: 'MY CARDS',
    defaultTextPrimary: 'A card needs to be on file to finalize your connection to high-quality laborers',
    addCard: 'Add card',
    removeCard: 'Remove card',
    saveCard: 'Save card',
  },
  transactionHistoryScreen: {
    title: 'Transaction history',
    sorryText: `Sorry, you don't have any transactions`,
    close: 'Close',
    search: 'Search',
    emptyList: 'Offers not found',
  },
  changePasswordButton: {
    title: 'Change password',
  },
  changeEmailButton: {
    title: 'Change email',
  },
  paymentTypes: {
    instantPayment: 'Instant payment',
    monthlyPayment: 'Monthly payment',
  },
  myLocationsButton: {
    title: 'My Locations',
  },
  paymentCard: {
    dots: `\u2022\u2022\u2022\u2022 \u2022\u2022\u2022\u2022 \u2022\u2022\u2022\u2022`,
    dateLabel: 'month/year',
  },
  paymentDetails: {
    title: 'Payment details',
    search: 'Search',
    emptyList: 'Offers not found',
  },
  paymentSystemModal: {
    title: 'Payment Notification',
    warning: 'Your connection fee of 20$ will be charged\n to the card on file',
    warningCost: '$20.00',
    buttonTitle: 'Got it',
    addCardWarning: `You don't have any cards`,
    addCardButton: 'Add card',
    selectAnotherCard: 'Select another card',
    payLaterButton: 'Pay Later',
    payNowButton: 'Pay Now',
    failedPaymentWarning: 'Failed payment',
    modalExpression: 'To connect with the Laborer,\nplease make a payment',
    addCardSucceed: 'Your card was successfully added.',
    deleteCardSucceed: 'Your card was successfully deleted.',
  },
  failedPaymentScreen: {
    title: 'failed payments',
    fee: '$20.00',
  },
  defaultCard: {
    dots: `\u2022\u2022\u2022\u2022`,
  },
  mapModalScreen: {
    sorryText: `Sorry, don't find any jobs`,
  },
  mapFilterModalScreen: {
    industry: 'Industry',
    specifications: 'Specifications',
    screenTitle: 'Filter',
    resetFilter: 'Reset Filter',
    buttonSave: 'Save',
    cityTitle: 'City/Town',
    cityPlaceholder: 'City',
  },
  agFooter: {
    copyright: '\u00A92022 AgButler. All rights reserved.',
  },
  agHeader: {
    goBack: 'Go back',
    createNewAccount: 'Create new account',
  },
  referencesModalScreen: {
    screenTitle: 'References',
    defaultTextPrimary: "Sorry, you don't have references",
    addReference: 'Add reference',
  },
  referencesActionModalScreen: {
    screenTitleEdit: 'Edit reference',
    screenTitleAdd: 'Add reference',
    nameTitle: 'Name',
    namePlaceholder: 'Name',
    emailTitle: 'Email',
    emailPlaceholder: 'Email',
    phoneTitle: 'Cell phone',
    phonePlaceholder: '000-000-000',
  },
  approvedByAgButler: {
    approved: 'Approved by\nAgButler',
  },
  notificationsModalScreen: {
    screenTitle: 'Notifications',
    defaultTextPrimary: "Sorry, you don't have notifications",
  },
  warnings: {
    selectSpecification: 'Please select specification',
    selectIndustry: 'Please select industry first',
    selectState: 'Please select state first',
    selectCity: 'Please select city first',
    selectStateAndCity: 'Please select state and city first',
    urlProblem: `Don't know how to open this URL:`,
    userLocation: `Unable to determine the user's location`,
    locationNotFound: `Unable to determine the coordinates`,
    commonWarning: `Something went wrong, please contact support: ${SUPPORT_EMAIL}`,
    openUrlError: "Sorry, we don't know how to open it",
    blockedUser: 'The user is blocked',
    blockedJob: 'This job is blocked',
    changePasswordMessage: 'Password successfully changed. Please sign in with your new password.',
    timeoutMessage: 'Looks like the server is taking too long to respond',
    networkMessage: 'The network connection is lost',
    stripeNotLoaded: 'Stripe is loading...',
    versionOutdated: 'Your version is outdated!',
    redirectStore: 'Redirect to the Store',
    largeImage: 'Uploaded image is too large',
  },
};

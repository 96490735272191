import { useCallback, useEffect } from 'react';
import { editNotificationAction, reduceUnreadNotifications } from '@root/redux/actions';
import { useDispatch } from 'react-redux';

export const useReadNotification = (notificationId: number | null) => {
  const dispatch = useDispatch();

  const editNotificationCallback = useCallback(
    (isSuccess: boolean) => {
      isSuccess && dispatch(reduceUnreadNotifications());
    },
    [dispatch],
  );

  useEffect(() => {
    notificationId &&
      dispatch(
        editNotificationAction({
          notificationId,
          callback: editNotificationCallback,
        }),
      );
  }, [dispatch, notificationId, editNotificationCallback]);
};

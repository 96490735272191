import { Job } from '@root/general/types';
import React, { useRef, useState } from 'react';
import {
  jobActionFieldFormData,
  jobActionScreenMode,
  useJobActionModalScreen,
} from '@root/hooks/useJobActionModalScreen';
import { CustomInputAdapter } from '@root/web/components/CustomInput/customInputAdapter';
import { FormikHandleChangeType, FormikSetFieldTouchedType, FormikValuesType } from '@root/mobile/types/inputTypes';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import { validationSchema } from '@root/mobile/utils/validationSchema';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { strings } from '@root/strings';
import { DatePickerModal } from '@root/web/components/DatePickerModal';
import { WarningModal } from '@root/web/components/WarningModal';
import { useHistory } from 'react-router-dom';
import { route } from '@root/web/consts';
import { useStyles } from './styles';
import { deviceType, PAY_METHODS } from '@root/general/consts';

import { CustomTabHeader } from '@root/web/components/CustomTabHeader';
import Scrollbars from 'react-custom-scrollbars';
import { CustomTabContainer } from '@root/web/components/CustomTabContainer';
import { PickerMenu } from '@root/web/components/PickerMenu';
import { AddCardModal } from '@root/web/screens/ProfileScreen/components/MyCards/components/AddCardModal';
import { usePaymentCardsWeb } from '@root/web/hooks/usePaymentCardWeb';

const { buttons, jobActionsModalScreens: string } = strings;

type JobActionScreenProps = {
  job?: Job;
  mode: jobActionScreenMode;
  setJobCallback?: (editedJob: Job) => void;
  goBackCallback: () => void;
};

export const JobActionScreen = (props: JobActionScreenProps) => {
  const styles = useStyles();
  const history = useHistory();
  const ref = useRef<any>();

  const { job, mode, goBackCallback, setJobCallback } = props;

  const [isCardModalVisible, setCardModalVisible] = useState(false);

  const isEditJobMode = mode === jobActionScreenMode.EDIT;

  const deleteCallback = () => {
    history.push(route.JOBS);
  };

  const goToCardsScreen = () => {
    // history.push(route.CARDS);
    setCardModalVisible(true);
  };

  const [, onAddNewCardWeb] = usePaymentCardsWeb();

  const [
    farmNameModalVisible,
    setFarmNameModalVisible,
    industryModalVisible,
    setIndustryModalVisible,
    jobSpecificationsModalVisible,
    setJobSpecificationsModalVisible,
    workPeriodModalVisible,
    setWorkPeriodModalVisible,
    rateTypeModalVisible,
    setRateTypeModalVisible,
    allIndustries,
    initialValue,
    farms,
    isLoading,
    setFarmId,
    setSpecificationId,
    setRateTypeId,
    formFields,
    specificationsData,
    ratesData,
    onPressJobAction,
    clearDependentOnIndustryFields,
    isDeleteWarningVisible,
    setDeleteWarningModalVisible,
    onPressDeletePostedJob,
    jobName,
    industryId,
    specificationId,
    farmId,
    rateTypeId,
    paymentModalVisible,
    setPaymentModalVisible,
    paymentTypeId,
    setPaymentTypeId,
  ] = useJobActionModalScreen(
    isEditJobMode,
    deleteCallback,
    goBackCallback,
    setJobCallback,
    job,
    deviceType.web,
    goToCardsScreen,
  );

  const renderInputForms = (
    handleChange: FormikHandleChangeType,
    values: FormikValuesType,
    errors: FormikErrors<FormikValuesType>,
    touched: FormikTouched<FormikValuesType>,
    setFieldTouched: FormikSetFieldTouchedType,
  ) => {
    return formFields.map((field) => {
      const { id: fieldId, title, onPress, valuesName } = field;

      switch (fieldId) {
        case jobActionFieldFormData.payment_type:
          return (
            <PickerMenu
              key={fieldId}
              title={title}
              onOpen={onPress}
              data={PAY_METHODS}
              value={paymentTypeId}
              setValue={setPaymentTypeId}
              handleChange={handleChange(valuesName)}
              isOpen={paymentModalVisible}
              onClose={() => setPaymentModalVisible(false)}
            />
          );
        case jobActionFieldFormData.industry:
          return (
            <PickerMenu
              key={fieldId}
              title={title}
              onOpen={onPress}
              data={allIndustries}
              value={industryId}
              setValue={clearDependentOnIndustryFields(handleChange)}
              handleChange={handleChange(valuesName)}
              isOpen={industryModalVisible}
              onClose={() => setIndustryModalVisible(false)}
            />
          );
        case jobActionFieldFormData.jobSpecification:
          return (
            <PickerMenu
              key={fieldId}
              title={title}
              onOpen={onPress}
              data={specificationsData}
              value={specificationId}
              setValue={setSpecificationId}
              handleChange={handleChange(valuesName)}
              isOpen={jobSpecificationsModalVisible}
              onClose={() => setJobSpecificationsModalVisible(false)}
            />
          );
        case jobActionFieldFormData.farmName:
          return (
            <PickerMenu
              key={fieldId}
              title={title}
              onOpen={onPress}
              data={farms}
              value={farmId}
              setValue={setFarmId}
              handleChange={handleChange(valuesName)}
              isOpen={farmNameModalVisible}
              onClose={() => setFarmNameModalVisible(false)}
            />
          );
        case jobActionFieldFormData.rateType:
          return (
            <PickerMenu
              key={fieldId}
              title={title}
              onOpen={onPress}
              data={ratesData}
              value={rateTypeId}
              setValue={setRateTypeId}
              handleChange={handleChange(valuesName)}
              isOpen={rateTypeModalVisible}
              onClose={() => setRateTypeModalVisible(false)}
            />
          );
        default:
          return (
            <div key={fieldId}>
              <CustomInputAdapter item={{ ...field, handleChange, values, errors, touched, setFieldTouched }} />
            </div>
          );
      }
    });
  };

  return (
    <Formik
      initialValues={initialValue}
      onSubmit={onPressJobAction}
      validationSchema={validationSchema(formFields)}
      validateOnMount={true}
    >
      {({ handleChange, handleSubmit, values, errors, touched, setFieldTouched, isValid }) => (
        <CustomTabContainer ref={ref} tabPositionFixed>
          <CustomTabHeader
            title={mode === jobActionScreenMode.EDIT ? string.editJobScreenTitle : string.postJobScreenTitle}
            onTabHeaderClose={goBackCallback}
            buttonIcon={require('@root/web/assets/icons/delete.svg')}
            buttonPress={isEditJobMode ? () => setDeleteWarningModalVisible(true) : undefined}
            buttonTitle={buttons.delete}
          />
          <Scrollbars>
            <div className={styles.content}>
              {renderInputForms(handleChange, values, errors, touched, setFieldTouched)}
            </div>
          </Scrollbars>
          <div className={styles.submitButtonContainer}>
            <CustomButton
              type={customButtonType.RED}
              title={string.saveButton}
              onPress={handleSubmit}
              disabled={isLoading || !isValid}
            />
          </div>
          {workPeriodModalVisible && (
            <DatePickerModal
              setValue={handleChange('workPeriod')}
              isModalVisible={workPeriodModalVisible}
              setModalVisible={setWorkPeriodModalVisible}
              ref={ref}
            />
          )}
          <WarningModal
            expression={`delete ${jobName}`}
            isModalVisible={isDeleteWarningVisible}
            setModalVisible={setDeleteWarningModalVisible}
            onApply={onPressDeletePostedJob}
          />
          {isCardModalVisible && (
            <AddCardModal
              onAddNewCardWeb={onAddNewCardWeb}
              isModalVisible={isCardModalVisible}
              setModalVisible={setCardModalVisible}
            />
          )}
        </CustomTabContainer>
      )}
    </Formik>
  );
};

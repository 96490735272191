import { Button } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';

type CustomRightTabHeaderProps = {
  title: string;
  onTabHeaderClose?: () => void;
  buttonPress?: () => void;
  buttonTitle?: string;
  buttonIcon?: string;
};

export const CustomTabHeader = (props: CustomRightTabHeaderProps) => {
  const { title, onTabHeaderClose, buttonPress, buttonTitle = '', buttonIcon } = props;

  const styles = useStyles();

  const capitalizeTitle = title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();

  return (
    <div className={styles.headerContainer}>
      <span className={styles.headerTitleText}>{capitalizeTitle}</span>
      <div className={styles.buttonsContainer}>
        {buttonPress && (
          <CustomButton
            type={customButtonType.WHITE}
            title={buttonTitle}
            onPress={buttonPress}
            icon={buttonIcon}
            className={styles.jobActionTabButton}
            iconClassName={styles.jobActionTabButtonIcon}
            ellipse
          />
        )}
        {onTabHeaderClose && (
          <Button onClick={onTabHeaderClose} variant='outlined' className={styles.closeImageContainer}>
            <img src={require('@root/web/assets/icons/add.svg')} alt='' className={styles.closeImage} />
          </Button>
        )}
      </div>
    </div>
  );
};

import { Api, CustomApiResponse } from '@root/services/api';
import { FetchJobsByOffersAction } from '@root/redux/actions/types';
import { call, put } from 'redux-saga/effects';
import { ErrorResponse, JobArray, OfferArray } from '@root/general/types';
import { CALL_COMMON_ERROR_HANDLER, CALL_UNEXPECTED_ERROR_HANDLER } from '@root/general/consts/actions';
import { JOBS_STATUS } from '@root/general/consts';
import { getNotOfferedJobs } from '@root/mobile/utils/getNotOfferedJobs';

const DEFAULT_PAGE = 1;

export function* fetchJobsByOffers(api: Api, action: FetchJobsByOffersAction) {
  const { page, laborerId, callback } = action.payload;

  try {
    const response: CustomApiResponse<JobArray, ErrorResponse> = yield call(
      api.fetchUserJobs,
      JOBS_STATUS.OFFERED.id,
      page,
    );

    if (response.ok && response.data) {
      const jobIdsArray = response.data.map((job) => job.id);

      const additionalResponse: CustomApiResponse<OfferArray, ErrorResponse> = yield call(
        api.fetchUserOffersByJobIds,
        laborerId,
        jobIdsArray,
      );

      if (additionalResponse.ok && additionalResponse.data) {
        yield callback &&
          call(
            callback,
            true,
            getNotOfferedJobs(response.data, additionalResponse.data),
            response.meta?.lastPage ?? DEFAULT_PAGE,
          );
      }

      if (!additionalResponse.ok) {
        yield callback && call(callback, false);
        yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: additionalResponse });
      }
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

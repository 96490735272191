import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    left: '50%',
    top: '50%',
  },
  loader: {
    position: 'relative',
    left: '-50%',
    top: '-50%',
  },
}));

import React from 'react';
import { CustomLoader } from '@root/web/components/CustomLoader';
import { useStyles } from './styles';

export const ScrollBottomLoader = () => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <CustomLoader />
    </div>
  );
};

import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles({
  endAdornment: {
    paddingRight: 10,
  },
  autocomplete: {
    marginBottom: 25,
  },
});

export const stateFieldTheme = createMuiTheme({
  overrides: {
    MuiChip: {
      root: {
        backgroundColor: colors.red,
      },
      label: {
        color: colors.white,
      },
      deleteIcon: {
        color: colors.white,
      },
    },
  },
  palette: {
    secondary: {
      main: colors.grey,
    },
    primary: {
      main: colors.grey,
    },
  },
});

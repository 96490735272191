import { SignInData } from '@root/redux/reducers/types';
import {
  AUTH_INITIALISE,
  SIGN_UP_REQUEST,
  SIGN_IN_REQUEST,
  SET_AUTH_HEADER,
  CHANGE_PASSWORD_REQUEST,
  EDIT_USER_REQUEST,
  EDIT_USER_AVATAR_REQUEST,
  FETCH_USER_REQUEST,
  FETCH_USER_BY_ID_REQUEST,
  RESET_PASSWORD_REQUEST,
  RESTORE_PASSWORD_REQUEST,
  SET_TOKEN,
  EDIT_USER_ROLE_REQUEST,
  REDUCE_UNREAD_NOTIFICATION,
  FETCH_FIELD_REPS_REQUEST,
  CHANGE_EMAIL_REQUEST,
  CONFIRM_CHANGE_EMAIL_REQUEST,
  DELETE_ACCOUNT_REQUEST,
  CONFIRM_DELETE_ACCOUNT_REQUEST,
  AUTH_SIGN_OUT,
  CLEAR_USER_DATA_STATE,
} from '@root/general/consts/actions';
import {
  ChangePasswordPayload,
  EditUserAvatarPayload,
  EditUserPayload,
  FetchUserPayload,
  FetchUserByIdPayload,
  InitializeAppPayload,
  ResetPasswordPayload,
  RestorePasswordPayload,
  SignUpPayload,
  EditUserRolePayload,
  ChangeEmailPayload,
  ConfirmChangeEmailPayload,
  DeleteAccountPayloadType,
  ConfirmDeleteAccountPayloadType,
} from '@root/redux/actions/types';

export const initialiseAppAction = (payload: InitializeAppPayload) => ({
  type: AUTH_INITIALISE,
  payload,
});

export const signUpRequestAction = (payload: SignUpPayload) => ({
  type: SIGN_UP_REQUEST,
  payload,
});

export const signInRequestAction = (signInData: SignInData, deviceId: string, onSuccessCallback?: () => void) => ({
  type: SIGN_IN_REQUEST,
  payload: { signInData, deviceId, onSuccessCallback },
});

export const signOutAction = (appVersionChecked = true) => ({
  type: AUTH_SIGN_OUT,
  payload: { appVersionChecked },
});

export const clearUserDataStatesAction = (appVersionChecked = true) => ({
  type: CLEAR_USER_DATA_STATE,
  payload: { appVersionChecked },
});

export const deleteAccountAction = (payload?: DeleteAccountPayloadType) => ({
  type: DELETE_ACCOUNT_REQUEST,
  payload,
});

export const setAuthHeaderAction = (token: string) => ({
  type: SET_AUTH_HEADER,
  payload: token,
});

export const setTokenAction = (token: string) => ({
  type: SET_TOKEN,
  payload: token,
});

export const resetPasswordAction = (payload: ResetPasswordPayload) => ({
  type: RESET_PASSWORD_REQUEST,
  payload,
});

export const restorePasswordAction = (payload: RestorePasswordPayload) => ({
  type: RESTORE_PASSWORD_REQUEST,
  payload,
});

export const changePasswordAction = (payload: ChangePasswordPayload) => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload,
});

export const changeEmailAction = (payload: ChangeEmailPayload) => ({
  type: CHANGE_EMAIL_REQUEST,
  payload,
});

export const confirmChangeEmailAction = (payload: ConfirmChangeEmailPayload) => ({
  type: CONFIRM_CHANGE_EMAIL_REQUEST,
  payload,
});

export const confirmDeleteAccountAction = (payload: ConfirmDeleteAccountPayloadType) => ({
  type: CONFIRM_DELETE_ACCOUNT_REQUEST,
  payload,
});

export const fetchUserAction = (payload?: FetchUserPayload) => ({
  type: FETCH_USER_REQUEST,
  payload,
});

export const fetchUserByIdAction = (payload: FetchUserByIdPayload) => ({
  type: FETCH_USER_BY_ID_REQUEST,
  payload,
});

export const editUserAction = (payload: EditUserPayload) => ({
  type: EDIT_USER_REQUEST,
  payload,
});

export const editUserRoleAction = (payload: EditUserRolePayload) => ({
  type: EDIT_USER_ROLE_REQUEST,
  payload,
});

export const editUserAvatarAction = (payload: EditUserAvatarPayload) => ({
  type: EDIT_USER_AVATAR_REQUEST,
  payload,
});

export const reduceUnreadNotifications = () => {
  return {
    type: REDUCE_UNREAD_NOTIFICATION,
  };
};

export const fetchFieldRepsAction = () => ({
  type: FETCH_FIELD_REPS_REQUEST,
});

import { colors } from '@root/general/colors';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      backgroundColor: colors.white,
      borderRadius: 10,
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      borderColor: colors.athensGray,
      borderWidth: 1,
      borderStyle: 'solid',
      padding: '30px 20px',
      [theme.breakpoints.down('md')]: {
        padding: '15px 10px',
      },
    },
    userName: {
      color: colors.black,
      textAlign: 'center',
      letterSpacing: -0.24,
      fontSize: 19,
      fontFamily: fonts.SFUiDisplayBold,
      lineHeight: '24px',
      margin: '8px 0px 0px',
    },
    userEmail: {
      color: colors.black,
      textAlign: 'center',
      letterSpacing: -0.24,
      fontSize: 16,
      fontFamily: fonts.SFUiDisplayMedium,
      margin: '4px 0px 12px',
    },
    customSwitcher: {
      marginBottom: 0,
    },
    removePhotoContainer: {
      height: 40,
    },
    referencesContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      backgroundColor: colors.white,
      marginTop: 10,
      borderRadius: 10,
      borderColor: colors.athensGray,
      borderWidth: 1,
      borderStyle: 'solid',
    },
    contactSupportBorderText: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: colors.athensGray,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderRadius: 0,
    },
  }),
);

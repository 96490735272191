import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';
import { isMobile } from 'react-device-detect';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    backgroundColor: colors.lightGrey,
    width: 450,
    borderColor: colors.athensGray,
    borderWidth: 1,
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      width: isMobile ? '100%' : 450,
    },
  },
  horizontalScroll: {
    display: 'none',
  },
  notificationsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  notificationCard: {
    display: 'flex',
    width: '100%',
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
    borderLeftColor: colors.grey,
    marginBottom: 2,
    boxSizing: 'border-box',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.white,
    },
  },
  notificationCardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flex: 1,
    padding: '12px 20px 12px 12px',
    boxSizing: 'border-box',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: colors.redOpacity2,
  },
  notificationCardLeftContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    maxWidth: '70%',
  },
  notificationTitle: {
    color: colors.darkGrey,
    letterSpacing: -0.24,
    fontSize: 15,
    fontFamily: fonts.SFUiDisplayBold,
    marginBottom: 8,
  },
  notificationDescription: {
    color: colors.darkGrey,
    letterSpacing: -0.24,
    fontSize: 15,
    fontFamily: fonts.SFUiDisplayLight,
    wordWrap: 'break-word',
  },
  notificationDate: {
    display: 'flex',
    width: 80,
    color: colors.grey,
    letterSpacing: -0.24,
    fontSize: 12,
    justifyContent: 'flex-end',
  },
}));

import React from 'react';
import { IndustryValueProps } from '@root/general/types';
import { useStyles } from './styles';

export const IndustryValue = (props: IndustryValueProps) => {
  const styles = useStyles();
  const { industries = [] } = props;

  const industryValueText = industries.map((industry, index, industries) => {
    const { name, color, id } = industry;

    const addComma = index !== industries.length - 1 ? ',' : '';
    const industryValue = name + addComma;

    return (
      <div className={styles.valueContainer} key={id}>
        <div className={styles.icon} style={{ backgroundColor: color }} />
        <span className={styles.valueText}>{industryValue}</span>
      </div>
    );
  });

  return <div className={styles.container}>{industryValueText}</div>;
};

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles({
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 30px 10px 30px',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: colors.athensGray,
  },
  resetButton: {
    marginTop: -20,
  },
});

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tabsContainer: {
    paddingLeft: 20,
    backgroundColor: colors.white,
    borderColor: colors.athensGray,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  tabsIndicator: {
    backgroundColor: colors.red,
  },
  tabRoot: {
    minWidth: 'auto',
    color: colors.darkGrey,
    fontSize: 17,
    fontFamily: fonts.IBMPlexMonoMedium,
    lineHeight: '22px',
    letterSpacing: -0.41,
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: colors.materialHover,
    },
  },
}));

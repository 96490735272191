import { Api, CustomApiResponse } from '@root/services/api';
import { FetchAdditionalNewOffersAction, FetchOffersAction } from '@root/redux/actions/types';
import { ErrorResponse, OfferArray } from '@root/general/types';
import { call, put } from 'redux-saga/effects';
import {
  CALL_COMMON_ERROR_HANDLER,
  CALL_UNEXPECTED_ERROR_HANDLER,
  FETCH_OFFERS_SUCCESS,
} from '@root/general/consts/actions';
import { getOffersFilterById } from '@root/utils/getOffersFilterById';
import { getOffersTypeByFilterId } from '@root/mobile/utils/getOffersTypeByFilterId';

const DEFAULT_PAGE = 1;

export function* fetchOffers(api: Api, action: FetchOffersAction) {
  const { offersFilterId, callback } = action.payload;

  try {
    const offersType = getOffersTypeByFilterId(offersFilterId);
    const { offerStatusId, name: tabName } = getOffersFilterById(offersFilterId);

    const response: CustomApiResponse<OfferArray, ErrorResponse> = yield call(
      api.fetchUserOffersRequest,
      offersType,
      { offerStatusId },
      DEFAULT_PAGE,
    );

    if (response.ok && response.data) {
      yield put({
        type: FETCH_OFFERS_SUCCESS,
        payload: { tabName, offerStatusId, offers: response.data, lastPage: response.meta?.lastPage ?? DEFAULT_PAGE },
      });
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  } finally {
    yield callback && call(callback);
  }
}

export function* fetchAdditionalOffers(api: Api, action: FetchAdditionalNewOffersAction) {
  const { offersFilterId, callback, page } = action.payload;

  try {
    const offersType = getOffersTypeByFilterId(offersFilterId);
    const { offerStatusId } = getOffersFilterById(offersFilterId);

    const response: CustomApiResponse<OfferArray, ErrorResponse> = yield call(
      api.fetchUserOffersRequest,
      offersType,
      { offerStatusId },
      page,
    );

    if (response.ok && response.data) {
      yield callback && call(callback, true, response.data);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

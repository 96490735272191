import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { CityType } from '@root/general/types';
import { useDispatch } from 'react-redux';
import { searchCitiesAction } from '@root/redux/actions';
import { debounce } from 'lodash';
import { FETCH_DEBOUNCE_TIME } from '@root/general/consts';

type UseChooseCityModalScreenReturnType = [
  never[],
  Dispatch<SetStateAction<string>>,
  string,
  (city: CityType) => void,
  boolean,
];

export const useChooseCityModalScreen = (
  selectedStateId: number | null,
  setSelectedCityId: Dispatch<SetStateAction<number | null>>,
  setValue?: (e: string | ChangeEvent<any>) => void,
  goBackCallback?: () => void,
  setCity?: Dispatch<SetStateAction<CityType | null>>,
): UseChooseCityModalScreenReturnType => {
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();
  const [cities, setCities] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const searchCities = useCallback(
    debounce((searchValue: string) => {
      setLoading(true);
      dispatch(
        searchCitiesAction({
          selectedStateId,
          searchValue,
          setCities,
          callback: () => {
            setLoading(false);
          },
        }),
      );
    }, FETCH_DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    searchCities(searchValue);
  }, [searchValue, searchCities]);

  const onPress = (city: CityType) => {
    const { id, name } = city;

    setSelectedCityId(id);
    if (setValue) {
      setValue(name);
    }
    if (setCity) {
      setCity(city);
    }
    if (goBackCallback) {
      goBackCallback();
    }
  };
  return [cities, setSearchValue, searchValue, onPress, isLoading];
};

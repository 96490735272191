import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      minWidth: 200,
      backgroundColor: colors.white,
      '& .MuiOutlinedInput-input': {
        fontSize: 17,
        fontFamily: fonts.OpenSansRegular,
        lineHeight: '23px',
        letterSpacing: -0.175,
      },
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.athensGray,
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
        backgroundColor: colors.white,
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.athensGray,
      },
    },
    itemText: {
      fontSize: 17,
      fontFamily: fonts.OpenSansRegular,
      lineHeight: '23px',
      letterSpacing: -0.175,
    },
  }),
);

import { JobRequestFeedbackCallbackProps, Offer } from '@root/general/types';
import { OFFERS_BY_STATUS } from '@root/general/consts';
import { Dispatch, SetStateAction } from 'react';

export const proposalsJobRequestFeedbackCallback = (
  proposalsByState: Array<Offer>,
  setProposalsByState: Dispatch<SetStateAction<Array<Offer>>>,
  proposalsFilterName: string,
) => (jobRequestFeedbackCallbackProps: JobRequestFeedbackCallbackProps) => {
  const { isSuccess, offerStatusId, offerId, navigationCallback } = jobRequestFeedbackCallbackProps;

  if (isSuccess) {
    const filteredProposals = proposalsByState.filter((offer: Offer) => {
      if (offer.id === offerId) {
        offer.status = offerStatusId;
      }

      return offer.id !== offerId || proposalsFilterName === OFFERS_BY_STATUS.ALL.name;
    });

    setProposalsByState(filteredProposals);
    if (offerStatusId === OFFERS_BY_STATUS.DECLINED.offerStatusId) {
      navigationCallback();
    }
  }
};

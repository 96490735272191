import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() => ({
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  gridContainer: {
    padding: '0px 30px',
  },
  tapToRateContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  tapToRateText: {
    fontSize: 17,
    fontFamily: fonts.SFUiDisplayBold,
    letterSpacing: -0.41,
    lineHeight: '22px',
    color: colors.grey,
    textAlign: 'center',
  },
  submitButtonContainer: {
    marginTop: 'auto',
    padding: '10px 30px 20px',
  },
  horizontalScroll: {
    display: 'none',
  },
}));

import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { strings } from '@root/strings';
import { AuthFooter } from '@root/web/components/AuthFooter';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { useStyles } from './styles';
import { RightImageContainer } from '@root/web/components/RightImageContainer';
import { AuthHeader } from '@root/web/components/AuthHeader';
import { ToastContainer } from 'react-toastify';
import { WEB_NOTIFICATION_TIMEOUT } from '@root/general/consts';
import { route } from '@root/web/consts';

const { welcomeScreen: string } = strings;

export const WelcomePage = () => {
  const styles = useStyles();
  let location = useLocation();
  let history = useHistory();

  const goToRegistration = () => {
    history.push(route.CHOOSE_ROLE, location.state);
  };

  const goToLogin = () => {
    history.push(route.LOGIN, location.state);
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <AuthHeader goBackVisible={false} createNewAccountVisible={false} />
        <div className={styles.main}>
          <span className={styles.descriptionHeader}>{string.descriptionHeader}</span>
          <CustomButton type={customButtonType.RED} title={string.signIn} onPress={goToLogin} maxWidth={350} />
          <CustomButton type={customButtonType.TEXT} title={string.createAccount} onPress={goToRegistration} />
        </div>
        <AuthFooter />
      </div>
      <ToastContainer autoClose={WEB_NOTIFICATION_TIMEOUT} />
      <RightImageContainer isLogo />
    </div>
  );
};

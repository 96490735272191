import { OFFERS_BY_STATUS, offersType } from '@root/general/consts';

export const getOffersTypeByFilterId = (offersFilterId: number) => {
  switch (offersFilterId) {
    case OFFERS_BY_STATUS.NEW.id:
    case OFFERS_BY_STATUS.RECEIVED.id:
      return offersType.incoming;
    case OFFERS_BY_STATUS.SENT.id:
    case OFFERS_BY_STATUS.APPLIED.id:
      return offersType.outgoing;
    default:
      return offersType.offers;
  }
};

import React, { Dispatch, forwardRef, ForwardRefRenderFunction, SetStateAction } from 'react';
import { Modal } from '@material-ui/core';
import { StateLocationModalType } from '@root/mobile/navigators/types';
import { useChooseStateModalScreenReturnType } from '@root/hooks/useChooseStateModalScreen';
import { StateType } from '@root/general/types';
import { useStyles } from './styles';
import { SearchInput } from '@root/web/components/SearchInput';
import { Scrollbars } from 'react-custom-scrollbars';
import { modalStyle } from '@root/general/consts';

type ChooseStateModalProps = {
  isModalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
} & StateLocationModalType;

const ChooseStateModalComponent: ForwardRefRenderFunction<any, ChooseStateModalProps> = (props, ref: any) => {
  const styles = useStyles();

  const {
    isModalVisible,
    setModalVisible,
    setValue = () => {},
    setSelectedStateId = () => {},
    setSelectedCityId = () => {},
    setCityValue = () => {},
    setState = () => {},
    setCity = () => {},
    setRadius = () => {},
    isOneState = true,
  } = props;

  const closeModal = () => {
    setModalVisible(false);
  };

  const [searchValue, setSearchValue, filteredStates, onPress] = useChooseStateModalScreenReturnType(
    setValue,
    setSelectedStateId,
    setSelectedCityId,
    setCityValue,
    setState,
    setCity,
    closeModal,
    setRadius,
    isOneState,
  );

  const renderItem = (item: StateType) => {
    const { id, name, abbreviation } = item;

    return (
      <span key={id} className={styles.item} onClick={() => onPress(item)}>
        {`${name}, ${abbreviation}`}
      </span>
    );
  };

  return (
    <Modal
      open={isModalVisible}
      style={modalStyle}
      onClose={closeModal}
      className={styles.modalContainer}
      container={ref?.current}
    >
      <div className={styles.container}>
        <SearchInput value={searchValue} placeholder={'Search'} onChange={setSearchValue} />
        <Scrollbars className={styles.scrollbars}>{filteredStates.map(renderItem)}</Scrollbars>
      </div>
    </Modal>
  );
};

export const ChooseStateModal = forwardRef(ChooseStateModalComponent);

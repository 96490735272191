import React from 'react';
import { TERMS_AND_PRIVACY_URL } from '@root/general/consts';
import { strings } from '@root/strings';
import { Checkbox, ThemeProvider } from '@material-ui/core';
import { useStyles, checkBoxTheme } from '@root/web/screens/RegistrationScreen/styles';
import clsx from 'clsx';
import { TermsAndConditionsProps } from '@root/general/types';

const { registrationScreen: string } = strings;

export const TermsAndConditions = (props: TermsAndConditionsProps) => {
  const styles = useStyles();
  const { isChecked, toggleCheckBox } = props;

  return (
    <div className={styles.checkBoxContainer}>
      <ThemeProvider theme={checkBoxTheme}>
        <Checkbox checked={isChecked} onChange={toggleCheckBox} disableRipple disableFocusRipple disableTouchRipple />
      </ThemeProvider>
      <span className={styles.checkBoxText}>
        {string.termsAndConditionsPart1}
        <a
          className={clsx(styles.checkBoxText, styles.checkBoxTextLink)}
          href={TERMS_AND_PRIVACY_URL}
          target={'_blank'}
        >
          {string.termsAndConditionsPart2}
        </a>
        {string.termsAndConditionsPart3}
      </span>
    </div>
  );
};

import {
  FETCH_INDUSTRIES_REQUEST,
  FETCH_SPECIFICATIONS_REQUEST,
  FETCH_INDUSTRIES_WITH_SPECIFICATIONS_REQUEST,
} from '@root/general/consts/actions';

export const fetchIndustriesAction = () => ({
  type: FETCH_INDUSTRIES_REQUEST,
});

export const fetchSpecificationsAction = () => ({
  type: FETCH_SPECIFICATIONS_REQUEST,
});

export const fetchIndustriesWithSpecificationsAction = () => ({
  type: FETCH_INDUSTRIES_WITH_SPECIFICATIONS_REQUEST,
});

import React from 'react';
import { useStyles } from './styles';
import { strings } from '@root/strings';
import { RightImageContainer } from '@root/web/components/RightImageContainer';
import { AuthHeader } from '@root/web/components/AuthHeader';
import { AuthFooter } from '@root/web/components/AuthFooter';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import { validationSchema } from '@root/mobile/utils/validationSchema';
import { FormikHandleChangeType, FormikSetFieldTouchedType, FormikValuesType } from '@root/mobile/types/inputTypes';
import { CustomInputAdapter } from '@root/web/components/CustomInput/customInputAdapter';
import { useRestorePasswordScreen } from '@root/hooks/useRestorePassword';
import { useHistory } from 'react-router-dom';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { WEB_NOTIFICATION_TIMEOUT } from '@root/general/consts';
import { ToastContainer } from 'react-toastify';

const { restorePasswordModalScreen: string, buttons } = strings;

export const RestorePasswordScreen = () => {
  let history = useHistory();
  const styles = useStyles();

  const navigationCallback = () => {
    history.goBack();
  };

  const [initialValue, restorePasswordData, loading, onRestorePassword] = useRestorePasswordScreen(navigationCallback);

  const renderInputForms = (
    handleChange: FormikHandleChangeType,
    values: FormikValuesType,
    errors: FormikErrors<FormikValuesType>,
    touched: FormikTouched<FormikValuesType>,
    setFieldTouched: FormikSetFieldTouchedType,
  ) => {
    return restorePasswordData.map((item) => (
      <CustomInputAdapter key={item.id} item={{ ...item, handleChange, values, errors, touched, setFieldTouched }} />
    ));
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <AuthHeader />
        <Formik
          initialValues={initialValue}
          onSubmit={onRestorePassword}
          validationSchema={validationSchema(restorePasswordData)}
          validateOnMount={true}
        >
          {({ handleChange, handleSubmit, values, errors, isValid, touched, setFieldTouched }) => (
            <div className={styles.main}>
              <span className={styles.title}>{string.screenName}</span>
              <div className={styles.formContainer}>
                {renderInputForms(handleChange, values, errors, touched, setFieldTouched)}
              </div>
              <CustomButton
                type={customButtonType.RED}
                title={buttons.send}
                onPress={handleSubmit}
                maxWidth={350}
                disabled={loading || !isValid}
              />
            </div>
          )}
        </Formik>
        <AuthFooter />
      </div>
      <ToastContainer autoClose={WEB_NOTIFICATION_TIMEOUT} />
      <RightImageContainer />
    </div>
  );
};

import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { InputFieldPropsType } from '@root/mobile/components/CustomInput/types';
import { CustomInputType } from '@root/mobile/types/inputTypes';
import { CustomInput } from './index';

const InputFieldComponentAdapter: ForwardRefRenderFunction<any, InputFieldPropsType> = ({ item }, ref: any) => {
  const {
    id,
    title,
    values,
    valuesName,
    placeHolder,
    keyboardType,
    webMask = '',
    handleChange,
    multiline,
    autoLength,
    fieldStatus,
    setFieldStatus,
    icon,
    changedIcon,
    webIcon,
    webChangedIcon,
    onPress,
    errors = {},
    touched = {},
    setFieldTouched = () => {},
    infoMode = false,
  } = item;

  const customInputDefaultValue = values[valuesName];
  const customInputOnChangeText = handleChange(valuesName);
  const customInputErrors = errors[valuesName];
  const customInputTouched = touched[valuesName];
  const customInputOnPress = () => {
    onPress && onPress(handleChange(valuesName), handleChange('city'));
    setFieldTouched(valuesName);
  };
  const customInputSetFieldTouched = () => setFieldTouched(valuesName);

  const customInputItem: CustomInputType = {
    id,
    title,
    placeHolder,
    keyboardType,
    multiline,
    autoLength,
    fieldStatus,
    setFieldStatus,
    icon: webIcon ? webIcon : icon,
    changedIcon: webChangedIcon ? webChangedIcon : changedIcon,
    onPress: onPress ? customInputOnPress : null,
    webMask: webMask,
    errors: customInputErrors,
    touched: customInputTouched,
    onBlur: customInputSetFieldTouched,
    defaultValue: customInputDefaultValue,
    onChangeText: customInputOnChangeText,
    infoMode,
  };

  return <CustomInput key={id} item={customInputItem} ref={ref} />;
};

export const CustomInputAdapter = forwardRef(InputFieldComponentAdapter);

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    height: '100vh',
  },
  leftContainer: {
    display: 'flex',
    flex: 6,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '24px 24px 12px',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    marginBottom: 22,
  },
  title: {
    letterSpacing: -0.24,
    color: colors.black,
    textAlign: 'center',
    fontSize: 27,
    fontWeight: 'bold',
    marginBottom: 12,
  },
  subTitle: {
    letterSpacing: -0.24,
    color: colors.darkGrey,
    textAlign: 'center',
    fontSize: 17,
    marginBottom: 16,
    maxWidth: 560,
  },
}));

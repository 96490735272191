import { call, put, select } from 'redux-saga/effects';
import { Api, CustomApiResponse } from '@root/services/api';
import { ArrayFarmType, ErrorResponse, FarmType } from '@root/general/types';
import { getAuth, getUser } from '@root/selectors';
import { isArray } from 'lodash';
import {
  CreateFarmAction,
  DeleteFarmAction,
  EditFarmAction,
  FetchAdditionalFarmsAction,
  FetchFarmsAction,
} from '@root/redux/actions/types';
import { FarmData } from '@root/redux/reducers/types';
import {
  FETCH_FARMS_SUCCESS,
  CREATE_FARM_SUCCESS,
  DELETE_FARM_SUCCESS,
  EDIT_FARM_SUCCESS,
  CALL_COMMON_ERROR_HANDLER,
  CALL_UNEXPECTED_ERROR_HANDLER,
} from '@root/general/consts/actions';
import { commonNotificationHandler } from '@root/redux/sagas/helpers';

const DEFAULT_PAGE = 1;

export function* fetchFarms(api: Api, action: FetchFarmsAction) {
  const fetchCallback = action.payload?.callback;

  try {
    const { user } = yield select(getAuth);
    const { id } = user;
    const response: CustomApiResponse<ArrayFarmType, ErrorResponse> = yield call(api.fetchFarms, id, DEFAULT_PAGE);

    if (response.ok && isArray(response.data)) {
      yield fetchCallback && call(fetchCallback, true, response.data);
      yield put({
        type: FETCH_FARMS_SUCCESS,
        payload: { farms: response.data, lastPage: response.meta?.lastPage ?? DEFAULT_PAGE },
      });
    }

    if (!response.ok) {
      yield fetchCallback && call(fetchCallback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield fetchCallback && call(fetchCallback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* fetchAdditionalFarms(api: Api, action: FetchAdditionalFarmsAction) {
  const { page, callback } = action.payload;

  try {
    const { id } = yield select(getUser);

    const response: CustomApiResponse<ArrayFarmType, ErrorResponse> = yield call(api.fetchFarms, id, page);

    if (response.ok && response.data) {
      yield callback && call(callback, response.ok, response.data);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* createFarm(api: Api, action: CreateFarmAction) {
  const { data, callback } = action.payload;

  try {
    const response: CustomApiResponse<FarmData, ErrorResponse> = yield call(api.createFarm, data);

    if (response.ok && response.data) {
      yield put({ type: CREATE_FARM_SUCCESS, payload: response.data });
      yield commonNotificationHandler(response.messages);
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }

    yield callback && call(callback, response.ok);
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* deleteFarm(api: Api, action: DeleteFarmAction) {
  const { farmId, callback } = action.payload;

  try {
    const response: CustomApiResponse<{ messages: Array<string>; data: FarmData }, ErrorResponse> = yield call(
      api.deleteFarm,
      farmId,
    );

    if (response.ok && response.data) {
      yield put({ type: DELETE_FARM_SUCCESS, payload: farmId });
      yield commonNotificationHandler(response.messages);
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }

    yield callback && call(callback, response.ok);
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* editFarm(api: Api, action: EditFarmAction) {
  const { data, callback } = action.payload;

  try {
    const response: CustomApiResponse<FarmType, ErrorResponse> = yield call(api.editFarm, data);

    if (response.ok && response.data) {
      yield put({ type: EDIT_FARM_SUCCESS, payload: response.data });
      yield callback && call(callback, response.ok, response.data);

      yield commonNotificationHandler(response.messages);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core';
import { fonts } from '@root/general/fonts';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelContainer: {
    display: 'flex',
    flex: 1,
    padding: '16px 0px',
  },
  iconContainer: {
    display: 'flex',
    width: 24,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 6,
  },
  icon: {
    height: 20,
    width: 20,
  },
  labelText: {
    fontSize: 17,
    fontFamily: fonts.SFUiDisplaySemiBold,
    lineHeight: '22px',
    letterSpacing: -0.41,
  },
});

export const theme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        '&::before': {
          display: 'none',
        },
      },
      elevation1: {
        boxShadow: 'none',
      },
    },
    MuiAccordion: {
      root: {
        margin: `0 !important`,
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: 0,
        minHeight: 'auto !important',
        borderBottomWidth: 1,
        borderBottomColor: colors.athensGray,
        borderBottomStyle: 'solid',
      },
      content: {
        margin: `0 !important`,
      },
    },
  },
});

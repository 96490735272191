import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormikFieldFormDataType, FormikValuesType } from '@root/mobile/types/inputTypes';
import { changePasswordAction } from '@root/redux/actions';

enum fieldIds {
  password = 1,
  newPassword = 2,
  newPasswordConfirmation = 3,
}

type useChangePasswordScreenReturnType = [
  FormikValuesType,
  FormikFieldFormDataType,
  (changePasswordData: FormikValuesType, obj: any) => void,
  boolean,
];

export const useChangePasswordScreen = (successCallback?: () => void): useChangePasswordScreenReturnType => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const [hideInitialPassword, setHideInitialPassword] = useState(true);
  const [hideRepeatPassword, setHideRepeatPassword] = useState(true);

  const initialValue = {
    password: '',
    newPassword: '',
    newPasswordConfirmation: '',
  } as FormikValuesType;

  const changePasswordFormData: FormikFieldFormDataType = [
    {
      id: fieldIds.password,
      title: 'Current password',
      valuesName: 'password',
      placeHolder: '********',
      keyboardType: 'default',
      fieldStatus: hideInitialPassword,
      setFieldStatus: setHideInitialPassword,
      icon: require('@root/assets/icons/invisibleInput.png'),
      changedIcon: require('@root/assets/icons/visibleInput.png'),
      webIcon: require('@root/web/assets/icons/hidePassword.svg'),
      webChangedIcon: require('@root/web/assets/icons/visiblePassword.svg'),
      sm: 12,
    },
    {
      id: fieldIds.newPassword,
      title: 'New password',
      valuesName: 'newPassword',
      placeHolder: '********',
      keyboardType: 'default',
      fieldStatus: hidePassword,
      setFieldStatus: setHidePassword,
      icon: require('@root/assets/icons/invisibleInput.png'),
      changedIcon: require('@root/assets/icons/visibleInput.png'),
      webIcon: require('@root/web/assets/icons/hidePassword.svg'),
      webChangedIcon: require('@root/web/assets/icons/visiblePassword.svg'),
    },
    {
      id: fieldIds.newPasswordConfirmation,
      title: 'Repeat password',
      valuesName: 'newPasswordConfirmation',
      placeHolder: '********',
      keyboardType: 'default',
      fieldStatus: hideRepeatPassword,
      setFieldStatus: setHideRepeatPassword,
      icon: require('@root/assets/icons/invisibleInput.png'),
      changedIcon: require('@root/assets/icons/visibleInput.png'),
      webIcon: require('@root/web/assets/icons/hidePassword.svg'),
      webChangedIcon: require('@root/web/assets/icons/visiblePassword.svg'),
    },
  ];

  const onChangePassword = (changePasswordData: FormikValuesType) => {
    setLoading(true);

    const callback = (isSuccess: boolean) => {
      isSuccess && successCallback && successCallback();
      setLoading(false);
    };

    dispatch(changePasswordAction({ changePasswordData, callback }));
  };

  return [initialValue, changePasswordFormData, onChangePassword, isLoading];
};

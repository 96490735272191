import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    padding: '30px 10px 20px 20px',
    borderColor: colors.athensGray,
    borderWidth: '0px 1px 1px',
    borderStyle: 'solid',
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    backgroundColor: colors.white,
  },
  containerPadding: {
    paddingRight: 20,
  },
}));

import React, { forwardRef, ForwardRefRenderFunction, useCallback } from 'react';
import { ThemeProvider, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';
import { theme, infoInputTheme } from './styles';
import { CustomInputPropsType } from '@root/mobile/components/CustomInput/types';
import { debounce } from 'lodash';
import { KeyboardTypeOptions } from 'react-native';
import { MAX_INPUT_LENGTH, MAX_MULTILINE_INPUT_LENGTH } from '@root/general/consts/inputs';

const CustomInputComponent: ForwardRefRenderFunction<any, CustomInputPropsType> = ({ item }, ref: any) => {
  const {
    id,
    title,
    placeHolder,
    multiline,
    fieldStatus,
    setFieldStatus,
    icon,
    webIcon,
    changedIcon,
    webChangedIcon,
    onPress,
    webMask = '',
    errors,
    touched,
    onBlur,
    defaultValue,
    onChangeText = () => {},
    keyboardType,
    infoMode,
  } = item;

  const inputIcon = webIcon ? webIcon : icon;
  const inputChangedIcon = webChangedIcon ? webChangedIcon : changedIcon;
  const inputAutoComplete = title === 'Current password' ? 'new-password' : 'off';

  const isError = !!(touched && errors);
  const errorText = isError ? errors : ' ';
  const handleStatusChange = () => setFieldStatus && setFieldStatus(!fieldStatus);
  const handleImageChange = fieldStatus ? inputIcon : inputChangedIcon;

  const handleMouseDown = (event: any) => {
    event.preventDefault();
  };

  const RenderActiveIcon = () => (
    <IconButton aria-label='toggle password visibility' onClick={handleStatusChange} onMouseDown={handleMouseDown}>
      <img src={onPress ? inputIcon : handleImageChange} alt='' />
    </IconButton>
  );

  const onClick = () => {
    onPress && onPress();
  };

  const debounceChange = useCallback(
    debounce((value: string) => onChangeText(value), 500),
    [onChangeText],
  );

  const onChange = (event: any) => {
    debounceChange(event.target.value);
  };

  const onBlurInput = () => {
    onBlur && onBlur();
  };

  if (infoMode) {
    return (
      <ThemeProvider theme={infoInputTheme}>
        <TextField
          id={`${id}`}
          multiline={multiline}
          label={title}
          disabled
          placeholder={placeHolder}
          defaultValue={defaultValue}
          helperText={errorText}
          type={'text'}
          variant={'outlined'}
          color={'secondary'}
          fullWidth
        />
      </ThemeProvider>
    );
  }

  if (webMask) {
    return (
      <ThemeProvider theme={theme}>
        <InputMask
          id={`${id}`}
          mask={webMask}
          onBlur={onBlurInput}
          onChange={onChange}
          placeholder={placeHolder}
          defaultValue={defaultValue}
          maskChar={''}
        >
          {(inputProps: any) => (
            <TextField
              label={title}
              {...inputProps}
              type={!fieldStatus ? 'text' : 'password'}
              helperText={errorText}
              error={isError}
              variant={'outlined'}
              color={'secondary'}
              fullWidth
              InputProps={{
                endAdornment: inputIcon ? <RenderActiveIcon /> : null,
              }}
              inputProps={{ maxLength: MAX_INPUT_LENGTH }}
            />
          )}
        </InputMask>
      </ThemeProvider>
    );
  }

  if (onPress) {
    return (
      <ThemeProvider theme={theme}>
        <TextField
          id={`${id}`}
          multiline={multiline}
          label={title}
          onClick={onClick}
          disabled
          onBlur={onBlurInput}
          onChange={onChange}
          placeholder={placeHolder}
          value={defaultValue}
          type={'text'}
          helperText={errorText}
          error={isError}
          variant={'outlined'}
          color={'secondary'}
          fullWidth
          InputProps={{
            endAdornment: inputIcon ? <RenderActiveIcon /> : null,
          }}
        />
      </ThemeProvider>
    );
  }

  const getTextFieldType = (fieldStatus: boolean | undefined, keyboardType: KeyboardTypeOptions | undefined) => {
    if (fieldStatus) {
      return 'password';
    }

    return keyboardType === 'number-pad' || keyboardType === 'decimal-pad' ? 'number' : 'text';
  };

  const addRef = (r: any) => {
    if (ref) {
      ref.current[id] = r;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <TextField
        ref={addRef}
        multiline={multiline}
        label={title}
        onClick={onClick}
        disabled={!!onPress}
        onBlur={onBlurInput}
        onChange={onChange}
        placeholder={placeHolder}
        defaultValue={defaultValue}
        type={getTextFieldType(fieldStatus, keyboardType)}
        helperText={errorText}
        error={isError}
        autoComplete={inputAutoComplete}
        variant={'outlined'}
        color={'secondary'}
        fullWidth
        InputProps={{
          endAdornment: inputIcon ? <RenderActiveIcon /> : null,
        }}
        inputProps={{
          maxLength: multiline ? MAX_MULTILINE_INPUT_LENGTH : MAX_INPUT_LENGTH,
        }}
      />
    </ThemeProvider>
  );
};

export const CustomInput = forwardRef(CustomInputComponent);

import { makeStyles, Theme } from '@material-ui/core/styles';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 500,
  },
  primaryText: {
    fontFamily: fonts.SFUiDisplayBold,
    fontSize: 24,
    letterSpacing: -0.24,
    textAlign: 'center',
    marginBottom: 10,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
  secondaryText: {
    fontFamily: fonts.SFUiDisplayMedium,
    fontSize: 17,
    letterSpacing: -0.24,
    textAlign: 'center',
    marginBottom: 10,
  },
  image: {
    marginTop: 10,
    width: 300,
    height: 300,
    [theme.breakpoints.down('md')]: {
      width: 230,
      height: 230,
    },
  },
}));

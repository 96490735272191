import { Job, JobArray, OfferArray } from '@root/general/types';

export const getJobListingWithOfferState = (jobs: JobArray, offers: OfferArray, userId: number) => {
  return jobs.map((job: Job) => {
    const offer = offers.find((offer) => offer.jobId === job.id);

    const offerStatus = offer?.status;

    if (offerStatus) {
      job.offerStatus = offerStatus;
      job.isOfferFromUs = userId === offer?.ownerId;
      job.offerId = offer?.id;
    }

    return job;
  });
};

import { Api, CustomApiResponse } from '@root/services/api';
import {
  AddReferenceAction,
  DeleteReferenceAction,
  EditReferenceAction,
  FetchAdditionalReferencesAction,
  FetchReferencesAction,
} from '@root/redux/actions/types';
import { call, put } from 'redux-saga/effects';
import {
  CALL_UNEXPECTED_ERROR_HANDLER,
  ADD_REFERENCE_SUCCESS,
  CALL_COMMON_ERROR_HANDLER,
  FETCH_REFERENCES_SUCCESS,
} from '@root/general/consts/actions';
import { ErrorResponse, Reference, ReferencesArray } from '@root/general/types';
import { commonNotificationHandler } from '@root/redux/sagas/helpers';

export function* addReference(api: Api, action: AddReferenceAction) {
  const { reference, callback } = action.payload;
  try {
    const response: CustomApiResponse<Reference, ErrorResponse> = yield call(api.addReference, reference);

    if (response.ok && response.data) {
      yield put({ type: ADD_REFERENCE_SUCCESS, payload: response.data });
      yield commonNotificationHandler(response.messages);
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }

    yield callback && call(callback, response.ok);
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* updateReference(api: Api, action: EditReferenceAction) {
  const { reference, referenceId, callback } = action.payload;

  try {
    const response: CustomApiResponse<any, ErrorResponse> = yield call(api.updateReference, referenceId, reference);

    if (response.ok && response.data) {
      yield callback && call(callback, true, response.data);
      yield commonNotificationHandler(response.messages);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* deleteReference(api: Api, action: DeleteReferenceAction) {
  const { referenceId, callback } = action.payload;

  try {
    const response: CustomApiResponse<any, ErrorResponse> = yield call(api.deleteReference, referenceId);

    if (response.ok && response.data) {
      yield commonNotificationHandler(response.messages);
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }

    yield callback && call(callback, response.ok);
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* fetchReferences(api: Api, action: FetchReferencesAction) {
  const { callback } = action.payload;
  try {
    const response: CustomApiResponse<ReferencesArray, ErrorResponse> = yield call(api.fetchReferences, 1);

    if (response.ok && response.data) {
      yield put({
        type: FETCH_REFERENCES_SUCCESS,
        payload: { references: response.data, lastPage: response.meta?.lastPage ?? 1 },
      });
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  } finally {
    yield callback && call(callback);
  }
}

export function* fetchAdditionalReferences(api: Api, action: FetchAdditionalReferencesAction) {
  const { callback, page } = action.payload;
  try {
    const response: CustomApiResponse<ReferencesArray, ErrorResponse> = yield call(api.fetchReferences, page);

    if (response.ok && response.data) {
      yield callback && call(callback, response.ok, response.data);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

import React from 'react';
import { strings } from '@root/strings';
import { Rating } from '@root/general/types';
import { getRating } from '@root/utils/getRating';
import { useStyles } from '../styles';
import { ApprovedByAgButler } from '@root/web/components/ApprovedByAgButler';
import { ProfileImage } from '@root/web/components/ProfileImage';

const { laborerInfoModalScreen: string } = strings;

export type UserHeaderProps = {
  name: string;
  avatar: string | null;
  rating?: Rating | null;
  isApproved: null | boolean;
  isLaborer: boolean;
  isAccepted: boolean;
  inline?: boolean;
  isLoading: boolean;
};

export const UserHeader = (props: UserHeaderProps) => {
  const { name, avatar, rating, isApproved, isAccepted, isLaborer, isLoading } = props;

  const styles = useStyles(props);

  const { ratingValue, reviewsCount } = getRating(rating);

  const userNameAfterLoadingCondition = name ? name : string.deletedUser;
  const userNameCondition = isLoading ? '' : userNameAfterLoadingCondition;

  const ratingMark = (
    <div className={styles.ratingContainer}>
      <img src={require('@root/web/assets/icons/filledStar.svg')} alt='' />
      <span className={styles.ratingText}>{` ${ratingValue}/5`}</span>
      <span className={styles.ratingCountText}>{` (${reviewsCount} ${string.ratings})`}</span>
    </div>
  );

  const acceptedMark = <span className={styles.accepted}>{string.accepted}</span>;

  return (
    <div className={styles.headerContainer}>
      <ProfileImage
        avatar={avatar}
        customDefaultImageStyles={styles.defaultAvatar}
        customDefaultImageContainerStyles={styles.defaultAvatarContainer}
        customImageStyles={styles.customImageStyle}
        customImageContainerStyles={styles.avatarContainer}
      />
      <span className={styles.userName}>{userNameCondition}</span>
      {!isLaborer && ratingMark}
      {!isLaborer && isApproved && <ApprovedByAgButler />}
      {!isLaborer && isAccepted && acceptedMark}
    </div>
  );
};

import {
  FETCH_ADDITIONAL_LABORERS_BY_INDUSTRY_REQUEST,
  FETCH_LABORERS_BY_INDUSTRY_REQUEST,
  REFRESH_LABORERS_BY_INDUSTRY_REQUEST,
  SET_CURRENT_LABORERS_INDUSTRY_ID,
  SET_LABORERS_FILTER,
  CLEAR_LABORERS_FILTER,
} from '@root/general/consts/actions';
import {
  FetchAdditionalLaborersByIndustryPayload,
  FetchLaborersByIndustryPayload,
  SetCurrentLaborersIndustryIdPayload,
} from './types';
import { LaborersFilter } from '@root/redux/reducers/laborersReducer';

export const fetchLaborersByIndustryAction = (payload: FetchLaborersByIndustryPayload) => ({
  type: FETCH_LABORERS_BY_INDUSTRY_REQUEST,
  payload,
});

export const refreshLaborersByIndustryAction = (payload: FetchLaborersByIndustryPayload) => ({
  type: REFRESH_LABORERS_BY_INDUSTRY_REQUEST,
  payload,
});

export const fetchAdditionalLaborersByIndustryAction = (payload: FetchAdditionalLaborersByIndustryPayload) => ({
  type: FETCH_ADDITIONAL_LABORERS_BY_INDUSTRY_REQUEST,
  payload,
});

export const setCurrentLaborersIndustryIdAction = (payload: SetCurrentLaborersIndustryIdPayload) => {
  return {
    type: SET_CURRENT_LABORERS_INDUSTRY_ID,
    payload,
  };
};

export const setLaborerFilterAction = (payload: LaborersFilter) => {
  return {
    type: SET_LABORERS_FILTER,
    payload,
  };
};

export const clearLaborerFilterAction = () => {
  return {
    type: CLEAR_LABORERS_FILTER,
  };
};

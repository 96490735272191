import { PaymentType } from '@root/general/types';
import { ActionType, AdditionActionType } from '@root/hooks/useFlatListPagination';
import { transactionHistoryTab } from '@root/mobile/navigators/TransactionHistoryTopNavigator/types';
import { useSelector } from 'react-redux';
import { getPaymentsByTabName } from '@root/selectors';
import { useCallback } from 'react';
import {
  fetchAdditionalPaymentsRequestAction,
  fetchPaymentsAction,
  refreshPaymentsRequestAction,
} from '@root/redux/actions';

type UseTransactionHistoryScreen = [
  Array<PaymentType>,
  number,
  ActionType,
  ActionType,
  AdditionActionType<PaymentType>,
];

export const useTransactionHistoryScreen = (paymentType: transactionHistoryTab): UseTransactionHistoryScreen => {
  const { payments, lastPage } = useSelector(getPaymentsByTabName(paymentType));

  const fetchAction = useCallback(
    (fetchCallback) => {
      return fetchPaymentsAction({ paymentType, callback: fetchCallback });
    },
    [paymentType],
  );

  const refreshAction = useCallback(
    (refreshCallback) => {
      return refreshPaymentsRequestAction({ paymentType, callback: refreshCallback });
    },
    [paymentType],
  );

  const fetchAdditionAction = useCallback(
    (page: number, fetchAdditionalCallback: (isSuccess: boolean, additionalPayments: any) => void) => {
      return fetchAdditionalPaymentsRequestAction({ paymentType, page, callback: fetchAdditionalCallback });
    },
    [paymentType],
  );

  return [payments, lastPage, fetchAction, refreshAction, fetchAdditionAction];
};

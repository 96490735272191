import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { useCustomModalWithSections } from '@root/hooks/useCustomModalWithSections';
import { Modal } from '@material-ui/core';
import { IndustryWithSpecifications, Specification } from '@root/general/types';
import { CustomModalWithSectionsProps } from '@root/mobile/components/CustomModalWithSections/types';
import { useStyles } from './styles';
import { strings } from '@root/strings';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { SelectAllHeader } from '@root/web/components/SelectAllHeader';
import { Scrollbars } from 'react-custom-scrollbars';
import { modalStyle } from '@root/general/consts';

const { buttons: string } = strings;

const CustomModalWithSectionsComponent: ForwardRefRenderFunction<any, CustomModalWithSectionsProps> = (
  props,
  ref: any,
) => {
  const styles = useStyles();
  const {
    setValue,
    isModalVisible,
    setModalVisible,
    sections,
    selectedItemIds,
    setSelectedItemIds,
    headerTitle,
  } = props;

  const [isSelected, onPress, onPressDone, closeModal, onPresAll] = useCustomModalWithSections(
    selectedItemIds,
    setSelectedItemIds,
    setModalVisible,
    isModalVisible,
    sections,
    setValue,
  );

  const renderItem = (item: Specification) => {
    const { id, name, color } = item;

    return (
      <div className={styles.itemContainer} onClick={onPress(item)} key={id}>
        <div className={styles.itemContent}>
          <div className={styles.circle} style={{ backgroundColor: color }} />
          <span className={styles.itemText}>{name}</span>
        </div>
        {isSelected(id) && (
          <img className={styles.checkMarkImage} src={require('@root/web/assets/icons/checkmark.svg')} alt='' />
        )}
      </div>
    );
  };

  const renderSection = (item: IndustryWithSpecifications) => {
    const { industry, data } = item;

    return (
      <div key={industry.id}>
        <span style={{ color: industry.color }} className={styles.sectionHeader}>
          {industry.name}
        </span>
        {data.map(renderItem)}
      </div>
    );
  };

  return (
    <Modal
      open={isModalVisible}
      style={modalStyle}
      onClose={closeModal}
      className={styles.modalContainer}
      container={ref?.current}
    >
      <div className={styles.contentContainer}>
        <SelectAllHeader headerTitle={headerTitle} onPressAll={onPresAll} />
        <Scrollbars className={styles.scrollbars} autoHeight autoHeightMin={'20vh'} autoHeightMax={'60vh'}>
          {sections.map(renderSection)}
        </Scrollbars>
        <CustomButton type={customButtonType.RED} title={string.done} onPress={onPressDone} disabledTopBorder />
      </div>
    </Modal>
  );
};

export const CustomModalWithSections = forwardRef(CustomModalWithSectionsComponent);

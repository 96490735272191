import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles({
  pageContainer: {
    display: 'flex',
    flex: 1,
  },
  navigationContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  container: {
    backgroundColor: colors.athensGrayOpacity3,
  },
});

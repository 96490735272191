import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { getAuth } from '@root/selectors';
import { initialiseAppAction, setAuthHeaderAction } from '@root/redux/actions/authenticationActions';
import { fetchIndustriesWithSpecificationsAction } from '@root/redux/actions';

type UseAuthLoadingReturnType = [boolean, boolean, () => void, string, boolean];
export const useAuthLoading = (): UseAuthLoadingReturnType => {
  const dispatch = useDispatch();
  const [initialisedError, setInitialisedError] = useState(false);
  const [initialisedLoading, setInitialisedLoading] = useState(true);

  const { token, appVersionChecked } = useSelector(getAuth);

  const initialiseApp = useCallback(() => {
    setInitialisedError(false);
    setInitialisedLoading(true);
    dispatch(
      initialiseAppAction({
        callback: (isSuccess) => {
          !isSuccess && setInitialisedError(true);
          setInitialisedLoading(false);
        },
      }),
    );
    dispatch(fetchIndustriesWithSpecificationsAction());
  }, [dispatch]);

  useEffect(() => {
    initialiseApp();
  }, [dispatch, initialiseApp]);

  useEffect(() => {
    dispatch(setAuthHeaderAction(token));
  }, [dispatch, token]);

  return [initialisedLoading, initialisedError, initialiseApp, token, appVersionChecked];
};

import { Region } from 'react-native-maps';
import { DEFAULT_RADIUS } from '@root/hooks/useMapModalScreen';
import { Coords } from 'google-map-react';

const LONGITUDE_DELTA = 0.035,
  DEFAULT_LATITUDE = 37.773972,
  DEFAULT_LONGITUDE = -122.431297; // San Francisco, CA, USA

export const getRegionFromCity = (lat: number | null, lng: number | null, radius = DEFAULT_RADIUS): Region => {
  return {
    latitude: lat || DEFAULT_LATITUDE,
    longitude: lng || DEFAULT_LONGITUDE,
    latitudeDelta: (radius / 69) * 2, //1 latitudeDelta = 69miles
    longitudeDelta: LONGITUDE_DELTA,
  };
};

export const getRegionFromCityWeb = (
  lat: number | null,
  lng: number | null,
  radius = DEFAULT_RADIUS,
): Coords & { zoom: number } => {
  //  metersPerPx = (156543.03392 * Math.cos((center.lat * Math.PI) / 180)) / Math.pow(2, zoom)

  const getMapZoomFromRadius = (radius: number) => {
    switch (radius) {
      case 500:
        return 6;
      case 250:
        return 8;
      case 100:
        return 9;
      case 50:
        return 10;
      case 30:
        return 10.7;
      case 15:
        return 11.7;
      case 10:
        return 12.3;
      default:
        return 13.3;
    }
  };

  return {
    lat: lat || DEFAULT_LATITUDE,
    lng: lng || DEFAULT_LONGITUDE,
    zoom: getMapZoomFromRadius(radius),
  };
};

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles(() => ({
  locationAddButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  scrollComponent: {
    backgroundColor: colors.white,
    borderColor: colors.athensGray,
    borderWidth: '0px 1px 1px',
    borderStyle: 'solid',
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
}));

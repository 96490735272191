import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import { fonts } from '@root/general/fonts';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles(() =>
  createStyles({
    contentContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    content: {
      padding: 30,
    },
    fillGeneralInfoContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 10,
    },
    fillGeneralInfo: {
      letterSpacing: -0.24,
      fontSize: 17,
      fontFamily: fonts.SFUiDisplayBold,
      textAlign: 'center',
      color: colors.red,
      cursor: 'pointer',
    },
    submitButtonContainer: {
      padding: '20px 30px',
      marginTop: 'auto',
    },
  }),
);

import React, { ChangeEvent, Dispatch, forwardRef, ForwardRefRenderFunction, SetStateAction, useState } from 'react';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { useStyles } from './styles';
import { strings } from '@root/strings';
import { Modal } from '@material-ui/core';
import clsx from 'clsx';
import { capitalize } from '@root/utils/capitalize';
import { modalStyle } from '@root/general/consts';

const { buttons: string } = strings;

const initial = {
  id: null,
  name: '',
};

export type PickerModalWithConfirmationProps = {
  setValue?: ((e: string | ChangeEvent<any>) => void) | Dispatch<SetStateAction<string>>;
  setRespondedValue?: Dispatch<SetStateAction<any>>;
  isModalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  data: Array<any>;
};

export type StandardType = {
  id: number | null;
  name: string;
};

export const PickerModalWithConfirmationComponent: ForwardRefRenderFunction<any, PickerModalWithConfirmationProps> = (
  props: PickerModalWithConfirmationProps,
  ref: any,
) => {
  const { setValue = () => {}, isModalVisible, setModalVisible, data, setRespondedValue } = props;
  const styles = useStyles();

  const [selectedItem, setSelectedItem] = useState<StandardType>(initial);

  const onDone = () => {
    const { id, name } = selectedItem;
    if (id) {
      setValue(capitalize(name));
      setRespondedValue && setRespondedValue(id);
      setSelectedItem(initial);
    }
    setModalVisible(false);
  };

  const onPressItem = (item: StandardType) => () => {
    setSelectedItem(item);
  };

  const isSelected = (id: number | null) => {
    return selectedItem?.id === id;
  };

  const renderItem = (item: StandardType) => {
    const { id, name } = item;
    const textStyle = clsx(styles.itemText, isSelected(id) && styles.itemSelectedText);

    return (
      <div key={id || 0} className={styles.itemContainer} onClick={onPressItem(item)}>
        <span className={textStyle}>{capitalize(name)}</span>
      </div>
    );
  };

  const closeModal = () => setModalVisible(false);

  return (
    <Modal
      className={styles.modalContainer}
      style={modalStyle}
      open={isModalVisible}
      onClose={closeModal}
      container={ref?.current}
    >
      <div className={styles.contentContainer}>
        {data.map(renderItem)}
        <CustomButton type={customButtonType.RED} title={string.done} onPress={onDone} disabledTopBorder />
      </div>
    </Modal>
  );
};

export const PickerModalWithConfirmation = forwardRef(PickerModalWithConfirmationComponent);

import React from 'react';
import { MyButton, TextButton } from './buttons';
import { useStyles, useButtonStyles } from './styles';
import clsx from 'clsx';

export enum customButtonType {
  TEXT = 'TEXT',
  RED = 'RED',
  WHITE = 'WHITE',
  GREY = 'GREY',
  GREEN = 'GREEN',
  WHISPER = 'WHISPER',
}

export type CustomButtonProps = {
  type: customButtonType;
  title: string;
  onPress: () => void;
  disabled?: boolean;
  marginTop?: number;
  disabledTopBorder?: boolean;
  maxWidth?: number;
  icon?: any;
  className?: string;
  iconClassName?: string;
  ellipse?: boolean;
};

export const CustomButton = (props: CustomButtonProps) => {
  const { title, onPress, disabled, icon, className, iconClassName, type } = props;

  const styles = useStyles(props);
  const buttonStyles = useButtonStyles(props);

  const startIcon = (src: any) => <img src={src} alt='' className={iconClassName} />;

  if (type === customButtonType.TEXT) {
    return (
      <TextButton className={clsx(styles.textButtonContainer, className)} onClick={onPress} disableRipple>
        {title}
      </TextButton>
    );
  }

  return (
    <MyButton
      startIcon={icon ? startIcon(icon) : undefined}
      disabled={disabled}
      className={clsx(styles.buttonContainer, className)}
      onClick={onPress}
      variant='contained'
      classes={{ ...buttonStyles }}
    >
      {title}
    </MyButton>
  );
};

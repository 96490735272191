import { Api, CustomApiResponse } from '@root/services/api';
import { call, put } from 'redux-saga/effects';
import {
  CALL_COMMON_ERROR_HANDLER,
  CALL_UNEXPECTED_ERROR_HANDLER,
  DELETE_PAYMENT_CARD_SUCCESS,
  FETCH_PAYMENT_CARD_SUCCESS,
  FETCH_PAYMENTS_SUCCESS,
  UPDATE_DEFAULT_PAYMENT_CARD_SUCCESS,
} from '@root/general/consts/actions';
import {
  DeletePaymentCardAction,
  FetchAdditionalPaymentsAction,
  FetchPaymentAction,
  FetchPaymentCardsAction,
  FetchPaymentsAction,
  FetchPaymentSecretAction,
  FetchSetupSecretAction,
  PayForJobAction,
  SetDefaultPaymentCardAction,
} from '@root/redux/actions/types';
import { ErrorResponse, PaymentCardDataType, PaymentsArray, PaymentType, SecretDataType } from '@root/general/types';
import { isArray } from 'lodash';
import { commonNotificationHandler } from '@root/redux/sagas/helpers';
import { transactionHistoryTab } from '@root/mobile/navigators/TransactionHistoryTopNavigator/types';

const DEFAULT_PAGE = 1;

export function* deletePaymentCard(api: Api, action: DeletePaymentCardAction) {
  const { cardId, callback } = action.payload;
  try {
    const response: CustomApiResponse<PaymentCardDataType, ErrorResponse> = yield call(api.deletePaymentCard, cardId);

    if (response.ok && response.data) {
      yield put({ type: DELETE_PAYMENT_CARD_SUCCESS, payload: cardId });
    }
    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  } finally {
    yield callback && call(callback);
  }
}

export function* setDefaultPaymentCard(api: Api, action: SetDefaultPaymentCardAction) {
  const { cardId, callback } = action.payload;
  try {
    const response: CustomApiResponse<PaymentCardDataType, ErrorResponse> = yield call(
      api.setDefaultPaymentCard,
      cardId,
    );

    if (response.ok && response.data) {
      yield put({ type: UPDATE_DEFAULT_PAYMENT_CARD_SUCCESS, payload: cardId });
    }
    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  } finally {
    yield callback && call(callback);
  }
}

export function* fetchPaymentCards(api: Api, action: FetchPaymentCardsAction) {
  const { callback } = action.payload;

  try {
    const response: CustomApiResponse<PaymentCardDataType, ErrorResponse> = yield call(api.fetchPaymentCards);

    if (response.ok && isArray(response.data)) {
      yield put({
        type: FETCH_PAYMENT_CARD_SUCCESS,
        payload: { cards: response.data },
      });
      yield callback && call(callback, true);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* payForJob(api: Api, action: PayForJobAction) {
  const { jobId, callback } = action.payload;

  try {
    const response: CustomApiResponse<any, ErrorResponse> = yield call(api.payForJob, jobId);

    if (response.ok && response.data) {
      yield commonNotificationHandler(response.messages);
      yield callback && call(callback, response.ok);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* fetchSetupSecret(api: Api, action: FetchSetupSecretAction) {
  const { resolve, reject } = action.payload;
  try {
    const response: CustomApiResponse<SecretDataType, ErrorResponse> = yield call(api.fetchSetupSecret);

    if (response.ok && response.data) {
      yield call(resolve, response.data);
    }

    if (!response.ok) {
      yield call(reject);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield call(reject);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* fetchPaymentSecret(api: Api, action: FetchPaymentSecretAction) {
  const { paymentId, resolve, reject } = action.payload;
  try {
    const response: CustomApiResponse<SecretDataType, ErrorResponse> = yield call(api.fetchPaymentSecret, paymentId);

    if (response.ok && response.data) {
      yield call(resolve, response.data);
    }

    if (!response.ok) {
      yield call(reject);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield call(reject);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* fetchPayments(api: Api, action: FetchPaymentsAction) {
  const { callback, paymentType } = action.payload;
  try {
    const response: CustomApiResponse<PaymentsArray, ErrorResponse> =
      paymentType === transactionHistoryTab.OPEN
        ? yield call(api.fetchDebts, { page: DEFAULT_PAGE })
        : yield call(api.fetchPayments, { page: DEFAULT_PAGE });

    if (response.ok && isArray(response.data)) {
      yield put({
        type: FETCH_PAYMENTS_SUCCESS,
        payload: { paymentType, payments: response.data, lastPage: response.meta?.lastPage ?? DEFAULT_PAGE },
      });
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  } finally {
    yield callback && call(callback);
  }
}

export function* fetchAdditionalPayments(api: Api, action: FetchAdditionalPaymentsAction) {
  const { page, callback, paymentType } = action.payload;

  try {
    const response: CustomApiResponse<PaymentsArray, ErrorResponse> =
      paymentType === transactionHistoryTab.OPEN
        ? yield call(api.fetchDebts, { page })
        : yield call(api.fetchPayments, { page });

    if (response.ok && response.data) {
      yield callback && call(callback, response.ok, response.data);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* fetchPayment(api: Api, action: FetchPaymentAction) {
  const { paymentId, callback } = action.payload;

  try {
    const response: CustomApiResponse<PaymentType, ErrorResponse> = yield call(api.fetchPayment, paymentId);
    if (response.ok && response.data) {
      yield callback && call(callback, response.ok, response.data);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

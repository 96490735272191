import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() => ({
  headerCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 8,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    flexGrow: 1,
    width: '60%',
  },
  title: {
    fontFamily: fonts.SFUiDisplayBold,
    fontSize: 19,
    letterSpacing: -0.24,
    color: colors.darkGrey,
    wordWrap: 'break-word',
    width: '100%',
  },
  zipCodeContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '40%',
  },
  zipCode: {
    paddingLeft: 12,
    fontFamily: fonts.IBMPlexMonoMedium,
    letterSpacing: -0.41,
    color: colors.black,
    width: 'max-content',
    wordWrap: 'break-word',
  },
  fieldContainer: {
    display: 'flex',
    marginBottom: 6,
    alignItems: 'center',
  },
  imageContainer: {
    width: 20,
    height: 20,
    marginRight: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fieldText: {
    fontFamily: fonts.SFUiDisplayMedium,
    fontSize: 17,
    letterSpacing: -0.24,
    color: colors.darkGrey,
    wordWrap: 'break-word',
    width: '80%',
  },
  locationContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  location: {
    fontFamily: fonts.IBMPlexMonoMedium,
    fontSize: 13,
    letterSpacing: -0.41,
    color: colors.black,
    textTransform: 'uppercase',
  },
  footerCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 10,
    borderTopWidth: 1,
    borderTopColor: colors.athensGray,
    borderTopStyle: 'solid',
  },
  editIcon: {
    filter:
      'brightness(0) saturate(100%) invert(27%) sepia(71%) saturate(2791%) hue-rotate(348deg) brightness(86%) contrast(87%);',
  },
}));

import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() =>
  createStyles({
    container: ({ isDefaultFont }: { isDefaultFont?: boolean }) => {
      return {
        backgroundColor: colors.white,
        marginBottom: 16,
        '& .MuiOutlinedInput-input': {
          fontSize: isDefaultFont ? 16 : 17,
          fontFamily: isDefaultFont ? 'inherit' : fonts.OpenSansRegular,
          lineHeight: '23px',
          letterSpacing: -0.175,
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.athensGray,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
          backgroundColor: colors.white,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.athensGray,
          borderWidth: 1,
        },
      };
    },
    selectContainer: {
      minWidth: '100%',
    },
    selectIcon: {
      marginRight: 10,
      color: colors.black,
    },
    selectLabel: {
      color: colors.grey,
      backgroundColor: colors.white,
      zIndex: 1,
      '&.Mui-focused': {
        backgroundColor: colors.white,
        color: colors.grey,
        zIndex: 1,
      },
    },
    itemText: ({ isDefaultFont }: { isDefaultFont?: boolean }) => {
      return {
        fontSize: isDefaultFont ? 16 : 17,
        fontFamily: isDefaultFont ? 'inherit' : fonts.OpenSansRegular,
        lineHeight: '23px',
        letterSpacing: -0.175,
      };
    },
  }),
);

import { IActionType } from '@root/redux/types';
import { ADD_REFERENCE_SUCCESS, CLEAR_USER_DATA_STATE, FETCH_REFERENCES_SUCCESS } from '@root/general/consts/actions';
import { ReferencesArray } from '@root/general/types';

export type ReferencesReducer = {
  references: ReferencesArray;
  lastPage: number;
};

const INITIAL_STATE: ReferencesReducer = {
  references: [],
  lastPage: 1,
};

export const referencesReducer = (state = INITIAL_STATE, action: IActionType) => {
  switch (action.type) {
    case FETCH_REFERENCES_SUCCESS: {
      const { references, lastPage } = action.payload;
      return { ...state, references, lastPage };
    }
    case ADD_REFERENCE_SUCCESS: {
      return { ...state, references: [action.payload, ...state.references] };
    }
    case CLEAR_USER_DATA_STATE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
};

import { IActionType } from '@root/redux/types';
import { CLEAR_USER_DATA_STATE, FETCH_PROPOSALS_SUCCESS } from '@root/general/consts/actions';
import { OfferArray } from '@root/general/types';
import { OFFERS_BY_STATUS } from '@root/general/consts';

export type ProposalsReducer = {
  [key in string]: { proposals: OfferArray; lastPage: number };
};

const INITIAL_STATE: ProposalsReducer = {
  [OFFERS_BY_STATUS.ALL.name]: { proposals: [], lastPage: 1 },
  [OFFERS_BY_STATUS.SENT.name]: { proposals: [], lastPage: 1 },
  [OFFERS_BY_STATUS.RECEIVED.name]: { proposals: [], lastPage: 1 },
  [OFFERS_BY_STATUS.ACCEPTED.name]: { proposals: [], lastPage: 1 },
  [OFFERS_BY_STATUS.DECLINED.name]: { proposals: [], lastPage: 1 },
  [OFFERS_BY_STATUS.CANCELLED.name]: { proposals: [], lastPage: 1 },
};

export const proposalsReducer = (state = INITIAL_STATE, action: IActionType) => {
  switch (action.type) {
    case FETCH_PROPOSALS_SUCCESS:
      const { tabName, proposals, lastPage } = action.payload;
      return { ...state, [tabName]: { proposals, lastPage } };
    case CLEAR_USER_DATA_STATE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
};

import React, { useRef, useState } from 'react';
import { useStyles } from './styles';
import { CustomTabHeader } from '@root/web/components/CustomTabHeader';
import { strings } from '@root/strings';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { jobListingFilterField, useJobListingFilterModalScreen } from '@root/hooks/useJobListingModalFilterScreen';
import { showToastAction, ToastTypes } from '@root/redux/actions/commonActions';
import { StateType } from '@root/general/types';
import { useDispatch } from 'react-redux';
import { CustomInput } from '@root/web/components/CustomInput';
import { ChooseStateModal } from '@root/web/components/ChooseStateModal';
import { ChooseCityModal } from '@root/web/components/ChooseCityModal';
import { PickerMenu } from '@root/web/components/PickerMenu';
import { RADIUS } from '@root/general/consts';
import { CustomTabContainer } from '@root/web/components/CustomTabContainer';

const { jobListingFilterModalScreen: string, warnings } = strings;

type JobListingFilterScreenProps = {
  goBackCallback: () => void;
};

export const JobListingFilterScreen = (props: JobListingFilterScreenProps) => {
  const { goBackCallback } = props;
  const styles = useStyles();

  const dispatch = useDispatch();
  const ref = useRef<any>();

  const [isStateModalVisible, setStateModalVisible] = useState(false);
  const [isCityModalVisible, setCityModalVisible] = useState(false);

  const onStatePress = () => () => {
    setStateModalVisible(true);
  };

  const onCityPress = (filterState: StateType[] | null) => () => {
    if (filterState?.length === 1) {
      setCityModalVisible(true);
    } else {
      dispatch(showToastAction({ message: warnings.selectState, type: ToastTypes.Error }));
    }
  };

  const [
    filterFieldFormData,
    resetFilter,
    onSave,
    filterRadius,
    setFilterRadius,
    radiusModalVisible,
    setRadiusModalVisible,
    filterState,
    setFilterState,
    setFilterCity,
  ] = useJobListingFilterModalScreen(onStatePress, onCityPress, goBackCallback);

  const renderInputForms = () => {
    return filterFieldFormData.map((item) => {
      const { id: fieldId, title, onPress } = item;

      switch (fieldId) {
        case jobListingFilterField.radius:
          return (
            <PickerMenu
              key={fieldId}
              title={title}
              data={RADIUS}
              value={filterRadius}
              setValue={setFilterRadius}
              isOpen={radiusModalVisible}
              onOpen={onPress}
              onClose={() => setRadiusModalVisible(false)}
            />
          );
        default:
          return <CustomInput key={fieldId} item={item} />;
      }
    });
  };

  return (
    <CustomTabContainer ref={ref} tabPositionFixed>
      <CustomTabHeader title={string.screenTitle} onTabHeaderClose={goBackCallback} />
      <div className={styles.content}>
        {renderInputForms()}
        {<CustomButton type={customButtonType.TEXT} title={string.resetFilter} onPress={resetFilter} />}
      </div>
      <div className={styles.submitButtonContainer}>
        <CustomButton type={customButtonType.RED} title={string.save} onPress={onSave} />
      </div>
      {isStateModalVisible && (
        <ChooseStateModal
          isModalVisible={isStateModalVisible}
          setModalVisible={setStateModalVisible}
          setState={setFilterState}
          setCity={setFilterCity}
          ref={ref}
        />
      )}
      {isCityModalVisible && filterState?.length === 1 && (
        <ChooseCityModal
          isModalVisible={isCityModalVisible}
          setModalVisible={setCityModalVisible}
          selectedStateId={filterState[0].id ?? null}
          setCity={setFilterCity}
          ref={ref}
        />
      )}
    </CustomTabContainer>
  );
};

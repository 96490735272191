import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';

import useSupercluster from 'use-supercluster';
import { MapCityType } from '@root/general/types';
import { MapMarker } from '@root/web/screens/JobListingMap/components/MapMarker';
import { useStyles } from './styles';
import { ClusteredPoint, ClusterPoint, JobListingMapViewProps } from './types';
import { RadiusList } from '@root/web/screens/JobListingMap/components/RadiusList';
import { getMapOptions, GOOGLE_MAP_ZOOM, MapControls } from '@root/web/components/MapControls';
import { GOOGLE_MAP_API_KEY } from '@root/web/consts';

export const JobListingMapView = (props: JobListingMapViewProps) => {
  const {
    setMapReady,
    mapRef,
    initialRegion,
    data,
    selectedCities,
    setSelectedCities,
    onLocationPress,
    radius,
    onRadiusPress,
  } = props;
  const styles = useStyles();

  const [zoom, setZoom] = useState(GOOGLE_MAP_ZOOM);
  const [center, setCenter] = useState(initialRegion);
  const [bounds, setBounds] = useState<Array<number>>([]);

  const points: Array<ClusterPoint> = data.map((city) => ({
    type: 'Feature',
    properties: { cluster: false, city },
    geometry: { type: 'Point', coordinates: [city.location.longitude, city.location.latitude] },
  }));

  const { clusters, supercluster } = useSupercluster({ points, bounds, zoom, options: { radius: 75, maxZoom: 20 } });

  const onMarkerPress = (selectedCities: Array<MapCityType>) => () => {
    setSelectedCities(selectedCities);

    // todo if need to expand cluster const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(clusterId), 20);
    //  mapRef.current?.setZoom(expansionZoom);
    const { location } = selectedCities[0];
    mapRef.current?.panTo({ lat: location.latitude, lng: location.longitude });
  };

  const renderMarkers = () => {
    return clusters.map((cluster: ClusteredPoint) => {
      const { geometry, properties, id: clusterId } = cluster;

      const [longitude, latitude] = geometry.coordinates;
      const coords = { lat: latitude, lng: longitude };

      if (properties.cluster) {
        const clusteredCities: Array<MapCityType> = supercluster
          .getLeaves(clusterId)
          .map((clusterPoint: ClusterPoint) => clusterPoint.properties.city);

        const jobsCount = clusteredCities.reduce((acc, city) => acc + city.jobsCount, 0);

        const isSelected = clusteredCities.every((clusteredCity) => {
          return selectedCities.some((city) => city.id === clusteredCity.id);
        });

        return (
          <MapMarker
            key={clusterId}
            {...coords}
            isSelected={isSelected}
            text={jobsCount}
            onPress={onMarkerPress(clusteredCities)}
          />
        );
      }

      const city = properties.city;
      const { id: cityId, jobsCount } = city;

      const isSelected =
        selectedCities.length > 0 && selectedCities.every((selectedCity) => cityId === selectedCity.id);

      return (
        <MapMarker key={cityId} {...coords} isSelected={isSelected} text={jobsCount} onPress={onMarkerPress([city])} />
      );
    });
  };

  return (
    <div className={styles.container}>
      <RadiusList radius={radius} onRadiusPress={onRadiusPress} />
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
        defaultZoom={GOOGLE_MAP_ZOOM}
        center={center}
        yesIWantToUseGoogleMapApiInternals
        options={getMapOptions}
        onGoogleApiLoaded={({ map }) => {
          setMapReady(true);
          mapRef.current = map;
        }}
        onChange={({ zoom, center, bounds }) => {
          setCenter(center);
          setZoom(zoom);
          setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
        }}
      >
        {renderMarkers()}
      </GoogleMapReact>
      <MapControls
        zoom={mapRef?.current?.zoom}
        setZoom={(zoom: number) => mapRef.current?.setZoom(zoom)}
        onLocationPress={onLocationPress}
      />
    </div>
  );
};

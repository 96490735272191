import React from 'react';
import { strings } from '@root/strings';
import { DefaultCardProps } from '@root/mobile/screens/PaymentSystemModalScreen/components/DefaultCard/types';
import { useStyles } from './styles';
import { capitalize } from '@root/utils/capitalize';
import { getCardBrandLogo } from '@root/web/utils/getCardBrandLogo';
import { cardBrandLogoType } from '@root/general/consts';

const { defaultCard: string } = strings;

export const DefaultCard = (props: DefaultCardProps) => {
  const { last4, brand, expMonth, expYear } = props;

  const styles = useStyles(props);

  const month = expMonth < 10 ? `0${expMonth}` : expMonth;

  return (
    <div className={styles.defaultCardContent}>
      <img className={styles.defaultCardBrandImage} src={getCardBrandLogo(brand, cardBrandLogoType.small)} alt='' />
      <div className={styles.defaultCardRightContainer}>
        <div className={styles.defaultCardNumberContainer}>
          <span className={styles.defaultCardBrand}>{capitalize(brand)}</span>
          <span className={styles.defaultCardNumberDots}>{string.dots}</span>
          <span className={styles.defaultCardNumber}>{last4}</span>
        </div>
        <span className={styles.defaultCardExpireDate}>{`${month}/${expYear}`}</span>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { List, Paper } from '@material-ui/core';
import { useStyles } from './styles';
import { getUser } from '@root/selectors';
import { setNavigationBarWidthAction, signOutAction } from '@root/redux/actions';
import { Routes } from '@root/web/navigators/PrivateRoutes';
import { RouteType } from '@root/web/navigators/PrivateRoutes/types';
import { StyledListItem } from '@root/web/navigators/NavigationBar/StyledListItem';
import { route } from '@root/web/consts';
import { WarningModal } from '@root/web/components/WarningModal';
import { strings } from '@root/strings';

const { profileScreen } = strings;

const NAVIGATION_BAR_CLOSE_WIDTH = 75;
const NAVIGATION_BAR_OPEN_WIDTH = 240;

export const NavigationBar = () => {
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { roleId, name: userName } = useSelector(getUser);

  const [isOpen, setOpen] = useState(false);
  const [isLogoutModalVisible, setLogoutModalVisible] = useState(false);

  const navigationBarWidth = isOpen ? NAVIGATION_BAR_OPEN_WIDTH : NAVIGATION_BAR_CLOSE_WIDTH;

  useEffect(() => {
    dispatch(setNavigationBarWidthAction({ navigationBarWidth }));
  }, [dispatch, isOpen, navigationBarWidth]);

  const activeRoute = (path: string) => {
    return history.location.pathname.startsWith(path);
  };

  const onSidebarClick = (path: string) => () => history.push(path);

  const onToggleMenu = () => {
    setOpen(!isOpen);
  };

  const onLogout = () => {
    dispatch(signOutAction());
  };

  const SidebarMenu = () => {
    return (
      <div className={styles.menuBorderWrapper}>
        <StyledListItem
          isOpen={isOpen}
          sidebarName={'Menu'}
          icon={require('@root/web/assets/icons/menu.svg')}
          onClick={onToggleMenu}
          isMenu={true}
        />
      </div>
    );
  };

  const SidebarLogout = () => {
    return (
      <StyledListItem
        isOpen={isOpen}
        sidebarName={'Logout'}
        icon={require('@root/web/assets/icons/logout.svg')}
        onClick={() => setLogoutModalVisible(true)}
      />
    );
  };

  const renderList = (routes: Array<RouteType>, isTop: boolean) => {
    return (
      <List className={styles.listContainer}>
        {isTop && <SidebarMenu />}
        {routes.map((item) => {
          const { path, sidebarName = '', icon } = item;

          return (
            <StyledListItem
              key={path}
              isOpen={isOpen}
              sidebarName={path === route.PROFILE ? userName : sidebarName}
              icon={icon}
              onClick={onSidebarClick(path)}
              isActive={activeRoute(path)}
            />
          );
        })}
        {!isTop && <SidebarLogout />}
      </List>
    );
  };

  const topRoutes = Routes.filter(({ type, sidebarName }) => type === roleId && sidebarName);
  const bottomRoutes = Routes.filter(({ type = null, sidebarName }) => type === null && sidebarName);

  return (
    <div className={styles.container}>
      <Paper className={clsx(styles.drawer, { [styles.drawerOpen]: isOpen, [styles.drawerClose]: !isOpen })}>
        <div className={styles.listsWrapper}>
          {renderList(topRoutes, true)}
          {renderList(bottomRoutes, false)}
        </div>
      </Paper>
      <WarningModal
        expression={profileScreen.logout}
        isModalVisible={isLogoutModalVisible}
        setModalVisible={setLogoutModalVisible}
        onApply={onLogout}
      />
    </div>
  );
};

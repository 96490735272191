import React from 'react';
import { useStyles } from './styles';

type EmptyListComponentProps = {
  primaryText?: string;
  secondaryText?: string;
};

export const EmptyListComponent = (props: EmptyListComponentProps) => {
  const { primaryText, secondaryText } = props;
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {primaryText && <span className={styles.primaryText}>{primaryText}</span>}
        {secondaryText && <span className={styles.secondaryText}>{secondaryText}</span>}
        <img className={styles.image} src={require('@root/web/assets/images/emptyListImage.png')} alt={''} />
      </div>
    </div>
  );
};

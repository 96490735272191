import { Api, CustomApiResponse } from '@root/services/api';
import { call, put } from 'redux-saga/effects';
import { FetchAdditionalLaborersByIndustryAction, FetchLaborersByIndustryAction } from '@root/redux/actions/types';
import { ErrorResponse, GeneralUsersArray } from '@root/general/types';
import {
  CALL_COMMON_ERROR_HANDLER,
  CALL_UNEXPECTED_ERROR_HANDLER,
  FETCH_LABORERS_BY_INDUSTRY_SUCCESS,
} from '@root/general/consts/actions';

export function* fetchLaborersByIndustry(api: Api, action: FetchLaborersByIndustryAction) {
  const { industryId, filter, searchValue, callback } = action.payload;

  try {
    const response: CustomApiResponse<GeneralUsersArray, ErrorResponse> = yield call(
      api.fetchLaborersByIndustry,
      industryId,
      filter,
      searchValue,
      1,
    );

    if (response.ok && response.data) {
      yield put({
        type: FETCH_LABORERS_BY_INDUSTRY_SUCCESS,
        payload: { industryId, laborers: response.data, lastPage: response.meta?.lastPage ?? 1 },
      });
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  } finally {
    yield callback && call(callback);
  }
}

export function* fetchAdditionalLaborersByIndustry(api: Api, action: FetchAdditionalLaborersByIndustryAction) {
  const { industryId, page, filter, searchValue, callback } = action.payload;
  try {
    const response: CustomApiResponse<GeneralUsersArray, ErrorResponse> = yield call(
      api.fetchLaborersByIndustry,
      industryId,
      filter,
      searchValue,
      page,
    );

    if (response.ok && response.data) {
      yield callback && call(callback, true, response.data);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

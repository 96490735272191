import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { TransactionCardProps } from '@root/web/screens/ProfileScreen/components/TransactionHistory/components/TransactionCard/index';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() => ({
  containerClasses: ({ isFailed }: TransactionCardProps) => {
    return {
      backgroundColor: isFailed ? colors.redOpacity05 : colors.lightGrey,
    };
  },
  contentClasses: ({ isFailed }: TransactionCardProps) => {
    return {
      borderLeftWidth: isFailed ? 3 : 1,
      borderLeftColor: isFailed ? colors.red : colors.athensGray,
      borderLeftStyle: 'solid',
    };
  },
  transactionCardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    position: 'relative',
  },
  transactionCardLeftContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  transactionCardRightContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: 100,
    paddingLeft: 5,
  },
  transactionCardJob: {
    display: 'inline-block',
    fontSize: 15,
    fontFamily: fonts.SFUiDisplayBold,
    lineHeight: '20px',
    letterSpacing: -0.24,
    marginBottom: 6,
  },
  transactionCardJobTextWrap: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  transactionCardTitleContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '50%',
  },
  transactionCardFullWidth: {
    width: '100%',
  },
  transactionCardDate: {
    fontSize: 12,
    fontFamily: fonts.SFUiDisplayMedium,
    lineHeight: '16px',
    letterSpacing: -0.24,
    color: colors.grey,
    marginBottom: 6,
  },
  transactionCardStatus: ({ isFailed }: TransactionCardProps) => {
    return {
      fontSize: 10,
      fontFamily: fonts.IBMPlexMonoRegular,
      lineHeight: '22px',
      letterSpacing: -0.41,
      color: isFailed ? colors.red : colors.black,
      borderColor: isFailed ? colors.redOpacity2 : colors.greyOpacity2,
      borderRadius: '30px',
      borderWidth: 1,
      borderStyle: 'solid',
      padding: '2px 10px',
      alignSelf: 'flex-start',
    };
  },
  transactionCardAmount: {
    fontSize: 17,
    fontFamily: fonts.IBMPlexMonoRegular,
    lineHeight: '20px',
    letterSpacing: -0.24,
    color: colors.red,
  },
  paymentMethodTitle: {
    textAlign: 'right',
    width: 130,
    fontFamily: fonts.SFUiDisplayBold,
    fontSize: 12,
    margin: '3px 0',
    color: colors.mineShaft,
  },
  tooltip: {
    padding: '6px 10px',
    fontSize: 12,
    fontFamily: fonts.SFUiDisplayMedium,
    lineHeight: '22px',
    letterSpacing: -0.41,
    color: colors.black,
    backgroundColor: colors.white,
    marginLeft: 2,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
  },
  tooltipArrow: {
    color: colors.white,
  },
}));

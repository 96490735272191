import React, { useEffect } from 'react';
import { AuthHeader } from '@root/web/components/AuthHeader';
import { AuthFooter } from '@root/web/components/AuthFooter';
import { ToastContainer } from 'react-toastify';
import { WEB_NOTIFICATION_TIMEOUT } from '@root/general/consts';
import { RightImageContainer } from '@root/web/components/RightImageContainer';
import { useStyles } from './styles';
import { strings } from '@root/strings';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { useHistory, useLocation } from 'react-router-dom';
import { route } from '@root/web/consts';
import { useDispatch } from 'react-redux';
import { setTokenAction } from '@root/redux/actions';
import { profileTabsMode } from '@root/web/screens/ProfileScreen/types';

const { registrationSuccessfulModalScreen: string } = strings;

type LocationState = {
  from: {
    pathname: string;
  };
  token: string;
};

export const RegistrationSuccessfulScreen = () => {
  const styles = useStyles();
  let location = useLocation<LocationState>();
  let history = useHistory();

  const token = location.state?.token ?? null;
  const from = location.state?.from ?? route.PROFILE;

  useEffect(() => {
    !token && history.replace(route.WELCOME);
  }, [history, token]);

  const dispatch = useDispatch();

  const goToReference = () => {
    dispatch(setTokenAction(token));

    history.replace(route.REFERENCES, { tabMode: profileTabsMode.ADD_REFERENCE });
  };

  const onPressSkip = () => {
    dispatch(setTokenAction(token));

    history.replace(from);
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <AuthHeader createNewAccountVisible={false} goBackVisible={false} />
        <div className={styles.main}>
          <img className={styles.image} src={require('@root/web/assets/images/registrationSuccessful.svg')} alt='' />
          <span className={styles.title}>{string.titleText}</span>
          <span className={styles.subTitle}>{string.subTitleText}</span>
          <CustomButton type={customButtonType.RED} title={string.buttonText} onPress={goToReference} maxWidth={350} />
          <CustomButton type={customButtonType.TEXT} title={string.skip} onPress={onPressSkip} marginTop={12} />
        </div>
        <AuthFooter />
      </div>
      <RightImageContainer />
      <ToastContainer autoClose={WEB_NOTIFICATION_TIMEOUT} />
    </div>
  );
};

import React from 'react';
import { useStyles } from './styles';
import { JobValueProps } from '@root/general/types';

export const JobValue = (props: JobValueProps) => {
  const { jobName } = props;
  const styles = useStyles(props);

  return (
    <div className={styles.container}>
      <span className={styles.jobName}>{jobName}</span>
    </div>
  );
};

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderWidth: 1,
    borderRadius: 5,
    borderColor: colors.athensGray,
    borderStyle: 'solid',
    padding: 20,
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 7,
  },
  defaultAvatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 34,
    height: 34,
    borderRadius: '50%',
    backgroundColor: colors.redOpacity2,
  },
  avatarContainer: {
    display: 'flex',
    borderWidth: 1,
    borderRadius: 50,
    borderColor: colors.red,
    borderStyle: 'solid',
    padding: 4,
    marginRight: 10,
  },
  avatarIcon: {
    width: 34,
    height: 34,
    borderRadius: 50,
  },
  nameText: {
    fontSize: 17,
    fontFamily: fonts.SFUiDisplayBold,
    letterSpacing: -0.24,
    lineHeight: '20px',
  },
  ratingContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  ratingText: {
    fontSize: 14,
    fontFamily: fonts.SFUiDisplayBold,
    letterSpacing: -0.24,
    lineHeight: '20px',
    backgroundColor: colors.red,
    color: colors.white,
    padding: 6,
    marginLeft: 15,
    borderRadius: 5,
  },
  icon: {
    height: 30,
    width: 30,
  },
  fullIcon: {
    filter:
      'brightness(0) saturate(100%) invert(91%) sepia(25%) saturate(1001%) hue-rotate(337deg) brightness(99%) contrast(96%);',
  },
}));

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() => ({
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    outline: 0,
    backgroundColor: colors.white,
    padding: 12,
    borderRadius: 5,
    borderColor: colors.athensGray,
    borderWidth: 1,
    borderStyle: 'solid',
    maxWidth: 540,
  },
  modalExpressionText: {
    color: colors.black,
    letterSpacing: -0.24,
    fontSize: 17,
    fontFamily: fonts.SFUiDisplayMedium,
    textAlign: 'center',
    marginTop: 10,
    wordWrap: 'break-word',
  },
}));

import React from 'react';
import { ListItem, ListItemText, styled, Tooltip } from '@material-ui/core';
import { useStyles } from './styles';
import { colors } from '@root/general/colors';

export type StyledListItemProps = {
  onClick: () => void;
  isOpen: boolean;
  sidebarName: string;
  icon: string;
  isActive?: boolean;
  isMenu?: boolean;
};

export const StyledListItem = (props: StyledListItemProps) => {
  const { onClick, isOpen, sidebarName, icon, isActive, isMenu } = props;
  const styles = useStyles(props);

  const StyledListItem = styled(ListItem)({
    '&.Mui-selected': { backgroundColor: colors.red },
    '&.Mui-selected:hover': { backgroundColor: colors.red },
  });

  return (
    <Tooltip
      title={sidebarName}
      enterDelay={300}
      enterNextDelay={200}
      placement='right'
      arrow
      disableHoverListener={isOpen}
      disableTouchListener={isOpen}
      classes={{ tooltip: styles.tooltip, arrow: styles.tooltipArrow }}
    >
      <StyledListItem button key={sidebarName} onClick={onClick} selected={isActive} className={styles.listItem}>
        <div className={styles.imageContainer}>
          <img className={styles.imageIcon} alt={''} src={icon} />
        </div>
        {isOpen && <ListItemText classes={{ primary: styles.listItemText }} primary={sidebarName} />}
        {isMenu && isOpen && (
          <div className={styles.closeMenuIcon}>
            <img src={require('@root/web/assets/icons/arrowLeft.svg')} alt='' />
          </div>
        )}
      </StyledListItem>
    </Tooltip>
  );
};

import React from 'react';
import clsx from 'clsx';
import { RateValueProps } from '@root/general/types';
import { useStyles } from './styles';

export const RateValue = (props: RateValueProps) => {
  const { rate, rateType } = props;
  const styles = useStyles();

  return (
    <span className={styles.valueText}>
      <span className={clsx(styles.valueText, styles.valueTextRed)}>{rate && `$${rate} `}</span>
      {rateType}
    </span>
  );
};

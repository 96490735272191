import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';

export const adBannerHeight = 100;

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    height: adBannerHeight,
    width: '100%',
    position: 'relative',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: colors.athensGray,
  },
  imageContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  image: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    width: 'auto',
    height: 'auto',
    objectFit: 'contain',
  },
}));

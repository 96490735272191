import { IActionType } from '@root/redux/types';
import {
  SET_INDUSTRIES_LOADING,
  FETCH_SPECIFICATIONS_SUCCESS,
  FETCH_INDUSTRIES_SUCCESS,
  FETCH_INDUSTRIES_WITH_SPECIFICATIONS_SUCCESS,
} from '@root/general/consts/actions';
import { ArrayIndustries, ArraySpecifications, Industry } from '@root/general/types';

export type IndustriesReducer = {
  allIndustries: ArrayIndustries;
  allSpecifications: ArraySpecifications;
  loading: boolean;
};

const INITIAL_STATE: IndustriesReducer = {
  allIndustries: [],
  allSpecifications: [],
  loading: false,
};

export const industriesReducer = (state = INITIAL_STATE, action: IActionType) => {
  switch (action.type) {
    case SET_INDUSTRIES_LOADING:
      return { ...state, loading: action.payload };
    case FETCH_SPECIFICATIONS_SUCCESS:
      return { ...state, allSpecifications: action.payload };
    case FETCH_INDUSTRIES_SUCCESS: {
      const allIndustries = action.payload.filter(
        (industry: Industry) => industry.specificationsCount > 0 && industry.rateTypesCount > 0,
      );

      return { ...state, allIndustries };
    }
    case FETCH_INDUSTRIES_WITH_SPECIFICATIONS_SUCCESS: {
      const allIndustries = action.payload.filter(
        (industry: Industry) => industry.specificationsCount > 0 && industry.rateTypesCount > 0,
      );

      const allSpecifications = allIndustries.reduce(
        (acc: ArrayIndustries, curr: ArraySpecifications & { specifications: ArraySpecifications }) => {
          return [...acc, ...curr.specifications];
        },
        [],
      );

      return { ...state, allIndustries, allSpecifications };
    }
    default:
      return state;
  }
};

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() => ({
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    outline: 0,
    minWidth: 320,
    width: 540,
    marginRight: 20,
    marginLeft: 20,
    borderRadius: 5,
    borderColor: colors.athensGray,
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor: colors.white,
  },
  itemContainer: {
    padding: '17px 20px',
    cursor: 'pointer',
    borderWidth: '0px 0px 1px',
    borderColor: colors.athensGray,
    borderStyle: 'solid',
    '&:hover span': {
      fontFamily: fonts.SFUiDisplayBold,
      color: colors.red,
    },
  },
  itemText: {
    fontSize: 17,
    fontFamily: fonts.SFUiDisplayMedium,
    lineHeight: '22px',
    letterSpacing: -0.24,
  },
  itemSelectedText: {
    fontFamily: fonts.SFUiDisplayBold,
    color: colors.red,
  },
}));

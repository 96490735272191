import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { CustomLoader } from '@root/web/components/CustomLoader';
import Scrollbars from 'react-custom-scrollbars';
import { ScrollBottomLoader } from '@root/web/components/ScrollBottomLoader';
import { useStyles } from './styles';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { EmptyListComponent } from '@root/web/components/EmptyListComponent';

export type CustomScrollComponentProps = {
  data: Array<any>;
  renderItem: (item: any, index: number, array: Array<any>) => JSX.Element | null;
  isFlatListLoading: boolean;
  onEndReached: () => void;
  hasMore: boolean;
  header?: JSX.Element;
  footer?: JSX.Element;
  containerStyle?: string;
  headerStyle?: string;
  defaultPrimaryText?: string;
  defaultSecondaryText?: string;
};

export const CustomScrollComponent = (props: CustomScrollComponentProps) => {
  const {
    data,
    renderItem,
    isFlatListLoading,
    hasMore,
    onEndReached,
    header,
    footer,
    containerStyle,
    headerStyle,
    defaultPrimaryText,
    defaultSecondaryText,
  } = props;

  const styles = useStyles(props);

  return (
    <div className={clsx(styles.container, containerStyle)}>
      {header && <div className={clsx(styles.headerContainer, headerStyle)}>{header}</div>}
      {isFlatListLoading ? (
        <CustomLoader />
      ) : (
        <>
          {data && data.length > 0 ? (
            <Scrollbars
              renderTrackHorizontal={(props) => <div {...props} className={styles.horizontalScroll} />}
              renderThumbHorizontal={(props) => <div {...props} className={styles.horizontalScroll} />}
            >
              <InfiniteScroll
                useWindow={false}
                pageStart={0}
                loadMore={onEndReached}
                hasMore={hasMore}
                loader={<ScrollBottomLoader key={0} />}
              >
                <Grid className={styles.gridContainer} container spacing={3}>
                  {data.map(renderItem)}
                </Grid>
              </InfiniteScroll>
            </Scrollbars>
          ) : (
            <EmptyListComponent primaryText={defaultPrimaryText} secondaryText={defaultSecondaryText} />
          )}
          {footer && <div className={styles.footerContainer}>{footer}</div>}
        </>
      )}
    </div>
  );
};

import { FetchCitiesOnMapPayload, FetchJobsOnMapPayload } from '@root/redux/actions/types';
import { FETCH_CITIES_ON_MAP_REQUEST, FETCH_JOBS_ON_MAP_REQUEST } from '@root/general/consts/actions';

export const fetchCitiesOnMap = (payload: FetchCitiesOnMapPayload) => ({
  type: FETCH_CITIES_ON_MAP_REQUEST,
  payload,
});

export const fetchJobsOnMap = (payload: FetchJobsOnMapPayload) => ({
  type: FETCH_JOBS_ON_MAP_REQUEST,
  payload,
});

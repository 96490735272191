import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles({
  valueText: {
    fontSize: 17,
    fontFamily: fonts.SFUiDisplayMedium,
    lineHeight: '22px',
    letterSpacing: -0.24,
    color: colors.darkGrey,
  },
  valueTextRed: {
    color: colors.red,
  },
});

import React, { Dispatch, SetStateAction, useRef } from 'react';
import { JobDetails } from '@root/web/components/JobDetails';
import { Job } from '@root/general/types';
import { JOBS_STATUS } from '@root/general/consts';
import { strings } from '@root/strings';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { PickerModalWithConfirmation } from '@root/web/components/PickerModalWithConfirmation';
import { useStyles } from './styles';
import { Button } from '@material-ui/core';
import { CustomTabHeader } from '@root/web/components/CustomTabHeader';
import { JobStatusSwitcher } from '@root/web/screens/JobListingScreen/components/JobStatusSwitcher';
import { CustomTabContainer } from '@root/web/components/CustomTabContainer';
import { CustomLoader } from '@root/web/components/CustomLoader';
import Scrollbars from 'react-custom-scrollbars';

const { buttons, jobDetailsModalScreen: string } = strings;

export type EmployerJobDetailsProps = {
  job: Job;
  setJob: Dispatch<SetStateAction<Job>>;
  goBackCallback: () => void;
  isLoading: boolean;
  isJobEditable: boolean;
  setNewJobStatusId: (newJobStatusId: number) => void;
  jobStatusesModalVisible: boolean;
  setJobStatesModalVisible: Dispatch<SetStateAction<boolean>>;
  goToReviewLaborers: (setJob: Dispatch<SetStateAction<Job>>, job: Job) => () => void;
  goToProposals: () => void;
  goToEditJob: () => void;
  isHidden: boolean;
};

export const EmployerJobDetails = (props: EmployerJobDetailsProps) => {
  const styles = useStyles(props);
  const ref = useRef<any>();

  const {
    job,
    setJob,
    goBackCallback,
    isLoading,
    isJobEditable,
    setNewJobStatusId,
    jobStatusesModalVisible,
    setJobStatesModalVisible,
    goToReviewLaborers,
    goToProposals,
    goToEditJob,
    isHidden,
  } = props;
  const { status: jobStatusId, reviewsCount, offersCount, isBlocked } = job;

  const submitReviewEnable = jobStatusId === JOBS_STATUS.COMPLETED.id && reviewsCount === 0;

  const switchJobStatusEnable =
    !isBlocked && jobStatusId !== JOBS_STATUS.CANCELLED.id && jobStatusId !== JOBS_STATUS.COMPLETED.id;

  const onSwitcherPress = () => {
    setJobStatesModalVisible(!jobStatusesModalVisible);
  };

  const renderButton = () => {
    const isCancelledState = jobStatusId === JOBS_STATUS.CANCELLED.id;

    const isDisabled = (!isCancelledState && !offersCount) || isLoading;
    const title = isCancelledState ? string.repostJob : `Proposals ${offersCount ? `(${offersCount})` : ''}`;
    const onPress = isCancelledState ? () => setNewJobStatusId(JOBS_STATUS.OFFERED.id) : goToProposals;

    return <CustomButton type={customButtonType.RED} title={title} onPress={onPress} disabled={isDisabled} />;
  };

  const renderSubmitReviewButton = () => {
    return (
      <Button
        onClick={goToReviewLaborers(setJob, job)}
        variant={'contained'}
        className={styles.submitReviewContainer}
        startIcon={<img src={require('@root/web/assets/icons/review.svg')} alt='' />}
      >
        {string.submitReviewButton}
      </Button>
    );
  };

  const availableStates = jobStatusId
    ? Object.values(JOBS_STATUS).filter((jobStatus) => jobStatus.id > jobStatusId)
    : Object.values(JOBS_STATUS);

  return (
    <CustomTabContainer ref={ref} tabPositionFixed isHidden={isHidden}>
      <CustomTabHeader
        title={string.screenTitle}
        onTabHeaderClose={goBackCallback}
        buttonIcon={require('@root/web/assets/icons/edit.svg')}
        buttonPress={isJobEditable ? goToEditJob : undefined}
        buttonTitle={buttons.edit}
      />
      <Scrollbars>
        <div className={styles.scrollbarContent}>
          {submitReviewEnable && !isLoading && renderSubmitReviewButton()}
          <JobDetails job={job} />
        </div>
      </Scrollbars>
      <div className={styles.jobStatusSwitcherContainer}>
        {jobStatusId && (
          <JobStatusSwitcher
            jobStatusId={jobStatusId}
            disabled={isLoading || !switchJobStatusEnable}
            onSwitcherPress={switchJobStatusEnable ? onSwitcherPress : undefined}
          />
        )}
      </div>
      {jobStatusesModalVisible && (
        <PickerModalWithConfirmation
          isModalVisible={jobStatusesModalVisible}
          setModalVisible={setJobStatesModalVisible}
          data={availableStates}
          setRespondedValue={setNewJobStatusId}
          ref={ref}
        />
      )}
      <div className={styles.footerContainer}>{isBlocked ? <span>{string.jobBlocked}</span> : renderButton()}</div>
      {isLoading && <CustomLoader />}
    </CustomTabContainer>
  );
};

import React from 'react';
import { IndustryTopNavigator } from '@root/web/navigators/IndustryTopNavigator';
import { Grid } from '@material-ui/core';
import { useJobListingScreen } from '@root/hooks/useJobListingScreen';
import { useFlatListPagination } from '@root/hooks/useFlatListPagination';
import { Industry, Job } from '@root/general/types';
import { JobCard } from '@root/web/components/JobCard';
import { useStyles } from './styles';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { WEB_NOTIFICATION_TIMEOUT } from '@root/general/consts';
import { ToastContainer } from 'react-toastify';
import { strings } from '@root/strings';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { JobDetailsScreen } from '@root/web/screens/JobListingScreen/components/JobDetailsScreen';
import { MainHeader } from '@root/web/components/MainHeader';
import { route } from '@root/web/consts';
import { jobListingCallback } from '@root/utils/jobListingCallback';
import { setMapFilter } from '@root/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { JobListingFilterScreen } from '@root/web/screens/JobListingScreen/components/JobListingFilterScreen';
import { CustomScrollComponent } from '@root/web/components/CustomScrollComponent';
import { MainFooter } from '@root/web/components/MainFooter';
import { BrowserView } from 'react-device-detect';
import { CustomTabContainer } from '@root/web/components/CustomTabContainer';
import { getNavigationBarWidth } from '@root/selectors';

const { JobListingScreen: string, buttons } = strings;

type locationType = {
  isTabShown?: boolean;
};

type JobListingParams = {
  jobId: string;
  industryName: string;
  specificationName: string;
};

export const JobListingScreen = (props: { path: string }) => {
  const { jobId } = useParams<JobListingParams>();

  const styles = useStyles({ jobId });

  const history = useHistory();
  const location = useLocation<locationType>();

  const navigationBarWidth = useSelector(getNavigationBarWidth);

  const isTabShown = location?.state?.isTabShown ?? false;

  const toggleFilterTab = () => {
    history.push({ state: { isTabShown: !isTabShown } });
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.navigationContainer}>
        {!jobId && <MainHeader />}
        <IndustryTopNavigator
          {...props}
          navigationBarWidth={navigationBarWidth}
          needSpecification
          specificationRightButton={
            <CustomButton
              type={customButtonType.WHITE}
              title={buttons.filter}
              onPress={toggleFilterTab}
              icon={require('@root/web/assets/icons/filter.svg')}
              maxWidth={120}
              ellipse
            />
          }
          isNavHidden={!!jobId}
        >
          <JobListingView specificationId={1} />
        </IndustryTopNavigator>
        <ToastContainer autoClose={WEB_NOTIFICATION_TIMEOUT} />
      </div>
      {isTabShown && (
        <>
          <CustomTabContainer isCarcass />
          <JobListingFilterScreen goBackCallback={() => history.goBack()} />
        </>
      )}
    </div>
  );
};

type JobListingViewProps = {
  specificationId: number;
  industry?: Industry;
};

const JobListingView = (props: JobListingViewProps) => {
  const dispatch = useDispatch();

  const { specificationId, industry } = props;

  const { jobId, industryName, specificationName } = useParams<JobListingParams>();

  const styles = useStyles({ jobId });

  const history = useHistory();
  const location = useLocation<locationType>();
  const isTabShown = location?.state?.isTabShown ?? false;

  const [jobs, lastPage, fetchAction, refreshAction, fetchAdditionAction] = useJobListingScreen(specificationId);

  const [jobListing, isFlatListLoading, , , , onEndReached, setJobListing, hasMore] = useFlatListPagination<Job>(
    jobs,
    lastPage,
    fetchAction,
    refreshAction,
    fetchAdditionAction,
  );

  const goToJobDetails = (jobId: number | null) => () => {
    history.push(`${route.JOB_LISTING}/${industryName}/${specificationName}/${jobId}`);
  };

  const renderJobCard = (job: Job) => {
    const { id: itemJobId, offerStatus } = job;
    return (
      <Grid key={itemJobId || 0} item xs={12} md={isTabShown ? 12 : 6} lg={isTabShown ? 6 : 4} xl={isTabShown ? 4 : 3}>
        <JobCard job={job} offerStatusId={offerStatus} industry={industry} onPress={goToJobDetails(itemJobId)} />
      </Grid>
    );
  };

  if (jobId) {
    return (
      <JobDetailsScreen
        jobId={Number(jobId)}
        goBackCallback={history.goBack}
        jobDetailsCallback={jobListingCallback(jobListing, setJobListing)}
      />
    );
  }

  return (
    <>
      <CustomScrollComponent
        data={jobListing}
        renderItem={renderJobCard}
        isFlatListLoading={isFlatListLoading}
        onEndReached={onEndReached}
        hasMore={hasMore}
        footer={<MainFooter />}
        defaultPrimaryText={string.defaultPrimaryText}
      />
      <BrowserView>
        <CustomButton
          type={customButtonType.RED}
          className={styles.viewOnMapButton}
          title={buttons.viewMap}
          onPress={() => {
            dispatch(setMapFilter({ mapFilter: { filterSpecificationIds: [specificationId] } }));
            history.push(route.JOB_LISTING_MAP, { specificationId });
          }}
          icon={require('@root/web/assets/icons/map.svg')}
          maxWidth={200}
          ellipse
        />
      </BrowserView>
    </>
  );
};

import { OFFER_STATUS, OFFERS_BY_STATUS } from '@root/general/consts';
import { colors } from '@root/general/colors';

export const getLabelColorByOfferStatus = (
  offerStatus: number | undefined | null,
  isOfferFromUs: boolean | undefined,
  isLaborerCard: boolean,
) => {
  switch (offerStatus) {
    case OFFER_STATUS.SENT.id:
      if (isLaborerCard) {
        return isOfferFromUs
          ? { name: OFFERS_BY_STATUS.SENT.name, color: colors.apricot }
          : { name: OFFERS_BY_STATUS.RECEIVED.name, color: colors.white };
      } else {
        return isOfferFromUs
          ? { name: OFFERS_BY_STATUS.APPLIED.name, color: colors.apricot }
          : { name: OFFERS_BY_STATUS.NEW.name, color: colors.white }; //textColor: colors.black
      }
    case OFFER_STATUS.DECLINED.id:
      return { name: OFFERS_BY_STATUS.DECLINED.name, color: colors.darkRed };
    case OFFER_STATUS.ACCEPTED.id:
      return { name: OFFERS_BY_STATUS.ACCEPTED.name, color: colors.celery };
    case OFFER_STATUS.CANCELLED.id:
      return { name: OFFERS_BY_STATUS.CANCELLED.name, color: colors.roman };
    default:
      return { name: '', color: colors.white };
  }
};

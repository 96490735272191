import React from 'react';
// @ts-ignore
import styles from './styles.module.css';

enum socialNetworkIds {
  Facebook = 1,
  Mail = 2,
  Twitter = 3,
  Instagram = 4,
}

type SocialNetworkObject = {
  id: socialNetworkIds;
  title: string;
  link: string;
  icon: any;
};

export const SocialNetworks = () => {
  const socialNetworksData: Array<SocialNetworkObject> = [
    {
      id: socialNetworkIds.Facebook,
      title: 'Facebook',
      link: 'https://www.facebook.com/AgButlerApp',
      icon: require('src/web/assets/icons/facebook.svg'),
    },
    {
      id: socialNetworkIds.Mail,
      title: 'Mail',
      link: 'mailto:agbutlerapp@gmail.com',
      icon: require('src/web/assets/icons/mail.svg'),
    },
    {
      id: socialNetworkIds.Twitter,
      title: 'Twitter',
      link: 'https://twitter.com/AgButlerApp',
      icon: require('src/web/assets/icons/twitter.svg'),
    },
    {
      id: socialNetworkIds.Instagram,
      title: 'Instagram',
      link: 'https://www.instagram.com/agbutlerapp/',
      icon: require('src/web/assets/icons/instagram.svg'),
    },
  ];

  const renderItem = (item: SocialNetworkObject) => {
    const { id, icon, link, title } = item;
    return (
      <a key={id} className={styles.socialNetwork} href={link} target={'_blank'}>
        <img src={icon} alt={title} />
      </a>
    );
  };

  return <div className={styles.socialNetworksContainer}>{socialNetworksData.map(renderItem)}</div>;
};

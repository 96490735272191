import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArraySpecifications, CityType, StateType } from '@root/general/types';
import { getLaborersFilter, getSpecificationsByIndustryId } from '@root/selectors';
import { fetchSpecificationsAction, setLaborerFilterAction } from '@root/redux/actions';
import { showToastAction, ToastTypes } from '@root/redux/actions/commonActions';
import { LaborersFilter } from '@root/redux/reducers/laborersReducer';
import { strings } from '@root/strings';
import { getStateFullName } from '@root/utils/getStateFullName';
import { CustomInputFormDataType } from '@root/mobile/types/inputTypes';
import { random } from 'lodash';

const { laborersFilterModalScreen: string, warnings } = strings;

export enum laborersFilterFieldFormData {
  specification = 0,
  rate = 1,
  rating = 2,
  state = 3,
  city = 4,
  radius = 5,
}

type UseLaborersFilterModalScreenReturnType = [
  CustomInputFormDataType,
  () => void,
  () => void,
  Dispatch<SetStateAction<string>>,
  Dispatch<SetStateAction<number | null>>,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  ArraySpecifications,
  Dispatch<SetStateAction<number | null>>,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  Dispatch<SetStateAction<number | null>>,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  StateType[] | null,
  Dispatch<SetStateAction<StateType[] | null>>,
  Dispatch<SetStateAction<CityType | null>>,
  number | null,
  number | null,
  number | null,
  number,
];

export const useLaborersFilterModalScreen = (
  onStatePress: (
    setFilterState: Dispatch<SetStateAction<StateType[] | null>>,
    setFilterCity: Dispatch<SetStateAction<CityType | null>>,
    setFilterRadius: Dispatch<SetStateAction<number | null>>,
    states?: StateType[] | null,
  ) => () => void,
  onCityPress: (
    filterState: StateType[] | null,
    setFilterCity: Dispatch<SetStateAction<CityType | null>>,
  ) => () => void,
  navigationCallback: () => void,
): UseLaborersFilterModalScreenReturnType => {
  const dispatch = useDispatch();
  const { filter, currentIndustryId } = useSelector(getLaborersFilter);
  const specifications = useSelector(getSpecificationsByIndustryId(currentIndustryId));

  const [jobSpecificationModalVisible, setJobSpecificationModalVisible] = useState(false);
  const [ratingModalVisible, setRatingModalVisible] = useState(false);
  const [radiusModalVisible, setRadiusModalVisible] = useState(false);

  const { specificationId, state, city, radius, rate, rating } = filter;

  const [jobSpecificationId, setJobSpecificationId] = useState<number | null>(specificationId);

  const defaultJobSpecification = specifications.find((item) => item.id === jobSpecificationId);

  const initialJobSpecification = defaultJobSpecification?.name ?? '';

  const [jobSpecificationName, setJobSpecificationName] = useState(initialJobSpecification);
  const [filterRate, setFilterRate] = useState<number | null>(rate);
  const [filterRating, setFilterRating] = useState<number | null>(rating);
  const [filterRadius, setFilterRadius] = useState<number | null>(radius);

  const [filterCity, setFilterCity] = useState<CityType | null>(city);
  const [filterState, setFilterState] = useState<StateType[] | null>(state ?? []);

  const getStatesFullNames = (filterState: StateType[] | null) => {
    return Array.isArray(filterState) ? filterState.map((state) => getStateFullName(state)) : [];
  };

  const cityName = filterCity?.name ?? '';

  const [keyresetFilter, setKeyresetFilter] = useState(0);

  const disableCityAndRadiusFields = !!(filterState && filterState.length > 1);

  useEffect(() => {
    if (filterState && filterState.length > 1) {
      setFilterCity(null);
      setFilterRadius(null);
    }
  }, [filterState]);

  useEffect(() => {
    if (filterState && filterState.length > 1) {
      setFilterCity(null);
      setFilterRadius(null);
    }
  }, [filterState]);

  useEffect(() => {
    dispatch(fetchSpecificationsAction());
  }, [dispatch]);

  const onPressRadius = () => {
    if (filterState?.length === 1 && filterCity) {
      setRadiusModalVisible(!radiusModalVisible);
    } else if (filterState?.length === 1 && !filterCity) {
      dispatch(showToastAction({ message: warnings.selectCity, type: ToastTypes.Error }));
    } else if (!filterState?.length && !filterCity) {
      dispatch(showToastAction({ message: warnings.selectStateAndCity, type: ToastTypes.Error }));
    }
  };

  const onPressJobSpecification = () => {
    setJobSpecificationModalVisible(!jobSpecificationModalVisible);
  };

  // const onPressRating = () => {
  //   setRatingModalVisible(!ratingModalVisible);
  // };

  const filterFieldFormData: CustomInputFormDataType = [
    {
      id: laborersFilterFieldFormData.specification,
      title: string.jobSpecificationTitle,
      defaultValue: jobSpecificationName,
      placeHolder: string.jobSpecificationPlaceholder,
      onPress: onPressJobSpecification,
      icon: require('@root/assets/icons/dropdownArrow.png'),
    },

    // TODO AIMA-402 hide fields
    // {
    //   id: laborersFilterFieldFormData.rate,
    //   title: string.rateTitle,
    //   defaultValue: filterRate ? `$${filterRate}` : '',
    //   placeHolder: string.ratePlaceholder,
    //   onChangeText: (stringRate: string) => {
    //     setFilterRate(Number(stringRate.substr(1)) || null);
    //   },
    //   keyboardType: 'decimal-pad',
    //   mask: '$[99999990].[90]',
    //   webMask: '$9999999.99',
    // },
    // {
    //   id: laborersFilterFieldFormData.rating,
    //   title: string.ratingTitle,
    //   defaultValue: filterRating ? String(filterRating) : '',
    //   onPress: onPressRating,
    //   placeHolder: string.ratingPlaceholder,
    //   icon: require('@root/assets/icons/dropdownArrow.png'),
    // },
    {
      id: laborersFilterFieldFormData.state,
      title: string.stateTitle,
      defaultValue: getStatesFullNames(filterState),
      placeHolder: string.statePlaceholder,
      onPress: onStatePress(setFilterState, setFilterCity, setFilterRadius, filterState),
      icon: require('@root/assets/icons/arrowRight.png'),
      webIcon: require('@root/web/assets/icons/arrowRight.svg'),
      autoLength: true,
    },
    {
      id: laborersFilterFieldFormData.city,
      title: string.cityTitle,
      defaultValue: cityName,
      placeHolder: string.cityPlaceholder,
      onPress: onCityPress(filterState, setFilterCity),
      icon: require('@root/assets/icons/arrowRight.png'),
      webIcon: require('@root/web/assets/icons/arrowRight.svg'),
      autoLength: true,
      disabled: disableCityAndRadiusFields,
    },
    {
      id: laborersFilterFieldFormData.radius,
      title: string.radiusTitle,
      defaultValue: filterRadius ? String(filterRadius) + ' Miles' : '',
      placeHolder: string.radiusPlaceholder,
      onPress: onPressRadius,
      icon: require('@root/assets/icons/dropdownArrow.png'),
      disabled: disableCityAndRadiusFields,
    },
  ];

  const onSave = () => {
    const filter: LaborersFilter = {
      specificationId: jobSpecificationId,
      rate: filterRate,
      rating: Number(filterRating),
      state: filterState,
      city: filterCity,
      radius: filterRadius,
    };

    dispatch(setLaborerFilterAction(filter));
    navigationCallback();
  };

  const resetFilter = () => {
    const key = random(1, 20);
    const uniqKey = key === keyresetFilter ? key + 1 : key;
    setKeyresetFilter(uniqKey);

    setFilterState([]);
    setFilterCity(null);
    setFilterRate(null);
    setFilterRating(null);
    setFilterRadius(null);
    setJobSpecificationId(null);
    setJobSpecificationName('');
  };

  return [
    filterFieldFormData,
    resetFilter,
    onSave,
    setJobSpecificationName,
    setJobSpecificationId,
    jobSpecificationModalVisible,
    setJobSpecificationModalVisible,
    specifications,
    setFilterRating,
    ratingModalVisible,
    setRatingModalVisible,
    setFilterRadius,
    radiusModalVisible,
    setRadiusModalVisible,
    filterState,
    setFilterState,
    setFilterCity,
    jobSpecificationId,
    filterRating,
    filterRadius,
    keyresetFilter,
  ];
};

import React from 'react';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { Grid } from '@material-ui/core';
import { strings } from '@root/strings';
import { useReferencesModalScreen } from '@root/hooks/useReferencesModalScreen';
import { useFlatListPagination } from '@root/hooks/useFlatListPagination';
import { Reference } from '@root/general/types';
import { useStyles } from './styles';
import { ReferenceCard } from '@root/web/screens/ProfileScreen/components/References/components/ReferenceCard';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { route } from '@root/web/consts';
import { ProfileScreensProps, profileTabsMode } from '@root/web/screens/ProfileScreen/types';
import { ReferenceActionScreen } from '@root/web/screens/ProfileScreen/components/ReferenceActionScreen';
import { referencesActionModalMode } from '@root/general/consts';
import { ReferenceActionCallbackProps } from '@root/mobile/screens/ReferencesModalScreen/types';
import { CustomScrollComponent } from '@root/web/components/CustomScrollComponent';

const { referencesModalScreen: string } = strings;

export const References = (props: ProfileScreensProps) => {
  const { tabMode, setTabMode } = props;
  const styles = useStyles();
  const history = useHistory();

  let location = useLocation<{ tabMode: string; reference: Reference }>();
  const selectedReference = location?.state?.reference;

  const params = useParams<{ id?: string; profileParam?: string }>();
  const { id: referenceId = '' } = params;

  const [references, lastPage, fetchAction, refreshAction, fetchAdditionalAction] = useReferencesModalScreen();

  const [referencesArray, isFlatListLoading, , , , onEndReached, setReferencesArray, hasMore] = useFlatListPagination<
    Reference
  >(references, lastPage, fetchAction, refreshAction, fetchAdditionalAction);

  const goToAddReference = () => {
    history.push(route.REFERENCES, { tabMode: profileTabsMode.ADD_REFERENCE });
  };

  const goToEditReference = (reference: Reference) => () => {
    history.push(`${route.REFERENCES}/${reference.id}`, { reference });
  };

  const getReferencesRightTab = () => {
    const referenceActionCallback = (referenceActionCallbackProps: ReferenceActionCallbackProps) => {
      const { isDelete, updatedReference, referenceId } = referenceActionCallbackProps;

      let newReferences;

      if (isDelete) {
        newReferences = referencesArray.filter((reference: Reference) => {
          return reference.id !== referenceId;
        });
      } else {
        newReferences = referencesArray.map((reference: Reference) => {
          if (reference.id === updatedReference?.id) {
            return updatedReference;
          }
          return reference;
        });
      }

      setReferencesArray(newReferences);
    };

    switch (tabMode) {
      case profileTabsMode.ADD_REFERENCE:
        return (
          <ReferenceActionScreen
            mode={referencesActionModalMode.ADD}
            setTabMode={setTabMode}
            referenceActionCallback={referenceActionCallback}
          />
        );
      case profileTabsMode.EDIT_REFERENCE:
        return (
          <ReferenceActionScreen
            key={referenceId}
            mode={referencesActionModalMode.EDIT}
            setTabMode={setTabMode}
            referenceActionCallback={referenceActionCallback}
            reference={selectedReference}
          />
        );
      default:
        return null;
    }
  };

  const renderReferences = (reference: Reference) => {
    const { id } = reference;

    return (
      <Grid key={id || 0} item xs={12} md={6}>
        <ReferenceCard
          reference={reference}
          onPress={goToEditReference(reference)}
          isSelected={id === Number(referenceId)}
        />
      </Grid>
    );
  };

  return (
    <>
      <CustomScrollComponent
        data={referencesArray}
        renderItem={renderReferences}
        isFlatListLoading={isFlatListLoading}
        onEndReached={onEndReached}
        hasMore={hasMore}
        defaultPrimaryText={string.defaultTextPrimary}
        header={
          <div className={styles.referenceAddButtonContainer}>
            <CustomButton
              type={customButtonType.GREY}
              title={string.addReference}
              onPress={goToAddReference}
              icon={require('@root/web/assets/icons/add.svg')}
              maxWidth={170}
              ellipse
            />
          </div>
        }
        containerStyle={styles.scrollComponent}
      />
      {!!tabMode && getReferencesRightTab()}
    </>
  );
};

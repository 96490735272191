export const fonts = {
  OpenSansBold: 'OpenSans-Bold',
  OpenSansBoldItalic: 'OpenSans-BoldItalic',
  OpenSansExtraBold: 'OpenSans-ExtraBold',
  OpenSansExtraBoldItalic: 'OpenSans-ExtraBoldItalic',
  OpenSansItalic: 'OpenSans-Italic',
  OpenSansLight: 'OpenSans-Light',
  OpenSansLightItalic: 'OpenSans-LightItalic',
  OpenSansRegular: 'OpenSans-Regular',
  OpenSansSemiBold: 'OpenSans-SemiBold',
  OpenSansSemiBoldItalic: 'OpenSans-SemiBoldItalic',
  IBMPlexMonoBold: 'IBMPlexMono-Bold',
  IBMPlexMonoBoldItalic: 'IBMPlexMono-BoldItalic',
  IBMPlexMonoExtraLight: 'IBMPlexMono-ExtraLight',
  IBMPlexMonoExtraLightItalic: 'IBMPlexMono-ExtraLightItalic',
  IBMPlexMonoItalic: 'IBMPlexMono-Italic',
  IBMPlexMonoLight: 'IBMPlexMono-Light',
  IBMPlexMonoLightItalic: 'IBMPlexMono-LightItalic',
  IBMPlexMonoRegular: 'IBMPlexMono-Regular',
  IBMPlexMonoSemiBold: 'IBMPlexMono-SemiBold',
  IBMPlexMonoSemiBoldItalic: 'IBMPlexMono-SemiBoldItalic',
  IBMPlexMonoMedium: 'IBMPlexMono-Medium',
  RobotoBlack: 'Roboto-Black',
  RobotoBlackItalic: 'Roboto-BlackItalic',
  RobotoBold: 'Roboto-Bold',
  RobotoBoldItalic: 'Roboto-BoldItalic',
  RobotoItalic: 'Roboto-Italic',
  RobotoLight: 'Roboto-Light',
  RobotoLightItalic: 'Roboto-LightItalic',
  RobotoMedium: 'Roboto-Medium',
  RobotoMediumItalic: 'Roboto-MediumItalic',
  RobotoRegular: 'Roboto-Regular',
  RobotoThin: 'Roboto-Thin',
  RobotoThinItalic: 'Roboto-ThinItalic',
  SFUiDisplayBlack: 'SFUIDisplay-Black',
  SFUiDisplayBold: 'SFUIDisplay-Bold',
  SFUiDisplayHeavy: 'SFUIDisplay-Heavy',
  SFUiDisplayLight: 'SFUIDisplay-Light',
  SFUiDisplayMedium: 'SFUIDisplay-Medium',
  SFUiDisplaySemiBold: 'SFUIDisplay-Semibold',
  SFUiDisplayThin: 'SFUIDisplay-Thin',
  SFUiDisplayUltraLight: 'SFUIDisplay-Ultralight',
};

import React, { ReactElement } from 'react';
import { useStyles } from './styles';

type InfoContainerProps = {
  label: string;
  value?: string | number | null;
  valueElement?: ReactElement;
  iconSource?: any;
  multiline?: boolean;
  // todo go to employer details
  // iconStyle?: any;
  // style?: any;
  // onPress?: () => void;
};

export const InfoContainer = (props: InfoContainerProps) => {
  const styles = useStyles(props);
  const { label, value, iconSource = null, multiline = false, valueElement } = props;

  return (
    <div className={styles.container}>
      <div className={styles.inlineContainer}>
        <div className={styles.labelContainer}>
          <div className={styles.iconContainer}>
            {iconSource && <img className={styles.icon} src={iconSource} alt='' />}
          </div>
          <span className={styles.labelText}>{label}</span>
        </div>
        <div className={styles.valueContainer}>
          {valueElement ? valueElement : !multiline && <span className={styles.valueText}>{value}</span>}
        </div>
      </div>
      {multiline && (
        <div className={styles.multilineContainer}>
          <span className={styles.descriptionText}>{value}</span>
        </div>
      )}
    </div>
  );
};

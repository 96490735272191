import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '@root/general/fonts';
import { DefaultCardProps } from '@root/mobile/screens/PaymentSystemModalScreen/components/DefaultCard/types';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles(() => ({
  defaultCardContent: ({ isFailed }: DefaultCardProps) => {
    return {
      display: 'flex',
      width: '100%',
      backgroundColor: isFailed ? colors.redOpacity05 : colors.lightGrey,
      borderRadius: '4px',
    };
  },
  defaultCardRightContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  defaultCardNumberContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  defaultCardBrand: {
    fontSize: 15,
    fontFamily: fonts.SFUiDisplayBold,
    lineHeight: '20px',
    letterSpacing: -0.24,
  },
  defaultCardNumberDots: {
    fontSize: 18,
    fontFamily: fonts.IBMPlexMonoMedium,
    lineHeight: '20px',
    marginRight: 12,
    whiteSpace: 'nowrap',
    margin: '0px 8px',
  },
  defaultCardNumber: {
    fontSize: 12,
    fontFamily: fonts.IBMPlexMonoMedium,
    lineHeight: '22px',
    letterSpacing: 3,
  },
  defaultCardExpireDate: {
    fontSize: 13,
    fontFamily: fonts.IBMPlexMonoMedium,
    lineHeight: '22px',
    textTransform: 'uppercase',
  },
  defaultCardBrandImage: {
    height: 22,
    padding: '24px 22px',
  },
}));

import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Input } from '@material-ui/core';
import { useStyles } from './styles';

type SearchInputProps = {
  value: string;
  placeholder: string;
  onChange: Dispatch<SetStateAction<string>>;
};

export const SearchInput = (props: SearchInputProps) => {
  const styles = useStyles();
  const { onChange, placeholder, value } = props;

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Input
      className={styles.search}
      classes={{ input: styles.input }}
      placeholder={placeholder}
      value={value}
      onChange={onChangeValue}
      startAdornment={<img className={styles.icon} src={require('@root/web/assets/icons/search.svg')} alt='' />}
      fullWidth
    />
  );
};

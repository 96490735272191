import { SET_NAVIGATION_BAR_WIDTH } from '@root/general/consts/actions';
import { IActionType } from '@root/redux/types';

export type NavigationBarReducer = {
  navigationBarWidth: number;
};

const INITIAL_STATE: NavigationBarReducer = {
  navigationBarWidth: 75,
};

export const navigationBarReducer = (state = INITIAL_STATE, action: IActionType) => {
  switch (action.type) {
    case SET_NAVIGATION_BAR_WIDTH:
      const { navigationBarWidth } = action.payload;

      return { ...state, navigationBarWidth };
    default:
      return state;
  }
};

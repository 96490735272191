import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    pageContainer: {
      display: 'flex',
      flex: 1,
    },
    navigationContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    viewOnMapButton: {
      position: 'absolute',
      bottom: 20,
      right: 30,
      fontSize: 15,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  };
});

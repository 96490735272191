import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const INITIAL_PAGE = 1;

export type fetchActionCallback<T> = (isSuccess: boolean, newItems?: Array<T>, lastPage?: number) => void;

export type fetchActionWithCallback<T> = (
  page: number,
  callback: fetchActionCallback<T>,
) => { type: string; payload: any };

export type UseFlatListPaginationWithoutReduxReturnType<T> = [
  Array<T>,
  boolean,
  boolean,
  boolean,
  () => void,
  () => void,
  Dispatch<SetStateAction<T[]>>,
  boolean,
];

export const useFlatListPaginationWithoutRedux = <T extends {}>(
  fetchAction: fetchActionWithCallback<T>,
  enableFetching = true,
): UseFlatListPaginationWithoutReduxReturnType<T> => {
  const dispatch = useDispatch();

  const [page, setPage] = useState<number>(INITIAL_PAGE);
  const [lastPage, setLastPage] = useState<number>(INITIAL_PAGE);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [isFooterLoading, setFooterLoading] = useState<boolean>(false);
  const [isFlatListLoading, setFlatListLoading] = useState<boolean>(false);
  const [items, setItems] = useState<Array<T>>([]);

  useEffect(() => {
    const callback = (isSuccess: boolean, newItems: Array<T> = [], lastPage?: number) => {
      if (isSuccess && lastPage) {
        setPage(INITIAL_PAGE);
        setLastPage(lastPage);
        setItems(newItems);
      }
      setFlatListLoading(false);
    };

    setFlatListLoading(true);

    if (enableFetching) {
      dispatch(fetchAction(INITIAL_PAGE, callback));
    } else {
      setFlatListLoading(false);
    }
  }, [dispatch, fetchAction, enableFetching]);

  const handleRefresh = () => {
    const callback = (isSuccess: boolean, newItems: Array<T> = [], lastPage?: number) => {
      if (isSuccess && lastPage) {
        setLastPage(lastPage);
        setItems(newItems);
      }
      setRefreshing(false);
    };

    setRefreshing(true);
    setPage(INITIAL_PAGE);

    dispatch(fetchAction(INITIAL_PAGE, callback));
  };

  const onEndReached = () => {
    const callback = (isSuccess: boolean, newItems: Array<T> = []) => {
      if (isSuccess) {
        setPage(page + 1);
        setItems([...items, ...newItems]);
      }
      setFooterLoading(false);
    };

    if (page < lastPage && !isFooterLoading) {
      setFooterLoading(true);
      dispatch(fetchAction(page + 1, callback));
    }
  };

  const hasMore = page < lastPage;

  return [items, isFlatListLoading, isFooterLoading, refreshing, handleRefresh, onEndReached, setItems, hasMore];
};

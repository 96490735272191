import { useState } from 'react';
import { FormikFieldFormDataType, FormikValuesType } from '@root/mobile/types/inputTypes';
import { restorePasswordAction } from '@root/redux/actions';
import { useDispatch } from 'react-redux';
import { strings } from '@root/strings';

const { restorePasswordModalScreen: string } = strings;

type useRestorePasswordScreenReturnType = [
  FormikValuesType,
  FormikFieldFormDataType,
  boolean,
  (data: FormikValuesType) => void,
];

export const useRestorePasswordScreen = (navigationCallback: () => void): useRestorePasswordScreenReturnType => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const initialValue = { email: '' } as FormikValuesType;

  const restorePasswordData: FormikFieldFormDataType = [
    {
      id: 1,
      title: string.emailTitle,
      valuesName: 'email',
      placeHolder: string.emailPlaceholder,
      keyboardType: 'email-address',
    },
  ];

  const onRestorePassword = (data: FormikValuesType) => {
    const callback = (isSuccess: boolean) => {
      isSuccess && navigationCallback();
      setLoading(false);
    };

    setLoading(true);

    dispatch(restorePasswordAction({ email: data.email, callback }));
  };

  return [initialValue, restorePasswordData, loading, onRestorePassword];
};

import {
  ConfirmDeleteAccountActionType,
  DeleteAccountActionType,
  InitialiseAppAction,
  ResetPasswordAction,
  RestorePasswordAction,
  SetAuthHeaderAction,
  SignUpAction,
  SingInAction,
  SingOutActionType,
} from '@root/redux/actions/types';
import { all, call, put } from 'redux-saga/effects';
import { Api, CustomApiResponse } from '@root/services/api';
import { ApiResponse } from 'apisauce';
import { ArrayRoles, ArrayStateType, ErrorResponse, FieldReps, GeneralUser } from '@root/general/types';
import { isArray } from 'lodash';
import {
  CALL_COMMON_ERROR_HANDLER,
  CALL_UNEXPECTED_ERROR_HANDLER,
  FETCH_FIELD_REPS_SUCCESS,
  FETCH_ROLES_SUCCESS,
  FETCH_STATES_SUCCESS,
  SET_AUTH_HEADER,
  SET_AUTH_USER,
  SET_TOKEN,
} from '@root/general/consts/actions';
import { commonNotificationHandler } from '@root/redux/sagas/helpers';
import { accountType } from '@root/general/consts';
import { clearUserDataStatesAction } from '@root/redux/actions';

export function* initialiseApp(api: Api, action: InitialiseAppAction) {
  const { callback } = action.payload;
  try {
    const [rolesResponse, statesResponse]: Array<ApiResponse<ArrayRoles | ArrayStateType>> = yield all([
      call(api.fetchRoles),
      call(api.fetchStates),
    ]);

    if (rolesResponse.ok && isArray(rolesResponse.data) && statesResponse.ok && isArray(statesResponse.data)) {
      yield put({ type: FETCH_ROLES_SUCCESS, payload: rolesResponse.data });
      yield put({ type: FETCH_STATES_SUCCESS, payload: statesResponse.data });

      yield callback && call(callback, true);
    } else {
      yield callback && call(callback, false);
      if (!rolesResponse.ok || isArray(rolesResponse.data)) {
        yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: rolesResponse });
      } else {
        yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: statesResponse });
      }
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

const isUserObjectResponseSuccess = (data: GeneralUser) => {
  return data.id && data.stateId && data.cityId && data.state && data.city && data.roleId;
};

export function* signInUser(api: Api, action: SingInAction) {
  try {
    const { signInData, deviceId, onSuccessCallback } = action.payload;

    const response: CustomApiResponse<{ token: string }, ErrorResponse> = yield call(
      api.signInRequest,
      signInData,
      deviceId,
    );

    if (response.ok && response.data) {
      const { token } = response.data;
      yield put({ type: SET_AUTH_HEADER, payload: token });

      const userResponse: CustomApiResponse<GeneralUser, ErrorResponse> = yield call(api.fetchUser);
      if (userResponse.ok && userResponse.data && isUserObjectResponseSuccess(userResponse.data)) {
        yield put({ type: SET_AUTH_USER, payload: userResponse.data });
        yield put({ type: SET_TOKEN, payload: token });
        onSuccessCallback && onSuccessCallback();
      }

      if (!userResponse.ok) {
        yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: userResponse });
      }
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* signUpUser(api: Api, action: SignUpAction) {
  const { signUpData, uniqueId, callback } = action.payload;

  try {
    const response: CustomApiResponse<{ token: string }, ErrorResponse> = yield call(
      api.signUpRequest,
      signUpData,
      uniqueId,
    );

    if (response.ok && response.data) {
      const { token } = response.data;
      yield put({ type: SET_AUTH_HEADER, payload: token });

      const userResponse: CustomApiResponse<GeneralUser, ErrorResponse> = yield call(api.fetchUser);
      if (userResponse.ok && userResponse.data && isUserObjectResponseSuccess(userResponse.data)) {
        yield put({ type: SET_AUTH_USER, payload: userResponse.data });

        if (signUpData.roleId === accountType.EMPLOYER) {
          yield put({ type: SET_TOKEN, payload: token });
          yield callback && call(callback, true);
        } else {
          yield callback && call(callback, true, token);
        }
      }

      if (!userResponse.ok) {
        yield callback && call(callback, false);
        yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: userResponse });
      }
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* setAuthHeader(api: Api, action: SetAuthHeaderAction) {
  try {
    yield call(api.setHeader, 'Authorization', `Bearer ${action.payload}`);
  } catch (error) {
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* restorePassword(api: Api, action: RestorePasswordAction) {
  const { email, callback } = action.payload;
  try {
    const response: CustomApiResponse<any, ErrorResponse> = yield call(api.restorePasswordRequest, email);

    if (response.ok) {
      yield commonNotificationHandler(response.messages);
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }

    yield callback && call(callback, response.ok);
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* resetPassword(api: Api, action: ResetPasswordAction) {
  const { token, password, passwordConfirmation, callback } = action.payload;
  try {
    const response: CustomApiResponse<any, ErrorResponse> = yield call(
      api.resetPasswordRequest,
      token,
      password,
      passwordConfirmation,
    );

    if (response.ok) {
      yield commonNotificationHandler(response.messages);
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }

    yield callback && call(callback, response.ok);
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* fetchFieldReps(api: Api) {
  try {
    const response: CustomApiResponse<FieldReps, ErrorResponse> = yield call(api.fetchFieldReps);

    if (response.ok && isArray(response.data)) {
      yield put({ type: FETCH_FIELD_REPS_SUCCESS, payload: response.data });
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* signOut(api: Api, action: SingOutActionType) {
  try {
    const response: CustomApiResponse<any, ErrorResponse> = yield call(api.signOutRequest);

    if (response.ok) {
      yield put(clearUserDataStatesAction(action.payload));
      yield commonNotificationHandler(response.messages);
    }
  } catch (error) {
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* deleteAccount(api: Api, action: DeleteAccountActionType) {
  const callback = action.payload?.callback;

  try {
    const response: CustomApiResponse<string[], ErrorResponse> = yield call(api.deleteAccountRequest);

    if (response.ok) {
      yield commonNotificationHandler(response.messages);
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  } finally {
    yield callback && call(callback);
  }
}

export function* confirmDeleteAccount(api: Api, action: ConfirmDeleteAccountActionType) {
  const { deleteAccountToken, setLoading, redirect } = action.payload;

  try {
    const response: CustomApiResponse<string[], ErrorResponse> = yield call(
      api.confirmDeleteAccountRequest,
      deleteAccountToken,
    );

    if (response.ok) {
      yield put(clearUserDataStatesAction());

      yield setLoading && call(setLoading, false);
      yield redirect && call(redirect);

      yield call(commonNotificationHandler, response.messages);
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield setLoading && call(setLoading, false);

    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() => ({
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    outline: 0,
    minWidth: 320,
    width: 540,
    marginRight: 20,
    marginLeft: 20,
    borderRadius: 5,
    borderColor: colors.athensGray,
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor: colors.white,
  },
  scrollbars: {
    backgroundColor: colors.white,
  },
  itemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '17px 23px',
    alignItems: 'center',
    borderWidth: '0px 0px 1px',
    borderColor: colors.athensGray,
    borderStyle: 'solid',
    cursor: 'pointer',
    '&:hover': {
      color: colors.red,
      fontFamily: fonts.SFUiDisplayBold,
    },
  },
  itemText: {
    marginLeft: 11,
    letterSpacing: -0.24,
    fontSize: 17,
    fontFamily: fonts.SFUiDisplayBold,
  },
  itemContent: {
    display: 'flex',
    alignItems: 'center',
  },
  circle: {
    height: 8,
    width: 8,
    borderRadius: '50%',
  },
  checkMarkImage: {
    width: 16,
    height: 12,
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 17,
    fontFamily: fonts.SFUiDisplayBold,
    letterSpacing: -0.24,
    textAlign: 'center',
    padding: '17px 0px',
    borderColor: colors.athensGray,
    borderWidth: '0px 0px 1px',
    borderStyle: 'solid',
  },
}));

import {
  CREATE_NEW_JOB_REQUEST,
  FETCH_PROPOSALS_REQUEST,
  REFRESH_PROPOSALS_REQUEST,
  FETCH_POSTED_JOBS_REQUEST,
  REFRESH_POSTED_JOBS_REQUEST,
  FETCH_ADDITIONAL_POSTED_JOBS_REQUEST,
  EDIT_POSTED_JOB_REQUEST,
  DELETE_POSTED_JOB_REQUEST,
  FETCH_JOB_REQUEST,
  FETCH_JOBS_BY_OFFERS,
  FETCH_ADDITIONAL_PROPOSALS_REQUEST,
} from '@root/general/consts/actions';
import {
  FetchPostedJobsPayload,
  FetchAdditionalPostedJobsPayload,
  FetchProposalsPayload,
  FetchAdditionalProposalsPayload,
  CreateNewJobPayload,
  EditPostedJobPayload,
  DeletePostedJobPayload,
  FetchJobPayload,
  FetchJobsByOffersPayload,
} from '@root/redux/actions/types';

export const fetchPostedJobsAction = (payload: FetchPostedJobsPayload) => ({
  type: FETCH_POSTED_JOBS_REQUEST,
  payload,
});

export const refreshPostedJobsAction = (payload: FetchPostedJobsPayload) => ({
  type: REFRESH_POSTED_JOBS_REQUEST,
  payload,
});

export const fetchAdditionalPostedJobsAction = (payload: FetchAdditionalPostedJobsPayload) => ({
  type: FETCH_ADDITIONAL_POSTED_JOBS_REQUEST,
  payload,
});

export const createNewJobAction = (payload: CreateNewJobPayload) => ({
  type: CREATE_NEW_JOB_REQUEST,
  payload,
});

export const editPostedJobAction = (payload: EditPostedJobPayload) => ({
  type: EDIT_POSTED_JOB_REQUEST,
  payload,
});

export const deletePostedJobAction = (payload: DeletePostedJobPayload) => ({
  type: DELETE_POSTED_JOB_REQUEST,
  payload,
});

export const fetchProposalsAction = (payload: FetchProposalsPayload) => ({
  type: FETCH_PROPOSALS_REQUEST,
  payload,
});

export const refreshProposalsAction = (payload: FetchProposalsPayload) => ({
  type: REFRESH_PROPOSALS_REQUEST,
  payload,
});

export const fetchAdditionalProposalsAction = (payload: FetchAdditionalProposalsPayload) => ({
  type: FETCH_ADDITIONAL_PROPOSALS_REQUEST,
  payload,
});

export const fetchJobAction = (payload: FetchJobPayload) => ({
  type: FETCH_JOB_REQUEST,
  payload,
});

export const fetchJobsByOffersAction = (payload: FetchJobsByOffersPayload) => ({
  type: FETCH_JOBS_BY_OFFERS,
  payload,
});

import React, { useState } from 'react';
import { PaymentSystemModalType } from '@root/mobile/navigators/types';
import { useHistory } from 'react-router-dom';
import { route } from '@root/web/consts';
import { CustomTabHeader } from '@root/web/components/CustomTabHeader';
import { strings } from '@root/strings';
import { useStyles } from './styles';
import { paymentStatus } from '@root/general/consts';
import { DefaultCard } from './components/DefaultCard';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { WarningModal } from '@root/web/components/WarningModal';
import { usePaymentSystemScreen } from '@root/hooks/usePaymentSystemScreen';
import { CustomLoader } from '@root/web/components/CustomLoader';
import { usePaymentCardsWeb } from '@root/web/hooks/usePaymentCardWeb';
import { CustomTabContainer } from '@root/web/components/CustomTabContainer';
import { Grid } from '@material-ui/core';

const { paymentSystemModal: string } = strings;

export const PaymentSystemScreen = (props: PaymentSystemModalType) => {
  const { paymentId, setJobPaymentSucceed, transactionHistorySuccessCallback, goBackCallback } = props;

  const styles = useStyles();
  const history = useHistory();

  const [isPayLaterModalVisible, setIsPayLaterModalVisible] = useState<boolean>(false);

  const [payment, defaultCard, isLoading] = usePaymentSystemScreen(paymentId);
  const [onPayPress, , isPaymentLoading] = usePaymentCardsWeb();
  const status = payment?.status;

  const isFailed = status === paymentStatus.FAILED || status === paymentStatus.INCOMPLETE;
  const isSucceed = status === paymentStatus.SUCCEEDED;

  const onPayNowPressed = () => {
    const successCallback = () => {
      transactionHistorySuccessCallback && transactionHistorySuccessCallback();
      setJobPaymentSucceed && setJobPaymentSucceed();
      goBack();
    };
    paymentId && defaultCard && onPayPress(paymentId, defaultCard.id, successCallback);
  };

  const onPayLaterPressed = () => {
    setIsPayLaterModalVisible(true);
  };

  const goBack = () => {
    if (goBackCallback) {
      goBackCallback();
    } else {
      history.goBack();
    }
  };

  const renderCardData = () => {
    const goToMyCardsScreen = () => {
      goBack();
      history.push(route.CARDS);
    };

    if (defaultCard) {
      const { last4, expYear, expMonth, brand } = defaultCard;

      return (
        <div className={styles.defaultCardContainer}>
          <DefaultCard last4={last4} brand={brand} expMonth={expMonth} expYear={expYear} isFailed={isFailed} />
          {isFailed && (
            <CustomButton type={customButtonType.TEXT} title={string.selectAnotherCard} onPress={goToMyCardsScreen} />
          )}
        </div>
      );
    }

    return (
      <div className={styles.defaultCardContainer}>
        <span className={styles.dontHaveAnyCardsWarning}>{string.addCardWarning}</span>
        <CustomButton type={customButtonType.TEXT} title={string.addCardButton} onPress={goToMyCardsScreen} />
      </div>
    );
  };

  const renderFooterButtons = () => {
    if (isFailed) {
      return (
        <Grid container spacing={2} className={styles.paymentSystemButtonsContainer}>
          <Grid item xs={6}>
            <CustomButton
              type={customButtonType.GREY}
              title={string.payLaterButton}
              onPress={onPayLaterPressed}
              disabled={isLoading || isPaymentLoading}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomButton
              type={customButtonType.RED}
              title={string.payNowButton}
              onPress={onPayNowPressed}
              disabled={!defaultCard || isLoading || isPaymentLoading}
            />
          </Grid>
        </Grid>
      );
    }

    const onPress = () => {
      setJobPaymentSucceed && setJobPaymentSucceed();
      goBack();
    };

    return (
      <CustomButton
        type={customButtonType.RED}
        title={string.buttonTitle}
        onPress={onPress}
        disabled={!defaultCard || isLoading}
      />
    );
  };

  return (
    <CustomTabContainer tabPositionFixed>
      <CustomTabHeader title={string.title} onTabHeaderClose={goBack} />
      <div className={styles.paymentSystemContent}>
        {isSucceed && <span className={styles.warningText}>{string.warning}</span>}
        <span className={styles.warningCostText}>{string.warningCost}</span>
        {isFailed && <span className={styles.failedPaymentWarning}>{string.failedPaymentWarning}</span>}
        {renderCardData()}
      </div>
      <div className={styles.paymentSystemFooterContainer}>{!!payment && renderFooterButtons()}</div>
      {(isLoading || isPaymentLoading) && <CustomLoader />}
      <WarningModal
        expression={string.modalExpression}
        isModalVisible={isPayLaterModalVisible}
        setModalVisible={setIsPayLaterModalVisible}
        onApply={goBack}
        changedExpression
      />
    </CustomTabContainer>
  );
};

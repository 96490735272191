import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() => ({
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    height: '60%',
    outline: 0,
    minWidth: 320,
    width: 540,
    marginRight: 20,
    marginLeft: 20,
  },
  scrollbars: {
    marginTop: 4,
    backgroundColor: colors.white,
    height: '100%',
    maxHeight: 'calc(100% - 49px)', // 49 - SearchInput height + margin
    borderRadius: 5,
    borderColor: colors.athensGray,
    borderWidth: 1,
    borderStyle: 'solid',
    boxSizing: 'border-box',
  },
  item: {
    display: 'block',
    letterSpacing: -0.24,
    fontSize: 17,
    fontFamily: fonts.SFUiDisplayMedium,
    padding: '12px 0px 12px 20px',
    borderWidth: '0px 0px 1px',
    borderColor: colors.athensGray,
    borderStyle: 'solid',
    cursor: 'pointer',
    color: colors.mineShaft,
    '&:hover': {
      color: colors.red,
      fontFamily: fonts.SFUiDisplayBold,
    },
  },
}));

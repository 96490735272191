import { makeStyles } from '@material-ui/core/styles';
import { AuthHeaderProps } from '@root/web/components/AuthHeader/index';

export const useStyles = makeStyles(() => ({
  headerContainer: () => {
    return {
      display: 'flex',
      marginBottom: 20,
      minHeight: 48,
    };
  },
  goBackContainer: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    justifyContent: 'flex-start',
  },
  goBack: (props: AuthHeaderProps) => {
    const { goBackVisible = true } = props;
    return {
      display: goBackVisible ? 'block' : 'none',
      width: 32,
      cursor: 'pointer',
    };
  },
  headerLogoImageContainer: {
    display: 'flex',
  },
  headerLogoImage: {},
  createNewAccountContainer: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  createNewAccount: (props: AuthHeaderProps) => {
    const { createNewAccountVisible = true } = props;
    return {
      display: createNewAccountVisible ? 'flex' : 'none',
    };
  },
}));

import {
  FetchAdditionalJobRequestsByIndustryPayload,
  FetchJobRequestsByIndustryPayload,
} from '@root/redux/actions/types';
import {
  FETCH_ADDITIONAL_JOB_REQUESTS_BY_INDUSTRY_REQUEST,
  FETCH_JOB_REQUESTS_BY_INDUSTRY_REQUEST,
  REFRESH_JOB_REQUESTS_BY_INDUSTRY_REQUEST,
} from '@root/general/consts/actions';

export const fetchJobRequestsByIndustryAction = (payload: FetchJobRequestsByIndustryPayload) => ({
  type: FETCH_JOB_REQUESTS_BY_INDUSTRY_REQUEST,
  payload,
});

export const refreshJobRequestsByIndustryAction = (payload: FetchJobRequestsByIndustryPayload) => ({
  type: REFRESH_JOB_REQUESTS_BY_INDUSTRY_REQUEST,
  payload,
});

export const fetchAdditionalJobRequestsByIndustryAction = (payload: FetchAdditionalJobRequestsByIndustryPayload) => ({
  type: FETCH_ADDITIONAL_JOB_REQUESTS_BY_INDUSTRY_REQUEST,
  payload,
});

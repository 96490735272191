import { ADD_REVIEWS_REQUEST, CREATE_OFFER_REQUEST, UPDATE_OFFER_REQUEST } from '@root/general/consts/actions';
import { AddReviewsPayload, CreateOfferPayload, UpdateOfferPayload } from '@root/redux/actions/types';

export const createOfferAction = (payload: CreateOfferPayload) => ({
  type: CREATE_OFFER_REQUEST,
  payload,
});

export const updateOfferAction = (payload: UpdateOfferPayload) => ({
  type: UPDATE_OFFER_REQUEST,
  payload,
});

export const addReviewsAction = (payload: AddReviewsPayload) => ({
  type: ADD_REVIEWS_REQUEST,
  payload,
});

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles(() => ({
  search: {
    backgroundColor: colors.white,
    borderRadius: 5,
    height: 36,
    paddingLeft: '12px',
    borderColor: colors.athensGray,
    borderWidth: 1,
    borderStyle: 'solid',
    '&::after': {
      display: 'none',
    },
    '&::before': {
      display: 'none',
    },
  },
  input: {
    padding: 0,
  },
  icon: {
    marginRight: 12,
  },
}));

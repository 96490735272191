import { put } from 'redux-saga/effects';
import { ApiErrorResponse } from 'apisauce';
import { get } from 'lodash';
import { ToastTypes } from '@root/redux/actions/commonActions';
import { ErrorResponse } from '@root/general/types';
import { SHOW_TOAST, CLEAR_USER_DATA_STATE, HANDLE_MINIMAL_AVAILABLE_VERSION } from '@root/general/consts/actions';
import { strings } from '@root/strings';

const { warnings } = strings;

export function* commonHandler(response: ApiErrorResponse<ErrorResponse>) {
  if (response.status === 401) {
    yield put({
      type: SHOW_TOAST,
      payload: {
        message: 'Authorization error. You have to login again',
        type: ToastTypes.Error,
      },
    });

    return yield put({
      type: CLEAR_USER_DATA_STATE,
    });
  }

  if (response.status === 404) {
    return yield put({
      type: SHOW_TOAST,
      payload: {
        message: 'Server responded with 404 error',
        type: ToastTypes.Error,
      },
    });
  }

  if (response.status === 500) {
    return yield put({
      type: SHOW_TOAST,
      payload: {
        message: 'Internal server error',
        type: ToastTypes.Error,
      },
    });
  }

  if (response.status === 503) {
    return yield put({
      type: SHOW_TOAST,
      payload: {
        message: 'Service temporarily unavailable. Please try again later',
        type: ToastTypes.Error,
      },
    });
  }

  if (response.status === 400 || response.status === 423) {
    const errors = get(response.data, 'errors', [warnings.commonWarning]);
    const message = Array.isArray(errors)
      ? errors.join('\n')
      : Object.values(errors)
          .reduce((total, curr) => [...total, ...curr], [])
          .join('\n');

    return yield put({
      type: SHOW_TOAST,
      payload: {
        message,
        type: ToastTypes.Error,
      },
    });
  }

  if (response.problem === 'NETWORK_ERROR') {
    return yield put({
      type: SHOW_TOAST,
      payload: {
        message: warnings.networkMessage,
        type: ToastTypes.Notification,
      },
    });
  }

  if (response.problem === 'TIMEOUT_ERROR') {
    return yield put({
      type: SHOW_TOAST,
      payload: {
        message: warnings.timeoutMessage,
        type: ToastTypes.Notification,
      },
    });
  }

  if (!response.ok && response.status === 200) {
    return yield put({
      type: HANDLE_MINIMAL_AVAILABLE_VERSION,
    });
  }

  yield put({
    type: SHOW_TOAST,
    payload: {
      message: warnings.commonWarning,
      type: ToastTypes.Error,
    },
  });
}

export function* unexpectedErrorHandler() {
  yield put({
    type: SHOW_TOAST,
    payload: {
      message: warnings.commonWarning,
      type: ToastTypes.Error,
    },
  });
}

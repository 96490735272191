import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() => ({
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    outline: 0,
    backgroundColor: colors.white,
    minWidth: 320,
    borderWidth: 1,
    borderColor: colors.athensGray,
    borderStyle: 'solid',
  },
  datePicker: {
    backgroundColor: colors.white,
    borderWidth: 0,
  },
  day: {
    fontSize: 14,
    fontFamily: fonts.SFUiDisplaySemiBold,
    letterSpacing: -0.175,
    padding: '10px 11px',
    borderWidth: 0,
    margin: '5px 0px',
    outline: 'none',
  },
  startDaySelected: {
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    backgroundColor: colors.red,
    color: colors.white,
  },
  middleDaySelected: {
    backgroundColor: colors.red,
  },
  endDaySelected: {
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    backgroundColor: colors.red,
  },
  currentDay: {
    backgroundColor: colors.celery,
    borderRadius: 50,
    color: colors.white,
  },
  weekdays: {
    backgroundColor: colors.white,
    fontSize: 14,
    fontFamily: fonts.SFUiDisplaySemiBold,
    lineHeight: '19px',
    letterSpacing: -0.175,
    padding: '5px 10px',
    margin: '5px 1px',
    borderWidth: 1,
    borderColor: colors.athensGray,
    borderStyle: 'solid',
    borderRadius: 5,
  },
  monthStyle: {
    backgroundColor: colors.white,
  },
  calendarHeaderContainer: {
    margin: '10px 1px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  calendarHeaderText: {
    fontSize: 14,
    fontFamily: fonts.SFUiDisplaySemiBold,
    lineHeight: '19px',
    letterSpacing: -0.175,
  },
  calendarMonthContainer: {
    backgroundColor: colors.red,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 25,
    minHeight: 25,
  },
}));

import { FarmType } from '@root/general/types';
import { ActionType, AdditionActionType } from '@root/hooks/useFlatListPagination';
import { useSelector } from 'react-redux';
import { getFarms } from '@root/selectors';
import { useCallback } from 'react';
import {
  fetchAdditionalFarmsRequestAction,
  fetchFarmsRequestAction,
  refreshFarmsRequestAction,
} from '@root/redux/actions';

type useMyFarmsScreenReturnType = [Array<FarmType>, number, ActionType, ActionType, AdditionActionType<FarmType>];

export const useMyFarmsScreen = (): useMyFarmsScreenReturnType => {
  const farmsInfo = useSelector(getFarms);
  const { farms, lastPage } = farmsInfo;

  const fetchAction = useCallback((fetchCallback) => {
    return fetchFarmsRequestAction({ callback: fetchCallback });
  }, []);

  const refreshAction = useCallback((refreshCallback) => {
    return refreshFarmsRequestAction({ callback: refreshCallback });
  }, []);

  const fetchAdditionAction = useCallback(
    (page: number, fetchAdditionalCallback: (isSuccess: boolean, additionalFarms: any) => void) => {
      return fetchAdditionalFarmsRequestAction({ page, callback: fetchAdditionalCallback });
    },
    [],
  );

  return [farms, lastPage, fetchAction, refreshAction, fetchAdditionAction];
};

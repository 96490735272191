import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 2,
    borderRadius: '3px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.red,
    marginBottom: 25,
    width: 200,
  },
  switchLeftButton: {
    borderRadius: '2px 0px 0px 2px',
  },
  switchRightButton: {
    borderRadius: '0px 2px 2px 0px',
  },
  switchButton: {
    height: 40,
    '&:hover': {
      borderWidth: 0,
    },
  },
}));

import { ReactElement, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accountType, defaultGeneralUser, OFFER_STATUS, paymentStatus, userInfoModalMode } from '@root/general/consts';
import { getUser } from '@root/selectors';
import { fetchUserByIdAction, updateOfferAction } from '@root/redux/actions';
import {
  ArrayIndustries,
  GeneralUser,
  Job,
  JobRequestFeedbackCallbackProps,
  PaymentType,
  Offer,
  RateValueProps,
  UserInfoData,
  UserInfoField,
} from '@root/general/types';
import { showToastAction, ToastTypes } from '@root/redux/actions/commonActions';
import { strings } from '@root/strings';
import { getSpecificationString } from '@root/utils/getSpecificationsString';

const { laborerInfoModalScreen: string, warnings } = strings;

type UseUserInfoModalScreenReturnType = [
  GeneralUser,
  UserInfoData,
  boolean,
  boolean,
  (jobRequestStateId: number) => () => void,
  userInfoModalMode,
];

export type NavigationPaymentCallback = (paymentId: number, setJobPaymentSucceed: () => void) => void;

export const useUserInfoModalScreen = (
  userId: number | null,
  navigationCallback: () => void,
  navigationPaymentCallback: NavigationPaymentCallback,
  jobValueElement: (jobName: string | undefined) => ReactElement,
  industryValueElement: (industries?: ArrayIndustries) => ReactElement,
  rateValueElement: (rateValueProps: RateValueProps) => ReactElement,
  jobId?: number | null,
  jobRequestFeedbackCallback?: (jobRequestFeedbackCallbackProps: JobRequestFeedbackCallbackProps) => void,
  updateNotificationCallback?: () => void,
  onPressEmail?: (email: string) => () => void,
  onPressPhone?: (phone: string) => () => void,
): UseUserInfoModalScreenReturnType => {
  const dispatch = useDispatch();

  const { roleId } = useSelector(getUser);

  const isLaborer = roleId === accountType.LABORER;

  const [isLoading, setLoading] = useState(false);
  const [user, setUser] = useState(defaultGeneralUser);
  const [job, setJob] = useState<Job | null>(null);
  const [offer, setOffer] = useState<Offer | null>(null);
  const [mode, setMode] = useState<userInfoModalMode>(userInfoModalMode.init);

  useLayoutEffect(() => {
    const fetchUserCallback = (isSuccess: boolean, user?: GeneralUser, job?: Job, offer?: Offer) => {
      if (isSuccess && user) {
        updateNotificationCallback && updateNotificationCallback();
        setUser(user);
        job && setJob(job);
        offer && setOffer(offer);
      }
      setLoading(false);
    };

    !!userId && setLoading(true);
    !!userId && dispatch(fetchUserByIdAction({ userId, jobId, callback: fetchUserCallback }));
  }, [dispatch, userId, jobId, updateNotificationCallback]);

  useEffect(() => {
    if (job?.id && offer) {
      switch (offer.status) {
        case OFFER_STATUS.SENT.id:
          return setMode(offer.ownerId === userId ? userInfoModalMode.request : userInfoModalMode.sent);
        case OFFER_STATUS.ACCEPTED.id:
          return setMode(userInfoModalMode.accepted);
        default:
          return setMode(userInfoModalMode.info);
      }
    } else {
      setMode(userInfoModalMode.info);
    }
  }, [userId, job, offer]);

  const { email = '', phone = '', zipCode, rate, city, state, industries, specifications, description } = user;

  const jobName = job?.name ?? '';
  const cityName = city?.name ?? '';
  const stateName = state?.name ?? '';

  const jobInfo: UserInfoField = {
    id: 0,
    label: string.requestJobLabel,
    valueElement: jobValueElement(jobName),
    icon: require('@root/assets/icons/jobName.png'),
    iconWeb: require('@root/web/assets/icons/jobName.svg'),
  };

  const employerInfoData: UserInfoData = [
    {
      id: 1,
      label: string.emailLabel,
      hiddenValue: string.hiddenValue,
      value: email,
      onPress: onPressEmail ? onPressEmail(email) : undefined,
      icon: require('@root/assets/icons/email.png'),
      iconWeb: require('@root/web/assets/icons/email.svg'),
    },
    {
      id: 2,
      label: string.phoneLabel,
      hiddenValue: string.hiddenValue,
      value: phone,
      onPress: onPressPhone ? onPressPhone(phone) : undefined,
      icon: require('@root/assets/icons/cell.png'),
      iconWeb: require('@root/web/assets/icons/phone.svg'),
    },
    {
      id: 3,
      label: string.cityLabel,
      value: cityName,
      icon: require('@root/assets/icons/town.png'),
      iconWeb: require('@root/web/assets/icons/town.svg'),
    },
    {
      id: 4,
      label: string.stateLabel,
      value: stateName,
      icon: require('@root/assets/icons/state.png'),
      iconWeb: require('@root/web/assets/icons/state.svg'),
    },
    {
      id: 5,
      label: string.zipCodeLabel,
      hiddenValue: zipCode,
      value: zipCode,
      icon: require('@root/assets/icons/zip.png'),
      iconWeb: require('@root/web/assets/icons/zip.svg'),
    },
  ];

  const laborerInfoData: UserInfoData = [
    {
      id: 6,
      label: string.industryLabel,
      valueElement: industryValueElement(industries),
      icon: require('@root/assets/icons/industry.png'),
      iconWeb: require('@root/web/assets/icons/industry.svg'),
    },
    {
      id: 7,
      label: string.specificationLabel,
      value: getSpecificationString(specifications, industries),
      icon: require('@root/assets/icons/specification.png'),
      iconWeb: require('@root/web/assets/icons/specification.svg'),
    },
    {
      id: 8,
      label: string.salaryLabel,
      valueElement: rateValueElement({ rate }),
      icon: require('@root/assets/icons/salary.png'),
      iconWeb: require('@root/web/assets/icons/salary.svg'),
      iconStyle: {
        height: 16,
      },
    },
    {
      id: 9,
      label: string.description,
      value: description ? description : '',
      icon: require('@root/assets/icons/description.png'),
      iconWeb: require('@root/web/assets/icons/description.svg'),
      multiline: true,
    },
  ];

  const userInfoData = () => {
    if (isLaborer) {
      return employerInfoData;
    }

    return jobId ? [jobInfo, ...employerInfoData, ...laborerInfoData] : [...employerInfoData, ...laborerInfoData];
  };

  const setJobPaymentSucceed = (payment: PaymentType) => () => {
    job && setJob({ ...job, payment: { ...payment, status: paymentStatus.SUCCEEDED } });
  };

  const jobRequestFeedback = (offerStatusId: number) => () => {
    if (offer?.id && jobId) {
      const { id: offerId } = offer;

      const updateOfferCallback = (isSuccess: boolean, recentPayment?: PaymentType) => {
        if (isSuccess && offer) {
          setOffer({ ...offer, status: offerStatusId });

          if (offerStatusId === OFFER_STATUS.ACCEPTED.id) {
            const fetchUserCallback = (isSuccess: boolean, user?: GeneralUser) => {
              if (isSuccess && user) {
                setUser(user);
              }
              setLoading(false);
            };

            setLoading(true);
            dispatch(fetchUserByIdAction({ userId, callback: fetchUserCallback }));
          }
        }

        if (isSuccess && recentPayment) {
          const { status, id: paymentId } = recentPayment;

          switch (status) {
            case paymentStatus.FAILED:
            case paymentStatus.INCOMPLETE:
            case paymentStatus.SUCCEEDED:
              navigationPaymentCallback(paymentId, setJobPaymentSucceed(recentPayment));
              break;
            default:
              // todo if another status show notification
              //  dispatch(showToastAction({ message: 'payment in processing', type: ToastTypes.Error }));
              break;
          }
        } else {
          //appeared in the screen from push notifications
          !jobRequestFeedbackCallback && isSuccess && navigationCallback();
        }

        jobRequestFeedbackCallback &&
          jobRequestFeedbackCallback({ isSuccess, offerStatusId, offerId, navigationCallback });

        setLoading(false);
      };

      if (user?.isBlocked) {
        return dispatch(showToastAction({ message: warnings.blockedUser, type: ToastTypes.Error }));
      }

      if (job?.isBlocked) {
        return dispatch(showToastAction({ message: warnings.blockedJob, type: ToastTypes.Error }));
      }

      setLoading(true);
      dispatch(
        updateOfferAction({
          offerId,
          offerStatusId,
          callback: updateOfferCallback,
        }),
      );
    }
  };

  return [user, userInfoData(), isLoading, isLaborer, jobRequestFeedback, mode];
};

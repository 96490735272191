import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    display: 'flex',
    backgroundColor: colors.lightGrey,
    marginTop: 'auto',
  },
  copyright: {
    fontSize: 17,
    fontFamily: fonts.SFUiDisplayMedium,
    color: colors.mineShaft,
    lineHeight: '20px',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
      lineHeight: '17px',
    },
  },
}));

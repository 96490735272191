import { IActionType } from '@root/redux/types';
import { ArrayStateType } from '@root/general/types';
import { SET_LOCATION_LOADING, FETCH_STATES_SUCCESS } from '@root/general/consts/actions';

export type LocationReducer = {
  loading: boolean;
  states: ArrayStateType;
};

const INITIAL_STATE: LocationReducer = {
  loading: false,
  states: [],
};

export const locationReducer = (state = INITIAL_STATE, action: IActionType) => {
  switch (action.type) {
    case SET_LOCATION_LOADING:
      return { ...state, loading: action.payload };
    case FETCH_STATES_SUCCESS:
      return { ...state, states: action.payload };
    default:
      return state;
  }
};

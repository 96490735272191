import { IndustryWithSpecificationsArray, Specification } from '@root/general/types';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { arrayToString } from '@root/utils/arrayToString';

type UseCustomModalWithSectionsReturnType = [
  (itemId: number) => boolean,
  (item: Specification) => () => void,
  () => void,
  () => void,
  () => void,
];

export const useCustomModalWithSections = (
  selectedItemIds: number[],
  setSelectedItemIds: Dispatch<SetStateAction<number[]>>,
  setModalVisible: Dispatch<SetStateAction<boolean>>,
  isModalVisible: boolean,
  sections: IndustryWithSpecificationsArray,
  setValue: Dispatch<SetStateAction<string>> | ((e: string | ChangeEvent<any>) => void),
): UseCustomModalWithSectionsReturnType => {
  const [itemIds, setItemIds] = useState(selectedItemIds);

  useEffect(() => {
    setItemIds(selectedItemIds);
  }, [selectedItemIds]);

  const isSelected = (itemId: number) => {
    return itemIds.some((selectedId) => selectedId === itemId);
  };

  const onPress = (item: Specification) => () => {
    const result = isSelected(item.id) ? itemIds.filter((selectedId) => selectedId !== item.id) : [...itemIds, item.id];

    setItemIds(result);
  };

  const onPressAll = () => {
    const result = sections.reduce((acc: Array<number>, curr) => {
      const currIds = curr.data.map((item) => item.id);
      return [...acc, ...currIds];
    }, []);

    setItemIds(result);
  };

  const onPressDone = () => {
    setModalVisible(!isModalVisible);

    const initial: Array<string> = [];

    const selectedNamesArray = sections.reduce((acc, curr) => {
      const { data, industry } = curr;

      const selectedNames = data
        .filter((item) => isSelected(item.id))
        .map(({ name }) => {
          return name.toUpperCase() === 'OTHER' ? industry.name + ' ' + name : name;
        });

      return [...acc, ...selectedNames];
    }, initial);

    setSelectedItemIds(itemIds);
    setValue(arrayToString(selectedNamesArray));
  };

  const closeModal = () => {
    setModalVisible(false);
    setItemIds(selectedItemIds);
  };

  return [isSelected, onPress, onPressDone, closeModal, onPressAll];
};

import { CustomInputFormDataType } from '@root/mobile/types/inputTypes';
import { ArraySpecifications, CityType, IndustryWithSpecificationsArray } from '@root/general/types';
import { Dispatch, SetStateAction, useState } from 'react';
import { arrayToString } from '@root/utils/arrayToString';
import { useDispatch, useSelector } from 'react-redux';
import { getIndustries, getMapFilter, getSpecificationByIndustryIdsWithTitle, getUser } from '@root/selectors';
import { showToastAction, ToastTypes } from '@root/redux/actions/commonActions';
import { setMapFilter } from '@root/redux/actions';
import { strings } from '@root/strings';

const { mapFilterModalScreen: string, warnings } = strings;

enum mapFilterField {
  specification = 0,
  city = 1,
}

type UseFilterModalScreenReturnType = [
  CustomInputFormDataType,
  () => void,
  () => void,
  IndustryWithSpecificationsArray,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  number[],
  Dispatch<SetStateAction<number[]>>,
  Dispatch<SetStateAction<string>>,
  CityType | null,
  Dispatch<SetStateAction<CityType | null>>,
];

export type OnCityPressType = (setFilterCity: Dispatch<SetStateAction<CityType | null>>) => () => void;

const getSpecificationNamesBySelectedIds = (selectedIds: Array<number>, allSpecifications: ArraySpecifications) => {
  return arrayToString(
    allSpecifications
      .filter((specification) => selectedIds.some((selectedId) => selectedId === specification.id))
      .map((specification) => specification.name),
  );
};

export const useMapFilterModalScreen = (
  onCityPress: OnCityPressType,
  navigationCallback: () => void,
): UseFilterModalScreenReturnType => {
  const dispatch = useDispatch();

  const { allIndustries: INDUSTRIES, allSpecifications: SPECIFICATIONS } = useSelector(getIndustries);
  const industriesWithSpecifications = useSelector(getSpecificationByIndustryIdsWithTitle(INDUSTRIES.map((e) => e.id)));

  const { specifications: userSpecifications, city: userCity, state: userState } = useSelector(getUser);
  const { filterSpecificationIds, filterCity } = useSelector(getMapFilter);
  const initialSpecificationValues = getSpecificationNamesBySelectedIds(filterSpecificationIds, SPECIFICATIONS);

  const [specificationsModalVisible, setSpecificationsModalVisible] = useState<boolean>(false);
  const [specificationIds, setSpecificationIds] = useState<Array<number>>(filterSpecificationIds);
  const [specificationValues, setSpecificationValues] = useState<string>(initialSpecificationValues);
  const [city, setCity] = useState<CityType | null>(filterCity);

  const cityName = city ? city.name + (city.state ? ', ' + city.state.name : '') : '';

  const filterFieldFormData: CustomInputFormDataType = [
    {
      id: mapFilterField.specification,
      title: string.specifications,
      placeHolder: string.specifications,
      defaultValue: specificationValues,
      onPress: () => setSpecificationsModalVisible(!specificationsModalVisible),
      icon: require('@root/assets/icons/arrowRight.png'),
      webIcon: require('@root/web/assets/icons/arrowRight.svg'),
    },
    {
      id: mapFilterField.city,
      title: string.cityTitle,
      placeHolder: string.cityPlaceholder,
      defaultValue: cityName,
      onPress: onCityPress(setCity),
      icon: require('@root/assets/icons/arrowRight.png'),
      webIcon: require('@root/web/assets/icons/arrowRight.svg'),
    },
  ];

  const onSave = () => {
    if (specificationIds.length === 0) {
      return dispatch(showToastAction({ message: warnings.selectSpecification, type: ToastTypes.Error }));
    }

    dispatch(setMapFilter({ mapFilter: { filterSpecificationIds: specificationIds, filterCity: city } }));
    navigationCallback();
  };

  const resetFilter = () => {
    const specificationIds = userSpecifications?.map((specification) => specification.id) ?? [];
    setSpecificationIds(specificationIds);
    setSpecificationValues(getSpecificationNamesBySelectedIds(specificationIds, SPECIFICATIONS));
    setCity({ ...userCity, state: userState });
  };

  return [
    filterFieldFormData,
    resetFilter,
    onSave,
    industriesWithSpecifications,
    specificationsModalVisible,
    setSpecificationsModalVisible,
    specificationIds,
    setSpecificationIds,
    setSpecificationValues,
    city,
    setCity,
  ];
};

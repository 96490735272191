import { GeneralUser, Offer } from '@root/general/types';

export const getUserProfileFromOffer = (userId: number, offer: Offer | null): null | GeneralUser => {
  if (offer?.ownerId === userId) {
    return offer?.user ?? null;
  }

  if (offer?.userId === userId) {
    return offer?.owner ?? null;
  }

  return null;
};

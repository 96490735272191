import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';
import { IndustryTopNavigatorProps } from '@root/web/navigators/IndustryTopNavigator/index';

export const useStyles = makeStyles((theme: Theme) => ({
  industryContainer: ({ navigationBarWidth }: IndustryTopNavigatorProps) => {
    return {
      display: 'flex',
      flex: 1,
      maxWidth: `calc(100vw - ${navigationBarWidth}px)`,
      flexDirection: 'column',
    };
  },
  tabsContainer: ({ isNavHidden }: IndustryTopNavigatorProps) => {
    return {
      display: isNavHidden ? 'none' : 'flex',
      paddingLeft: 30,
      backgroundColor: colors.white,
      ...theme.mixins.toolbar,
      borderBottomColor: colors.athensGray,
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
    };
  },
  tabsIndicator: {
    backgroundColor: 'transparent',
  },
  tabsFlexContainer: {
    height: '100%',
  },
  tabRoot: {
    minWidth: 'auto',
    padding: 15,
    color: colors.darkGrey,
    fontSize: 17,
    fontFamily: fonts.IBMPlexMonoMedium,
    lineHeight: '22px',
    letterSpacing: -0.41,
    textTransform: 'uppercase',
    height: '100%',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: colors.materialHover,
    },
  },
  industryChildContainer: {
    flex: 1,
  },
}));

import React, { useCallback, useRef } from 'react';
import { useStyles } from './styles';
import { JobListingMapView } from '@root/web/screens/JobListingMap/components/JobListingMapView';
import { useSelector } from 'react-redux';
import { getUser } from '@root/selectors';
import { useMapModalScreen } from '@root/hooks/useMapModalScreen';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getRegionFromCityWeb } from '@root/mobile/utils/getRegionFromCity';
import { Job } from '@root/general/types';
import { Grid } from '@material-ui/core';
import { JobCard } from '@root/web/components/JobCard';
import { JobDetailsScreen } from '@root/web/screens/JobListingScreen/components/JobDetailsScreen';
import { jobListingCallback } from '@root/utils/jobListingCallback';
import { MapFilter } from '@root/web/screens/JobListingMap/components/MapFilter';
import { WEB_NOTIFICATION_TIMEOUT } from '@root/general/consts';
import { ToastContainer } from 'react-toastify';
import { route } from '@root/web/consts';
import { useFlatListPaginationWithoutRedux } from '@root/hooks/useFlatListPaginationWithoutRedux';
import { CustomScrollComponent } from '@root/web/components/CustomScrollComponent';
import { strings } from '@root/strings';
import { useReadNotification } from '@root/web/hooks/useReadNotification';

const { mapModalScreen: string } = strings;

type locationType = {
  notificationId?: number | null;
};

export const JobListingMap = () => {
  const { jobId } = useParams<{ jobId: string }>();

  const styles = useStyles({ jobId });

  const ref = useRef<any>();

  const history = useHistory();
  const location = useLocation<locationType>();
  const notificationId = location?.state?.notificationId ?? null;

  useReadNotification(notificationId);

  const mapRef = useRef<any>(null);

  const onMapChangeRegion = useCallback((latitude: number | null, longitude: number | null, radius: number) => {
    const { lat, lng, zoom } = getRegionFromCityWeb(latitude, longitude, radius);
    mapRef.current?.panTo({ lat, lng });
    mapRef.current?.setZoom(zoom);
  }, []);

  const { city: userCity } = useSelector(getUser);

  const [
    setMapReady,
    allIndustries,
    mapCities,
    selectedCities,
    setSelectedCities,
    radius,
    onRadiusPress,
    onLocationPress,
    fetchAction,
    initialised,
  ] = useMapModalScreen(userCity, onMapChangeRegion);

  const [jobs, isFlatListLoading, , , , onEndReached, setJobs, hasMore] = useFlatListPaginationWithoutRedux<Job>(
    fetchAction,
    initialised,
  );

  const goToJobDetails = (jobId: number | null) => () => {
    history.push(`${route.JOB_LISTING_MAP}/${jobId}`);
  };

  const renderJobCard = (job: Job) => {
    const { id: itemJobId, industryId, offerStatus } = job;
    const industry = allIndustries?.find((industry) => industry.id === industryId);
    const title = industry?.name ?? '';

    return (
      <Grid key={itemJobId || 0} item xs={12}>
        <JobCard
          title={title}
          job={job}
          offerStatusId={offerStatus}
          onPress={goToJobDetails(itemJobId)}
          industry={industry}
        />
      </Grid>
    );
  };

  return (
    <>
      <div className={styles.container}>
        <div ref={ref} className={styles.leftContainer}>
          <MapFilter ref={ref} />
          <CustomScrollComponent
            data={jobs}
            renderItem={renderJobCard}
            isFlatListLoading={isFlatListLoading}
            onEndReached={onEndReached}
            hasMore={hasMore}
            defaultPrimaryText={string.sorryText}
          />
        </div>
        <JobListingMapView
          mapRef={mapRef}
          data={mapCities}
          initialRegion={getRegionFromCityWeb(userCity.latitude, userCity.longitude)}
          selectedCities={selectedCities}
          setSelectedCities={setSelectedCities}
          setMapReady={setMapReady}
          onLocationPress={onLocationPress}
          radius={radius}
          onRadiusPress={onRadiusPress}
        />
        <ToastContainer autoClose={WEB_NOTIFICATION_TIMEOUT} />
      </div>
      {jobId && (
        <JobDetailsScreen
          jobId={Number(jobId)}
          goBackCallback={history.goBack}
          jobDetailsCallback={jobListingCallback(jobs, setJobs)}
        />
      )}
    </>
  );
};

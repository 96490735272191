import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getRateTypeName } from '@root/mobile/utils/getRateTypeName';
import { getFarmDate } from '@root/mobile/utils/getFarmDate';
import { getFarms, getIndustries, getIndustryById, getSpecificationById, getUser } from '@root/selectors';
import { showToastAction, ToastTypes } from '@root/redux/actions/commonActions';
import {
  createNewJobAction,
  deletePostedJobAction,
  editPostedJobAction,
  fetchFarmsRequestAction,
  fetchIndustriesWithSpecificationsAction,
} from '@root/redux/actions';
import { FormikFieldFormDataType, FormikHandleChangeType, FormikValuesType } from '@root/mobile/types/inputTypes';
import { ArrayFarmType, ArrayIndustries, ArrayRates, ArraySpecifications, Job } from '@root/general/types';
import { defaultFarm, deviceType, JOBS_STATUS, PAY_METHODS } from '@root/general/consts';
import { strings } from '@root/strings';
import { EditJobData, PostJobData } from '@root/redux/reducers/types';
import { useGetCards } from '@root/mobile/hooks/useGetCards';

const { jobActionsModalScreens: string, warnings } = strings;

export enum jobActionScreenMode {
  EDIT = 'EDIT',
  POST = 'POST',
}

export enum jobActionFieldFormData {
  jobName,
  industry,
  jobSpecification,
  workPeriod,
  farmName,
  rate,
  rateType,
  peopleAmount,
  jobDescription,
  payment_type,
}

type UseJobActionModalScreenType = [
  boolean,
  Dispatch<SetStateAction<boolean>>,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  ArrayIndustries,
  FormikValuesType,
  ArrayFarmType,
  boolean,
  Dispatch<SetStateAction<number | null>>,
  Dispatch<SetStateAction<number | null>>,
  Dispatch<SetStateAction<number | null>>,
  FormikFieldFormDataType,
  ArraySpecifications,
  ArrayRates,
  (values: FormikValuesType) => void,
  (handleChange: FormikHandleChangeType) => (id: number) => void,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  () => void,
  string,
  number | null,
  number | null,
  number | null,
  number | null,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  number | null,
  Dispatch<SetStateAction<number | null>>,
];

export const useJobActionModalScreen = (
  isEditJobMode: boolean,
  deleteCallback: () => void,
  goBackCallback: () => void,
  setJobCallback: (editedJob: Job) => void = () => {},
  jobInfo: Job | null = null,
  device: deviceType = deviceType.web,
  goToCardsScreen: () => void,
): UseJobActionModalScreenType => {
  const dispatch = useDispatch();
  const { farms } = useSelector(getFarms);
  const { id: userId } = useSelector(getUser);
  const { allIndustries, allSpecifications } = useSelector(getIndustries);

  const [isLoading, setLoading] = useState(false);
  const [farmNameModalVisible, setFarmNameModalVisible] = useState(false);
  const [industryModalVisible, setIndustryModalVisible] = useState(false);
  const [rateTypeModalVisible, setRateTypeModalVisible] = useState(false);
  const [workPeriodModalVisible, setWorkPeriodModalVisible] = useState(false);
  const [isDeleteWarningVisible, setDeleteWarningModalVisible] = useState(false);
  const [jobSpecificationsModalVisible, setJobSpecificationsModalVisible] = useState(false);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);

  const jobId = jobInfo?.id ?? null;
  const jobRate = jobInfo?.rate ?? null;
  const jobName = jobInfo?.name ?? '';
  const jobStatus = jobInfo?.status ?? 1;
  const jobFarmId = jobInfo?.farmId ?? null;
  const jobEndDate = jobInfo?.endDate ?? '';
  const jobRateType = jobInfo?.rateTypeId ?? null;
  const jobFarm = jobInfo?.farm ?? defaultFarm;
  const jobStartDate = jobInfo?.startDate ?? '';
  const jobDescription = jobInfo?.description ?? '';
  const initialIndustryId = jobInfo?.industryId ?? null;
  const initialSpecificationId = jobInfo?.specificationId ?? null;
  const jobPeopleAmount = jobInfo ? String(jobInfo.peopleAmount) : '';
  const jobPaymentTypeId = jobInfo?.paymentType ?? null;

  const industry = useSelector(getIndustryById(initialIndustryId));
  const specification = useSelector(getSpecificationById(initialSpecificationId));

  const [cards] = useGetCards(!isEditJobMode);

  const farmName = jobFarm?.name ?? '';
  const industryName = industry?.name ?? '';
  const specificationName = specification?.name ?? '';
  const initialRateTypeName = jobRateType ? getRateTypeName(jobRateType, industry) : '';
  const paymentType = jobPaymentTypeId !== null ? PAY_METHODS[jobPaymentTypeId].name : '';
  const initialPaymentId = jobPaymentTypeId !== null ? PAY_METHODS[jobPaymentTypeId].id : null;

  const getInitialRate = () => {
    if (jobRate) {
      return device === deviceType.web ? `${jobRate}` : `$${jobRate}`;
    } else {
      return '';
    }
  };

  const jobActionCallback = (isSuccess: boolean, editedJob?: Job) => {
    if (isSuccess) {
      goBackCallback();
      editedJob && setJobCallback(editedJob);
    }

    setLoading(false);
  };

  const jobDeleteCallback = (isSuccess: boolean) => {
    isSuccess && deleteCallback();
    setLoading(false);
  };

  const onPressDeletePostedJob = () => {
    dispatch(
      deletePostedJobAction({
        jobId,
        callback: jobDeleteCallback,
      }),
    );
  };

  const initialValue = {
    jobName: jobName,
    industry: industryName,
    jobSpecification: specificationName,
    workPeriod: getFarmDate(jobStartDate, jobEndDate),
    farmName: farmName,
    rate: getInitialRate(),
    rateType: initialRateTypeName,
    peopleAmount: jobPeopleAmount,
    jobDescription: jobDescription,
    payment_type: paymentType,
  } as FormikValuesType;

  const [farmId, setFarmId] = useState<number | null>(jobFarmId);
  const [industryId, setIndustryId] = useState<number | null>(initialIndustryId);
  const [specificationId, setSpecificationId] = useState<number | null>(initialSpecificationId);
  const [rateTypeId, setRateTypeId] = useState<number | null>(jobRateType);
  const [paymentTypeId, setPaymentTypeId] = useState<number | null>(initialPaymentId);

  useEffect(() => {
    dispatch(
      fetchFarmsRequestAction({
        callback: (isSuccess, farms: ArrayFarmType = []) => {
          if (isSuccess && !isEditJobMode && farms.length === 0) {
            dispatch(showToastAction({ message: string.postJobLocationMessage, type: ToastTypes.Error }));
          }
        },
      }),
    );
    dispatch(fetchIndustriesWithSpecificationsAction());
  }, [dispatch, farms.length, isEditJobMode]);

  const postJobFieldFormData: FormikFieldFormDataType = [
    {
      id: jobActionFieldFormData.jobName,
      title: string.jobNameTitle,
      valuesName: 'jobName',
      placeHolder: string.jobNamePlaceholder,
      keyboardType: 'default',
    },
    {
      id: jobActionFieldFormData.industry,
      title: string.industryTitle,
      valuesName: 'industry',
      placeHolder: string.industryPlaceholder,
      onPress: () => setIndustryModalVisible(!industryModalVisible),
      icon: require('@root/assets/icons/dropdownArrow.png'),
      autoLength: true,
    },
    {
      id: jobActionFieldFormData.jobSpecification,
      title: string.specificationTitle,
      valuesName: 'jobSpecification',
      placeHolder: string.specificationPlaceholder,
      onPress: () => {
        if (industryId && industryId > 0) {
          setJobSpecificationsModalVisible(!jobSpecificationsModalVisible);
        } else {
          dispatch(showToastAction({ message: warnings.selectIndustry, type: ToastTypes.Error }));
        }
      },
      icon: require('@root/assets/icons/dropdownArrow.png'),
      autoLength: true,
    },
    {
      id: jobActionFieldFormData.workPeriod,
      title: string.workPeriodTitle,
      valuesName: 'workPeriod',
      onPress: () => setWorkPeriodModalVisible(!workPeriodModalVisible),
      placeHolder: string.workPeriodPlaceholder,
      autoLength: true,
      icon: require('@root/assets/icons/dropdownArrow.png'),
      webIcon: require('@root/web/assets/icons/arrowRight.svg'),
    },
    {
      id: jobActionFieldFormData.payment_type,
      title: string.paymentTitle,
      valuesName: 'payment_type',
      placeHolder: string.paymentPlaceholder,
      onPress: () => setPaymentModalVisible(!paymentModalVisible),
      icon: require('@root/assets/icons/dropdownArrow.png'),
      autoLength: true,
    },
    {
      id: jobActionFieldFormData.farmName,
      title: string.locationNameTitle,
      valuesName: 'farmName',
      placeHolder: string.locationPlaceholder,
      onPress: () =>
        farms.length > 0
          ? setFarmNameModalVisible(!farmNameModalVisible)
          : dispatch(showToastAction({ message: string.postJobLocationMessage, type: ToastTypes.Error })),
      icon: require('@root/assets/icons/dropdownArrow.png'),
      autoLength: true,
    },
    {
      id: jobActionFieldFormData.rate,
      title: string.rateTitle,
      valuesName: 'rate',
      placeHolder: string.ratePlaceholder,
      keyboardType: 'decimal-pad',
      mask: '$[999990].[90]',
    },
    {
      id: jobActionFieldFormData.rateType,
      title: string.rateTypeTitle,
      valuesName: 'rateType',
      onPress: () => {
        if (industryId && industryId > 0) {
          setRateTypeModalVisible(!rateTypeModalVisible);
        } else {
          dispatch(showToastAction({ message: warnings.selectIndustry, type: ToastTypes.Error }));
        }
      },
      placeHolder: string.rateTypePlaceholder,
    },
    {
      id: jobActionFieldFormData.peopleAmount,
      title: string.peopleAmountTitle,
      valuesName: 'peopleAmount',
      placeHolder: string.peopleAmountPlaceholder,
      keyboardType: 'number-pad',
      mask: '[9990]',
    },
    {
      id: jobActionFieldFormData.jobDescription,
      title: string.descriptionTitle,
      valuesName: 'jobDescription',
      placeHolder: string.descriptionPlaceholder,
      keyboardType: 'default',
      multiline: true,
    },
  ];

  const formatDate = (str: string, endOfTheDay?: boolean) => {
    const date = endOfTheDay ? moment(str, 'DMMMMYYYY').endOf('day') : moment(str, 'DMMMMYYYY');
    return date.format('YYYY-MM-DD HH:mm:ss');
  };

  const onPressJobAction = (values: FormikValuesType) => {
    if (cards.length === 0) {
      goToCardsScreen();
      return;
    }

    const { jobName, jobDescription, workPeriod, rate, peopleAmount } = values;

    const dates = workPeriod.replace(/\s/g, '').split('-');
    const startDate = formatDate(dates[0]);
    const endDate = formatDate(dates.length > 1 ? dates[1] : dates[0], true);

    const job = {
      userId,
      farmId,
      industryId,
      specificationId,
      name: jobName,
      startDate,
      endDate,
      rate: Number(rate.replace('$', '')),
      rateTypeId,
      peopleAmount: Number(peopleAmount),
      description: jobDescription,
      payment_type: paymentTypeId === 1 ? 0 : 1,
    };

    const editedJob: EditJobData = { ...job, id: jobId, status: jobStatus };
    const postJob: PostJobData = { ...job, status: JOBS_STATUS.OFFERED.id };

    setLoading(true);

    if (isEditJobMode) {
      dispatch(
        editPostedJobAction({
          data: editedJob,
          callback: jobActionCallback,
        }),
      );
    } else {
      dispatch(
        createNewJobAction({
          data: postJob,
          callback: jobActionCallback,
        }),
      );
    }
  };

  const clearDependentOnIndustryFields = (handleChange: FormikHandleChangeType) => (id: number) => {
    if (id !== industryId) {
      setIndustryId(id);
      setSpecificationId(0);
      handleChange('jobSpecification')('');
      setRateTypeId(0);
      handleChange('rateType')('');
    }
  };

  const specificationsData = allSpecifications.filter((spec) => spec.industryId === industryId);
  const ratesData = allIndustries.find((industry) => industry.id === industryId)?.rateTypes ?? [];

  const getInitialPostJobFieldFromData = (fields: FormikFieldFormDataType) => {
    if (isEditJobMode) {
      fields.splice(4, 1);

      return fields;
    }

    return fields;
  };

  const initialPostJobFieldFromData = getInitialPostJobFieldFromData(postJobFieldFormData);

  return [
    farmNameModalVisible,
    setFarmNameModalVisible,
    industryModalVisible,
    setIndustryModalVisible,
    jobSpecificationsModalVisible,
    setJobSpecificationsModalVisible,
    workPeriodModalVisible,
    setWorkPeriodModalVisible,
    rateTypeModalVisible,
    setRateTypeModalVisible,
    allIndustries,
    initialValue,
    farms,
    isLoading,
    setFarmId,
    setSpecificationId,
    setRateTypeId,
    initialPostJobFieldFromData,
    specificationsData,
    ratesData,
    onPressJobAction,
    clearDependentOnIndustryFields,
    isDeleteWarningVisible,
    setDeleteWarningModalVisible,
    onPressDeletePostedJob,
    jobName,
    industryId,
    specificationId,
    farmId,
    rateTypeId,
    paymentModalVisible,
    setPaymentModalVisible,
    paymentTypeId,
    setPaymentTypeId,
  ];
};

import React, { useEffect, useState } from 'react';
import { profileParam, ProfileTopNavigator } from '@root/web/navigators/ProfileTopNavigator';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '@root/selectors';
import { WarningModal } from '@root/web/components/WarningModal';
import { strings } from '@root/strings';
import { useStyles } from './styles';
import {
  confirmChangeEmailAction,
  confirmDeleteAccountAction,
  editUserAvatarAction,
  signOutAction,
} from '@root/redux/actions';
import { CustomLoader } from '@root/web/components/CustomLoader';
import { ToastContainer } from 'react-toastify';
import { WEB, WEB_NOTIFICATION_TIMEOUT } from '@root/general/consts';
import { useSwitchUserRole } from '@root/hooks/useSwitchUserRole';
import { MainHeader } from '@root/web/components/MainHeader';
import { MainFooter } from '@root/web/components/MainFooter';
import { ProfileMainInfo } from '@root/web/screens/ProfileScreen/components/ProfileMainInfo';
import { ProfileRootScreenProps, profileTabsMode } from '@root/web/screens/ProfileScreen/types';
import { useLocation, useParams, useHistory, Prompt } from 'react-router-dom';
import { Reference } from '@root/general/types';
import { route } from '@root/web/consts';
import { SwitchRoleScreen } from '@root/web/screens/ProfileScreen/components/SwitchRoleScreen';
import { CustomTabContainer } from '@root/web/components/CustomTabContainer';
import Scrollbars from 'react-custom-scrollbars';

const { profileScreen: string } = strings;

export const ProfileScreen = (props: ProfileRootScreenProps) => {
  const { path, token, deleteAccountToken } = props;

  const dispatch = useDispatch();
  const { name, email = '', roleId, avatar = null } = useSelector(getUser);

  const [isLoading, setLoading] = useState(false);
  const [avatarLoading, setAvatarLoading] = useState(false);
  const [isPhotoDeleteModalVisible, setPhotoDeleteModalVisible] = useState(false);
  const [isRoleModalVisible, setRoleModalVisible] = useState(false);
  const [tabMode, setTabMode] = useState<profileTabsMode | null>(null);
  const [isLogoutModalVisible, setLogoutModalVisible] = useState(false);

  const styles = useStyles({ tabMode });

  let history = useHistory();
  let location = useLocation<{ tabMode: string; reference: Reference }>();
  const tabModeFromLocation = location?.state?.tabMode;
  const params = useParams<{ id?: string; profileParam?: string }>();
  const { id = '', profileParam: profileTopTab } = params;

  useEffect(() => {
    switch (tabModeFromLocation) {
      case profileTabsMode.ADD_REFERENCE:
      case profileTabsMode.ADD_LOCATION: {
        setTabMode(tabModeFromLocation);
      }
    }
  }, [tabModeFromLocation, setTabMode]);

  useEffect(() => {
    if (id !== '') {
      switch (profileTopTab) {
        case profileParam.references: {
          setTabMode(profileTabsMode.EDIT_REFERENCE);
          return;
        }
        case profileParam.locations: {
          setTabMode(profileTabsMode.EDIT_LOCATION);
          return;
        }
        case profileParam.transactions: {
          setTabMode(profileTabsMode.ADD_PAYMENT);
          return;
        }
      }
    } else {
      if (!profileTopTab) {
        setTabMode(null);
      }
    }
  }, [profileTopTab, id, setTabMode]);

  useEffect(() => {
    setAvatarLoading(false);
  }, [avatar, setAvatarLoading]);

  useEffect(() => {
    if (token) {
      dispatch(confirmChangeEmailAction({ token }));
      history.push(route.CHANGE_EMAIL);
    }
  }, [dispatch, history, token]);

  useEffect(() => {
    if (deleteAccountToken) {
      const redirect = () => history.replace(route.DELETE_ACCOUNT);
      dispatch(confirmDeleteAccountAction({ deleteAccountToken, redirect }));
    }
  }, [deleteAccountToken, dispatch, history]);

  const openSwitchRoleModal = () => {
    setTabMode(profileTabsMode.SWITCH_ROLE);
  };

  const onSwitchUserRoleSuccessCallback = () => {
    history.push(route.PROFILE);
  };

  const [onSwitchUserRole] = useSwitchUserRole(openSwitchRoleModal, setLoading, WEB, onSwitchUserRoleSuccessCallback);

  const onPressRemovePhoto = () => {
    setPhotoDeleteModalVisible(true);
  };

  const onPressSwitchRole = () => {
    setRoleModalVisible(true);
  };

  const onDeletePhoto = () => {
    setAvatarLoading(true);

    dispatch(editUserAvatarAction({}));
  };

  const onLogout = () => {
    dispatch(signOutAction());
  };

  return (
    <div className={styles.container}>
      <div className={styles.containerLeft}>
        <MainHeader />
        <Scrollbars>
          <div className={styles.scrollbar}>
            <div className={styles.content}>
              {isLoading && <CustomLoader />}
              <div className={styles.profileMainInfoContainer}>
                <ProfileMainInfo
                  name={name}
                  email={email}
                  avatar={avatar}
                  avatarLoading={avatarLoading}
                  setAvatarLoading={setAvatarLoading}
                  roleId={roleId}
                  onPressRemovePhoto={onPressRemovePhoto}
                  onPressSwitchRole={onPressSwitchRole}
                />
              </div>
              <div className={styles.profileTabContainer}>
                <ProfileTopNavigator path={path} tabMode={tabMode} setTabMode={setTabMode} />
              </div>
              <WarningModal
                expression={string.deletePhoto}
                isModalVisible={isPhotoDeleteModalVisible}
                setModalVisible={setPhotoDeleteModalVisible}
                onApply={onDeletePhoto}
              />
              <WarningModal
                expression={string.switchRole}
                isModalVisible={isRoleModalVisible}
                setModalVisible={setRoleModalVisible}
                onApply={onSwitchUserRole}
              />
            </div>
            <MainFooter className={styles.footer} />
          </div>
        </Scrollbars>
      </div>
      {!!tabMode && <CustomTabContainer isCarcass />}
      {tabMode === profileTabsMode.SWITCH_ROLE && <SwitchRoleScreen setTabMode={setTabMode} />}
      <ToastContainer autoClose={WEB_NOTIFICATION_TIMEOUT} />
      <Prompt
        message={(location: any) => {
          if (location.pathname.startsWith(route.WELCOME)) {
            setLogoutModalVisible(true);
            return false;
          }

          return true;
        }}
      />
      <WarningModal
        expression={string.logout}
        isModalVisible={isLogoutModalVisible}
        setModalVisible={setLogoutModalVisible}
        onApply={onLogout}
      />
    </div>
  );
};

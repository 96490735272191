import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getIndustries, getJobOffersByTabName, getUser } from '@root/selectors';
import { fetchAdditionalOffersAction, fetchOffersAction, refreshOffersAction } from '@root/redux/actions';
import { ActionType, AdditionActionType } from '@root/hooks/useFlatListPagination';
import { OFFERS_BY_STATUS } from '@root/general/consts';
import { ArrayIndustries, Offer } from '@root/general/types';
import { getOffersFilterById } from '@root/utils/getOffersFilterById';

type UseJobOffersScreenReturnType = [
  Array<Offer>,
  number,
  number | null,
  Dispatch<SetStateAction<number>>,
  string,
  ActionType,
  ActionType,
  AdditionActionType<Offer>,
  ArrayIndustries,
  number,
];

export const useJobOffersScreen = (): UseJobOffersScreenReturnType => {
  const [offersFilterId, setOffersFilterId] = useState(OFFERS_BY_STATUS.ALL.id);

  const { name: offersFilterName } = getOffersFilterById(offersFilterId);

  const { offers, lastPage } = useSelector(getJobOffersByTabName(offersFilterName));
  const { id: profileUserId } = useSelector(getUser);
  const { allIndustries } = useSelector(getIndustries);

  const fetchAction = useCallback(
    (fetchCallback) => {
      return fetchOffersAction({ offersFilterId, callback: fetchCallback });
    },
    [offersFilterId],
  );

  const refreshAction = useCallback(
    (setRefreshingCallback) => {
      return refreshOffersAction({ offersFilterId, callback: setRefreshingCallback });
    },
    [offersFilterId],
  );

  const fetchAdditionAction = useCallback(
    (page: number, fetchAdditionCallback: (isSuccess: boolean, additionalJobOffers: any) => void) => {
      return fetchAdditionalOffersAction({ offersFilterId, page, callback: fetchAdditionCallback });
    },
    [offersFilterId],
  );

  return [
    offers,
    lastPage,
    profileUserId,
    setOffersFilterId,
    offersFilterName,
    refreshAction,
    fetchAction,
    fetchAdditionAction,
    allIndustries,
    offersFilterId,
  ];
};

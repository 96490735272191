import React from 'react';
import { useStyles } from './styles';
import clsx from 'clsx';
import { strings } from '@root/strings';

const { agFooter: string } = strings;

type MainFooterProps = {
  className?: string;
};

export const MainFooter = (props: MainFooterProps) => {
  const { className } = props;
  const styles = useStyles();

  return (
    <div className={clsx(styles.footer, className)}>
      <span className={styles.copyright}>{string.copyright}</span>
    </div>
  );
};

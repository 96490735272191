import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { JobCardProps } from '@root/web/components/JobCard/index';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles({
  containerTop: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  containerBottom: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5,
  },
  locationContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rateContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  workPeriodContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
    width: '75%',
  },
  titleText: (props: JobCardProps) => {
    return {
      textAlign: 'center',
      padding: '2px 8px',
      lineHeight: '22px',
      fontSize: 10,
      fontFamily: fonts.SFUiDisplayBold,
      color: colors.white,
      textTransform: 'uppercase',
      backgroundColor: props.industry?.color,
      borderRadius: 5,
      display: 'inline-block',
      marginBottom: 10,
    };
  },
  paymentMethodTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 130,
    fontFamily: fonts.SFUiDisplayBold,
    fontSize: 12,
    marginBottom: 5,
    color: colors.mineShaft,
  },
  nameText: {
    fontSize: 17,
    fontFamily: fonts.SFUiDisplayBold,
    lineHeight: '22px',
    letterSpacing: -0.24,
    wordWrap: 'break-word',
    marginBottom: 5,
  },
  workPeriodText: {
    fontSize: 17,
    fontFamily: fonts.SFUiDisplayMedium,
    lineHeight: '20px',
    letterSpacing: -0.24,
  },
  farmAddressText: {
    marginLeft: 7,
    fontSize: 13,
    fontFamily: fonts.SFUiDisplayMedium,
    lineHeight: '22px',
    letterSpacing: -0.41,
  },
  statusText: {
    textAlign: 'center',
    padding: '4px 8px 4px 8px',
    fontSize: 10,
    fontFamily: fonts.SFUiDisplayMedium,
    textTransform: 'uppercase',
    borderRadius: 30,
    borderStyle: 'solid',
    borderWidth: 0,
  },
  salaryText: {
    fontSize: 17,
    fontFamily: fonts.SFUiDisplayMedium,
    color: colors.red,
    lineHeight: '20px',
    letterSpacing: -0.24,
  },
  rateTypeText: {
    fontSize: 12,
    fontFamily: fonts.SFUiDisplaySemiBold,
    color: colors.grey,
    lineHeight: '16px',
    letterSpacing: -0.24,
    textAlign: 'end',
  },
  locationIcon: {
    height: 20,
    width: 14,
  },
  selectedCardContainer: {
    borderWidth: 1,
    borderColor: colors.red,
    borderRadius: 5,
    borderStyle: 'solid',
  },
});

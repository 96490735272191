import React, { useRef } from 'react';
import { JobDetails } from '@root/web/components/JobDetails';
import { Job } from '@root/general/types';
import { JOBS_STATUS, OFFER_STATUS } from '@root/general/consts';
import { strings } from '@root/strings';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { useStyles } from './styles';
import { CustomTabHeader } from '@root/web/components/CustomTabHeader';
import { jobDetailsMode } from '@root/mobile/hooks/useJobDetailsModalScreen';
import moment from 'moment';
import { JobStatusSwitcher } from '@root/web/screens/JobListingScreen/components/JobStatusSwitcher';
import Scrollbars from 'react-custom-scrollbars';
import { CustomLoader } from '@root/web/components/CustomLoader';
import { CustomTabContainer } from '@root/web/components/CustomTabContainer';
import { Grid } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

const { jobDetailsModalScreen: string } = strings;

export const daysBeforeEnableCancel = 3;

export type LaborerJobDetailsProps = {
  job: Job;
  isLoading: boolean;
  mode: jobDetailsMode | null;
  onPressJobAction: (status: number, jobCallback?: (isSuccess: boolean) => void) => () => void;
};

export const LaborerJobDetails = (props: LaborerJobDetailsProps) => {
  const styles = useStyles();
  const ref = useRef<any>();
  const history = useHistory();

  const { job, isLoading, mode, onPressJobAction } = props;
  const { startDate, isBlocked, status: jobStatusId } = job;

  const datesDiff = moment(startDate).diff(moment(), 'days');

  const isAccepted = mode === jobDetailsMode.accepted;
  const isDisableAction = isBlocked || jobStatusId !== JOBS_STATUS.OFFERED.id;
  const cancelEnable = isAccepted && !isDisableAction && datesDiff >= daysBeforeEnableCancel;

  const lastDateBeforeClosing = moment(startDate)
    .subtract(daysBeforeEnableCancel, 'days')
    .format('DD MMMM YYYY');

  const renderButton = () => {
    switch (mode) {
      case jobDetailsMode.applied:
        return <CustomButton type={customButtonType.GREEN} title={string.applied} onPress={() => {}} disabled />;
      case jobDetailsMode.offered:
        return (
          <Grid container spacing={2} className={styles.buttonContainerRow}>
            <Grid item xs={6}>
              <CustomButton
                type={customButtonType.WHISPER}
                title={string.decline}
                onPress={onPressJobAction(OFFER_STATUS.DECLINED.id)}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomButton
                type={customButtonType.GREEN}
                title={string.accept}
                onPress={onPressJobAction(OFFER_STATUS.ACCEPTED.id)}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        );
      case jobDetailsMode.accepted:
        return datesDiff >= daysBeforeEnableCancel ? (
          <CustomButton
            type={customButtonType.RED}
            title={string.cancelJob}
            onPress={onPressJobAction(OFFER_STATUS.CANCELLED.id)}
            disabled={isLoading}
          />
        ) : null;
      case jobDetailsMode.apply:
        return (
          <CustomButton
            type={customButtonType.GREEN}
            title={string.applyForJob}
            onPress={onPressJobAction(OFFER_STATUS.SENT.id)}
            disabled={isLoading}
          />
        );
      default:
        return null;
    }
  };

  return (
    <CustomTabContainer tabPositionFixed={isMobile}>
      <CustomTabHeader title={string.screenTitle} onTabHeaderClose={isMobile ? history.goBack : undefined} />
      <Scrollbars>
        <div className={styles.content} ref={ref}>
          <JobDetails job={job} />
          {isAccepted && <JobStatusSwitcher jobStatusId={jobStatusId} disabled={true} />}
        </div>
      </Scrollbars>
      {!!mode && (
        <div className={styles.footerContainer}>
          {cancelEnable && (
            <span className={styles.cancelDateText}>{string.cancelString + ' ' + lastDateBeforeClosing}</span>
          )}
          {!isDisableAction && renderButton()}
          {isDisableAction && <span className={styles.availabilityText}>{string.jobAvailability}</span>}
        </div>
      )}
      {isLoading && <CustomLoader />}
    </CustomTabContainer>
  );
};

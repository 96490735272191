import React, { Dispatch, SetStateAction } from 'react';
import { useStyles } from './styles';
import { Modal } from '@material-ui/core';
import { PRIVACY_POLICY, privacyPolicyScreenMode, TERMS_OF_SERVICE } from '@root/general/consts';
import { strings } from '@root/strings';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { BASE_URL } from '@root/web/consts';

const { buttons } = strings;

type PrivacyPolicyModalProps = {
  mode: privacyPolicyScreenMode | null;
  setModalMode: Dispatch<SetStateAction<privacyPolicyScreenMode | null>>;
};

export const PrivacyPolicyModal = (props: PrivacyPolicyModalProps) => {
  const { mode, setModalMode } = props;
  const styles = useStyles();

  const closeModal = () => {
    setModalMode(null);
  };

  return (
    <Modal className={styles.modalContainer} open={mode !== null} onClose={closeModal}>
      <div className={styles.contentContainer}>
        <iframe
          src={`${BASE_URL}${mode === privacyPolicyScreenMode.termsOfService ? TERMS_OF_SERVICE : PRIVACY_POLICY}`}
          className={styles.content}
          frameBorder={0}
        />
        <div className={styles.footerContainer}>
          <CustomButton type={customButtonType.GREY} title={buttons.close} onPress={closeModal} maxWidth={120} />
        </div>
      </div>
    </Modal>
  );
};

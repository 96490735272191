import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() => ({
  headerContainer: (props: any) => {
    return {
      display: 'flex',
      flexDirection: props?.inline ? 'row' : 'column',
      justifyContent: props?.inline ? 'center' : 'flex-start',
      alignItems: 'center',
    };
  },
  scrollbarContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '20px 30px',
  },
  sendJobOfferButton: {
    padding: '10px 30px 20px',
  },
  buttonsContainer: {
    display: 'flex',
    padding: '20px 30px',
  },
  avatarContainer: (props: any) => {
    return {
      borderRadius: '50%',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: colors.red,
      padding: props?.inline ? 4 : 9,
    };
  },
  defaultAvatarContainer: (props: any) => {
    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: colors.redOpacity2,
      borderRadius: '50%',
      height: props?.inline ? 43 : 83,
      width: props?.inline ? 43 : 83,
    };
  },
  defaultAvatar: {
    width: 34,
    height: 34,
  },
  userName: (props: any) => {
    return {
      fontSize: 19,
      letterSpacing: -0.24,
      textAlign: 'center',
      color: colors.black,
      fontFamily: fonts.SFUiDisplayBold,
      wordWrap: 'break-word',
      width: props?.inline ? 'auto' : '100%',
      marginLeft: props?.inline ? 12 : 0,
      marginTop: props?.inline ? 0 : 12,
    };
  },
  ratingContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
  },
  ratingText: {
    letterSpacing: -0.24,
    fontSize: 14,
    fontFamily: fonts.SFUiDisplayMedium,
    marginLeft: 4,
    marginRight: 4,
  },
  ratingCountText: {
    letterSpacing: -0.24,
    fontSize: 14,
    fontFamily: fonts.SFUiDisplayMedium,
    color: colors.grey,
  },
  accepted: {
    marginTop: 8,
    fontSize: 10,
    fontFamily: fonts.IBMPlexMonoRegular,
    backgroundColor: colors.celery,
    color: colors.white,
    textTransform: 'uppercase',
    borderRadius: 30,
    textAlign: 'center',
    padding: '4px 8px 4px 8px',
    letterSpacing: -0.24,
  },
  customImageStyle: (props: any) => {
    return {
      height: props?.inline ? 43 : 83,
      width: props?.inline ? 43 : 83,
    };
  },
  selectJob: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 12px',
    letterSpacing: -0.24,
    fontSize: 19,
    textAlign: 'center',
    color: colors.black,
    fontFamily: fonts.SFUiDisplayBold,
  },
  offeredPostedJobsEmpty: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 12px 8px',
    letterSpacing: -0.41,
    fontSize: 17,
    textAlign: 'center',
    color: colors.red,
    fontFamily: fonts.SFUiDisplaySemiBold,
  },
}));

import React from 'react';
import './App.css';
import firebase from 'firebase/app';
import { Provider } from 'react-redux';
import { getStore } from '@root/redux/store.web';
import { PersistGate } from 'redux-persist/integration/react';
import { AuthLoadingScreen } from '@root/web/navigators/AuthLoadingComponent';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyAsqmDWU3WKiuwRKLmse5IxtQWe1TdWCb8',
  authDomain: 'agbutler-d0547.firebaseapp.com',
  databaseURL: 'https://agbutler-d0547.firebaseio.com',
  projectId: 'agbutler-d0547',
  storageBucket: 'agbutler-d0547.appspot.com',
  messagingSenderId: '507711929715',
  appId: '1:507711929715:web:2cd7f0d4c671d2b042d906',
  measurementId: 'G-VR87ZDYS3D',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const App = () => {
  const { store, persistor } = getStore();

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AuthLoadingScreen />
      </PersistGate>
    </Provider>
  );
};

import { JOBS_STATUS } from '@root/general/consts';

export const JOB_STATUS_ALL = { id: 0, name: 'ALL', color: '' };

export const jobStatusesWithAll = [JOB_STATUS_ALL, ...Object.values(JOBS_STATUS)];

export const getJobStatusByStatusId = (statusId: number | null) => {
  return jobStatusesWithAll.find((jobStatus) => jobStatus.id === statusId) || { id: null, name: '', color: '' };
};

export const getJobStatusByStatusName = (statusName: string) => {
  return jobStatusesWithAll.find((jobStatus) => jobStatus.name === statusName) || { id: null, name: '', color: '' };
};

import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Job, JobArray } from '@root/general/types';
import { useDispatch } from 'react-redux';
import { createOfferAction, fetchJobsByOffersAction } from '@root/redux/actions';
import { fetchActionWithCallback } from '@root/hooks/useFlatListPaginationWithoutRedux';

type UseOfferedJobListModalScreenReturnType = [
  boolean,
  Dispatch<SetStateAction<boolean>>,
  Job | null,
  (job: Job) => () => void,
  () => void,
  fetchActionWithCallback<Job>,
  Dispatch<SetStateAction<Job | null>>,
];

export const useOfferedJobListModalScreen = (
  laborerId: number | null,
  createOfferCallback: () => void,
): UseOfferedJobListModalScreenReturnType => {
  const dispatch = useDispatch();
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);

  const onPressJob = (job: Job) => () => {
    setModalVisible(true);
    setSelectedJob(job);
  };

  const onSendJobOffer = () => {
    const jobId = selectedJob?.id ?? 0;

    dispatch(createOfferAction({ jobId: jobId, userId: laborerId, callback: createOfferCallback }));
  };

  const fetchAction = useCallback(
    (page: number, fetchCallback: (isSuccess: boolean, newItems?: JobArray, lastPage?: number) => void) => {
      return fetchJobsByOffersAction({ laborerId, page, callback: fetchCallback });
    },
    [laborerId],
  );

  return [isModalVisible, setModalVisible, selectedJob, onPressJob, onSendJobOffer, fetchAction, setSelectedJob];
};

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() => ({
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  copyright: {
    alignSelf: 'center',
    fontSize: 17,
    fontFamily: fonts.SFUiDisplayMedium,
    color: colors.mineShaft,
    margin: 0,
  },
}));

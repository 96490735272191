import { IActionType } from '../types';
import {
  CLEAR_USER_DATA_STATE,
  CLEAR_LABORERS_FILTER,
  FETCH_LABORERS_BY_INDUSTRY_SUCCESS,
  SET_CURRENT_LABORERS_INDUSTRY_ID,
  SET_LABORERS_FILTER,
} from '@root/general/consts/actions';
import { CityType, LaborersByIndustry, StateType } from '@root/general/types';

export enum laborersFilterFields {
  specificationId = 'specificationId',
  rate = 'rate',
  rating = 'rating',
  state = 'state',
  city = 'city',
  radius = 'radius',
}

export type LaborersFilter = {
  [laborersFilterFields.specificationId]: number | null;
  [laborersFilterFields.rate]: number | null;
  [laborersFilterFields.rating]: number | null;
  [laborersFilterFields.state]: StateType[] | null;
  [laborersFilterFields.city]: CityType | null;
  [laborersFilterFields.radius]: number | null;
};

export const defaultLaborerFilter: LaborersFilter = {
  specificationId: null,
  rate: null,
  rating: null,
  state: null,
  city: null,
  radius: null,
};

export type LaborersReducer = {
  laborersByIndustry: LaborersByIndustry;
  filter: { [k in number]: LaborersFilter };
  currentIndustryId: number | null;
};

const INITIAL_STATE: LaborersReducer = {
  laborersByIndustry: {},
  filter: {},
  currentIndustryId: null,
};

export const laborersReducer = (state = INITIAL_STATE, action: IActionType) => {
  switch (action.type) {
    case SET_CURRENT_LABORERS_INDUSTRY_ID: {
      return {
        ...state,
        currentIndustryId: action.payload.id,
      };
    }
    case FETCH_LABORERS_BY_INDUSTRY_SUCCESS: {
      const { industryId, laborers, lastPage } = action.payload;

      return {
        ...state,
        laborersByIndustry: { ...state.laborersByIndustry, [industryId]: { laborers, lastPage } },
      };
    }
    case SET_LABORERS_FILTER: {
      if (state.currentIndustryId) {
        return {
          ...state,
          filter: { ...state.filter, [state.currentIndustryId]: action.payload },
        };
      }
      return state;
    }
    case CLEAR_LABORERS_FILTER: {
      return {
        ...state,
        filter: {},
        currentIndustryId: null,
      };
    }
    case CLEAR_USER_DATA_STATE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
};

import React from 'react';
import { ClipLoader } from 'react-spinners';
import { colors } from '@root/general/colors';
import { useStyles } from './styles';

type CustomLoaderProps = {
  size?: number;
};

export const CustomLoader = (props: CustomLoaderProps) => {
  const { size } = props;
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.loader}>
        <ClipLoader size={size ? size : 30} color={colors.red} />
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useProposalsScreen } from '@root/hooks/useProposalsScreen';
import { useFlatListPagination } from '@root/hooks/useFlatListPagination';
import { GeneralUser, Job, Offer } from '@root/general/types';
import { useStyles } from './styles';
import { defaultGeneralUser, OFFERS_BY_STATUS } from '@root/general/consts';
import { CustomStatusPicker } from '@root/web/components/CustomStatusPicker';
import { offersFilterData } from '@root/utils/getOffersFilterById';
import { strings } from '@root/strings';
import { CustomTabHeader } from '@root/web/components/CustomTabHeader';
import { LaborerCard } from '@root/web/components/LaborerCard';
import { proposalsJobRequestFeedbackCallback } from '@root/utils/proposalsJobRequestsFeedbackCallback';
import { UserInfoTab, UserInfoTabPropsType } from '@root/web/components/UserInfoTab';
import { CustomScrollComponent } from '@root/web/components/CustomScrollComponent';
import { CustomTabContainer } from '@root/web/components/CustomTabContainer';

const { proposalsScreen: string, laborerInfoModalScreen: modal } = strings;

type ProposalsScreenPropsType = {
  job: Job;
  goBackCallback: () => void;
};

const filterData = offersFilterData.filter(
  (offerFilter) => offerFilter.id !== OFFERS_BY_STATUS.NEW.id && offerFilter.id !== OFFERS_BY_STATUS.APPLIED.id,
);

export const ProposalsScreen = (props: ProposalsScreenPropsType) => {
  const { job, goBackCallback } = props;
  const { id: jobId } = job;

  const styles = useStyles();

  const [
    proposals,
    lastPage,
    ,
    ,
    setProposalsFilterId,
    proposalsFilterName,
    fetchAction,
    refreshAction,
    fetchAdditionAction,
    proposalsFilterId,
  ] = useProposalsScreen(jobId);

  const [proposalsByState, isFlatListLoading, , , , onEndReached, setProposalsByState, hasMore] = useFlatListPagination<
    Offer
  >(proposals, lastPage, fetchAction, refreshAction, fetchAdditionAction);

  const [userInfoProps, setUserInfoProps] = useState<UserInfoTabPropsType | null>(null);

  const goToLaborerInfo = (userId: number | null, jobId: number | null) => () => {
    setUserInfoProps({
      userId,
      jobId,
      jobRequestFeedbackCallback: proposalsJobRequestFeedbackCallback(
        proposalsByState,
        setProposalsByState,
        proposalsFilterName,
      ),
      goBackCallback: () => setUserInfoProps(null),
    });
  };

  const renderProposal = (item: Offer) => {
    const {
      id: offerId,
      status: offerStatusId,
      ownerId: offerOwnerId,
      owner: offerOwner = defaultGeneralUser,
      user: offerUser = defaultGeneralUser,
    } = item;

    const isOfferFromUs = job.userId === offerOwnerId;
    const laborerProfile: GeneralUser = isOfferFromUs ? offerUser : offerOwner;

    if (laborerProfile === null) {
      const onPress = goToLaborerInfo(null, job.id);

      return (
        <Grid key={offerId} item xs={12}>
          <LaborerCard
            name={modal.deletedUser}
            rate={0}
            jobName={job.name}
            isOfferFromUs={isOfferFromUs}
            rating={null}
            onPress={onPress}
          />
        </Grid>
      );
    } else {
      const { name: laborerName, rate: laborerRate = 0, rating } = laborerProfile;

      const onPress = goToLaborerInfo(laborerProfile.id, job.id);

      return (
        <Grid key={offerId} item xs={12}>
          <LaborerCard
            name={laborerName}
            rate={laborerRate}
            jobName={job.name}
            offerStatus={offerStatusId}
            isOfferFromUs={isOfferFromUs}
            rating={rating}
            onPress={onPress}
          />
        </Grid>
      );
    }
  };

  return (
    <>
      <CustomTabContainer tabPositionFixed isHidden={!!userInfoProps}>
        <CustomTabHeader title={string.screenName} onTabHeaderClose={goBackCallback} />
        <CustomScrollComponent
          data={proposalsByState}
          renderItem={renderProposal}
          isFlatListLoading={isFlatListLoading}
          onEndReached={onEndReached}
          hasMore={hasMore}
          defaultPrimaryText={string.defaultPrimaryText}
          header={
            <CustomStatusPicker
              data={filterData}
              setRespondedValue={setProposalsFilterId}
              respondedValue={proposalsFilterId}
              containerClasses={styles.proposalsPicker}
            />
          }
        />
      </CustomTabContainer>
      {userInfoProps && <UserInfoTab {...userInfoProps} tabPositionFixed />}
    </>
  );
};

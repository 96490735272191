import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { Modal } from '@material-ui/core';
import { useStyles } from '@root/web/screens/ProfileScreen/components/TransactionHistory/components/PaymentListModal/styles';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { strings } from '@root/strings';
import { PaymentType } from '@root/general/types';
import moment from 'moment';
import { SearchInput } from '@root/web/components/SearchInput';
import { usePaymentModal } from '@root/hooks/usePaymentModal';
import { modalStyle } from '@root/general/consts';

const { transactionHistoryScreen: string, laborerInfoModalScreen } = strings;

type PaymentListModalPropsType = {
  isModalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  payment: PaymentType;
};

export const PaymentListModal = (props: PaymentListModalPropsType): ReactElement => {
  const { isModalVisible, setModalVisible, payment } = props;
  const styles = useStyles();

  const { amountText, modalItems, searchValue, setSearchValue } = usePaymentModal(payment);

  const closeModal = () => {
    setModalVisible(false);
  };

  const renderItems = modalItems.map((modalItem, index) => {
    const { id, jobName, offers } = modalItem;

    const laborers = offers.map((offer) => {
      const { laborerName, createdAt } = offer;
      const date = moment(createdAt).format('lll');
      const laborerNameCondition = laborerName ? laborerName : laborerInfoModalScreen.deletedUser;

      return (
        <div className={styles.paymentItemContainer}>
          <div className={styles.nameAndPriceContainer}>
            <span className={styles.name}>{laborerNameCondition}</span>
            <span className={styles.price}>${amountText}</span>
          </div>
          <span className={styles.date}>{date}</span>
        </div>
      );
    });

    return (
      <div key={`${index}${id}`} className={styles.modalItemContainer}>
        <div className={styles.jobNameContainer}>
          <span className={styles.jobName}>{jobName}</span>
        </div>
        {laborers}
      </div>
    );
  });

  return (
    <Modal className={styles.modalContainer} style={modalStyle} open={isModalVisible} onClose={closeModal}>
      <div className={styles.container}>
        <div className={styles.searchBarContainer}>
          <SearchInput value={searchValue} placeholder={string.search} onChange={setSearchValue} />
        </div>
        {renderItems.length ? (
          <div className={styles.paymentsContainer}>{renderItems}</div>
        ) : (
          <div className={styles.emptyList}>
            <span className={styles.emptyListText}>{string.emptyList}</span>
          </div>
        )}
        <div className={styles.closeButtonContainer}>
          <CustomButton type={customButtonType.RED} title={string.close} onPress={closeModal} disabledTopBorder />
        </div>
      </div>
    </Modal>
  );
};

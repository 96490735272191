import React from 'react';
import { strings } from '@root/strings';
import { Checkbox, ThemeProvider } from '@material-ui/core';
import { useStyles, checkBoxTheme } from '@root/web/screens/RegistrationScreen/styles';
import { privacyPolicyScreenMode } from '@root/general/consts';
import clsx from 'clsx';
import { PrivacyPolicyProps } from '@root/general/types';

const { registrationScreen: string } = strings;

export const PrivacyPolicy = (props: PrivacyPolicyProps) => {
  const styles = useStyles();
  const { isChecked, toggleCheckBox, navigateToPrivacyPolicy } = props;

  return (
    <div className={styles.checkBoxContainer}>
      <ThemeProvider theme={checkBoxTheme}>
        <Checkbox checked={isChecked} onChange={toggleCheckBox} disableRipple disableFocusRipple disableTouchRipple />
      </ThemeProvider>
      <span className={styles.checkBoxText}>
        {string.privacyPolicyPart1}
        <span
          onClick={() => navigateToPrivacyPolicy(privacyPolicyScreenMode.termsOfService)}
          className={clsx(styles.checkBoxText, styles.checkBoxTextLink)}
        >
          {string.privacyPolicyPart2}
        </span>
        {string.privacyPolicyPart3}
        <span
          onClick={() => navigateToPrivacyPolicy(privacyPolicyScreenMode.privacyPolicy)}
          className={clsx(styles.checkBoxText, styles.checkBoxTextLink)}
        >
          {string.privacyPolicyPart4}
        </span>
      </span>
    </div>
  );
};

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftContainer: {
    width: '60%',
    flex: 1,
    marginRight: 20,
  },
  userName: {
    fontSize: 17,
    letterSpacing: -0.24,
    textAlign: 'center',
    color: colors.black,
    fontFamily: fonts.SFUiDisplayBold,
    wordWrap: 'break-word',
    width: '100%',
  },
  ratingContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0px',
  },
  ratingText: {
    letterSpacing: -0.24,
    fontSize: 14,
    fontFamily: fonts.SFUiDisplayMedium,
    marginLeft: 4,
    marginRight: 4,
  },
  ratingCountText: {
    letterSpacing: -0.24,
    fontSize: 14,
    fontFamily: fonts.SFUiDisplayMedium,
    color: colors.grey,
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  rateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  rateText: {
    fontSize: 17,
    fontFamily: fonts.SFUiDisplayMedium,
    letterSpacing: -0.24,
    lineHeight: '20px',
    color: colors.red,
  },
  perHour: {
    fontSize: 12,
    fontFamily: fonts.SFUiDisplaySemiBold,
    lineHeight: '16px',
    letterSpacing: -0.24,
    color: colors.grey,
  },
  statusText: {
    textAlign: 'center',
    padding: '4px 8px 4px 8px',
    fontSize: 10,
    fontFamily: fonts.SFUiDisplayMedium,
    textTransform: 'uppercase',
    borderRadius: 30,
    borderStyle: 'solid',
    borderWidth: 0,
  },
}));

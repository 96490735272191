import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() => ({
  scrollbarContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 30px',
  },
  jobStatusSwitcherContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 30px',
  },
  footerContainer: {
    padding: '20px 30px',
  },
  submitReviewContainer: {
    alignSelf: 'center',
    marginBottom: 10,
    backgroundColor: colors.goldenRod,
    borderRadius: 36,
    padding: '14px 28px',
    fontSize: 15,
    fontFamily: fonts.SFUiDisplayBold,
    lineHeight: '20px',
    letterSpacing: -0.24,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.goldenRod,
    },
  },
}));

import moment from 'moment';

export const getFarmDate = (startDate: string, endDate: string) => {
  const formatDate = (date: string) => moment(date).format('DD MMMM');

  if (startDate === '' && endDate === '') {
    return '';
  }

  if (startDate === endDate) {
    return `${formatDate(startDate)}`;
  }

  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
};

import React from 'react';
import { Reference } from '@root/general/types';
import { useStyles } from './styles';
import { CustomCardContainer } from '@root/web/components/CustomCardContainer';

export type ReferenceCardProps = {
  reference: Reference;
  onPress: () => void;
  isSelected?: boolean;
};

export const ReferenceCard = (props: ReferenceCardProps) => {
  const styles = useStyles(props);

  const { reference, onPress, isSelected } = props;
  const { name, email, phone } = reference;

  return (
    <CustomCardContainer isSelected={isSelected} onPress={onPress}>
      <span className={styles.name}>{name}</span>
      {!!email && (
        <div className={styles.emailContainer}>
          <img className={styles.icon} src={require('@root/web/assets/icons/email.svg')} alt='' />
          <span className={styles.field}>{email}</span>
        </div>
      )}
      {!!phone && (
        <div className={styles.phoneContainer}>
          <img className={styles.icon} src={require('@root/web/assets/icons/phone.svg')} alt='' />
          <span className={styles.field}>{phone}</span>
        </div>
      )}
    </CustomCardContainer>
  );
};

import { NotificationAdditionalDataType } from '@root/general/types';
import camelcaseKeys from 'camelcase-keys';
import { notificationTypes } from '@root/general/consts';

export const navigateWithNotification = (
  notificationData: NotificationAdditionalDataType,
  navigateToJobOffer: (jobId: number | undefined) => void,
  navigateToNewJob: (jobId: number | undefined) => void,
  navigateToMyJob: (jobId: number | undefined) => void,
  navigateToJobRequest: (
    laborerId: number | undefined,
    jobId: number | undefined,
    industryId: number | undefined,
  ) => void,
  navigateToNewLaborer: (laborerId: number | undefined, industryId: number | undefined) => void,
  updateNotificationCallback = () => {},
) => {
  const { data, type } = notificationData;

  if (type) {
    if (data) {
      const { jobId, userId, industryId } = camelcaseKeys(data, { deep: true });

      switch (type) {
        case notificationTypes.LABORER_NEW_JOB_OFFER:
        case notificationTypes.LABORER_DECLINED_JOB_REQUEST:
        case notificationTypes.LABORER_ACCEPTED_JOB_REQUEST:
          return navigateToJobOffer(jobId);
        case notificationTypes.LABORER_NEW_JOB_IN_INDUSTRIES:
        case notificationTypes.LABORER_NEW_JOB_IN_AREA:
          return navigateToNewJob(jobId);
        case notificationTypes.EMPLOYER_EXPIRED_JOB:
        case notificationTypes.EMPLOYER_BLOCKED_JOB:
        case notificationTypes.EMPLOYER_UNBLOCKED_JOB:
          return navigateToMyJob(jobId);
        case notificationTypes.EMPLOYER_NEW_JOB_REQUEST:
        case notificationTypes.EMPLOYER_ACCEPTED_JOB_OFFER:
        case notificationTypes.EMPLOYER_DECLINED_JOB_OFFER:
          return navigateToJobRequest(userId, jobId, industryId);
        case notificationTypes.EMPLOYER_NEW_LABORER_IN_INDUSTRY:
        case notificationTypes.EMPLOYER_NEW_LABORER_IN_AREA:
          return navigateToNewLaborer(userId, industryId);
        default:
          return () => {};
      }
    } else {
      updateNotificationCallback();
    }
  }
};

import React, { forwardRef, ForwardRefRenderFunction, useEffect, useState } from 'react';
import { useStyles } from './styles';
import { useMapFilterModalScreen } from '@root/hooks/useMapFilterModalScreen';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { strings } from '@root/strings';
import { CustomInput } from '@root/web/components/CustomInput';
import { ChooseCityModal } from '@root/web/components/ChooseCityModal';
import { CustomModalWithSections } from '@root/web/components/CustomModal/CustomModalWithSections';
import { isEqual } from 'lodash';

const { mapFilterModalScreen: string } = strings;

const MapFilterComponent: ForwardRefRenderFunction<any> = (props: any, ref: any) => {
  const styles = useStyles(props);

  const [isCityModalVisible, setCityModalVisible] = useState(false);

  const onCityPress = () => () => {
    setCityModalVisible(true);
  };

  const [
    filterFieldFormData,
    resetFilter,
    onSave,
    industriesWithSpecifications,
    specificationsModalVisible,
    setSpecificationsModalVisible,
    selectedSpecificationIds,
    setSelectedSpecificationIds,
    setSelectedSpecifications,
    city,
    setCity,
  ] = useMapFilterModalScreen(onCityPress, () => {});

  const [filterCity, setFilterCity] = useState(city);
  const [specifications, setSpecifications] = useState(selectedSpecificationIds);

  // auto onSave when filter values change
  useEffect(() => {
    if (filterCity?.id !== city?.id || !isEqual(specifications, selectedSpecificationIds)) {
      if (filterCity?.id !== city?.id) {
        setFilterCity(city);
      } else {
        setSpecifications(selectedSpecificationIds);
      }

      onSave();
    }
  }, [city, filterCity, setFilterCity, selectedSpecificationIds, specifications, setSpecifications, onSave]);

  const renderInputForms = () => {
    return filterFieldFormData.map((item) => <CustomInput key={item.id} item={item} />);
  };

  return (
    <div className={styles.filterContainer}>
      {renderInputForms()}
      <CustomButton
        type={customButtonType.TEXT}
        title={string.resetFilter}
        onPress={resetFilter}
        className={styles.resetButton}
      />
      {isCityModalVisible && (
        <ChooseCityModal
          isModalVisible={isCityModalVisible}
          setModalVisible={setCityModalVisible}
          selectedStateId={null}
          setCity={setCity}
          ref={ref}
        />
      )}
      {specificationsModalVisible && (
        <CustomModalWithSections
          setValue={setSelectedSpecifications}
          isModalVisible={specificationsModalVisible}
          setModalVisible={setSpecificationsModalVisible}
          sections={industriesWithSpecifications}
          selectedItemIds={selectedSpecificationIds}
          setSelectedItemIds={setSelectedSpecificationIds}
          headerTitle={string.specifications}
          ref={ref}
        />
      )}
    </div>
  );
};

export const MapFilter = forwardRef(MapFilterComponent);

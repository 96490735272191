import React from 'react';
import { LaborersFilterModal } from '@root/web/screens/LaborersScreen/components/LaborersFilterModal';
import { UserInfoTab } from '@root/web/components/UserInfoTab';

type LaborersRightTabProps = {
  isTabShown: boolean;
  laborerId: number;
  onTabClose: () => void;
};

export const LaborersRightTab = (props: LaborersRightTabProps) => {
  const { isTabShown, laborerId, onTabClose } = props;

  if (laborerId) {
    return <UserInfoTab userId={laborerId} goBackCallback={onTabClose} tabPositionFixed />;
  }

  if (isTabShown) {
    return <LaborersFilterModal onTabClose={onTabClose} />;
  }

  return null;
};

import { Api, CustomApiResponse } from '@root/services/api';
import { call, put } from 'redux-saga/effects';
import { ArrayIndustries, ArraySpecifications, ErrorResponse } from '@root/general/types';
import {
  CALL_COMMON_ERROR_HANDLER,
  CALL_UNEXPECTED_ERROR_HANDLER,
  FETCH_INDUSTRIES_SUCCESS,
  FETCH_INDUSTRIES_WITH_SPECIFICATIONS_SUCCESS,
  FETCH_SPECIFICATIONS_SUCCESS,
} from '@root/general/consts/actions';
import { isArray } from 'lodash';

export function* fetchIndustries(api: Api) {
  try {
    const response: CustomApiResponse<ArrayIndustries, ErrorResponse> = yield call(api.fetchIndustries);

    if (response.ok && isArray(response.data)) {
      yield put({ type: FETCH_INDUSTRIES_SUCCESS, payload: response.data });
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* fetchSpecifications(api: Api) {
  try {
    const response: CustomApiResponse<ArraySpecifications, ErrorResponse> = yield call(api.fetchSpecifications);

    if (response.ok && isArray(response.data)) {
      yield put({ type: FETCH_SPECIFICATIONS_SUCCESS, payload: response.data });
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* fetchIndustriesWithSpecifications(api: Api) {
  try {
    const response: CustomApiResponse<ArraySpecifications, ErrorResponse> = yield call(api.fetchIndustries);

    if (response.ok && isArray(response.data)) {
      yield put({ type: FETCH_INDUSTRIES_WITH_SPECIFICATIONS_SUCCESS, payload: response.data });
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

import { CustomInputFormDataType } from '../mobile/types/inputTypes';
import { Dispatch, SetStateAction, useState } from 'react';
import { CityType, StateType } from '../general/types';
import { useDispatch, useSelector } from 'react-redux';
import { getJobListingFilter } from '../selectors';
import { showToastAction, ToastTypes } from '../redux/actions/commonActions';
import { JobListingFilter } from '../redux/reducers/jobListingReducer';
import { setJobListingFilterAction } from '../redux/actions';
import { strings } from '../strings';
import { getStateFullName } from '@root/utils/getStateFullName';

const { jobListingFilterModalScreen: string, warnings } = strings;

export enum jobListingFilterField {
  state = 0,
  city = 1,
  radius = 2,
}

type UseJobListingFilterModalScreenReturnType = [
  CustomInputFormDataType,
  () => void,
  () => void,
  number | null,
  Dispatch<SetStateAction<number | null>>,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  StateType[] | null,
  Dispatch<SetStateAction<StateType[] | null>>,
  Dispatch<SetStateAction<CityType | null>>,
];

export const useJobListingFilterModalScreen = (
  onStatePress: (
    setFilterState: Dispatch<SetStateAction<StateType[] | null>>,
    setFilterCity: Dispatch<SetStateAction<CityType | null>>,
  ) => () => void,
  onCityPress: (
    filterState: StateType[] | null,
    setFilterCity: Dispatch<SetStateAction<CityType | null>>,
  ) => () => void,
  navigationCallback: () => void,
): UseJobListingFilterModalScreenReturnType => {
  const dispatch = useDispatch();

  const filter = useSelector(getJobListingFilter);
  const { state, city, radius } = filter;

  const [radiusModalVisible, setRadiusModalVisible] = useState(false);
  const [filterRadius, setFilterRadius] = useState<number | null>(radius);

  const [filterCity, setFilterCity] = useState<CityType | null>(city);
  const [filterState, setFilterState] = useState<StateType[] | null>(state);

  const getStatesFullNames = (filterState: StateType[] | null) => {
    return Array.isArray(filterState) ? filterState.map((state) => getStateFullName(state)) : [];
  };

  const cityName = filterCity?.name ?? '';

  const disableCityAndRadiusFields = !filterState?.length;

  const onPressRadius = () => {
    if (filterState && filterCity) {
      setRadiusModalVisible(!radiusModalVisible);
    } else if (filterState && !filterCity) {
      dispatch(showToastAction({ message: warnings.selectCity, type: ToastTypes.Error }));
    } else {
      dispatch(showToastAction({ message: warnings.selectStateAndCity, type: ToastTypes.Error }));
    }
  };

  const filterFieldFormData: CustomInputFormDataType = [
    {
      id: jobListingFilterField.state,
      title: string.stateTitle,
      defaultValue: getStatesFullNames(filterState),
      placeHolder: string.statePlaceholder,
      onPress: onStatePress(setFilterState, setFilterCity),
      icon: require('@root/assets/icons/arrowRight.png'),
      webIcon: require('@root/web/assets/icons/arrowRight.svg'),
      autoLength: true,
    },
    {
      id: jobListingFilterField.city,
      title: string.cityTitle,
      defaultValue: cityName,
      placeHolder: string.cityPlaceholder,
      onPress: onCityPress(filterState, setFilterCity),
      icon: require('@root/assets/icons/arrowRight.png'),
      webIcon: require('@root/web/assets/icons/arrowRight.svg'),
      autoLength: true,
      disabled: disableCityAndRadiusFields,
    },
    {
      id: jobListingFilterField.radius,
      title: string.radiusTitle,
      defaultValue: filterRadius ? String(filterRadius) + ' Miles' : '',
      placeHolder: string.radiusPlaceholder,
      onPress: onPressRadius,
      icon: require('@root/assets/icons/dropdownArrow.png'),
      disabled: disableCityAndRadiusFields,
    },
  ];

  const onSave = () => {
    const filter: JobListingFilter = {
      state: filterState,
      city: filterCity,
      radius: filterRadius,
    };

    dispatch(setJobListingFilterAction(filter));
    navigationCallback();
  };

  const resetFilter = () => {
    setFilterState(null);
    setFilterCity(null);
    setFilterRadius(null);
  };

  return [
    filterFieldFormData,
    resetFilter,
    onSave,
    filterRadius,
    setFilterRadius,
    radiusModalVisible,
    setRadiusModalVisible,
    filterState,
    setFilterState,
    setFilterCity,
  ];
};

import React from 'react';
import { strings } from '@root/strings';
// @ts-ignore
import styles from './styles.module.css';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';

const { buttons } = strings;

type AuthErrorProps = {
  onPress: () => void;
};

export const AuthError = (props: AuthErrorProps) => {
  const { onPress } = props;

  return (
    <div className={styles.container}>
      <CustomButton type={customButtonType.RED} title={buttons.reload} onPress={onPress} maxWidth={350} />
    </div>
  );
};

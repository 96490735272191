import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles({
  container: ({ jobId }: { jobId: string }) => {
    return {
      height: '100%',
      display: jobId ? 'none' : 'flex',
      backgroundColor: colors.lightGrey,
      flex: 1,
    };
  },
  leftContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: 450,
    backgroundColor: colors.white,
    borderWidth: '0px 0px 0px 1px',
    borderStyle: 'solid',
    borderColor: colors.athensGray,
  },
});

import React, { useEffect, useState } from 'react';
import { Job } from '@root/general/types';
import { useJobDetailsModalScreen } from '@root/mobile/hooks/useJobDetailsModalScreen';
import { ReviewLaborersScreen } from '@root/web/screens/PostedJobsScreen/components/ReviewLaborersScreen';
import { ProposalsScreen } from '@root/web/screens/PostedJobsScreen/components/ProposalsScreen';
import { JobActionScreen } from '@root/web/screens/PostedJobsScreen/components/JobActionScreen';
import { EmployerJobDetails } from '@root/web/screens/PostedJobsScreen/components/EmployerJobDetails';
import { jobActionScreenMode } from '@root/hooks/useJobActionModalScreen';

export enum jobsTabModes {
  INFO,
  POST,
  EDIT,
  PROPOSALS,
  REVIEW,
}

type JobDetailsScreenProps = {
  jobId: number;
  goBackCallback: () => void;
  jobDetailsCallback?: () => void;
  updateNotificationCallback?: () => void;
};

export const JobDetailsScreen = (props: JobDetailsScreenProps) => {
  const { jobId, goBackCallback, jobDetailsCallback, updateNotificationCallback } = props;

  const [jobTabMode, setJobTabMode] = useState(jobsTabModes.INFO);

  useEffect(() => {
    setJobTabMode(jobsTabModes.INFO);
  }, [jobId]);

  const goToReviewLaborers = () => () => {
    setJobTabMode(jobsTabModes.REVIEW);
  };

  const goToProposals = () => {
    setJobTabMode(jobsTabModes.PROPOSALS);
  };

  const goToEditJob = () => {
    setJobTabMode(jobsTabModes.EDIT);
  };

  const setJobCallback = (editedJob: Job) => {
    setJob(editedJob);
  };

  const [
    job,
    setJob,
    ,
    isLoading,
    isJobEditable,
    ,
    jobStatusesModalVisible,
    setJobStatesModalVisible,
    setNewJobStatusId,
  ] = useJobDetailsModalScreen(
    jobId,
    goBackCallback,
    goToReviewLaborers,
    jobDetailsCallback,
    updateNotificationCallback,
  );

  const renderScreen = () => {
    const goBackCallback = () => setJobTabMode(jobsTabModes.INFO);

    switch (jobTabMode) {
      case jobsTabModes.EDIT:
        return (
          <JobActionScreen
            job={job}
            mode={jobActionScreenMode.EDIT}
            setJobCallback={setJobCallback}
            goBackCallback={goBackCallback}
          />
        );
      case jobsTabModes.PROPOSALS:
        return <ProposalsScreen job={job} goBackCallback={goBackCallback} />;
      case jobsTabModes.REVIEW:
        return <ReviewLaborersScreen job={job} setJob={setJob} goBackCallback={goBackCallback} />;
    }
  };

  return (
    <>
      <EmployerJobDetails
        job={job}
        isLoading={isLoading}
        setJob={setJob}
        setNewJobStatusId={setNewJobStatusId}
        jobStatusesModalVisible={jobStatusesModalVisible}
        setJobStatesModalVisible={setJobStatesModalVisible}
        goToReviewLaborers={goToReviewLaborers}
        goToProposals={goToProposals}
        goToEditJob={goToEditJob}
        goBackCallback={goBackCallback}
        isJobEditable={isJobEditable}
        isHidden={jobTabMode !== jobsTabModes.INFO}
      />
      {renderScreen()}
    </>
  );
};

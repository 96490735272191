import { Api, CustomApiResponse } from '@root/services/api';
import { FetchCitiesOnMapAction, FetchJobsOnMapAction } from '@root/redux/actions/types';
import { call, put, select } from 'redux-saga/effects';
import { ErrorResponse, ArrayCityType, JobArray, OfferArray } from '@root/general/types';
import { CALL_COMMON_ERROR_HANDLER, CALL_UNEXPECTED_ERROR_HANDLER } from '@root/general/consts/actions';
import { isArray } from 'lodash';
import { getJobListingWithOfferState } from '@root/mobile/utils/getJobListingWithOfferState';
import { getUser } from '@root/selectors';

export function* fetchCitiesOnMap(api: Api, action: FetchCitiesOnMapAction) {
  const { filter, callback } = action.payload;

  try {
    const response: CustomApiResponse<ArrayCityType, ErrorResponse> = yield call(api.fetchCitiesOnMap, filter);
    if (response.ok && isArray(response.data)) {
      yield callback && call(callback, true, response.data);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* fetchJobsOnMap(api: Api, action: FetchJobsOnMapAction) {
  const { page, filter, callback } = action.payload;

  try {
    const { id: userId } = yield select(getUser);

    const response: CustomApiResponse<JobArray, ErrorResponse> = yield call(api.fetchJobsOnMap, filter, page);
    if (response.ok && isArray(response.data)) {
      const jobIdsArray = response.data.map((job) => job.id);

      const additionalResponse: CustomApiResponse<OfferArray, ErrorResponse> = yield call(
        api.fetchUserOffersByJobIds,
        userId,
        jobIdsArray,
      );

      if (additionalResponse.ok && additionalResponse.data) {
        yield callback &&
          call(
            callback,
            true,
            getJobListingWithOfferState(response.data, additionalResponse.data, userId),
            response.meta?.lastPage ?? 1,
          );
      }

      if (!additionalResponse.ok) {
        yield callback && call(callback, false);
        yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: additionalResponse });
      }
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GeneralUser, SecretDataType } from '@root/general/types';
import {
  deletePaymentCardAction,
  fetchPaymentCardsAction,
  fetchPaymentSecretAction,
  setDefaultPaymentCardAction,
} from '@root/redux/actions';
import { getUser } from '@root/selectors';
import { showToastAction, ToastTypes } from '@root/redux/actions/commonActions';
import { capitalize } from '@root/utils/capitalize';
import { strings } from '@root/strings';

const { paymentSystemModal: string } = strings;

export type UsePaymentCardsType = [
  (paymentId: number, paymentMethodId: string, successCallback?: () => void) => void,
  (callback?: (isSuccess: boolean) => void) => void,
  boolean,
  (cardId: string) => () => void,
  (cardId: string) => () => void,
  boolean,
  () => void,
];

export type OnAddNewCardCallback = (user: GeneralUser) => Promise<{ setupIntentStatus: string; errorMessage: string }>;

export type OnPayPressCallback = (
  paymentId: number,
  paymentMethodId: string,
  clientSecret: string,
) => Promise<{ paymentIntentStatus: string; errorMessage: string }>;

export const usePaymentCards = (
  onAddNewCardCallback: OnAddNewCardCallback,
  onPayPressCallback: OnPayPressCallback,
): UsePaymentCardsType => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const [isLoading, setIsLoading] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);

  const onLongCardPress = () => {
    setDeleteMode(!deleteMode);
  };

  const onPressSetDefault = (cardId: string) => () => {
    setIsLoading(true);
    dispatch(setDefaultPaymentCardAction({ cardId, callback: () => setIsLoading(false) }));
  };

  const onDeleteCard = (cardId: string) => () => {
    setIsLoading(true);
    setDeleteMode(!deleteMode);

    dispatch(
      deletePaymentCardAction({
        cardId,
        callback: () => {
          setIsLoading(false);
          dispatch(fetchPaymentCardsAction({}));
          dispatch(
            showToastAction({
              message: string.deleteCardSucceed,
              type: ToastTypes.Notification,
            }),
          );
        },
      }),
    );
  };

  const onAddNewCard = async (callback?: (isSuccess: boolean) => void) => {
    try {
      const { setupIntentStatus, errorMessage } = await onAddNewCardCallback(user);

      if (errorMessage) {
        dispatch(showToastAction({ message: errorMessage, type: ToastTypes.Notification }));
        callback && callback(false);
        return;
      }

      if (setupIntentStatus === 'succeeded') {
        dispatch(
          showToastAction({
            message: string.addCardSucceed,
            type: ToastTypes.Notification,
          }),
        );

        dispatch(fetchPaymentCardsAction({ callback }));
      }
    } catch (error) {
      if (error) {
        const errorMessage = String(error).replace('Error: ', '');
        dispatch(showToastAction({ message: errorMessage, type: ToastTypes.Notification }));
      }
    }
  };

  const onPayPress = async (paymentId: number, paymentMethodId: string, successCallback?: () => void) => {
    try {
      setIsLoading(true);
      const data: SecretDataType = await new Promise((resolve, reject) => {
        dispatch(fetchPaymentSecretAction({ paymentId, resolve, reject }));
      });
      const { secret: clientSecret } = data;

      const { paymentIntentStatus, errorMessage } = await onPayPressCallback(paymentId, paymentMethodId, clientSecret);

      setIsLoading(false);

      if (errorMessage) {
        dispatch(showToastAction({ message: errorMessage, type: ToastTypes.Notification }));
        return;
      }

      dispatch(showToastAction({ message: capitalize(paymentIntentStatus), type: ToastTypes.Notification }));
      if (paymentIntentStatus === 'succeeded') {
        successCallback && successCallback();
      }
    } catch (error) {
      error && dispatch(showToastAction({ message: String(error), type: ToastTypes.Error }));
      setIsLoading(false);
    }
  };

  return [onPayPress, onAddNewCard, isLoading, onPressSetDefault, onDeleteCard, deleteMode, onLongCardPress];
};

import { FormikFieldFormDataType, FormikValuesType } from '@root/mobile/types/inputTypes';
import { MutableRefObject, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from '@root/selectors';
import { strings } from '@root/strings';
import { signInRequestAction } from '@root/redux/actions';
import { WEB } from '@root/general/consts';

type UseLoginScreenReturnType = [
  (data: FormikValuesType, onSuccessCallback?: () => void) => void,
  FormikFieldFormDataType,
  FormikValuesType,
  boolean,
  MutableRefObject<never[]>,
];
export const useLoginScreen = (uniqueId: string = WEB): UseLoginScreenReturnType => {
  const ref = useRef([]);
  const [hidePassword, setHidePassword] = useState(true);
  const dispatch = useDispatch();
  const { loading } = useSelector(getAuth);

  const initialValue = { email: '', password: '' } as FormikValuesType;

  const loginData: FormikFieldFormDataType = [
    {
      id: 1,
      title: strings.loginScreen.emailTitle,
      valuesName: 'email',
      placeHolder: strings.loginScreen.emailPlaceholder,
      keyboardType: 'email-address',
    },
    {
      id: 2,
      title: strings.loginScreen.passwordTitle,
      valuesName: 'password',
      placeHolder: strings.loginScreen.passwordPlaceholder,
      keyboardType: 'default',
      fieldStatus: hidePassword,
      setFieldStatus: setHidePassword,
      icon: require('@root/assets/icons/invisibleInput.png'),
      changedIcon: require('@root/assets/icons/visibleInput.png'),
      webIcon: require('@root/web/assets/icons/hidePassword.svg'),
      webChangedIcon: require('@root/web/assets/icons/visiblePassword.svg'),
    },
  ];

  const signInSubmit = (data: FormikValuesType, onSuccessCallback?: () => void) => {
    dispatch(signInRequestAction(data, uniqueId, onSuccessCallback));
  };
  return [signInSubmit, loginData, initialValue, loading, ref];
};

import { IActionType } from '@root/redux/types';
import { CLEAR_USER_DATA_STATE, FETCH_JOB_REQUESTS_BY_INDUSTRY_SUCCESS } from '@root/general/consts/actions';
import { JobRequestsByIndustry } from '@root/general/types';

export type JobRequestsReducer = {
  jobRequestsByIndustry: JobRequestsByIndustry;
};

const INITIAL_STATE: JobRequestsReducer = {
  jobRequestsByIndustry: {},
};

export const jobRequestsReducer = (state = INITIAL_STATE, action: IActionType) => {
  switch (action.type) {
    case FETCH_JOB_REQUESTS_BY_INDUSTRY_SUCCESS:
      const { industryId, jobRequests, lasPage } = action.payload;
      return {
        ...state,
        jobRequestsByIndustry: { ...state.jobRequestsByIndustry, [industryId]: { jobRequests, lasPage } },
      };
    case CLEAR_USER_DATA_STATE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
};

import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormikFieldFormDataType, FormikValuesType } from '@root/mobile/types/inputTypes';
import { strings } from '@root/strings';
import { changeEmailAction } from '@root/redux/actions';

enum fieldIds {
  email = 1,
  password = 2,
}

type useChangeEmailScreenReturnType = [
  FormikValuesType,
  FormikFieldFormDataType,
  (changeEmailData: FormikValuesType, obj: any) => void,
  boolean,
];

const { emailTitle, emailPlaceHolder, passwordTitle, passwordPlaceholder } = strings.changeEmailScreen;

export const useChangeEmailScreen = (successCallback?: () => void): useChangeEmailScreenReturnType => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [hideInitialPassword, setHideInitialPassword] = useState<boolean>(true);

  const initialValue = {
    email: '',
    password: '',
  } as FormikValuesType;

  const changeEmailFormData: FormikFieldFormDataType = [
    {
      id: fieldIds.email,
      title: emailTitle,
      valuesName: 'email',
      placeHolder: emailPlaceHolder,
      keyboardType: 'email-address',
    },
    {
      id: fieldIds.password,
      title: passwordTitle,
      valuesName: 'password',
      placeHolder: passwordPlaceholder,
      keyboardType: 'default',
      fieldStatus: hideInitialPassword,
      setFieldStatus: setHideInitialPassword,
      icon: require('@root/assets/icons/invisibleInput.png'),
      changedIcon: require('@root/assets/icons/visibleInput.png'),
      webIcon: require('@root/web/assets/icons/hidePassword.svg'),
      webChangedIcon: require('@root/web/assets/icons/visiblePassword.svg'),
      sm: 12,
    },
  ];

  const callback = useCallback(
    (isSuccess: boolean) => {
      isSuccess && successCallback && successCallback();
      setLoading(false);
    },
    [successCallback],
  );

  const onChangeEmail = (changeEmailData: FormikValuesType) => {
    setLoading(true);
    dispatch(changeEmailAction({ changeEmailData, callback }));
  };

  return [initialValue, changeEmailFormData, onChangeEmail, isLoading];
};

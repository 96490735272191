import { capitalize } from '@root/utils/capitalize';

export const arrayToString = (array: Array<string> | undefined) => {
  if (array) {
    return capitalize(
      array
        .toString()
        .split(',')
        .join(', '),
    )
      .split(' ')
      .join(' ');
  } else {
    return '';
  }
};

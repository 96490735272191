import { MessagesResponse } from '@root/general/types';
import { put } from 'redux-saga/effects';
import { SHOW_TOAST } from '@root/general/consts/actions';
import { ToastTypes } from '@root/redux/actions/commonActions';

export function* commonNotificationHandler(messages?: MessagesResponse) {
  const message = messages?.join('\n') ?? 'Successful';

  return yield put({
    type: SHOW_TOAST,
    payload: {
      message,
      type: ToastTypes.Notification,
    },
  });
}

import React from 'react';
import { RootNavigator } from '@root/web/navigators';
import { useAuthLoading } from '@root/hooks/useAuthLoading';
import { CustomLoader } from '@root/web/components/CustomLoader';
import { AuthError } from '@root/web/components/AuthError';

export const AuthLoadingScreen = () => {
  const [initialisedLoading, initialisedError, initialiseApp, token] = useAuthLoading();
  if (initialisedLoading) {
    return <CustomLoader />;
  }

  if (initialisedError) {
    return <AuthError onPress={initialiseApp} />;
  }

  return <RootNavigator isAuth={!!token} />;
};

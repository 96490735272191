import { Job } from '@root/general/types';
import { ActionType, AdditionActionType } from '@root/hooks/useFlatListPagination';
import { useSelector } from 'react-redux';
import { getJobListingBySpecificationId, getJobListingFilter, getUser } from '@root/selectors';
import { useCallback } from 'react';
import { fetchAdditionalJobListingAction, fetchJobListingAction, refreshJobListingAction } from '@root/redux/actions';

type UseJobListingScreenReturnType = [Array<Job>, number, ActionType, ActionType, AdditionActionType<Job>];

export const useJobListingScreen = (specificationId: number): UseJobListingScreenReturnType => {
  const filter = useSelector(getJobListingFilter);
  const { id: userId } = useSelector(getUser);
  const jobListing = useSelector(getJobListingBySpecificationId(specificationId));
  const { jobs, lastPage } = jobListing;

  const fetchAction = useCallback(
    (fetchCallback: () => void) => {
      return fetchJobListingAction({
        id: specificationId,
        ownerId: userId,
        filter,
        callback: fetchCallback,
      });
    },
    [specificationId, userId, filter],
  );

  const refreshAction = useCallback(
    (refreshCallback: () => void) => {
      return refreshJobListingAction({
        id: specificationId,
        ownerId: userId,
        filter,
        callback: refreshCallback,
      });
    },
    [specificationId, userId, filter],
  );

  const fetchAdditionAction = useCallback(
    (page: number, fetchAdditionCallback: (isSuccess: boolean, additionalItems: any) => void) => {
      return fetchAdditionalJobListingAction({
        id: specificationId,
        ownerId: userId,
        filter,
        page,
        callback: fetchAdditionCallback,
      });
    },
    [specificationId, userId, filter],
  );

  return [jobs, lastPage, fetchAction, refreshAction, fetchAdditionAction];
};

import React from 'react';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { Grid } from '@material-ui/core';
import { strings } from '@root/strings';
import { useFlatListPagination } from '@root/hooks/useFlatListPagination';
import { useStyles } from './styles';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { route } from '@root/web/consts';
import { ProfileScreensProps, profileTabsMode } from '@root/web/screens/ProfileScreen/types';
import { useMyFarmsScreen } from '@root/hooks/useMyFarmsScreen';
import { FarmType } from '@root/general/types';
import { MyLocationsActionScreen } from '@root/web/screens/ProfileScreen/components/MyLocationsActionScreen';
import { farmActionModalMode } from '@root/mobile/screens/FarmActionModalScreen/types';
import { LocationCard } from '@root/web/screens/ProfileScreen/components/MyLocations/components/LocationCard';
import { CustomScrollComponent } from '@root/web/components/CustomScrollComponent';

const { myFarmsModalScreen: string } = strings;

export const MyLocations = (props: ProfileScreensProps) => {
  const { tabMode, setTabMode } = props;
  const styles = useStyles();
  const history = useHistory();

  let location = useLocation<{ tabMode: string; location: FarmType }>();
  const selectedLocation = location?.state?.location;

  const params = useParams<{ id?: string; profileParam?: string }>();
  const { id: locationId = '' } = params;

  const [farms, lastPage, fetchAction, refreshAction, fetchAdditionAction] = useMyFarmsScreen();

  const [allLocations, isFlatListLoading, , , , onEndReached, , hasMore] = useFlatListPagination<FarmType>(
    farms,
    lastPage,
    fetchAction,
    refreshAction,
    fetchAdditionAction,
  );

  const goToAddLocation = () => {
    history.push(route.LOCATIONS, { tabMode: profileTabsMode.ADD_LOCATION });
  };

  const goToEditReference = (location: FarmType) => () => {
    history.push(`${route.LOCATIONS}/${location.id}`, { location });
  };

  const getLocationRightTab = () => {
    switch (tabMode) {
      case profileTabsMode.ADD_LOCATION:
        return <MyLocationsActionScreen mode={farmActionModalMode.ADD} setTabMode={setTabMode} />;
      case profileTabsMode.EDIT_LOCATION:
        return (
          <MyLocationsActionScreen
            key={locationId}
            mode={farmActionModalMode.EDIT}
            setTabMode={setTabMode}
            location={selectedLocation}
          />
        );
      default:
        return null;
    }
  };

  const renderLocation = (location: FarmType) => {
    const { id } = location;
    return (
      <Grid key={id || 0} item xs={12} md={6}>
        <LocationCard
          location={location}
          onPress={goToEditReference(location)}
          isSelected={id === Number(locationId)}
        />
      </Grid>
    );
  };

  return (
    <>
      <CustomScrollComponent
        isFlatListLoading={isFlatListLoading}
        onEndReached={onEndReached}
        hasMore={hasMore}
        data={allLocations}
        renderItem={renderLocation}
        defaultPrimaryText={string.defaultTextPrimary}
        defaultSecondaryText={string.defaultTextSecondary}
        header={
          <div className={styles.locationAddButtonContainer}>
            <CustomButton
              type={customButtonType.GREY}
              title={string.addLocation}
              onPress={goToAddLocation}
              icon={require('@root/web/assets/icons/add.svg')}
              maxWidth={150}
              ellipse
            />
          </div>
        }
        containerStyle={styles.scrollComponent}
      />
      {!!tabMode && getLocationRightTab()}
    </>
  );
};

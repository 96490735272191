import React from 'react';
import { useStyles } from './styles';
import { PaymentType } from '@root/general/types';
import moment from 'moment';
import { paymentStatus } from '@root/general/consts';
import { CustomCardContainer } from '@root/web/components/CustomCardContainer';
import { Tooltip } from '@material-ui/core';
import { strings } from '@root/strings';
import { getUserProfileFromOffer } from '@root/utils/getUserProfileFromOffer';
import clsx from 'clsx';

export type TransactionCardProps = {
  payment: PaymentType;
  isSelected: boolean;
  isFailed: boolean;
  onPress: () => void;
};

const { paymentTypes, laborerInfoModalScreen } = strings;

export const TransactionCard = (props: TransactionCardProps) => {
  const { payment, onPress, isSelected } = props;
  const { createdAt, status, amount, jobs = [], offers = [], userId } = payment;
  const { instantPayment, monthlyPayment } = paymentTypes;
  const styles = useStyles(props);

  const deletedUserAccount = laborerInfoModalScreen.deletedUserAccount;
  const isInstantPayment = jobs.length === 1 && jobs[0].paymentType === 0;
  const paymentType = isInstantPayment ? instantPayment : monthlyPayment;
  const allJobNames = jobs.map((job) => job.name).join(', ');

  const laborerCredentials = getUserProfileFromOffer(userId, offers[0]);
  const laborerName = laborerCredentials?.name ?? deletedUserAccount;
  const cardTitle = isInstantPayment ? laborerName : allJobNames;

  const date = moment(createdAt).format('lll');
  const amountText = amount ? amount?.toString().slice(0, -2) + '.' + amount.toString().slice(-2) : '';
  const cardTitleStyle = clsx(
    styles.transactionCardJob,
    cardTitle !== deletedUserAccount && styles.transactionCardJobTextWrap,
  );

  return (
    <CustomCardContainer
      isSelected={isSelected}
      onPress={onPress}
      containerClasses={styles.containerClasses}
      contentClasses={styles.contentClasses}
    >
      <div className={styles.transactionCardContainer}>
        <div className={styles.transactionCardLeftContainer}>
          <Tooltip
            title={allJobNames}
            enterDelay={300}
            enterNextDelay={200}
            placement='bottom'
            arrow
            classes={{ tooltip: styles.tooltip, arrow: styles.tooltipArrow }}
            disableHoverListener={isInstantPayment}
          >
            <div className={styles.transactionCardFullWidth}>
              <div className={styles.transactionCardTitleContainer}>
                <span className={cardTitleStyle}>{cardTitle}</span>
              </div>
            </div>
          </Tooltip>
          <span className={styles.transactionCardDate}>{date}</span>
          <span className={styles.transactionCardStatus}>{paymentStatus[status]}</span>
        </div>
        <div className={styles.transactionCardRightContainer}>
          <span className={styles.paymentMethodTitle}>{paymentType}</span>
          <span className={styles.transactionCardAmount}>${amountText}</span>
        </div>
      </div>
    </CustomCardContainer>
  );
};

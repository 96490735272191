import { FetchAdditionalOffersPayload, FetchOffersPayload } from '@root/redux/actions/types';
import {
  FETCH_ADDITIONAL_OFFERS_REQUEST,
  FETCH_OFFERS_REQUEST,
  REFRESH_OFFERS_REQUEST,
} from '@root/general/consts/actions';

export const fetchOffersAction = (payload: FetchOffersPayload) => ({
  type: FETCH_OFFERS_REQUEST,
  payload,
});

export const refreshOffersAction = (payload: FetchOffersPayload) => ({
  type: REFRESH_OFFERS_REQUEST,
  payload,
});

export const fetchAdditionalOffersAction = (payload: FetchAdditionalOffersPayload) => ({
  type: FETCH_ADDITIONAL_OFFERS_REQUEST,
  payload,
});

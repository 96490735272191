import { Offer } from '@root/general/types';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { ActionType, AdditionActionType } from '@root/hooks/useFlatListPagination';
import { OFFERS_BY_STATUS } from '@root/general/consts';
import { getOffersFilterById } from '@root/utils/getOffersFilterById';
import { useSelector } from 'react-redux';
import { getProposalsByFilterName } from '@root/selectors';
import { fetchAdditionalProposalsAction, fetchProposalsAction, refreshProposalsAction } from '@root/redux/actions';

type UseProposalsScreenReturnType = [
  Array<Offer>,
  number,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  Dispatch<SetStateAction<number>>,
  string,
  ActionType,
  ActionType,
  AdditionActionType<Offer>,
  number,
];

export const useProposalsScreen = (jobId: number | null): UseProposalsScreenReturnType => {
  const [proposalsFilterId, setProposalsFilterId] = useState(OFFERS_BY_STATUS.ALL.id);
  const [proposalsStatesModalVisible, setProposalsStatesModalVisible] = useState(false);

  const { name: proposalsFilterName } = getOffersFilterById(proposalsFilterId);

  const { proposals, lastPage } = useSelector(getProposalsByFilterName(proposalsFilterName));

  const fetchAction = useCallback(
    (fetchCallback: () => void) => {
      return fetchProposalsAction({ proposalsFilterId, jobId, callback: fetchCallback });
    },
    [proposalsFilterId, jobId],
  );

  const refreshAction = useCallback(
    (refreshCallback: () => void) => {
      return refreshProposalsAction({ proposalsFilterId, jobId, callback: refreshCallback });
    },
    [proposalsFilterId, jobId],
  );

  const fetchAdditionAction = useCallback(
    (page: number, fetchAdditionCallback: (isSuccess: boolean, additionalItems: any) => void) => {
      return fetchAdditionalProposalsAction({ proposalsFilterId, jobId, page, callback: fetchAdditionCallback });
    },
    [proposalsFilterId, jobId],
  );

  return [
    proposals,
    lastPage,
    proposalsStatesModalVisible,
    setProposalsStatesModalVisible,
    setProposalsFilterId,
    proposalsFilterName,
    fetchAction,
    refreshAction,
    fetchAdditionAction,
    proposalsFilterId,
  ];
};

import { all, takeLatest } from 'redux-saga/effects';

// @ts-ignore
import { AllEffect } from '@redux-saga/core/types/effects';
import { generalSagas } from '@root/redux/sagas/general';
import { CALL_COMMON_ERROR_HANDLER, CALL_UNEXPECTED_ERROR_HANDLER, SHOW_TOAST } from '@root/general/consts/actions';
import { showWebToast } from '@root/redux/sagas/toastWebSaga';
import { webCommonErrorHandler, webUnexpectedErrorHandler } from '@root/redux/sagas/webErrorHandlerSaga';
import { API_URL } from '@root/web/consts';

const webSagas: AllEffect<never> = [
  takeLatest(SHOW_TOAST, showWebToast),
  takeLatest(CALL_COMMON_ERROR_HANDLER, webCommonErrorHandler),
  takeLatest(CALL_UNEXPECTED_ERROR_HANDLER, webUnexpectedErrorHandler),
];

export const webRootSaga = function* root() {
  yield all([...generalSagas(API_URL), ...webSagas]);
};

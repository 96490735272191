import { makeStyles } from '@material-ui/core/styles';
import { CustomButtonProps, customButtonType } from '@root/web/components/CustomButton/index';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles({
  buttonContainer: (props: CustomButtonProps) => {
    const { marginTop, maxWidth, ellipse, disabledTopBorder } = props;

    const topBorder = disabledTopBorder
      ? { borderTopLeftRadius: disabledTopBorder ? 0 : 5, borderTopRightRadius: disabledTopBorder ? 0 : 5 }
      : undefined;

    return {
      marginTop: marginTop ? marginTop : 0,
      maxWidth: maxWidth ? maxWidth : '100%',
      borderRadius: ellipse ? 36 : 5,
      ...topBorder,
    };
  },
  textButtonContainer: (props: CustomButtonProps) => {
    const { marginTop } = props;
    return {
      marginTop: marginTop ? marginTop : 0,
    };
  },
});

const getButtonStyles = (type: customButtonType) => {
  switch (type) {
    case customButtonType.RED:
      return {
        root: {
          backgroundColor: colors.red,
        },
        disabled: {
          color: `${colors.white} !important `,
          backgroundColor: `${colors.red} !important `,
        },
        contained: {
          '&:hover': {
            backgroundColor: colors.darkRed,
          },
        },
        label: {
          color: colors.white,
        },
      };
    case customButtonType.GREEN:
      return {
        root: {
          backgroundColor: colors.celery,
        },
        disabled: {
          color: `${colors.white} !important `,
          backgroundColor: `${colors.celery} !important `,
        },
        contained: {
          '&:hover': {
            backgroundColor: colors.celery,
            opacity: 0.8,
          },
        },
        label: {
          color: colors.white,
        },
      };
    case customButtonType.GREY:
      return {
        root: {
          backgroundColor: colors.athensGrayOpacity3,
        },
        disabled: {
          color: `${colors.red} !important `,
          backgroundColor: `${colors.athensGrayOpacity3} !important `,
        },
        contained: {
          '&:hover': {
            backgroundColor: colors.athensGrayOpacity3,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: colors.red,
          },
        },
        label: {
          color: colors.red,
        },
      };
    case customButtonType.WHISPER:
      return {
        root: {
          backgroundColor: colors.athensGrayOpacity3,
        },
        disabled: {
          color: `${colors.red} !important `,
          backgroundColor: `${colors.athensGrayOpacity3} !important `,
        },
        contained: {
          '&:hover': {
            backgroundColor: colors.athensGrayOpacity3,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: colors.red,
          },
        },
        label: {
          color: colors.red,
        },
      };

    case customButtonType.WHITE:
      return {
        root: {
          backgroundColor: colors.white,
        },
        disabled: {
          color: `${colors.red} !important `,
          backgroundColor: `${colors.white} !important `,
        },
        contained: {
          '&:hover': {
            backgroundColor: colors.white,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: colors.red,
          },
        },
        label: {
          color: colors.red,
        },
      };
    default:
      return {
        root: {},
        disabled: {},
        contained: {},
        label: {},
      };
  }
};

export const useButtonStyles = makeStyles({
  root: ({ type }: CustomButtonProps) => {
    return getButtonStyles(type).root;
  },
  disabled: ({ type }: CustomButtonProps) => {
    return getButtonStyles(type).disabled;
  },
  contained: ({ type }: CustomButtonProps) => {
    return getButtonStyles(type).contained;
  },
  label: ({ type }: CustomButtonProps) => {
    return getButtonStyles(type).label;
  },
});

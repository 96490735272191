import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { StyledListItemProps } from './index';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    listItem: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      ...theme.mixins.toolbar,
    },
    imageContainer: {
      display: 'flex',
      width: 55,
      justifyContent: 'center',
    },
    imageIcon: ({ isActive = false }: StyledListItemProps) => {
      return {
        filter: isActive ? 'brightness(1000) saturate(100%)' : '',
      };
    },
    listItemText: ({ isMenu, isActive }: StyledListItemProps) => {
      const textColor = isMenu ? colors.darkGrey : isActive ? colors.white : colors.grey;

      return {
        color: textColor,
        fontSize: isMenu ? 24 : 17,
        fontFamily: fonts.IBMPlexMonoSemiBold,
        lineHeight: '22px',
        letterSpacing: -0.41,
        textTransform: isMenu ? 'capitalize' : 'uppercase',
      };
    },
    closeMenuIcon: {
      position: 'absolute',
      right: -1,
      backgroundColor: colors.red,
      height: 24,
      width: 12,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '5px 0px 0px 5px',
    },
    tooltip: {
      padding: '6px 10px',
      fontSize: 14,
      fontFamily: fonts.IBMPlexMonoMedium,
      lineHeight: '22px',
      letterSpacing: -0.41,
      color: colors.black,
      textTransform: 'uppercase',
      backgroundColor: colors.white,
      marginLeft: 2,
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
    },
    tooltipArrow: {
      color: colors.white,
    },
  });
});

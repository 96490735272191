import { IState } from '@root/redux/types';
import { createSelector } from 'reselect';
import { getJobStatusByStatusId } from '@root/mobile/utils/getJobStatusByStatusId';
import { defaultLaborerFilter } from '@root/redux/reducers/laborersReducer';
import { transactionHistoryTab } from '@root/mobile/navigators/TransactionHistoryTopNavigator/types';
import { ArraySpecifications } from '@root/general/types';

export const getAuth = (state: IState) => state.auth;

export const getRoles = createSelector(getAuth, (auth) => auth.roles);

export const getUser = createSelector(getAuth, (auth) => auth.user);

export const getFarms = (state: IState) => state.farms;

export const getStates = (state: IState) => state.location.states;

export const getIndustries = (state: IState) => state.industries;

export const getIndustryById = (industryId: number | null) => {
  return createSelector(getIndustries, ({ allIndustries }) => {
    return allIndustries.find(({ id }) => industryId === id);
  });
};

export const getSpecificationById = (specificationId: number | null) => {
  return createSelector(getIndustries, ({ allSpecifications }) =>
    allSpecifications.find(({ id }) => specificationId === id),
  );
};

export const getSpecificationsByIndustryId = (industryId: number | null) => {
  return createSelector(getIndustries, ({ allSpecifications }) => {
    return allSpecifications.filter((specification) => {
      return industryId === specification.industryId;
    });
  });
};

export const getSpecificationByIndustryIdsWithTitle = (industryIds: Array<number>) => {
  return createSelector(getIndustries, ({ allSpecifications, allIndustries }) => {
    const industryWithSpecificationsArray = allIndustries.map((industry) => {
      const filteredSpecifications = allSpecifications.filter((specification) => {
        return industry.id === specification.industryId;
      });

      const filteredSpecificationsWithColor = filteredSpecifications.map((specification) => {
        return { ...specification, color: industry.color, industry };
      });

      return { industry: industry, data: filteredSpecificationsWithColor };
    });

    return industryWithSpecificationsArray.filter(({ industry }) => {
      return industryIds.some((id) => id === industry.id);
    });
  });
};

export const getJobListingFilter = (status: IState) => {
  return status.jobListing.filter;
};

export const getJobListing = (state: IState) => state.jobListing.jobsBySpecifications;

export const getFilter = (state: IState) => state.mapFilter;

export const getMapFilter = createSelector(getFilter, getUser, ({ mapFilter }, user) => {
  const { filterSpecificationIds = [], filterCity } = mapFilter;
  const { specifications, city: userCity, state } = user;

  const specificationsArray = (specifications?: ArraySpecifications) => {
    return specifications?.length ? specifications.map((specification) => specification.id) : [];
  };

  const specificationIds =
    filterSpecificationIds.length > 0 ? filterSpecificationIds : specificationsArray(specifications);

  const city = filterCity ? filterCity : { ...userCity, state };
  return { filterSpecificationIds: specificationIds, filterCity: city };
});

export const getJobListingBySpecificationId = (specificationId: number) => {
  return createSelector(getJobListing, (jobsSpecifications) => {
    return jobsSpecifications[specificationId] || {};
  });
};

export const getLaborersFilter = (state: IState) => {
  const { filter, currentIndustryId } = state.laborers;
  return currentIndustryId
    ? { filter: filter[currentIndustryId] || defaultLaborerFilter, currentIndustryId }
    : { filter: defaultLaborerFilter, currentIndustryId };
};

export const getLaborersFilterById = (id: number) => (state: IState) => {
  const { filter } = state.laborers;
  return filter && filter[id] ? filter[id] : defaultLaborerFilter;
};

export const getLaborersByIndustry = (state: IState) => state.laborers.laborersByIndustry;

export const getLaborersByIndustryId = (industryId: number) => {
  return createSelector(getLaborersByIndustry, (laborersByIndustry) => laborersByIndustry[industryId] || {});
};

export const getJobRequestsByIndustry = (state: IState) => state.jobRequests.jobRequestsByIndustry;

export const getJobOffers = (state: IState) => state.jobOffers.offers;

export const getJobOffersByTabName = (offerStatusName: string) => {
  return createSelector(getJobOffers, (jobOffers) => jobOffers[offerStatusName]);
};

export const getJobRequestsByIndustryId = (industryId: number) => {
  return createSelector(getJobRequestsByIndustry, (jobRequestsByIndustry) => jobRequestsByIndustry[industryId] || {});
};

export const getPostedJobs = (state: IState) => state.postedJobs.postedJobs;

export const getPostedJobsByStateId = (jobStatusId: number) => {
  const { name } = getJobStatusByStatusId(jobStatusId);
  return createSelector(getPostedJobs, (postedJobs) => postedJobs[name]);
};

export const getProposals = (state: IState) => state.proposals;

export const getProposalsByFilterName = (proposalsStateFilterName: string) => {
  return createSelector(getProposals, (proposals) => proposals[proposalsStateFilterName]);
};

export const getPaymentCards = (state: IState) => {
  return state.paymentCards.cards;
};

export const getPayments = (state: IState) => {
  return state.paymentCards.payments;
};

export const getPaymentsByTabName = (tabName: transactionHistoryTab) => {
  return createSelector(getPayments, (payments) => payments[tabName]);
};

export const getReferences = (state: IState) => {
  return state.references;
};

export const getNavigationBarWidth = (state: IState) => {
  return state.navigationBar.navigationBarWidth;
};

export const getFieldReps = (state: IState) => {
  return state.auth.fieldReps;
};

import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '@root/general/fonts';
import { colors } from '@root/general/colors';
import { JobValueProps } from '@root/general/types';

export const useStyles = makeStyles(() => ({
  container: ({ jobName }: JobValueProps) => {
    return {
      display: jobName ? 'flex' : 'none',
    };
  },
  jobName: {
    textAlign: 'center',
    fontFamily: fonts.IBMPlexMonoSemiBold,
    fontSize: 10,
    letterSpacing: -0.41,
    color: colors.red,
    textTransform: 'uppercase',
    borderRadius: 30,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.redOpacity2,
    padding: '4px 8px',
  },
}));

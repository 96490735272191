import React from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { useStyles } from './styles';
import { capitalize } from '@root/utils/capitalize';
import clsx from 'clsx';

export type CustomStatusPickerProps = {
  setRespondedValue?: (value: any) => void;
  data: Array<{ id: any; name: string }>;
  respondedValue: any;
  containerClasses?: string;
};

export const CustomStatusPicker = (props: CustomStatusPickerProps) => {
  const { data, setRespondedValue, respondedValue, containerClasses = '' } = props;

  const styles = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    // @ts-ignore
    setRespondedValue(event.target.value);
  };

  return (
    <FormControl variant='outlined' size={'small'} className={clsx(styles.container, containerClasses)}>
      <Select value={respondedValue} onChange={handleChange}>
        {data.map((item) => (
          <MenuItem key={item.id} value={item.id} className={styles.itemText}>
            {capitalize(item.name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

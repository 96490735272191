import React, { useState } from 'react';
import { useStyles } from './styles';
import Scrollbars from 'react-custom-scrollbars';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { strings } from '@root/strings';
import { Grid } from '@material-ui/core';
import { useGetCards } from '@root/mobile/hooks/useGetCards';
import { PaymentCard } from '@root/web/screens/ProfileScreen/components/MyCards/components/PaymentCard';
import { usePaymentCardsWeb } from '@root/web/hooks/usePaymentCardWeb';
import { CustomLoader } from '@root/web/components/CustomLoader';
import { AddCardModal } from './components/AddCardModal';
import { WarningModal } from '@root/web/components/WarningModal';
import { cardDefaultType } from '@root/general/consts';
import { EmptyListComponent } from '@root/web/components/EmptyListComponent';

const { myCardsScreen: string } = strings;

export const MyCards = () => {
  const styles = useStyles();

  const [, onAddNewCardWeb, isLoading, onPressSetDefault, onDeleteCard] = usePaymentCardsWeb();

  const [isCardModalVisible, setCardModalVisible] = useState(false);
  const [isDeleteCardModalVisible, setDeleteCardModalVisible] = useState(false);
  const [cardIdToDelete, setCardIdToDelete] = useState<string | null>();

  const [cards] = useGetCards();

  const renderMyCards = () => {
    return (
      <Grid className={styles.gridContainer} container spacing={3}>
        {cards.map((card) => {
          const { last4, brand, expMonth, expYear, id: cardId, isDefault } = card;

          return (
            <Grid className={styles.cardContainer} key={cardId || 0} item xs={12} md={6} lg={4} xl={3}>
              <PaymentCard
                last4={last4}
                brand={brand}
                expYear={expYear}
                expMonth={expMonth}
                isSelected={isDefault === cardDefaultType.SELECTED}
                onPress={onPressSetDefault(cardId)}
              />
              <CustomButton
                type={customButtonType.GREY}
                title={string.removeCard}
                onPress={() => {
                  setDeleteCardModalVisible(true);
                  setCardIdToDelete(cardId);
                }}
                maxWidth={170}
                marginTop={10}
                icon={require('@root/web/assets/icons/delete.svg')}
                ellipse
                className={styles.removeButtonStyle}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <div className={styles.myCardsContainer}>
      <div className={styles.myCardsAddButtonContainer}>
        <CustomButton
          type={customButtonType.GREY}
          title={string.addCard}
          maxWidth={140}
          onPress={() => setCardModalVisible(true)}
          icon={require('@root/web/assets/icons/add.svg')}
          ellipse
        />
      </div>
      {isCardModalVisible && (
        <AddCardModal
          onAddNewCardWeb={onAddNewCardWeb}
          isModalVisible={isCardModalVisible}
          setModalVisible={setCardModalVisible}
        />
      )}
      <div className={styles.scrollbarContainer}>
        {cards && cards.length > 0 ? (
          <Scrollbars
            renderTrackHorizontal={(props) => <div {...props} className={styles.horizontalScroll} />}
            renderThumbHorizontal={(props) => <div {...props} className={styles.horizontalScroll} />}
          >
            {renderMyCards()}
          </Scrollbars>
        ) : (
          <EmptyListComponent primaryText={string.defaultTextPrimary} />
        )}
      </div>
      {isLoading && <CustomLoader />}
      {isDeleteCardModalVisible && cardIdToDelete && (
        <WarningModal
          expression={string.removeCard}
          isModalVisible={isDeleteCardModalVisible}
          setModalVisible={setDeleteCardModalVisible}
          onApply={() => {
            onDeleteCard(cardIdToDelete)();
            setCardIdToDelete(null);
          }}
        />
      )}
    </div>
  );
};

import React from 'react';
import { useJobDetailsModalScreen } from '@root/mobile/hooks/useJobDetailsModalScreen';
import { LaborerJobDetails } from '@root/web/screens/JobListingScreen/components/LaborerJobDetails';
import { JobDetailsMap } from '@root/web/screens/JobListingScreen/components/JobDetailsMap';
import { useStyles } from './styles';
import { JobDetailsCallbackProps } from '@root/mobile/screens/JobOffersScreen/types';
import { ToastContainer } from 'react-toastify';
import { WEB_NOTIFICATION_TIMEOUT } from '@root/general/consts';

type JobDetailsScreenProps = {
  jobId: number;
  goBackCallback: () => void;
  jobDetailsCallback?: (jobCallbackProps: JobDetailsCallbackProps) => void;
  updateNotificationCallback?: () => void;
};

export const JobDetailsScreen = (props: JobDetailsScreenProps) => {
  const { jobId, goBackCallback, jobDetailsCallback, updateNotificationCallback } = props;

  const styles = useStyles();

  const goToReviewLaborers = () => () => {};

  const [job, , , isLoading, , mode, , , , onPressJobAction] = useJobDetailsModalScreen(
    jobId,
    goBackCallback,
    goToReviewLaborers,
    jobDetailsCallback,
    updateNotificationCallback,
  );

  const { latitude, longitude } = job.city;

  return (
    <div className={styles.container}>
      <LaborerJobDetails job={job} isLoading={isLoading} mode={mode} onPressJobAction={onPressJobAction} />
      {latitude && longitude && <JobDetailsMap latitude={latitude} longitude={longitude} />}
      <ToastContainer autoClose={WEB_NOTIFICATION_TIMEOUT} />
    </div>
  );
};

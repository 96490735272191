import { ModalOfferType } from '@root/hooks/usePaymentModal';

export const searchOffer = (value: string, offer?: ModalOfferType) => {
  const lowerCaseName = offer?.laborerName.toLocaleLowerCase();
  const lowerCaseValue = value.toLocaleLowerCase();

  return lowerCaseName?.includes(lowerCaseValue);
};

export const searchJobs = (value: string, jobName: string) => {
  const lowerCaseValue = value.toLowerCase();
  const lowerCaseJobName = jobName.toLowerCase();

  return lowerCaseJobName.includes(lowerCaseValue);
};

import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { PaymentCardDataType, PaymentType } from '@root/general/types';
import { fetchPayment, fetchPaymentCardsAction } from '@root/redux/actions';
import { cardDefaultType } from '@root/general/consts';
import { useGetCards } from '@root/mobile/hooks/useGetCards';

type UsePaymentSystemScreenReturnType = [PaymentType | null, PaymentCardDataType | null, boolean];

export const usePaymentSystemScreen = (paymentId: number): UsePaymentSystemScreenReturnType => {
  const dispatch = useDispatch();

  const [payment, setPayment] = useState<PaymentType | null>(null);
  const [isLoading, setLoading] = useState(false);

  const [cards] = useGetCards(false);

  useEffect(() => {
    const callback = (isSuccess: boolean, payment?: PaymentType) => {
      isSuccess && payment && setPayment(payment);
      setLoading(false);
    };

    setLoading(true);
    dispatch(fetchPayment({ paymentId, callback }));
    dispatch(fetchPaymentCardsAction({}));
  }, [dispatch, paymentId]);

  const defaultCard = cards.find((item) => item.isDefault === cardDefaultType.SELECTED) || null;

  return [payment, defaultCard, isLoading];
};

import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStyles } from './styles';
import { strings } from '@root/strings';
import { route } from '@root/web/consts';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';

const { agHeader: string } = strings;

export type AuthHeaderProps = {
  goBackVisible?: boolean;
  createNewAccountVisible?: boolean;
};

export const AuthHeader = (props: AuthHeaderProps) => {
  let location = useLocation();
  let history = useHistory();
  const styles = useStyles(props);

  const goBack = () => {
    history.goBack();
  };

  const goToChooseRole = () => {
    history.push(route.CHOOSE_ROLE, location.state);
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.goBackContainer}>
        <img
          onClick={goBack}
          className={styles.goBack}
          src={require('src/web/assets/icons/goBack.svg')}
          title='Go back'
          alt='Go back'
        />
      </div>
      <div className={styles.headerLogoImageContainer}>
        <img
          className={styles.headerLogoImage}
          src={require('src/web/assets/images/logo.svg')}
          title='AgButler'
          alt='AgButler'
        />
      </div>
      <div className={styles.createNewAccountContainer}>
        <CustomButton
          type={customButtonType.GREY}
          className={styles.createNewAccount}
          title={string.createNewAccount}
          onPress={goToChooseRole}
          ellipse
          maxWidth={180}
        />
      </div>
    </div>
  );
};

import { useDispatch } from 'react-redux';
import { SecretDataType } from '@root/general/types';
import {
  OnAddNewCardCallback,
  OnPayPressCallback,
  usePaymentCards,
  UsePaymentCardsType,
} from '@root/hooks/usePaymentCards';
import { fetchSetupSecretAction } from '@root/redux/actions';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { strings } from '@root/strings';

export const usePaymentCardsWeb = (): UsePaymentCardsType => {
  const dispatch = useDispatch();

  const stripe = useStripe();
  const elements = useElements();

  const onAddNewCardWeb: OnAddNewCardCallback = async (user) => {
    if (!stripe || !elements) {
      return { setupIntentStatus: '', errorMessage: strings.warnings.stripeNotLoaded };
    }

    try {
      const { secret: clientSecret }: SecretDataType = await new Promise((resolve, reject) => {
        dispatch(fetchSetupSecretAction({ resolve, reject }));
      });

      const { name, email, zipCode: postalCode, state, city, phone } = user;
      const stateName = state?.name ?? '';
      const cityName = city?.name ?? '';

      const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          //@ts-ignore
          card: elements.getElement(CardNumberElement),
          billing_details: {
            email,
            name,
            phone,
            address: { state: stateName, city: cityName, postal_code: postalCode, country: 'US' },
          },
        },
      });

      return { setupIntentStatus: setupIntent?.status ?? '', errorMessage: error?.message ?? '' };
    } catch (error) {
      return {
        setupIntentStatus: '',
        errorMessage: error ? String(error).replace('Error: ', '') : strings.warnings.commonWarning,
      };
    }
  };

  const onPayPressWeb: OnPayPressCallback = async (paymentId, paymentMethodId, clientSecret) => {
    if (!stripe || !elements) {
      return { paymentIntentStatus: '', errorMessage: strings.warnings.stripeNotLoaded };
    }

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodId,
    });

    return { paymentIntentStatus: paymentIntent?.status ?? '', errorMessage: error?.message ?? '' };
  };

  return usePaymentCards(onAddNewCardWeb, onPayPressWeb);
};

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles({
  mapControlsContainer: {
    position: 'fixed',
    bottom: 30,
    right: 30,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 3,
  },
  mapControlButton: {
    backgroundColor: colors.white,
    height: 42,
    width: 42,
    borderRadius: 3,
  },
  mapCenterButton: {
    marginBottom: 10,
  },
  mapZoomInButton: {
    borderBottomColor: colors.athensGray,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
  },
  mapGoBackButton: {
    position: 'absolute',
    bottom: 20,
    left: 30,
    fontSize: 15,
  },
  mapGoBackIcon: {
    filter: 'brightness(1000%)',
    width: 23,
  },
});

import { Api, CustomApiResponse } from '@root/services/api';
import { call, put } from 'redux-saga/effects';
import {
  CALL_COMMON_ERROR_HANDLER,
  CALL_UNEXPECTED_ERROR_HANDLER,
  DELETE_POSTED_JOB_SUCCESS,
  FETCH_POSTED_JOBS_SUCCESS,
  SET_NEW_JOB,
  EDIT_POSTED_JOB_SUCCESS,
} from '@root/general/consts/actions';
import {
  CreateNewJobAction,
  DeletePostedJobAction,
  FetchPostedJobsAction,
  FetchAdditionalPostedJobsAction,
  EditPostedJobAction,
  FetchJobAction,
  AddReviewsAction,
} from '@root/redux/actions/types';
import { JobArray, ErrorResponse, Job, OfferArray, ReviewsArray } from '@root/general/types';
import { commonNotificationHandler } from '@root/redux/sagas/helpers';
import { isArray } from 'lodash';

const DEFAULT_PAGE = 1;

export function* fetchPostedJobs(api: Api, action: FetchPostedJobsAction) {
  const { jobStatus, callback } = action.payload;

  try {
    const response: CustomApiResponse<JobArray, ErrorResponse> = yield call(api.fetchUserJobs, jobStatus, DEFAULT_PAGE);

    if (response.ok && response.data) {
      yield put({
        type: FETCH_POSTED_JOBS_SUCCESS,
        payload: { jobStatus, postedJobs: response.data, lastPage: response.meta?.lastPage ?? DEFAULT_PAGE },
      });
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  } finally {
    yield callback && call(callback);
  }
}

export function* fetchAdditionalPostedJobs(api: Api, action: FetchAdditionalPostedJobsAction) {
  const { jobStatus, page, callback } = action.payload;
  try {
    const response: CustomApiResponse<JobArray, ErrorResponse> = yield call(api.fetchUserJobs, jobStatus, page);

    if (response.ok && response.data) {
      yield callback && call(callback, true, response.data);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* createJob(api: Api, action: CreateNewJobAction) {
  const { data, callback } = action.payload;
  try {
    const response: CustomApiResponse<Job, ErrorResponse> = yield call(api.createNewJob, data);

    if (response.ok && response.data) {
      yield callback && call(callback, true, response.data);
      yield put({ type: SET_NEW_JOB, payload: { postedJob: response.data } });
      yield commonNotificationHandler(response.messages);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* editJob(api: Api, action: EditPostedJobAction) {
  const { data, callback } = action.payload;

  try {
    const response: CustomApiResponse<Job, ErrorResponse> = yield call(api.editPostedJob, data);

    if (response.ok && response.data) {
      yield put({ type: EDIT_POSTED_JOB_SUCCESS, payload: response.data });
      yield callback && call(callback, true, response.data);

      yield commonNotificationHandler(response.messages);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* deleteJob(api: Api, action: DeletePostedJobAction) {
  const { jobId, callback } = action.payload;

  try {
    const response: CustomApiResponse<{ messages: Array<string>; data: Job }, ErrorResponse> = yield call(
      api.deletePostedJob,
      jobId,
    );

    if (response.ok && response.data) {
      yield put({ type: DELETE_POSTED_JOB_SUCCESS, payload: jobId });
      yield commonNotificationHandler(response.messages);
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }

    yield callback && call(callback, response.ok);
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* fetchJob(api: Api, action: FetchJobAction) {
  const { jobId, userId, callback } = action.payload;

  try {
    const response: CustomApiResponse<Job, ErrorResponse> = yield call(api.fetchJob, jobId);

    if (response.ok && response.data) {
      if (userId && jobId) {
        const offerResponse: CustomApiResponse<OfferArray, ErrorResponse> = yield call(
          api.fetchUserOffersByJobIds,
          userId,
          [jobId],
        );
        if (offerResponse.ok && isArray(offerResponse.data)) {
          yield callback && call(callback, true, response.data, offerResponse.data[0]);
        }

        if (!offerResponse.ok) {
          yield callback && call(callback, false);
          yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
        }
      } else {
        yield callback && call(callback, true, response.data);
      }
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* addReviews(api: Api, action: AddReviewsAction) {
  const { reviewsArray, callback } = action.payload;

  try {
    const response: CustomApiResponse<ReviewsArray, ErrorResponse> = yield call(api.addReviews, reviewsArray);

    if (response.ok && response.messages) {
      yield callback && call(callback, response.ok);
      yield commonNotificationHandler(response.messages);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

import React, { useState } from 'react';
import { useStyles } from './styles';
import GoogleMapReact from 'google-map-react';
import { getMapOptions, GOOGLE_MAP_ZOOM, MapControls } from '@root/web/components/MapControls';
import { GOOGLE_MAP_API_KEY } from '@root/web/consts';

export type JobDetailsMapProps = {
  latitude: number;
  longitude: number;
};

export const JobDetailsMap = (props: JobDetailsMapProps) => {
  const { latitude, longitude } = props;
  const styles = useStyles(props);

  const defaultCenter = { lat: latitude, lng: longitude };

  const [zoom, setZoom] = useState(GOOGLE_MAP_ZOOM);
  const [center, setCenter] = useState(defaultCenter);

  const CustomMarker = (props: { lat: number; lng: number }) => {
    const {} = props;
    return <img src={require('@root/web/assets/icons/locationMapMarker.svg')} alt={''} className={styles.markerIcon} />;
  };

  const onLocationPress = () => {
    setZoom(GOOGLE_MAP_ZOOM);
    setCenter(defaultCenter);
  };

  return (
    <div className={styles.container}>
      {latitude && longitude && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
          zoom={zoom}
          center={center}
          options={getMapOptions}
          onChange={({ center, zoom }) => {
            setCenter(center);
            setZoom(zoom);
          }}
        >
          <CustomMarker lat={latitude} lng={longitude} />
        </GoogleMapReact>
      )}
      <MapControls zoom={zoom} setZoom={setZoom} onLocationPress={onLocationPress} />
    </div>
  );
};

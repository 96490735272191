import { createMuiTheme } from '@material-ui/core';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const theme = createMuiTheme({
  overrides: {
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
      },
    },
    MuiInputBase: {
      root: {
        '&$disabled': {
          cursor: 'pointer',
        },
      },
      input: {
        '&$disabled': {
          cursor: 'pointer',
          color: colors.black,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: colors.grey,
        },
        '&$disabled $notchedOutline': {
          borderColor: colors.athensGray,
        },
        '&$error $notchedOutline': {
          borderColor: `#f44336 !important`, //default MU error color
        },
      },
      notchedOutline: {
        borderColor: colors.athensGray,
      },
    },
    MuiInputLabel: {
      root: {
        color: colors.grey,
        fontSize: 17,
        fontFamily: fonts.SFUiDisplayMedium,
      },
      outlined: {
        zIndex: 1,
      },
    },
    MuiFormHelperText: {
      root: {
        display: 'block',
        marginBottom: 5,
      },
    },
  },
  palette: {
    secondary: {
      main: colors.black,
    },
    primary: {
      main: colors.black,
    },
  },
});

export const infoInputTheme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        '&$disabled': {
          cursor: 'default',
        },
      },
      input: {
        '&$disabled': {
          cursor: 'default',
          color: colors.black,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: colors.grey,
        },
        '&$disabled $notchedOutline': {
          borderColor: colors.athensGray,
        },
        '&$error $notchedOutline': {
          borderColor: `#f44336 !important`, //default MU error color
        },
      },
      notchedOutline: {
        borderColor: colors.athensGray,
      },
    },
    MuiInputLabel: {
      root: {
        color: colors.grey,
        fontSize: 17,
        fontFamily: fonts.SFUiDisplayMedium,
      },
      outlined: {
        zIndex: 0,
      },
    },
    MuiFormHelperText: {
      root: {
        display: 'block',
        marginBottom: 5,
      },
    },
  },
  palette: {
    secondary: {
      main: colors.black,
    },
    primary: {
      main: colors.black,
    },
  },
});

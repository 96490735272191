import React, { useState, MouseEvent, CSSProperties, useEffect } from 'react';
import { useStyles, theme, popoverTheme } from './styles';
import { Badge, Popover, ThemeProvider } from '@material-ui/core';
import { NotificationsPopover } from '@root/web/components/NotificationsPopover';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '@root/selectors';
import { colors } from '@root/general/colors';
import { BrowserView, MobileView } from 'react-device-detect';
import { CustomTabContainer } from '@root/web/components/CustomTabContainer';
import { fetchUserAction } from '@root/redux/actions';

export const MainHeader = () => {
  const styles = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserAction());
  }, [dispatch]);

  const { unreadNotificationsCount } = useSelector(getUser);
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);

  const onPressNotifications = (event: MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const isOpenNotifications = Boolean(anchorEl);
  const id = isOpenNotifications ? 'simple-popover' : undefined;

  const iconStyle: CSSProperties = { filter: isOpenNotifications ? 'brightness(1000%)' : undefined };

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.container}>
        <div className={styles.leftContainer} />
        <img src={require('src/web/assets/images/logo.svg')} alt={'AgButler'} />
        <div className={styles.rightContainer}>
          <Badge
            aria-describedby={id}
            onClick={onPressNotifications}
            invisible={!unreadNotificationsCount || isOpenNotifications}
            color={'primary'}
            variant={'dot'}
            className={styles.badge}
            style={{ backgroundColor: isOpenNotifications ? colors.red : colors.athensGray }}
          >
            <img
              style={iconStyle}
              src={require('src/web/assets/icons/notifications.svg')}
              alt={'NotificationsPopover'}
            />
          </Badge>
          <BrowserView>
            <ThemeProvider theme={popoverTheme}>
              <Popover
                className={styles.popover}
                open={isOpenNotifications}
                onClose={onClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <NotificationsPopover onClose={onClose} />
              </Popover>
            </ThemeProvider>
          </BrowserView>
          <MobileView>
            <CustomTabContainer tabPositionFixed isHidden={!isOpenNotifications}>
              <NotificationsPopover onClose={onClose} />
            </CustomTabContainer>
          </MobileView>
        </div>
      </div>
    </ThemeProvider>
  );
};

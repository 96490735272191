import { EditNotificationPayload, FetchNotificationsPayload } from '@root/redux/actions/types';
import { EDIT_NOTIFICATION_REQUEST, FETCH_NOTIFICATIONS_REQUEST } from '@root/general/consts/actions';

export const fetchNotificationsAction = (payload: FetchNotificationsPayload) => ({
  type: FETCH_NOTIFICATIONS_REQUEST,
  payload,
});

export const editNotificationAction = (payload: EditNotificationPayload) => ({
  type: EDIT_NOTIFICATION_REQUEST,
  payload,
});

import React, { ReactNode } from 'react';
import { Card, CardActionArea, CardContent } from '@material-ui/core';
import { useStyles } from './styles';
import clsx from 'clsx';

export type CustomCardContainerProps = {
  isSelected?: boolean;
  onPress?: () => void;
  children: ReactNode;
  containerClasses?: string;
  contentClasses?: string;
};

export const CustomCardContainer = (props: CustomCardContainerProps) => {
  const styles = useStyles(props);

  const { isSelected = false, onPress, children, containerClasses = '', contentClasses = '' } = props;

  const Content = () => <CardContent className={clsx(styles.cardContent, contentClasses)}>{children}</CardContent>;

  return (
    <Card className={clsx(styles.cardContainer, containerClasses)}>
      {onPress ? (
        <CardActionArea onClick={!isSelected ? onPress : undefined} className={styles.cardActionArea}>
          <Content />
        </CardActionArea>
      ) : (
        <Content />
      )}
    </Card>
  );
};

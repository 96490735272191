import { call, put } from 'redux-saga/effects';
import { Api, CustomApiResponse } from '@root/services/api';
import { SearchCitiesAction, GetCityByIdAction } from '@root/redux/actions/types';
import {
  CALL_COMMON_ERROR_HANDLER,
  CALL_UNEXPECTED_ERROR_HANDLER,
  FETCH_STATES_SUCCESS,
  SET_LOCATION_LOADING,
} from '@root/general/consts/actions';
import { ArrayStateType, ArrayCityType, ErrorResponse, CityType } from '@root/general/types';

export function* getStates(api: Api) {
  try {
    yield put({ type: SET_LOCATION_LOADING, payload: true });

    const response: CustomApiResponse<ArrayStateType, ErrorResponse> = yield call(api.fetchStates);

    if (response.ok && response.data) {
      yield put({ type: FETCH_STATES_SUCCESS, payload: response.data });
    }

    yield put({ type: SET_LOCATION_LOADING, payload: false });

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield put({ type: SET_LOCATION_LOADING, payload: false });
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* searchCities(api: Api, action: SearchCitiesAction) {
  const { searchValue, selectedStateId, setCities, callback } = action.payload;
  try {
    yield put({ type: SET_LOCATION_LOADING, payload: true });

    const response: CustomApiResponse<ArrayCityType, ErrorResponse> = yield call(
      api.searchCitiesByState,
      selectedStateId,
      searchValue,
    );

    if (response.ok && response.data) {
      setCities(response.data);
    }

    yield put({ type: SET_LOCATION_LOADING, payload: false });

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield put({ type: SET_LOCATION_LOADING, payload: false });
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  } finally {
    yield callback && call(callback);
  }
}

export function* getCityById(api: Api, action: GetCityByIdAction) {
  const { cityId, callback } = action.payload;

  try {
    yield put({ type: SET_LOCATION_LOADING, payload: true });

    const response: CustomApiResponse<CityType, ErrorResponse> = yield call(api.fetchCityById, cityId);

    if (response.ok && response.data) {
      yield callback && call(callback, true, response.data);
    }

    yield put({ type: SET_LOCATION_LOADING, payload: false });

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false, null);
    yield put({ type: SET_LOCATION_LOADING, payload: false });
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

import { ArrayIndustries, ArraySpecifications } from '@root/general/types';
import { arrayToString } from '@root/utils/arrayToString';

export const getSpecificationString = (specifications: ArraySpecifications = [], industries: ArrayIndustries = []) => {
  const specificationNameArray = specifications.map((specification) => {
    if (specification.name.toUpperCase() === 'OTHER') {
      const industryName = industries.find((industry) => industry.id === specification.industryId)?.name;
      return industryName ? `${industryName} ${specification.name}` : specification.name;
    }

    return specification.name;
  });

  return arrayToString(specificationNameArray);
};

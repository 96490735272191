import React, { Dispatch, forwardRef, ForwardRefRenderFunction, SetStateAction } from 'react';
import { useStyles } from './styles';
import { Modal } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';
import Scrollbars from 'react-custom-scrollbars';
import { ScrollBottomLoader } from '@root/web/components/ScrollBottomLoader';
import { modalStyle } from '@root/general/consts';

type PickerScrollableModalComponentProps = {
  items: Array<any>;
  onPressItem: (item: any) => () => void;
  isModalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  onEndReached: () => void;
  hasMore: boolean;
};

const PickerScrollableModalComponent: ForwardRefRenderFunction<any, PickerScrollableModalComponentProps> = (
  props,
  ref: any,
) => {
  const { items, onPressItem, isModalVisible, setModalVisible, onEndReached, hasMore } = props;
  const styles = useStyles();

  const closeModal = () => setModalVisible(false);

  const onPress = (item: any) => () => {
    const onPressItemFunction = onPressItem(item);
    onPressItemFunction();
    closeModal();
  };

  const renderItems = () => {
    return (
      <div className={styles.itemsContainer}>
        {items.map((item) => {
          const { id, name } = item;
          return (
            <span key={id} onClick={onPress(item)} className={styles.item}>
              {name}
            </span>
          );
        })}
      </div>
    );
  };

  return (
    <Modal
      className={styles.modalContainer}
      style={modalStyle}
      open={isModalVisible}
      onClose={closeModal}
      container={ref?.current}
    >
      <div className={styles.contentContainer}>
        <Scrollbars
          renderTrackHorizontal={(props) => <div {...props} className={styles.horizontalScroll} />}
          renderThumbHorizontal={(props) => <div {...props} className={styles.horizontalScroll} />}
        >
          <InfiniteScroll
            useWindow={false}
            pageStart={0}
            loadMore={onEndReached}
            hasMore={hasMore}
            loader={<ScrollBottomLoader key={0} />}
          >
            {renderItems()}
          </InfiniteScroll>
        </Scrollbars>
      </div>
    </Modal>
  );
};

export const PickerScrollableModal = forwardRef(PickerScrollableModalComponent);

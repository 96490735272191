import {
  FETCH_ADDITIONAL_JOB_LISTING_REQUEST,
  FETCH_JOB_LISTING_REQUEST,
  SET_JOB_LISTING_FILTER,
  REFRESH_JOB_LISTING_REQUEST,
  CLEAR_JOB_LISTING_FILTER,
} from '@root/general/consts/actions';
import { FetchAdditionalJobListingPayload, FetchJobListingPayload } from '@root/redux/actions/types';
import { JobListingFilter } from '@root/redux/reducers/jobListingReducer';

export const setJobListingFilterAction = (payload: JobListingFilter) => {
  return {
    type: SET_JOB_LISTING_FILTER,
    payload,
  };
};

export const clearJobListingFilterAction = () => {
  return {
    type: CLEAR_JOB_LISTING_FILTER,
  };
};

export const fetchJobListingAction = (payload: FetchJobListingPayload) => ({
  type: FETCH_JOB_LISTING_REQUEST,
  payload,
});

export const refreshJobListingAction = (payload: FetchJobListingPayload) => ({
  type: REFRESH_JOB_LISTING_REQUEST,
  payload,
});

export const fetchAdditionalJobListingAction = (payload: FetchAdditionalJobListingPayload) => ({
  type: FETCH_ADDITIONAL_JOB_LISTING_REQUEST,
  payload,
});

import { useDispatch, useSelector } from 'react-redux';
import { clearJobListingFilterAction, clearLaborerFilterAction, editUserRoleAction } from '@root/redux/actions';
import { accountType, WEB } from '@root/general/consts';
import { getUser } from '@root/selectors';
import { Dispatch, SetStateAction } from 'react';

type UseSwitchUserRoleReturnType = [() => void];

export const useSwitchUserRole = (
  navigationCallback: () => void,
  setLoading: Dispatch<SetStateAction<boolean>>,
  uniqueId: string = WEB,
  onSwitchUserRoleSuccessCallback?: () => void,
): UseSwitchUserRoleReturnType => {
  const dispatch = useDispatch();

  const { roles, roleId } = useSelector(getUser);

  const isLaborer = roleId === accountType.LABORER;

  const onSwitchUserRole = () => {
    const callback = (isSuccess: boolean) => {
      if (isSuccess) {
        dispatch(clearLaborerFilterAction());
        dispatch(clearJobListingFilterAction());
        onSwitchUserRoleSuccessCallback && onSwitchUserRoleSuccessCallback();
      }
      setLoading(false);
    };

    if (isLaborer) {
      setLoading(true);
      dispatch(
        editUserRoleAction({
          roleId: accountType.EMPLOYER,
          deviceId: uniqueId,
          callback,
        }),
      );
    } else {
      const isLaborerRoleExists = roles.some((role) => role.id === accountType.LABORER);

      if (isLaborerRoleExists) {
        setLoading(true);
        dispatch(
          editUserRoleAction({
            roleId: accountType.LABORER,
            deviceId: uniqueId,
            callback,
          }),
        );
      } else {
        navigationCallback();
      }
    }
  };

  return [onSwitchUserRole];
};

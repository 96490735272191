import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IActionType } from '@root/redux/types';

const INITIAL_PAGE = 1;

export type ActionType = (callback: () => void) => { type: string; payload: any };

export type AdditionActionCallback<T> = (isSuccess: boolean, additionalItems: Array<T>) => void;

export type AdditionActionType<T> = (
  page: number,
  callback: AdditionActionCallback<T>,
) => { type: string; payload: any };

export type DebounceFetchAction = (dispatchFetchAction: () => IActionType) => void;

export type UseFlatListPaginationReturnType<T> = [
  Array<T>,
  boolean,
  boolean,
  boolean,
  () => void,
  () => void,
  Dispatch<SetStateAction<Array<T>>>,
  boolean,
];

export const useFlatListPagination = <T extends {}>(
  itemsArray: Array<T>,
  lastPage: number,
  fetchAction: ActionType,
  refreshAction: ActionType,
  fetchAdditionAction: AdditionActionType<T>,
  debounceFetchAction?: DebounceFetchAction,
): UseFlatListPaginationReturnType<T> => {
  const dispatch = useDispatch();

  const [page, setPage] = useState<number>(INITIAL_PAGE);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [isFooterLoading, setFooterLoading] = useState<boolean>(false);
  const [isFlatListLoading, setFlatListLoading] = useState<boolean>(true);
  const [items, setItems] = useState<Array<T>>(itemsArray);

  useEffect(() => {
    setItems(itemsArray);
    setPage(INITIAL_PAGE);
  }, [itemsArray]);

  useEffect(() => {
    setFlatListLoading(true);

    const fetchCallback = () => setFlatListLoading(false);

    if (debounceFetchAction) {
      debounceFetchAction(() => dispatch(fetchAction(fetchCallback)));
    } else {
      dispatch(fetchAction(fetchCallback));
    }
  }, [dispatch, fetchAction, debounceFetchAction]);

  const handleRefresh = () => {
    setRefreshing(true);
    setPage(INITIAL_PAGE);

    const refreshCallback = () => setRefreshing(false);

    dispatch(refreshAction(refreshCallback));
  };

  const onEndReached = () => {
    const callback = (isSuccess: boolean, additionalItems: Array<T> = []) => {
      if (isSuccess) {
        setPage(page + 1);
        setItems([...items, ...additionalItems]);
      }
      setFooterLoading(false);
    };

    if (page < lastPage && !isFooterLoading) {
      setFooterLoading(true);
      dispatch(fetchAdditionAction(page + 1, callback));
    }
  };

  const hasMore = page < lastPage;

  return [items, isFlatListLoading, isFooterLoading, refreshing, handleRefresh, onEndReached, setItems, hasMore];
};

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() => ({
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    overflowX: 'hidden',
    padding: '20px 30px',
  },
  buttonContainerRow: {
    display: 'flex',
  },
  footerContainer: {
    padding: '20px 30px',
    marginTop: 'auto',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelDateText: {
    fontSize: 17,
    fontFamily: fonts.SFUiDisplayMedium,
    color: colors.grey,
    textAlign: 'center',
    marginBottom: 10,
  },
  availabilityText: {
    textAlign: 'center',
    color: colors.red,
    fontSize: 15,
    fontFamily: fonts.SFUiDisplayMedium,
    fontWeight: 500,
    lineHeight: '20px',
  },
}));

import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '@root/general/fonts';
import { createMuiTheme } from '@material-ui/core';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    height: '100vh',
  },
  leftContainer: {
    display: 'flex',
    flex: 1,
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '24px 4px 12px 24px',
    boxSizing: 'border-box',
  },
  scrollbar: {
    display: 'flex',
    flex: 6,
    width: '100%',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    //todo so large fontSize
    fontSize: 42,
    fontFamily: fonts.SFUiDisplayBold,
    lineHeight: '32px',
    letterSpacing: -0.24,
    textAlign: 'center',
    marginBottom: 20,
  },
  formContainer: {
    display: 'flex',
    flex: 2,
    flexWrap: 'wrap',
  },
  containerPadding: {
    paddingRight: 20,
  },
  checkBoxContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 10,
    width: '100%',
  },
  checkBoxText: {
    lineHeight: '22px',
    fontSize: 15,
    fontFamily: fonts.SFUiDisplayMedium,
    letterSpacing: -0.24,
    color: colors.mineShaft,
  },
  checkBoxTextLink: {
    color: colors.red,
    cursor: 'pointer',
    textDecoration: 'none',
  },
}));

export const checkBoxTheme = createMuiTheme({
  overrides: {
    MuiCheckbox: {
      root: {
        padding: '0 12px 12px 0',
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
      },
    },
  },
  palette: {
    secondary: {
      main: colors.red,
    },
    primary: {
      main: colors.red,
    },
  },
});

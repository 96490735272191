import { ArrayAds, ArrayBanners, Banner } from '@root/general/types';
import { adScreenType, FETCH_THROTTLE_TIME } from '@root/general/consts';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { throttle } from 'lodash';
import { fetchAdsRequestAction } from '@root/redux/actions';

type useAdBannerReturnType = [boolean, Banner | null, () => void, () => number, number];

export const useAdBanner = (screenType: adScreenType): useAdBannerReturnType => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [ads, setAds] = useState<ArrayAds>([]);
  const [banners, setBanners] = useState<ArrayBanners>([]);
  const [frequency, setFrequency] = useState<number>(0);
  const [adIndex, setAdIndex] = useState<number>(0);
  const [bannerIndex, setBannerIndex] = useState<number>(0);

  useEffect(() => {
    if (ads.length > 0) {
      const { banners, params } = ads[adIndex];
      setBanners(banners);
      setFrequency(Number(params.frequency));
    }
  }, [ads, adIndex]);

  const setAdsCallback = (isSuccess: boolean, ads: ArrayAds = []) => {
    if (isSuccess && ads.length > 0) {
      const validAdsWithBanners = ads
        .filter((ad) => {
          const frequency = ad.params?.frequency ?? 0;

          return ad.banners.length > 0 && frequency > 0;
        })
        .map((ad) => {
          const validBanners = ad.banners.filter(
            (banner) => banner.params.images.xs && banner.params.images.sm && banner.params.images.xl,
          );
          return { ...ad, banners: validBanners };
        });

      if (validAdsWithBanners.length > 0) {
        setAds(validAdsWithBanners);
      }
    }
    setLoading(false);
  };

  const refreshAds = useCallback(
    throttle(() => {
      dispatch(fetchAdsRequestAction({ screenType, callback: setAdsCallback }));
    }, FETCH_THROTTLE_TIME),
    [dispatch, screenType],
  );

  const switchBanner = () => {
    return setInterval(() => {
      if (bannerIndex < banners.length - 1) {
        setBannerIndex(bannerIndex + 1);
      } else {
        if (adIndex < ads.length - 1) {
          setAdIndex(adIndex + 1);
        } else {
          setAdIndex(0);
        }
        setBannerIndex(0);
      }
    }, frequency);
  };

  return [loading, banners[bannerIndex], refreshAds, switchBanner, frequency];
};

import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    height: '100vh',
  },
  leftContainer: {
    display: 'flex',
    flex: 6,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '24px 24px 12px',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  descriptionHeader: {
    //todo so large fontSize
    fontSize: 42,
    fontFamily: fonts.SFUiDisplayBold,
    lineHeight: '48px',
    letterSpacing: -0.24,
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    marginBottom: 35,
  },
  rightImage: {
    width: '100%',
  },
}));

import React, { useRef } from 'react';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import { useChangeEmailScreen } from '@root/hooks/useChangeEmailScreen';
import { validationSchema } from '@root/mobile/utils/validationSchema';
import { Grid } from '@material-ui/core';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { strings } from '@root/strings';
import { CustomLoader } from '@root/web/components/CustomLoader';
import { useStyles } from './styles';
import { CustomInputAdapter } from '@root/web/components/CustomInput/customInputAdapter';
import { FormikHandleChangeType, FormikSetFieldTouchedType, FormikValuesType } from '@root/mobile/types/inputTypes';

const { buttons } = strings;

export const ChangeEmail = () => {
  const styles = useStyles();

  const ref = useRef([]);

  const successCallback = () => {
    ref.current.map((input: any) => {
      // @ts-ignore
      if (input) {
        input.children[1].children[0].value = '';
      }
    });
  };

  const [initialValue, changeEmailFormData, onChangeEmail, isLoading] = useChangeEmailScreen(successCallback);

  const renderInputForms = (
    handleChange: FormikHandleChangeType,
    values: FormikValuesType,
    errors: FormikErrors<FormikValuesType>,
    touched: FormikTouched<FormikValuesType>,
    setFieldTouched: FormikSetFieldTouchedType,
  ) => {
    return changeEmailFormData.map((item) => {
      return (
        <Grid item sm={item?.sm ?? 12} xs={12} key={item.id} className={styles.containerPadding}>
          <CustomInputAdapter item={{ ...item, handleChange, values, errors, touched, setFieldTouched }} ref={ref} />
        </Grid>
      );
    });
  };

  return (
    <Formik
      initialValues={initialValue}
      onSubmit={onChangeEmail}
      validationSchema={validationSchema(changeEmailFormData)}
      validateOnMount={true}
    >
      {({ handleChange, handleSubmit, values, errors, touched, setFieldTouched, isValid }) => (
        <Grid container className={styles.container}>
          {renderInputForms(handleChange, values, errors, touched, setFieldTouched)}
          <Grid container justify={'flex-end'}>
            <Grid item xs={3} md={2} className={styles.containerPadding}>
              <CustomButton
                type={customButtonType.RED}
                title={buttons.save}
                onPress={handleSubmit}
                disabled={isLoading || !isValid}
                icon={require('@root/web/assets/icons/edit.svg')}
              />
            </Grid>
            {isLoading && <CustomLoader />}
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

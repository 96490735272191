import React from 'react';
import { Job } from '@root/general/types';
import { jobDescription, useJobDetails } from '@root/hooks/useJobDetails';
import { InfoContainer } from '@root/web/components/InfoContainer';
import { useStyles, theme } from './styles';
import { RateValue } from '@root/web/components/RateValue';
import { Accordion, AccordionSummary, AccordionDetails, ThemeProvider } from '@material-ui/core';
import { UserInfoTab } from '@root/web/components/UserInfoTab';

export const JobDetails = (props: { job: Job }) => {
  const styles = useStyles();
  const { job } = props;

  const [jobInfoFields] = useJobDetails(job, () => {});

  const getValueElement = (fieldType: jobDescription, value?: string | number | null, extraValue?: string) => {
    switch (fieldType) {
      case jobDescription.rate:
        return <RateValue rate={value} rateType={extraValue} />;
      default:
        return undefined;
    }
  };

  return (
    <div className={styles.container}>
      {jobInfoFields.map((jobInfoField) => {
        const { id, label, value, extraValue, iconWeb, multiline } = jobInfoField;

        if (id === jobDescription.employer) {
          return (
            <ThemeProvider key={id} theme={theme}>
              <Accordion>
                <AccordionSummary expandIcon={<img src={require('@root/web/assets/icons/arrowDown.svg')} alt='' />}>
                  <div className={styles.labelContainer}>
                    <div className={styles.iconContainer}>
                      {iconWeb && <img className={styles.icon} src={iconWeb} alt={''} />}
                    </div>
                    <span className={styles.labelText}>{label}</span>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {!!job.userId && (
                    <UserInfoTab userId={job.userId} jobId={job.id} accordionInfo goBackCallback={() => {}} />
                  )}
                </AccordionDetails>
              </Accordion>
            </ThemeProvider>
          );
        }

        return (
          <InfoContainer
            key={id}
            label={label}
            value={value}
            iconSource={iconWeb}
            multiline={multiline}
            valueElement={getValueElement(id, value, extraValue)}
          />
        );
      })}
    </div>
  );
};

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() => ({
  name: {
    display: 'block',
    letterSpacing: -0.24,
    color: colors.darkGrey,
    fontSize: 19,
    fontFamily: fonts.SFUiDisplayBold,
    marginBottom: 6,
    wordWrap: 'break-word',
  },
  emailContainer: {
    display: 'flex',
    marginBottom: 4,
  },
  phoneContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 10,
  },
  field: {
    letterSpacing: -0.24,
    color: colors.darkGrey,
    fontSize: 17,
    fontFamily: fonts.SFUiDisplayMedium,
    wordWrap: 'break-word',
    width: '80%',
  },
}));

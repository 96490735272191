import React, { Dispatch, SetStateAction } from 'react';
import { Grid } from '@material-ui/core';
import { Job, Offer, ReviewsArray } from '@root/general/types';
import { useReviewLaborersModalScreen } from '@root/hooks/useReviewLaborersModalScreen';
import { useFlatListPagination } from '@root/hooks/useFlatListPagination';
import { ReviewLaborersCard } from './components/ReviewLaborersCard';
import { strings } from '@root/strings';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { CustomTabHeader } from '@root/web/components/CustomTabHeader';
import { useStyles } from './styles';
import { CustomScrollComponent } from '@root/web/components/CustomScrollComponent';
import { CustomTabContainer } from '@root/web/components/CustomTabContainer';

const { reviewLaborersModalScreen: string } = strings;

type ReviewLaborersScreenPropsType = {
  job: Job;
  setJob: Dispatch<SetStateAction<Job>>;
  goBackCallback: () => void;
};

export const ReviewLaborersScreen = (props: ReviewLaborersScreenPropsType) => {
  const { job, setJob, goBackCallback } = props;
  const { id: jobId, userId: jobUserId } = job;

  const styles = useStyles();

  const [
    proposals,
    lastPage,
    fetchAction,
    refreshAction,
    fetchAdditionAction,
    handleAddReviews,
    ,
    ,
    usersRatings,
    setUsersRatings,
  ] = useReviewLaborersModalScreen(jobId);

  const [offers, isFlatListLoading, , , , onEndReached, , hasMore] = useFlatListPagination<Offer>(
    proposals,
    lastPage,
    fetchAction,
    refreshAction,
    fetchAdditionAction,
  );

  const renderReviewLaborersCard = (item: Offer) => {
    const { userId, user, owner } = item;
    const laborer = jobUserId === userId ? owner : user;

    return laborer ? (
      <Grid key={item.id} item xs={12}>
        <ReviewLaborersCard laborer={laborer} usersRatings={usersRatings} setUsersRatings={setUsersRatings} />
      </Grid>
    ) : null;
  };

  const onPressSubmitReviewButton = () => {
    const reviewsArray = offers.reduce((prev: ReviewsArray, curr: Offer) => {
      const { ownerId, userId, jobId } = curr;
      const laborerId = jobUserId === userId ? ownerId : userId;
      const userRating = usersRatings[laborerId];

      if (userRating) {
        return [...prev, { userId: laborerId, jobId, rating: userRating }];
      }

      return prev;
    }, []);

    handleAddReviews(reviewsArray, setJob, job, goBackCallback);
  };

  return (
    <CustomTabContainer tabPositionFixed>
      <CustomTabHeader title={string.reviewLaborersScreenTitle} onTabHeaderClose={goBackCallback} />
      <CustomScrollComponent
        data={offers}
        renderItem={renderReviewLaborersCard}
        isFlatListLoading={isFlatListLoading}
        onEndReached={onEndReached}
        hasMore={hasMore}
        header={<span className={styles.tapToRateText}>{string.tapToRateLaborers}</span>}
        headerStyle={styles.tapToRateContainer}
        footer={
          <CustomButton
            type={customButtonType.RED}
            title={string.submitReviewButton}
            onPress={onPressSubmitReviewButton}
          />
        }
      />
    </CustomTabContainer>
  );
};

import React from 'react';
import { useStyles } from './styles';
import { strings } from '@root/strings';
import { getCardBrandLogo } from '@root/web/utils/getCardBrandLogo';
import { cardBrandLogoType } from '@root/general/consts';
import { CustomCardContainer } from '@root/web/components/CustomCardContainer';

const { paymentCard: string } = strings;

export type PaymentCardProps = {
  last4: string;
  brand: string;
  expYear: number;
  expMonth: number;
  onPress: () => void;
  isSelected: boolean;
};

export const PaymentCard = (props: PaymentCardProps) => {
  const styles = useStyles(props);

  const { last4, brand, expYear, expMonth, onPress, isSelected } = props;

  const month = expMonth < 10 ? `0${expMonth}` : expMonth;

  return (
    <CustomCardContainer isSelected={isSelected} onPress={onPress} containerClasses={styles.containerClasses}>
      <div className={styles.cardNumberContainer}>
        <span className={styles.dots}>{string.dots}</span>
        <span className={styles.cardNumberText}>{last4}</span>
      </div>
      <span className={styles.dateLabelText}>{string.dateLabel}</span>
      <div className={styles.cardInformationContainer}>
        <span className={styles.dateText}>{`${month}/${expYear}`}</span>
        <img src={getCardBrandLogo(brand, cardBrandLogoType.default)} alt='' height={22} />
      </div>
    </CustomCardContainer>
  );
};

import {
  ADD_REFERENCE_REQUEST,
  DELETE_REFERENCE_REQUEST,
  EDIT_REFERENCE_REQUEST,
  FETCH_ADDITIONAL_REFERENCES_REQUEST,
  FETCH_REFERENCES_REQUEST,
  REFRESH_REFERENCES_REQUEST,
} from '@root/general/consts/actions';
import {
  AddReferencePayload,
  DeleteReferencePatload,
  EditReferencePayload,
  FetchAdditionalReferencesPayload,
  FetchReferencesPayload,
} from '@root/redux/actions/types';

export const addReferenceAction = (payload: AddReferencePayload) => {
  return {
    type: ADD_REFERENCE_REQUEST,
    payload,
  };
};

export const editReferenceAction = (payload: EditReferencePayload) => {
  return {
    type: EDIT_REFERENCE_REQUEST,
    payload,
  };
};

export const deleteReferenceAction = (payload: DeleteReferencePatload) => {
  return {
    type: DELETE_REFERENCE_REQUEST,
    payload,
  };
};

export const fetchReferencesAction = (payload: FetchReferencesPayload) => {
  return {
    type: FETCH_REFERENCES_REQUEST,
    payload,
  };
};

export const refreshReferencesAction = (payload: FetchReferencesPayload) => {
  return {
    type: REFRESH_REFERENCES_REQUEST,
    payload,
  };
};

export const fetchAdditionalReferencesAction = (payload: FetchAdditionalReferencesPayload) => {
  return {
    type: FETCH_ADDITIONAL_REFERENCES_REQUEST,
    payload,
  };
};

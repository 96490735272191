import React from 'react';
import { useStyles } from './styles';
import Scrollbars from 'react-custom-scrollbars';
import InfiniteScroll from 'react-infinite-scroller';
import { ScrollBottomLoader } from '@root/web/components/ScrollBottomLoader';
import { useNotificationsModalScreen } from '@root/hooks/useNotificationsModalScreen';
import { useFlatListPaginationWithoutRedux } from '@root/hooks/useFlatListPaginationWithoutRedux';
import { Notification } from '@root/general/types';
import moment from 'moment';
import { colors } from '@root/general/colors';
import { CustomLoader } from '@root/web/components/CustomLoader';
import { navigateWithNotification } from '@root/web/utils/navigateWithNotification';
import { useHistory } from 'react-router-dom';
import { route } from '@root/web/consts';
import { useSelector } from 'react-redux';
import { getIndustries } from '@root/selectors';
import { EmptyListComponent } from '@root/web/components/EmptyListComponent';
import { strings } from '@root/strings';
import { CustomTabHeader } from '@root/web/components/CustomTabHeader';
import { MobileView } from 'react-device-detect';

const { notificationsModalScreen: string } = strings;

type NotificationsPopoverProps = {
  onClose: () => void;
};

export const NotificationsPopover = (props: NotificationsPopoverProps) => {
  const { onClose } = props;
  const styles = useStyles();

  const history = useHistory();

  const { allIndustries } = useSelector(getIndustries);

  const getCurrentIndustryName = (industryId: number) => {
    const { name: currentIndustryName } = allIndustries.find(({ id }) => industryId === id) || allIndustries[0];
    return currentIndustryName.toLowerCase();
  };

  const [fetchAction] = useNotificationsModalScreen();

  const [notifications, isFlatListLoading, , , , onEndReached, , hasMore] = useFlatListPaginationWithoutRedux<
    Notification
  >(fetchAction);

  const navigateToJobOffer = (unreadNotificationId: number | null) => (jobId: number | undefined) => {
    history.push(`${route.OFFERS}/${jobId}`, { notificationId: unreadNotificationId });
  };

  const navigateToNewJob = (unreadNotificationId: number | null) => (jobId: number | undefined) => {
    history.push(`${route.JOB_LISTING_MAP}/${jobId}`, { notificationId: unreadNotificationId });
  };

  const navigateToMyJob = (unreadNotificationId: number | null) => (jobId: number | undefined) => {
    history.push(`${route.JOBS}/${jobId}`, { notificationId: unreadNotificationId });
  };

  const navigateToJobRequest = (unreadNotificationId: number | null) => (
    laborerId: number | undefined,
    jobId: number | undefined,
    industryId: number | undefined,
  ) => {
    const currentIndustryName = getCurrentIndustryName(industryId || 0);

    history.push(`${route.REQUESTS}/${currentIndustryName}/${laborerId}/${jobId}`, {
      notificationId: unreadNotificationId,
      tabIndustryId: industryId,
    });
  };

  const navigateToNewLaborer = (unreadNotificationId: number | null) => (
    laborerId: number | undefined,
    industryId: number | undefined,
  ) => {
    const currentIndustryName = getCurrentIndustryName(industryId || 0);

    history.push(`${route.LABORERS}/${currentIndustryName}/${laborerId}`, {
      notificationId: unreadNotificationId,
      tabIndustryId: industryId,
    });
  };

  const renderItems = () => {
    return (
      <div className={styles.notificationsContainer}>
        {notifications.map((notification) => {
          const { id, title, readAt, description, createdAt, params } = notification;

          const unreadNotificationId = !readAt ? id : null;

          const onNotificationPress = () => {
            onClose();

            navigateWithNotification(
              params,
              navigateToJobOffer(unreadNotificationId),
              navigateToNewJob(unreadNotificationId),
              navigateToMyJob(unreadNotificationId),
              navigateToJobRequest(unreadNotificationId),
              navigateToNewLaborer(unreadNotificationId),
            );
          };

          return (
            <div
              className={styles.notificationCard}
              style={{ borderLeftColor: !readAt ? colors.red : colors.grey }}
              key={id}
              onClick={onNotificationPress}
            >
              <div className={styles.notificationCardContent}>
                <div className={styles.notificationCardLeftContainer}>
                  <span className={styles.notificationTitle}>{title}</span>
                  <span className={styles.notificationDescription}>{description}</span>
                </div>
                <span className={styles.notificationDate}>{moment(createdAt).format('ll')}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <MobileView>
        <CustomTabHeader title={string.screenTitle} onTabHeaderClose={onClose} />
      </MobileView>
      {isFlatListLoading ? (
        <CustomLoader />
      ) : (
        <>
          {notifications.length > 0 ? (
            <Scrollbars
              renderTrackHorizontal={(props) => <div {...props} className={styles.horizontalScroll} />}
              renderThumbHorizontal={(props) => <div {...props} className={styles.horizontalScroll} />}
            >
              <InfiniteScroll
                useWindow={false}
                pageStart={0}
                loadMore={onEndReached}
                hasMore={hasMore}
                loader={<ScrollBottomLoader key={0} />}
              >
                {renderItems()}
              </InfiniteScroll>
            </Scrollbars>
          ) : (
            <EmptyListComponent primaryText={string.defaultTextPrimary} />
          )}
        </>
      )}
    </div>
  );
};

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    backgroundColor: colors.lightGrey,
    display: 'flex',
  },
  containerLeft: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  filterContainer: ({ allJobsEmpty }: { allJobsEmpty: boolean }) => {
    return {
      display: 'flex',
      justifyContent: allJobsEmpty ? 'flex-end' : 'space-between',
      alignItems: 'center',
    };
  },
});

import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { CustomInputAdapter } from '@root/web/components/CustomInput/customInputAdapter';
import { showToastAction, ToastTypes } from '@root/redux/actions/commonActions';
import { useDispatch } from 'react-redux';
import { strings } from '@root/strings';
import { useUserProfileScreen } from '@root/hooks/useUserProfileScreen';
import { validationSchema } from '@root/mobile/utils/validationSchema';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import { FormikHandleChangeType, FormikSetFieldTouchedType, FormikValuesType } from '@root/mobile/types/inputTypes';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { CustomModal } from '@root/web/components/CustomModal/CustomModal';
import { CustomModalWithSections } from '@root/web/components/CustomModal/CustomModalWithSections';
import { ChooseStateModal } from '@root/web/components/ChooseStateModal';
import { ChooseCityModal } from '@root/web/components/ChooseCityModal';
import { useStyles } from './styles';
import { CustomLoader } from '@root/web/components/CustomLoader';
import Scrollbars from 'react-custom-scrollbars';
import { PickerMenu } from '@root/web/components/PickerMenu';

const { buttons: button, userProfileActionsScreen, warnings } = strings;

export const ProfileInfo = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [isEditMode, setEditMode] = useState(false);
  const [isStateModalVisible, setStateModalVisible] = useState(false);
  const [isCityModalVisible, setCityModalVisible] = useState(false);

  const onStatePress = () => () => {
    setStateModalVisible(true);
  };

  const onCityPress = (selectedStateId: number | null) => () => {
    if (selectedStateId) {
      setCityModalVisible(true);
    } else {
      dispatch(showToastAction({ message: warnings.selectState, type: ToastTypes.Error }));
    }
  };

  const [
    formFields,
    formInitialValues,
    formHandleSubmit,
    selectedIndustryIds,
    setSelectedIndustryIds,
    selectedSpecificationIds,
    setSelectedSpecificationIds,
    industryModalVisible,
    setIndustryModalVisible,
    specificationsModalVisible,
    setSpecificationsModalVisible,
    allIndustries,
    specificationsByIndustries,
    specificationsValidation,
    formikRef,
    isLoading,
    ,
    ,
    ,
    ,
    setSelectedStateId,
    setSelectedCityId,
    selectedStateId,
    ,
    ,
    fieldRepModalVisible,
    setFieldRepModalVisible,
    fieldReps,
    handleSetFieldRepId,
    formFieldsValidation,
    getFieldRepId,
  ] = useUserProfileScreen(undefined, onStatePress, onCityPress);

  const onSubmit = (data: FormikValuesType) => {
    if (!isEditMode) {
      setEditMode(true);
    } else {
      formHandleSubmit(data, () => setEditMode(false));
    }
  };

  const renderInputForms = (
    handleChange: FormikHandleChangeType,
    values: FormikValuesType,
    errors: FormikErrors<FormikValuesType>,
    touched: FormikTouched<FormikValuesType>,
    setFieldTouched: FormikSetFieldTouchedType,
  ) => {
    return (
      <Grid container className={styles.gridContainer}>
        {formFields.map((item) => {
          const { valuesName } = item;

          switch (valuesName) {
            case 'fieldRep':
              return (
                <Grid item sm={item?.sm ?? 6} xs={12} key={item.id} className={styles.containerPadding}>
                  <PickerMenu
                    key={item.id}
                    title={item.title}
                    data={fieldReps}
                    value={getFieldRepId()}
                    handleChange={handleChange('fieldRep')}
                    setValue={handleSetFieldRepId}
                    isOpen={fieldRepModalVisible}
                    onOpen={item.onPress}
                    onClose={() => setFieldRepModalVisible(false)}
                    disabled={!isEditMode}
                    fullWidth={true}
                    isDefaultFont={true}
                  />
                </Grid>
              );
            default:
              return (
                <Grid item sm={item?.sm ?? 6} xs={12} key={item.id} className={styles.containerPadding}>
                  <CustomInputAdapter
                    item={{ ...item, handleChange, values, errors, touched, setFieldTouched, infoMode: !isEditMode }}
                  />
                </Grid>
              );
          }
        })}
      </Grid>
    );
  };

  return (
    <Formik
      initialValues={formInitialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema(formFieldsValidation, specificationsValidation)}
      validateOnMount
      enableReinitialize
      innerRef={formikRef}
    >
      {({ handleChange, handleSubmit, values, errors, isValid, touched, setFieldTouched }) => (
        <div className={styles.scrollbarContainer}>
          <Scrollbars>
            <div className={styles.scrollbarContent}>
              {renderInputForms(handleChange, values, errors, touched, setFieldTouched)}
              <div className={styles.editButtonContainer}>
                <CustomButton
                  type={customButtonType.RED}
                  title={isEditMode ? button.save : button.edit}
                  onPress={handleSubmit}
                  disabled={isLoading || !isValid}
                  icon={require('@root/web/assets/icons/edit.svg')}
                  maxWidth={150}
                />
              </div>
            </div>
          </Scrollbars>
          {isLoading && <CustomLoader />}
          <CustomModal
            setValue={handleChange('industry')}
            isModalVisible={industryModalVisible}
            setModalVisible={setIndustryModalVisible}
            data={allIndustries}
            selectedItemIds={selectedIndustryIds}
            setSelectedItemIds={setSelectedIndustryIds}
            setDependentValue={handleChange('specifications')}
            dependentItem={selectedSpecificationIds}
            setDependentItem={setSelectedSpecificationIds}
            headerTitle={userProfileActionsScreen.industry}
          />
          <CustomModalWithSections
            setValue={handleChange('specifications')}
            isModalVisible={specificationsModalVisible}
            setModalVisible={setSpecificationsModalVisible}
            sections={specificationsByIndustries}
            selectedItemIds={selectedSpecificationIds}
            setSelectedItemIds={setSelectedSpecificationIds}
            headerTitle={userProfileActionsScreen.specifications}
          />
          {isStateModalVisible && (
            <ChooseStateModal
              isModalVisible={isStateModalVisible}
              setModalVisible={setStateModalVisible}
              setSelectedCityId={setSelectedCityId}
              setSelectedStateId={setSelectedStateId}
              setValue={handleChange('state')}
              setCityValue={handleChange('city')}
            />
          )}
          {isCityModalVisible && (
            <ChooseCityModal
              isModalVisible={isCityModalVisible}
              setModalVisible={setCityModalVisible}
              setSelectedCityId={setSelectedCityId}
              selectedStateId={selectedStateId}
              setValue={handleChange('city')}
            />
          )}
        </div>
      )}
    </Formik>
  );
};

import React from 'react';
import { useStyles } from './styles';
import clsx from 'clsx';
import { BASE_URL } from '@root/web/consts';

export type ProfileImageProps = {
  avatar: string | null;
  customImageContainerStyles?: string;
  customImageStyles?: string;
  customDefaultImageContainerStyles?: string;
  customDefaultImageStyles?: string;
};

export const ProfileImage = (props: ProfileImageProps) => {
  const {
    avatar,
    customImageContainerStyles = '',
    customDefaultImageStyles = '',
    customImageStyles = '',
    customDefaultImageContainerStyles = '',
  } = props;

  const styles = useStyles();

  const userAvatar = `${BASE_URL}storage/${avatar}`;

  return (
    <div className={clsx(styles.container, customImageContainerStyles)}>
      {avatar ? (
        <img src={userAvatar} className={clsx(styles.image, customImageStyles)} alt={''} />
      ) : (
        <div className={customDefaultImageContainerStyles}>
          <img
            src={require('@root/web/assets/icons/profile.svg')}
            className={clsx(styles.defaultImage, customDefaultImageStyles)}
            alt={''}
          />
        </div>
      )}
    </div>
  );
};

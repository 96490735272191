import { Dispatch, SetStateAction, useState } from 'react';
import { PaymentType } from '@root/general/types';
import { searchJobs, searchOffer } from '@root/mobile/utils/search';
import { getUserProfileFromOffer } from '@root/utils/getUserProfileFromOffer';

type UsePaymentModalReturnType = {
  amountText: string;
  modalItems: ModalItemType[];
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
};

export type ModalItemType = {
  id: number | null;
  jobName: string;
  offers: ModalOfferType[];
};

export type ModalOfferType = {
  id: number;
  createdAt: string;
  laborerName: string;
};

export const usePaymentModal = (payment: PaymentType): UsePaymentModalReturnType => {
  const { amount: allAmount, offers = [], jobs = [], userId } = payment;
  const amount = allAmount / offers.length;
  const amountText = amount ? amount?.toString().slice(0, -2) + '.' + amount.toString().slice(-2) : '';

  const [searchValue, setSearchValue] = useState<string>('');

  const modalItems = jobs.reduce((acc: ModalItemType[], job) => {
    const { id, name } = job;

    const filteredOffersById = offers.reduce<ModalOfferType[]>((acc, offer) => {
      const laborerCredentials = getUserProfileFromOffer(userId, offer);
      const laborerName = laborerCredentials?.name ?? '';

      if (offer.jobId === id) {
        const modalOffer: ModalOfferType = {
          id,
          laborerName,
          createdAt: offer.createdAt,
        };

        return [...acc, modalOffer];
      }

      return acc;
    }, []);

    const modalItem: ModalItemType = {
      id,
      jobName: name,
      offers: filteredOffersById,
    };

    if (searchJobs(searchValue, modalItem.jobName) && filteredOffersById.length) {
      acc.push(modalItem);
    }

    if (!searchJobs(searchValue, modalItem.jobName)) {
      const newFilteredOffers = filteredOffersById.filter((offer) => {
        return searchValue ? searchOffer(searchValue, offer) : true;
      });

      if (newFilteredOffers.length) {
        acc.push({ ...modalItem, offers: newFilteredOffers });
      }
    }

    return acc;
  }, []);

  return {
    amountText,
    modalItems,
    searchValue,
    setSearchValue,
  };
};

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { NavigationBar } from '@root/web/navigators/NavigationBar';
import { JobListingScreen } from '@root/web/screens/JobListingScreen';
import { JobListingMap } from '@root/web/screens/JobListingMap';
import { JobOffersScreen } from '@root/web/screens/JobOffersScreen';
import { JobRequestsScreen } from '@root/web/screens/JobRequestsScreen';
import { PostedJobsScreen } from '@root/web/screens/PostedJobsScreen';
import { LaborersScreen } from '@root/web/screens/LaborersScreen';
import { ProfileScreen } from '@root/web/screens/ProfileScreen';
import { accountType, adScreenType } from '@root/general/consts';
import { RouteType } from './types';
import { route, STRIPE_PUBLIC_KEY } from '@root/web/consts';
import { profileParam } from '@root/web/navigators/ProfileTopNavigator';
import { useSelector } from 'react-redux';
import { getUser } from '@root/selectors';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useStyles } from './styles';
import { AdBanner } from '@root/web/components/AdBanner';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export const Routes: Array<RouteType> = [
  {
    type: accountType.LABORER,
    path: route.JOB_LISTING,
    params: '/:industryName?/:specificationName?/:jobId?',
    sidebarName: 'Job Listing',
    component: JobListingScreen,
    icon: require('@root/web/assets/icons/listJobs.svg'),
  },
  {
    type: accountType.LABORER,
    params: '/:jobId?',
    path: route.JOB_LISTING_MAP,
    component: JobListingMap,
  },
  {
    type: accountType.LABORER,
    path: route.OFFERS,
    params: '/:jobId?',
    sidebarName: 'Job Offers',
    component: JobOffersScreen,
    icon: require('@root/web/assets/icons/offers.svg'),
  },
  {
    type: accountType.EMPLOYER,
    path: route.REQUESTS,
    params: '/:industryName?/:laborerId?/:jobId?',
    sidebarName: 'Job Requests',
    component: JobRequestsScreen,
    icon: require('@root/web/assets/icons/requests.svg'),
  },
  {
    type: accountType.EMPLOYER,
    path: route.JOBS,
    params: '/:jobId?',
    sidebarName: 'Jobs',
    component: PostedJobsScreen,
    icon: require('@root/web/assets/icons/jobs.svg'),
  },
  {
    type: accountType.EMPLOYER,
    path: route.LABORERS,
    params: '/:industryName?/:laborerId?',
    sidebarName: 'Laborers',
    component: LaborersScreen,
    icon: require('@root/web/assets/icons/laborers.svg'),
  },
  {
    path: route.PROFILE,
    params: `/:profileParam(${[...Object.values({ ...profileParam })].join('|')})?/:id?`,
    sidebarName: 'Profile',
    component: ProfileScreen,
    positionBottom: true,
    icon: require('@root/web/assets/icons/profileWithBackground.svg'),
  },
];

type PrivateRoutesProps = {
  isAuth: boolean;
};

export const PrivateRoutes = ({ isAuth }: PrivateRoutesProps) => {
  const styles = useStyles();

  const { roleId } = useSelector(getUser);

  return (
    <>
      {isAuth ? (
        <Elements
          stripe={stripePromise}
          options={{
            fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Open+Sans&display=swap' }],
          }}
        >
          <div className={styles.container}>
            <AdBanner screenType={adScreenType.laborers} />
            <div className={styles.content}>
              <NavigationBar />
              {Routes.filter(({ type = null }) => type === roleId || type === null).map((route: RouteType) => {
                const { path, params = '' } = route;
                return <Route exact path={path + params} key={path} render={() => <route.component path={path} />} />;
              })}
              <Route exact path='/'>
                <Redirect to={route.PROFILE} />
              </Route>
            </div>
          </div>
        </Elements>
      ) : (
        <Redirect to={route.WELCOME} />
      )}
    </>
  );
};

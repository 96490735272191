import React, { Dispatch, SetStateAction, useState } from 'react';
import { GeneralUser } from '@root/general/types';
import clsx from 'clsx';
import Rating from 'react-rating';
import { UsersRatings } from '@root/hooks/useReviewLaborersModalScreen';
import { useStyles } from './styles';
import { ProfileImage } from '@root/web/components/ProfileImage';

export type ReviewLaborerCardProps = {
  laborer: GeneralUser;
  usersRatings: UsersRatings;
  setUsersRatings: Dispatch<SetStateAction<UsersRatings>>;
};

export const ReviewLaborersCard = (props: ReviewLaborerCardProps) => {
  const { laborer, usersRatings, setUsersRatings } = props;
  const { id, avatar = null, name } = laborer;

  const styles = useStyles();

  const [rating, setRating] = useState<number>(0);

  const ratingCount = `${rating}/5`;

  const onPressRating = (ratingValue: number) => {
    setRating(ratingValue);
    id && setUsersRatings({ ...usersRatings, [id]: ratingValue });
  };

  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <ProfileImage
          avatar={avatar}
          customImageContainerStyles={styles.avatarContainer}
          customImageStyles={styles.avatarIcon}
          customDefaultImageContainerStyles={styles.defaultAvatarContainer}
        />
        <span className={styles.nameText}>{name}</span>
      </div>
      <div className={styles.ratingContainer}>
        <Rating
          initialRating={rating}
          emptySymbol={<img src={require('@root/web/assets/icons/star.svg')} className={styles.icon} alt={''} />}
          fullSymbol={
            <img
              src={require('@root/web/assets/icons/star.svg')}
              className={clsx(styles.icon, styles.fullIcon)}
              alt={''}
            />
          }
          onClick={onPressRating}
        />
        <span className={styles.ratingText}>{ratingCount}</span>
      </div>
    </div>
  );
};

import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchPaymentCardsAction } from '@root/redux/actions';
import { getPaymentCards } from '@root/selectors';
import { PaymentCardsArray } from '@root/general/types';

type UseGetCardsType = [PaymentCardsArray];

export const useGetCards = (enableFetching = true): UseGetCardsType => {
  const dispatch = useDispatch();

  const cards = useSelector(getPaymentCards);

  useEffect(() => {
    enableFetching && dispatch(fetchPaymentCardsAction({}));
  }, [dispatch, enableFetching]);

  return [cards];
};

import React from 'react';
import { useStyles } from './styles';
import { MainHeader } from '@root/web/components/MainHeader';
import { useJobOffersScreen } from '@root/hooks/useJobOffersScreen';
import { useFlatListPagination } from '@root/hooks/useFlatListPagination';
import { Offer } from '@root/general/types';
import { CustomStatusPicker } from '@root/web/components/CustomStatusPicker';
import { offersFilterData } from '@root/utils/getOffersFilterById';
import { defaultJob, OFFERS_BY_STATUS, WEB_NOTIFICATION_TIMEOUT } from '@root/general/consts';
import { Grid } from '@material-ui/core';
import { JobCard } from '@root/web/components/JobCard';
import { ToastContainer } from 'react-toastify';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { route } from '@root/web/consts';
import { JobDetailsScreen } from '@root/web/screens/JobListingScreen/components/JobDetailsScreen';
import { MainFooter } from '@root/web/components/MainFooter';
import { jobDetailsCallback } from '@root/utils/jobDetailsCollback';
import { CustomScrollComponent } from '@root/web/components/CustomScrollComponent';
import { strings } from '@root/strings';
import { useReadNotification } from '@root/web/hooks/useReadNotification';

const { jobOffersScreen: string } = strings;

type locationType = {
  notificationId?: number | null;
};

const filterData = offersFilterData.filter(
  (offerFilter) => offerFilter.id !== OFFERS_BY_STATUS.SENT.id && offerFilter.id !== OFFERS_BY_STATUS.RECEIVED.id,
);

export const JobOffersScreen = () => {
  const location = useLocation<locationType>();
  const notificationId = location?.state?.notificationId ?? null;

  useReadNotification(notificationId);

  const history = useHistory();

  const { jobId = '' } = useParams<{ jobId?: string }>();
  const numberJobId = Number(jobId);

  const styles = useStyles();

  const [
    jobOffers,
    lastPage,
    profileUserId,
    setOffersFilterId,
    offersFilterName,
    refreshAction,
    fetchAction,
    fetchAdditionAction,
    allIndustries,
    offersFilterId,
  ] = useJobOffersScreen();

  const [offers, isFlatListLoading, , , , onEndReached, setOffers, hasMore] = useFlatListPagination<Offer>(
    jobOffers,
    lastPage,
    fetchAction,
    refreshAction,
    fetchAdditionAction,
  );

  const goToJobDetails = (jobId: number | null) => () => {
    history.push(`${route.OFFERS}/${jobId}`);
  };

  const renderJobOffer = (offer: Offer) => {
    const { job = defaultJob, status: offerStatusId, ownerId: offerOwnerId } = offer;
    const { id: itemJobId, industryId } = job;

    const isOfferFromUs = profileUserId === offerOwnerId;

    const industry = allIndustries.find((industry) => industry.id === industryId);
    const title = industry?.name ?? '';

    job.isOfferFromUs = isOfferFromUs;

    return (
      <Grid
        key={itemJobId || 0}
        item
        xs={12}
        md={numberJobId ? 12 : 6}
        lg={numberJobId ? 6 : 4}
        xl={numberJobId ? 4 : 3}
      >
        <JobCard
          job={job}
          onPress={goToJobDetails(itemJobId)}
          title={title}
          offerStatusId={offerStatusId}
          industry={industry}
          isSelected={itemJobId === numberJobId}
        />
      </Grid>
    );
  };

  if (numberJobId) {
    return (
      <JobDetailsScreen
        jobId={numberJobId}
        jobDetailsCallback={jobDetailsCallback(jobOffers, offersFilterName, setOffers)}
        goBackCallback={history.goBack}
      />
    );
  }

  return (
    <div className={styles.container}>
      <MainHeader />
      <CustomScrollComponent
        data={offers}
        renderItem={renderJobOffer}
        isFlatListLoading={isFlatListLoading}
        onEndReached={onEndReached}
        hasMore={hasMore}
        defaultPrimaryText={string.defaultPrimaryText}
        header={
          <div className={styles.filterContainer}>
            <CustomStatusPicker
              data={filterData}
              respondedValue={offersFilterId}
              setRespondedValue={setOffersFilterId}
            />
          </div>
        }
        footer={<MainFooter />}
      />
      <ToastContainer autoClose={WEB_NOTIFICATION_TIMEOUT} />
    </div>
  );
};

import { IActionType } from '@root/redux/types';
import {
  CLEAR_USER_DATA_STATE,
  CLEAR_JOB_LISTING_FILTER,
  FETCH_JOB_LISTING_SUCCESS,
  SET_JOB_LISTING_FILTER,
} from '@root/general/consts/actions';
import { CityType, JobsBySpecification, StateType } from '@root/general/types';

export type JobListingFilter = {
  state: StateType[] | null;
  city: CityType | null;
  radius: number | null;
};

export const defaultJobListingFilter: JobListingFilter = {
  state: null,
  city: null,
  radius: null,
};

export type JobListingReducer = {
  jobsBySpecifications: JobsBySpecification;
  filter: JobListingFilter;
};

const INITIAL_STATE: JobListingReducer = {
  jobsBySpecifications: {},
  filter: defaultJobListingFilter,
};

export const jobListingReducer = (state = INITIAL_STATE, action: IActionType) => {
  switch (action.type) {
    case FETCH_JOB_LISTING_SUCCESS: {
      const { id, jobs, lastPage } = action.payload;

      return {
        ...state,
        jobsBySpecifications: { ...state.jobsBySpecifications, [id]: { jobs, lastPage } },
      };
    }
    case SET_JOB_LISTING_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case CLEAR_JOB_LISTING_FILTER: {
      return {
        ...state,
        filter: defaultJobListingFilter,
      };
    }
    case CLEAR_USER_DATA_STATE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
};

import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { useCustomModal } from '@root/hooks/useCutomModal';
import { Modal } from '@material-ui/core';
import { Industry } from '@root/general/types';
import { CustomModalProps } from '@root/mobile/components/CustomModal/types';
import { strings } from '@root/strings';
import { SelectAllHeader } from '@root/web/components/SelectAllHeader';
import { useStyles } from './styles';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { modalStyle } from '@root/general/consts';

const { buttons: string } = strings;

const CustomModalComponent: ForwardRefRenderFunction<any, CustomModalProps> = (props, ref: any) => {
  const styles = useStyles();
  const {
    setValue,
    isModalVisible,
    setModalVisible,
    data,
    selectedItemIds,
    setSelectedItemIds,
    setDependentValue = () => {},
    dependentItem,
    setDependentItem = () => {},
    headerTitle,
  } = props;

  const [isSelected, onPress, onPressDone, closeModal, onPresAll] = useCustomModal(
    selectedItemIds,
    setSelectedItemIds,
    setModalVisible,
    isModalVisible,
    setDependentValue,
    dependentItem,
    setDependentItem,
    setValue,
    data,
  );

  const renderItem = (item: Industry) => {
    const { id, name, color } = item;

    return (
      <div className={styles.itemContainer} onClick={onPress(item)} key={id}>
        <div className={styles.itemContent}>
          <div className={styles.circle} style={{ backgroundColor: color }} />
          <span className={styles.itemText}>{name}</span>
        </div>
        {isSelected(id) && (
          <img className={styles.checkMarkImage} src={require('@root/web/assets/icons/checkmark.svg')} alt='' />
        )}
      </div>
    );
  };

  return (
    <Modal
      open={isModalVisible}
      style={modalStyle}
      onClose={closeModal}
      className={styles.modalContainer}
      container={ref?.current}
    >
      <div className={styles.contentContainer}>
        <SelectAllHeader headerTitle={headerTitle} onPressAll={onPresAll} />
        {data.map(renderItem)}
        <CustomButton type={customButtonType.RED} title={string.done} onPress={onPressDone} disabledTopBorder />
      </div>
    </Modal>
  );
};

export const CustomModal = forwardRef(CustomModalComponent);

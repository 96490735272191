import React from 'react';
import { CustomSwitcherProps } from '@root/mobile/components/CustomSwitcher/types';
import { accountType } from '@root/general/consts';
import { strings } from '@root/strings';
import clsx from 'clsx';
import { useStyles } from './styles';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';

const { roleScreen: string } = strings;

type CustomSwitcherWebProps = CustomSwitcherProps & {
  className?: string;
};

export const CustomSwitcher = (props: CustomSwitcherWebProps) => {
  const { accountTypeValue, onPress, className } = props;
  const styles = useStyles();

  const isLaborer = accountTypeValue === accountType.LABORER;

  const onPressButtonLaborer = (value: boolean) => () => !value && onPress();

  return (
    <div className={clsx(styles.container, className)}>
      <CustomButton
        type={isLaborer ? customButtonType.RED : customButtonType.WHITE}
        title={string.laborer}
        onPress={onPressButtonLaborer(isLaborer)}
        className={clsx(styles.switchButton, styles.switchLeftButton)}
      />
      <CustomButton
        type={!isLaborer ? customButtonType.RED : customButtonType.WHITE}
        title={string.employer}
        onPress={onPressButtonLaborer(!isLaborer)}
        className={clsx(styles.switchButton, styles.switchRightButton)}
      />
    </div>
  );
};

import React from 'react';
import { useStyles } from './styles';

export type RightImageContainerProps = {
  isLogo?: boolean;
};

export const RightImageContainer = (props: RightImageContainerProps) => {
  const styles = useStyles();

  const { isLogo = false } = props;

  const [src, imageStyle] = isLogo
    ? [require('src/assets/images/welcomeBackground@3x.png'), styles.logoImage]
    : [require('src/assets/images/butlerLogo.png'), styles.butlerImage];

  return (
    <div className={styles.container}>
      <img src={src} className={imageStyle} alt='' />
    </div>
  );
};

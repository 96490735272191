import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    contentContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    content: {
      padding: 30,
    },
    submitButtonContainer: {
      padding: '20px 30px',
      marginTop: 'auto',
    },
  }),
);

import { ArrayFarmType } from '@root/general/types';
import { IActionType } from '@root/redux/types';
import {
  CLEAR_USER_DATA_STATE,
  CREATE_FARM_SUCCESS,
  DELETE_FARM_SUCCESS,
  EDIT_FARM_SUCCESS,
  FETCH_FARMS_SUCCESS,
} from '@root/general/consts/actions';

export type FarmsReducer = {
  farms: ArrayFarmType;
  lastPage: number;
};

const INITIAL_STATE: FarmsReducer = {
  farms: [],
  lastPage: 1,
};

export const farmsReducer = (state = INITIAL_STATE, action: IActionType) => {
  switch (action.type) {
    case FETCH_FARMS_SUCCESS:
      const { farms, lastPage } = action.payload;
      return { ...state, farms, lastPage };
    case CREATE_FARM_SUCCESS:
      return { ...state, farms: [action.payload, ...state.farms] };
    case DELETE_FARM_SUCCESS:
      return { ...state, farms: state.farms.filter((item) => item.id !== action.payload) };
    case EDIT_FARM_SUCCESS:
      const formatArray = state.farms.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          };
        }

        return item;
      });

      return { ...state, farms: formatArray };
    case CLEAR_USER_DATA_STATE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
};

import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '@root/general/fonts';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 4,
  },
  icon: {
    height: 8,
    width: 8,
    borderRadius: '50%',
    marginRight: 8,
  },
  valueText: {
    letterSpacing: -0.24,
    fontSize: 17,
    fontFamily: fonts.SFUiDisplayMedium,
    color: colors.darkGreyOpacity9,
  },
});

import React from 'react';
import { Grid } from '@material-ui/core';
import { usePostedJobsScreen } from '@root/hooks/usePostedJobScreen';
import { useFlatListPagination } from '@root/hooks/useFlatListPagination';
import { Job } from '@root/general/types';
import { useStyles } from './styles';
import { JobCard } from '@root/web/components/JobCard';
import { CustomStatusPicker } from '@root/web/components/CustomStatusPicker';
import { jobStatusesWithAll } from '@root/mobile/utils/getJobStatusByStatusId';
import { strings } from '@root/strings';
import { MainHeader } from '@root/web/components/MainHeader';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { WEB_NOTIFICATION_TIMEOUT } from '@root/general/consts';
import { ToastContainer } from 'react-toastify';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { CustomScrollComponent } from '@root/web/components/CustomScrollComponent';
import { MainFooter } from '@root/web/components/MainFooter';
import { JobActionScreen } from '@root/web/screens/PostedJobsScreen/components/JobActionScreen';
import { JobDetailsScreen } from '@root/web/screens/PostedJobsScreen/components/JobDetailsScreen';
import { CustomTabContainer } from '@root/web/components/CustomTabContainer';
import { jobActionScreenMode } from '@root/hooks/useJobActionModalScreen';
import { useReadNotification } from '@root/web/hooks/useReadNotification';

const { postedJobScreen: string } = strings;

type locationType = {
  isPostingJob?: boolean;
  notificationId?: number | null;
};

export const PostedJobsScreen = (props: { path: string }) => {
  const { path } = props;

  const location = useLocation<locationType>();
  const notificationId = location?.state?.notificationId ?? null;
  const isPostingJob = location?.state?.isPostingJob ?? false;

  useReadNotification(notificationId);

  const history = useHistory();
  const { jobId = '' } = useParams<{ jobId?: string }>();

  const isTabShown = Boolean(isPostingJob || jobId);

  const [
    postedJobs,
    lastPage,
    fetchAction,
    refreshAction,
    fetchAdditionAction,
    setJobStateFilterId, //jobStatusFilterName
    ,
    allIndustries,
    allJobs,
    jobStatusFilterId,
  ] = usePostedJobsScreen();

  const [jobs, isFlatListLoading, , , , onEndReached, , hasMore] = useFlatListPagination<Job>(
    postedJobs,
    lastPage,
    fetchAction,
    refreshAction,
    fetchAdditionAction,
  );

  const styles = useStyles({ allJobsEmpty: !allJobs.length });

  const goToJobDetails = (jobId: number | null) => () => {
    history.push(`${path}/${jobId}`, { prevPath: path });
  };

  const goBackCallback = () => {
    history.push(path);
  };

  const onPostNewJob = () => {
    history.push(`${path}`, { prevPath: path, isPostingJob: true });
  };

  const renderJob = (job: Job) => {
    const { id: itemJobId, industryId, status: jobStatus } = job;
    const industry = allIndustries.find((industry) => industry.id === industryId);
    const title = industry?.name ?? '';

    return (
      <Grid key={itemJobId || 0} item xs={12} md={isTabShown ? 12 : 6} lg={isTabShown ? 6 : 4} xl={isTabShown ? 4 : 3}>
        <JobCard
          job={job}
          onPress={goToJobDetails(itemJobId)}
          industry={industry}
          jobStatus={jobStatus}
          title={title}
          isSelected={itemJobId === Number(jobId)}
        />
      </Grid>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.containerLeft}>
        <MainHeader />
        <CustomScrollComponent
          data={jobs}
          renderItem={renderJob}
          isFlatListLoading={isFlatListLoading}
          onEndReached={onEndReached}
          hasMore={hasMore}
          defaultPrimaryText={string.defaultPrimaryText}
          defaultSecondaryText={string.defaultTextSecondary}
          header={
            <div className={styles.filterContainer}>
              {!!allJobs.length && (
                <CustomStatusPicker
                  data={jobStatusesWithAll}
                  setRespondedValue={setJobStateFilterId}
                  respondedValue={jobStatusFilterId}
                />
              )}
              <CustomButton
                type={customButtonType.WHITE}
                title={string.addJob}
                onPress={onPostNewJob}
                icon={require('@root/web/assets/icons/add.svg')}
                maxWidth={120}
                ellipse
              />
            </div>
          }
          footer={<MainFooter />}
        />
      </div>
      {(isPostingJob || jobId) && <CustomTabContainer isCarcass />}
      {isPostingJob && <JobActionScreen mode={jobActionScreenMode.POST} goBackCallback={goBackCallback} />}
      {jobId && <JobDetailsScreen jobId={Number(jobId)} goBackCallback={goBackCallback} />}
      <ToastContainer autoClose={WEB_NOTIFICATION_TIMEOUT} />
    </div>
  );
};

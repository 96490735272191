import React, { Dispatch, forwardRef, ForwardRefRenderFunction, SetStateAction } from 'react';
import { Modal } from '@material-ui/core';
import { CityLocationModalType } from '@root/mobile/navigators/types';
import { useStyles } from './styles';
import { useChooseCityModalScreen } from '@root/hooks/useChooseCityModalScreen';
import { SearchInput } from '@root/web/components/SearchInput';
import { CityType } from '@root/general/types';
import { Scrollbars } from 'react-custom-scrollbars';
import { CustomLoader } from '@root/web/components/CustomLoader';
import { modalStyle } from '@root/general/consts';

type ChooseCityModalProps = {
  isModalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
} & CityLocationModalType;

const ChooseCityModalComponent: ForwardRefRenderFunction<any, ChooseCityModalProps> = (props, ref: any) => {
  const styles = useStyles();

  const {
    isModalVisible,
    setModalVisible,
    selectedStateId,
    setSelectedCityId = () => {},
    setValue = () => {},
    setCity = () => {},
  } = props;

  const closeModal = () => {
    setModalVisible(false);
  };

  const [cities, onSearch, searchValue, onPress, isLoading] = useChooseCityModalScreen(
    selectedStateId,
    setSelectedCityId,
    setValue,
    closeModal,
    setCity,
  );

  const renderItem = (item: CityType) => {
    const { id, name, state } = item;
    const stateName = !selectedStateId && state ? `, ${state.name}` : '';

    return (
      <span key={id || 0} className={styles.item} onClick={() => onPress(item)}>
        {`${name}${stateName}`}
      </span>
    );
  };

  return (
    <Modal
      open={isModalVisible}
      style={modalStyle}
      onClose={closeModal}
      className={styles.modalContainer}
      container={ref?.current}
    >
      <div className={styles.container}>
        <SearchInput value={searchValue} placeholder={'Search'} onChange={onSearch} />
        <Scrollbars className={styles.scrollbars}>{cities.map(renderItem)}</Scrollbars>
        {isLoading && <CustomLoader />}
      </div>
    </Modal>
  );
};

export const ChooseCityModal = forwardRef(ChooseCityModalComponent);

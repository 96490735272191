import Tabs from '@material-ui/core/Tabs';
import React, { ChangeEvent, Children, cloneElement, isValidElement, useEffect, useState } from 'react';
import Tab from '@material-ui/core/Tab';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { getIndustries } from '@root/selectors';
import { useHistory } from 'react-router-dom';
import { Industry } from '@root/general/types';
import clsx from 'clsx';

export type SpecificationTopNavigatorProps = {
  industry: Industry;
  specificationName?: string;
  path?: string;
  children: React.ReactNode;
  isNavHidden?: boolean;
  specificationRightButton?: JSX.Element;
};

export const SpecificationsTopNavigator = (props: SpecificationTopNavigatorProps) => {
  const { industry, specificationName, path, children, specificationRightButton } = props;

  const styles = useStyles(props);
  const history = useHistory();

  const { allSpecifications } = useSelector(getIndustries);
  const specifications = allSpecifications.filter((specification) => specification.industryId === industry.id);

  const initialTabIndex = specifications.findIndex(
    (specification) => specification.name.toLowerCase() === specificationName?.toLowerCase(),
  );

  const [selectedTab, setSelectedTab] = useState(initialTabIndex < 0 ? 0 : initialTabIndex);

  useEffect(() => {
    if (!specificationName || initialTabIndex < 0) {
      setSelectedTab(0);
      history.push(`${path}/${specifications[0].name.toLowerCase()}`);
    }
  }, [path, specifications, specificationName, initialTabIndex, history]);

  const handleChange = (event: ChangeEvent<{}>, tabIndex: number) => {
    setSelectedTab(tabIndex);
    history.push(`${path}/${specifications[tabIndex].name.toLowerCase()}`);
  };

  const renderChildren = (specificationId: number, industry: Industry) =>
    Children.map(children, (child) => {
      // Checking isValidElement is the safe way and avoids a TS error too.
      return isValidElement(child) ? cloneElement(child, { specificationId, industry }) : child;
    });

  return (
    <div className={styles.specificationContainer}>
      <div className={styles.topBarContainer}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          classes={{ indicator: styles.tabsIndicator }}
          className={styles.tabsContainer}
          variant={'scrollable'}
          scrollButtons={'auto'}
        >
          {specifications.map((specification, index) => {
            const { id, name } = specification;
            const textStyle = `${styles.tabTextStyle} ${selectedTab === index ? styles.tabTextStyleSelected : ''}`;

            return <Tab key={id} label={name} classes={{ root: clsx(styles.tabRoot, textStyle) }} />;
          })}
        </Tabs>
        {specificationRightButton && <div className={styles.rightButtonContainer}>{specificationRightButton}</div>}
      </div>
      {specifications.map((specification, index) => (
        <div key={specification.id} hidden={selectedTab !== index} className={styles.specificationChildContainer}>
          {selectedTab === index && renderChildren(specification.id, industry)}
        </div>
      ))}
    </div>
  );
};

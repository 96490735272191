import { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteAccountAction } from '@root/redux/actions';

type UseDeleteAccountScreenReturnType = {
  onDeleteAccountPress: () => void;
  isLoading: boolean;
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
};

export const useDeleteAccountScreen = (): UseDeleteAccountScreenReturnType => {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const onDeleteAccountPress = () => {
    setLoading(true);

    const callback = () => {
      setLoading(false);
    };

    dispatch(deleteAccountAction({ callback }));
  };

  return { onDeleteAccountPress, isLoading, isModalVisible, setIsModalVisible };
};

import { makeStyles } from '@material-ui/core/styles';
import { fonts } from '@root/general/fonts';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles({
  radiusContainer: {
    display: 'flex',
    flex: 1,
    position: 'absolute',
    zIndex: 1,
    left: '50%' /* position the left edge of the element at the middle of the parent */,
    transform: 'translate(-50%, 20px)',
  },
  radiusButton: {
    margin: '0px 4px',
    padding: '12px 20px',
    fontSize: 15,
    fontFamily: fonts.IBMPlexMonoMedium,
    lineHeight: '22px',
    letterSpacing: -0.41,
    color: colors.black,
    textTransform: 'uppercase',
    backgroundColor: colors.white,
    borderRadius: 30,
    borderColor: colors.athensGray,
    borderWidth: 1,
    borderStyle: 'solid',

    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: colors.athensGray,
    },
  },
  radiusButtonSelected: {
    color: colors.red,
  },
});

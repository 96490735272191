import React, { Dispatch, forwardRef, ForwardRefRenderFunction, SetStateAction, useState } from 'react';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { Modal } from '@material-ui/core';
import clsx from 'clsx';
import { CARD_ELEMENT_OPTIONS, useStyles } from './styles';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { strings } from '@root/strings';
import { CustomLoader } from '@root/web/components/CustomLoader';
import { modalStyle } from '@root/general/consts';

const { myCardsScreen: string } = strings;

type AddCardModalProps = {
  isModalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  onAddNewCardWeb: (callback?: (isSuccess: boolean) => void) => void;
};

export const AddCardModalContainer: ForwardRefRenderFunction<any, AddCardModalProps> = (
  props: AddCardModalProps,
  ref: any,
) => {
  const { isModalVisible, setModalVisible, onAddNewCardWeb } = props;
  const styles = useStyles();

  const [isLoading, setIsLoading] = useState(false);

  const onAddNewCard = () => {
    const callback = (isSuccess: boolean) => {
      isSuccess && setModalVisible(false);
      setIsLoading(false);
    };

    setIsLoading(true);
    onAddNewCardWeb(callback);
  };

  return (
    <Modal
      className={styles.modalContainer}
      style={modalStyle}
      open={isModalVisible}
      onClose={() => setModalVisible(false)}
      container={ref?.current}
    >
      <div className={styles.container}>
        <img src={require('@root/web/assets/icons/addCardView.svg')} alt='' className={styles.cardImage} />
        <div className={styles.cardElementsContainer}>
          <CardNumberElement options={CARD_ELEMENT_OPTIONS} className={clsx(styles.cardElement, styles.cardNumber)} />
          <CardExpiryElement options={CARD_ELEMENT_OPTIONS} className={clsx(styles.cardElement, styles.cardMonth)} />
          <CardCvcElement options={CARD_ELEMENT_OPTIONS} className={clsx(styles.cardElement, styles.cardCVC)} />
        </div>
        <CustomButton
          type={customButtonType.RED}
          title={string.saveCard}
          onPress={onAddNewCard}
          disabledTopBorder
          disabled={isLoading}
        />
        {isLoading && <CustomLoader />}
      </div>
    </Modal>
  );
};

export const AddCardModal = forwardRef(AddCardModalContainer);

import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { adBannerHeight } from '@root/web/components/AdBanner/styles';
import { CustomTabContainerProps } from '@root/web/components/CustomTabContainer/index';

export const useStyles = makeStyles((theme: Theme) => ({
  customTabContainer: ({ isHidden = false, tabPositionFixed = false, isCarcass = false }: CustomTabContainerProps) => {
    return {
      display: isHidden ? 'none' : 'flex',
      flexDirection: 'column',
      width: 450,
      height: tabPositionFixed ? `calc(100% - ${adBannerHeight}px)` : '100%',
      position: tabPositionFixed ? 'fixed' : 'static',
      top: adBannerHeight + 1, // 1 - borderBottomWidth
      right: 0,
      zIndex: isCarcass ? 0 : 1,
      backgroundColor: colors.white,
      borderWidth: '0px 0px 0px 1px',
      borderStyle: 'solid',
      borderColor: colors.athensGray,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    };
  },
  customTabContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
  },
}));

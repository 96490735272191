import { Dispatch, SetStateAction } from 'react';

export enum profileTabsMode {
  SWITCH_ROLE = 'SWITCH_ROLE',
  ADD_REFERENCE = 'ADD_REFERENCE',
  EDIT_REFERENCE = 'EDIT_REFERENCE',
  ADD_LOCATION = 'ADD_LOCATION',
  EDIT_LOCATION = 'EDIT_LOCATION',
  ADD_PAYMENT = 'ADD_PAYMENT',
}

export type ProfileScreensProps = {
  tabMode: profileTabsMode | null;
  setTabMode: Dispatch<SetStateAction<profileTabsMode | null>>;
};

export type ProfileRootScreenProps = {
  path: string;
  token?: string;
  deleteAccountToken?: string;
};

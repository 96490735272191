import Tabs from '@material-ui/core/Tabs';
import React, { ChangeEvent, Children, cloneElement, CSSProperties, isValidElement, useEffect, useState } from 'react';
import Tab from '@material-ui/core/Tab';
import { useStyles } from './styles';
import { SpecificationsTopNavigator } from '@root/web/navigators/SpecificationsTopNavigator';
import { useSelector } from 'react-redux';
import { getIndustries } from '@root/selectors';
import { Redirect, useHistory, useParams } from 'react-router-dom';

export type IndustryTopNavigatorProps = {
  path: string;
  needSpecification?: boolean;
  specificationRightButton?: JSX.Element;
  children: React.ReactNode;
  isNavHidden?: boolean;
  navigationBarWidth?: number;
};

export const IndustryTopNavigator = (props: IndustryTopNavigatorProps) => {
  const { path = '', needSpecification, children, isNavHidden, specificationRightButton } = props;

  const styles = useStyles(props);
  const history = useHistory();

  const { industryName, specificationName }: { industryName?: string; specificationName?: string } = useParams();
  const { allIndustries } = useSelector(getIndustries);

  const initialTabIndex = allIndustries.findIndex(
    (industry) => industry.name.toLowerCase() === industryName?.toLowerCase(),
  );

  const [selectedTab, setSelectedTab] = useState(initialTabIndex < 0 ? 0 : initialTabIndex);

  useEffect(() => {
    if (!industryName || initialTabIndex < 0) {
      setSelectedTab(0);
      history.push(`${path}/${allIndustries[0].name.toLowerCase()}`);
    } else {
      setSelectedTab(initialTabIndex);
    }
  }, [path, allIndustries, industryName, initialTabIndex, history]);

  const handleChange = (event: ChangeEvent<{}>, tabIndex: number) => {
    setSelectedTab(tabIndex);
    history.push(`${path}/${allIndustries[tabIndex].name.toLowerCase()}`);
  };

  if (industryName && initialTabIndex === -1) {
    return <Redirect to={history.location.pathname} />;
  }

  const renderChildren = (industryId: number) =>
    Children.map(children, (child) => {
      // Checking isValidElement is the safe way and avoids a TS error too.
      return isValidElement(child) ? cloneElement(child, { industryId }) : child;
    });

  return (
    <div className={styles.industryContainer}>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        classes={{
          root: styles.tabsContainer,
          indicator: styles.tabsIndicator,
          scroller: styles.tabsFlexContainer,
          flexContainer: styles.tabsFlexContainer,
        }}
        variant={'scrollable'}
        scrollButtons={'auto'}
      >
        {allIndustries.map((industry, index) => {
          const { id, color, name } = industry;
          const indicatorStyle: CSSProperties = {
            borderBottomColor: color,
            borderBottomWidth: selectedTab === index ? 3 : 0,
            borderBottomStyle: 'solid',
          };

          return <Tab key={id} label={name} classes={{ root: styles.tabRoot }} style={indicatorStyle} />;
        })}
      </Tabs>
      {allIndustries.map((industry, index) => {
        const { id: industryId } = industry;
        return (
          <div key={industryId} hidden={selectedTab !== index} className={styles.industryChildContainer}>
            {selectedTab === index &&
              (needSpecification ? (
                <SpecificationsTopNavigator
                  industry={industry}
                  specificationName={specificationName}
                  path={`${path}/${allIndustries[selectedTab].name.toLowerCase()}`}
                  isNavHidden={isNavHidden}
                  specificationRightButton={specificationRightButton}
                >
                  {children}
                </SpecificationsTopNavigator>
              ) : (
                renderChildren(industryId)
              ))}
          </div>
        );
      })}
    </div>
  );
};

import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { getStates } from '@root/selectors';
import { ArrayStateType, CityType, StateType } from '@root/general/types';

type UseChooseStateModalScreenReturnType = [
  string,
  Dispatch<SetStateAction<string>>,
  ArrayStateType,
  (state: StateType) => void,
];

export const useChooseStateModalScreenReturnType = (
  setValue: (e: string | ChangeEvent<any>) => void,
  setSelectedStateId: Dispatch<SetStateAction<number | null>>,
  setSelectedCityId: Dispatch<SetStateAction<number | null>>,
  setCityValue: (e: string | ChangeEvent<any>) => void,
  setState: Dispatch<SetStateAction<StateType[] | null>>,
  setCity: Dispatch<SetStateAction<CityType | null>>,
  callback: () => void,
  setRadius: Dispatch<SetStateAction<number | null>>,
  isOneState: boolean,
): UseChooseStateModalScreenReturnType => {
  const [searchValue, setSearchValue] = useState('');
  const states = useSelector(getStates);

  const filteredStates = states.filter((state: StateType) => {
    return state.name?.toLowerCase().startsWith(searchValue.toLowerCase());
  });

  const onPress = (state: StateType) => {
    const { id, name, abbreviation } = state;

    setSelectedCityId(null);
    setCityValue('');
    setSelectedStateId(id);
    setValue(`${name}, ${abbreviation}`);
    if (isOneState) {
      setState([state]);
    } else {
      setState((prev) => {
        if (prev) {
          const selectedState = prev.find((item) => item.id === state.id);

          if (selectedState) {
            return prev.filter((item) => item.id !== state.id);
          } else {
            return [...prev, state];
          }
        }

        return [state];
      });
    }

    setRadius(null);
    setCity(null);

    isOneState && callback();
  };

  return [searchValue, setSearchValue, filteredStates ?? [], onPress];
};

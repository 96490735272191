import { RenderJobInfoArray } from '@root/mobile/components/JobDetails/types';
import { Job } from '@root/general/types';
import { useSelector } from 'react-redux';
import { getIndustryById, getSpecificationById, getUser } from '@root/selectors';
import { accountType } from '@root/general/consts';
import { getRateTypeName } from '@root/mobile/utils/getRateTypeName';
import { getFarmDate } from '@root/mobile/utils/getFarmDate';
import { strings } from '@root/strings';
import { getLocationAddress } from '@root/utils/getLocationAddress';

const { jobDetails: string, paymentTypes } = strings;

type UseJobDetailsReturnType = [RenderJobInfoArray];

export enum jobDescription {
  jobName,
  industry,
  specification,
  workPeriod,
  farmName,
  location,
  rate,
  peopleAmount,
  description,
  employer,
  paymentType,
}

export const useJobDetails = (jobInfo: Job, goToEmployerInfo: () => void): UseJobDetailsReturnType => {
  const {
    industryId,
    specificationId,
    farm,
    state,
    city,
    name: jobName,
    startDate,
    peopleAmount,
    endDate,
    rateTypeId,
    rate,
    description,
    paymentType,
  } = jobInfo;

  const { roleId } = useSelector(getUser);
  const isEmployer = roleId === accountType.EMPLOYER;

  const industry = useSelector(getIndustryById(industryId));
  const specification = useSelector(getSpecificationById(specificationId));

  const industryName = industry?.name ?? '';
  const specificationName = specification?.name ?? '';
  const { monthlyPayment, instantPayment } = paymentTypes;

  const rateTypeName = getRateTypeName(rateTypeId, industry);
  const farmAddress = getLocationAddress(state, city);
  const paymentMethod = paymentType === 0 ? instantPayment : monthlyPayment;

  const farmName = farm?.name ?? '';

  const renderJobInfoData: RenderJobInfoArray = [
    {
      id: jobDescription.jobName,
      label: string.nameLabel,
      value: jobName,
      icon: require('@root/assets/icons/jobName.png'),
      iconWeb: require('@root/web/assets/icons/jobName.svg'),
    },
    {
      id: jobDescription.industry,
      label: string.industryLabel,
      value: industryName,
      icon: require('@root/assets/icons/industry.png'),
      iconWeb: require('@root/web/assets/icons/industry.svg'),
    },
    {
      id: jobDescription.specification,
      label: string.specificationLabel,
      value: specificationName,
      icon: require('@root/assets/icons/specification.png'),
      iconWeb: require('@root/web/assets/icons/specification.svg'),
    },
    {
      id: jobDescription.workPeriod,
      label: string.workPeriodLabel,
      value: getFarmDate(startDate, endDate),
      icon: require('@root/assets/icons/period.png'),
      iconWeb: require('@root/web/assets/icons/period.svg'),
    },
    {
      id: jobDescription.paymentType,
      label: string.paymentType,
      icon: require('@root/assets/icons/paymentType.png'),
      iconWeb: require('@root/web/assets/icons/paymentType.svg'),
      value: paymentMethod,
    },
    {
      id: jobDescription.farmName,
      label: string.locationNameLabel,
      value: farmName,
      icon: require('@root/assets/icons/farmName.png'),
      iconWeb: require('@root/web/assets/icons/farmName.svg'),
    },
    {
      id: jobDescription.location,
      label: string.locationLabel,
      value: farmAddress,
      icon: require('@root/assets/icons/Location.png'),
      iconWeb: require('@root/web/assets/icons/locationMarker.svg'),
    },
    {
      id: jobDescription.rate,
      label: string.salaryLabel,
      icon: require('@root/assets/icons/salary.png'),
      iconWeb: require('@root/web/assets/icons/salary.svg'),
      value: rate,
      extraValue: rateTypeName,
    },
    {
      id: jobDescription.peopleAmount,
      label: string.howManyLaborersLabel,
      value: peopleAmount,
      icon: require('@root/assets/icons/howManyLaborers.png'),
      iconWeb: require('@root/web/assets/icons/howManyLaborers.svg'),
    },
    {
      id: jobDescription.description,
      label: string.descriptionLabel,
      value: description,
      icon: require('@root/assets/icons/description.png'),
      iconWeb: require('@root/web/assets/icons/description.svg'),
      multiline: true,
    },
  ];

  const renderJobInfoDataForLaborer: RenderJobInfoArray = [
    {
      id: jobDescription.employer,
      label: string.employerLabel,
      onPress: goToEmployerInfo,
      icon: require('@root/assets/icons/contactprof.png'),
      iconWeb: require('@root/web/assets/icons/profile.svg'),
      value: null,
    },
  ];

  const getJobInfoData = (): RenderJobInfoArray => {
    if (isEmployer) {
      return renderJobInfoData;
    } else {
      renderJobInfoData.splice(4, 1);
      return [...renderJobInfoData, ...renderJobInfoDataForLaborer];
    }
  };

  const jobInfoData = getJobInfoData();

  return [jobInfoData];
};

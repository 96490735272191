import React from 'react';
import { useStyles } from './styles';

type MapMarkerProps = {
  isSelected: boolean;
  text: number;
  onPress: () => void;
};

export const MapMarker = (props: MapMarkerProps) => {
  const { isSelected, text, onPress } = props;
  const styles = useStyles();

  return isSelected ? (
    <img src={require('@root/web/assets/icons/chosenLocationMarker.svg')} alt={''} />
  ) : (
    <div className={styles.markerContainer} onClick={onPress}>
      <span className={styles.markerText}>{text}</span>
    </div>
  );
};

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles({
  jobStatusSwitcherContainer: {
    marginTop: 10,
    backgroundColor: colors.lightGrey,
    padding: '14px 28px',
    '&:disabled': {
      backgroundColor: `${colors.lightGrey} !important`,
    },
  },
  buttonText: {
    textTransform: 'none',
    fontSize: 15,
    fontFamily: fonts.SFUiDisplayBold,
    lineHeight: '20px',
    letterSpacing: -0.24,
  },
});

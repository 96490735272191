import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';
import { SpecificationTopNavigatorProps } from '@root/web/navigators/SpecificationsTopNavigator/index';

export const useStyles = makeStyles(() => ({
  specificationContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
    backgroundColor: colors.lightGrey,
  },
  tabsContainer: ({ isNavHidden }: SpecificationTopNavigatorProps) => {
    return {
      display: isNavHidden ? 'none' : 'flex',
    };
  },
  tabsIndicator: {
    backgroundColor: 'transparent',
  },
  tabTextStyle: {
    color: colors.darkGrey,
    fontSize: 15,
    fontFamily: fonts.IBMPlexMonoMedium,
    lineHeight: '22px',
    letterSpacing: -0.41,
    textTransform: 'uppercase',
    borderStyle: 'solid',
    borderRadius: 5,
    borderColor: colors.athensGray,
    borderWidth: 2,
  },
  tabTextStyleSelected: {
    color: colors.red,
    borderColor: colors.red,
  },
  tabRoot: {
    marginRight: 10,
    minWidth: 120,
    '&:hover': {
      borderColor: colors.red,
    },
  },
  topBarContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '20px 30px 0px',
  },
  specificationChildContainer: {
    flex: 1,
  },
  rightButtonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    marginLeft: 10,
  },
}));

import { useState, Dispatch, SetStateAction, useEffect, useRef } from 'react';
import {
  getAuth,
  getFieldReps,
  getIndustries,
  getSpecificationByIndustryIdsWithTitle,
  getStates,
} from '@root/selectors';
import {
  fetchFieldRepsAction,
  fetchIndustriesWithSpecificationsAction,
  signUpRequestAction,
} from '@root/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { accountType, WEB } from '@root/general/consts';
import {
  FormikFieldFormDataType,
  FormikHandleChangeType,
  FormikValuesType,
  InputFieldType,
} from '@root/mobile/types/inputTypes';
import { EmployerEditData, EmployerSignUpData, OnlyLaborerData } from '@root/redux/reducers/types';
import { ArrayIndustries, FieldReps } from '@root/general/types';
import { strings } from '@root/strings';
import { useChooseCityModalScreen } from '@root/hooks/useChooseCityModalScreen';
import { editUserAction } from '@root/redux/actions/authenticationActions';
import { showToastAction, ToastTypes } from '@root/redux/actions/commonActions';
import { arrayToString } from '@root/utils/arrayToString';

const { userProfileActionsScreen: userProfile, warnings } = strings;

enum fieldIds {
  name = 0,
  email = 1,
  password = 2,
  passwordConfirmation = 3,
  phone = 4,
  state = 5,
  city = 6,
  zipCode = 7,
  industry = 8,
  specifications = 9,
  rate = 10,
  description = 11,
  fieldRep = 12,
}

type UseRegistrationScreenReturnType = [
  FormikFieldFormDataType,
  FormikValuesType,
  (data: FormikValuesType, onSuccessCallback?: (token?: string) => void) => void,
  number[],
  Dispatch<SetStateAction<number[]>>,
  number[],
  Dispatch<SetStateAction<number[]>>,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  ArrayIndustries,
  any,
  () => boolean,
  any,
  boolean,
  Dispatch<SetStateAction<number | null>>,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  accountType | null,
  Dispatch<SetStateAction<number | null>>,
  Dispatch<SetStateAction<number | null>>,
  number | null,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  FieldReps,
  (fieldRepId: number) => void,
  InputFieldType[],
  () => number | null,
];

export type OnStatePressType = (
  setSelectedStateId: Dispatch<SetStateAction<number | null>>,
  setSelectedCityId: Dispatch<SetStateAction<number | null>>,
) => (setValue?: FormikHandleChangeType, setCityValue?: FormikHandleChangeType) => void;

export type OnCityPressType = (
  selectedStateId: number | null,
  setSelectedCityId: Dispatch<SetStateAction<number | null>>,
) => (setValue?: FormikHandleChangeType) => void;

export const useUserProfileScreen = (
  uniqueId: string = WEB,
  onStatePress: OnStatePressType = () => () => {},
  onCityPress: OnCityPressType = () => () => {},
): UseRegistrationScreenReturnType => {
  const dispatch = useDispatch();

  const formikRef = useRef<any>();
  const { user, token } = useSelector(getAuth);
  const {
    name,
    email,
    phone,
    zipCode,
    state,
    city,
    roleId,
    rate,
    industries,
    specifications,
    description,
    fieldRepId,
    fieldRep,
  } = user;
  const { allIndustries } = useSelector(getIndustries);
  const fieldReps = useSelector(getFieldReps);
  const { id: stateId, name: stateName } = state;
  const { id: cityId, name: cityName } = city;

  const industriesNames = industries?.map((industry) => industry.name) ?? [];
  const industriesIds = industries?.map((industry) => industry.id) ?? [];
  const industriesString = arrayToString(industriesNames);

  const specificationsNames = specifications?.map((specification) => specification.name) ?? [];
  const specificationsIds = specifications?.map((specification) => specification.id) ?? [];
  const specificationsString = arrayToString(specificationsNames);

  const [currentRole, setCurrentRole] = useState<number | null>(roleId);
  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const [hideRepeatPassword, setHideRepeatPassword] = useState<boolean>(true);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [fieldRepModalVisible, setFieldRepModalVisible] = useState(false);

  const [industryModalVisible, setIndustryModalVisible] = useState<boolean>(false);
  const [specificationsModalVisible, setSpecificationsModalVisible] = useState<boolean>(false);

  const [selectedIndustryIds, setSelectedIndustryIds] = useState<Array<number>>(industriesIds);
  const [selectedSpecificationIds, setSelectedSpecificationIds] = useState<Array<number>>(specificationsIds);
  const [selectedStateId, setSelectedStateId] = useState<number | null>(stateId);
  const [selectedCityId, setSelectedCityId] = useState<number | null>(cityId);
  const [selectedFieldRepId, setSelectedFieldRepId] = useState<number | null>(fieldRepId);

  const specificationsByIndustries = useSelector(getSpecificationByIndustryIdsWithTitle(selectedIndustryIds));

  useEffect(() => {
    roleId && setCurrentRole(roleId);
  }, [roleId]);

  const specificationsValidation = () => {
    return specificationsByIndustries.every(({ data }) => {
      return data.some((specification) => {
        return selectedSpecificationIds.includes(specification.id);
      });
    });
  };

  useEffect(() => {
    formikRef?.current?.validateForm();
  }, [selectedIndustryIds, selectedSpecificationIds]);

  const [isLoading, setLoading] = useState(false);

  const getInitialRate = () => {
    if (rate) {
      return uniqueId === 'web' ? `${rate}` : `$${rate}`;
    } else {
      return '';
    }
  };

  const formInitialValues = {
    name,
    email,
    password: '',
    passwordConfirmation: '',
    phone,
    state: stateName,
    city: cityName,
    zipCode,
    industry: industriesString,
    specifications: specificationsString,
    rate: getInitialRate(),
    description: description ? description : '',
    fieldRep: fieldRep?.name || '',
  } as FormikValuesType;

  const states = useSelector(getStates);
  const [cities, setSearchValue] = useChooseCityModalScreen(selectedStateId, setSelectedCityId);

  const isRegistration = !token;

  useEffect(() => {
    dispatch(fetchIndustriesWithSpecificationsAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchFieldRepsAction());
  }, [dispatch]);

  const registrationFields: FormikFieldFormDataType = isRegistration
    ? [
        {
          id: fieldIds.email,
          title: userProfile.emailTitle,
          valuesName: 'email',
          placeHolder: userProfile.emailPlaceholder,
          keyboardType: 'email-address',
        },
        {
          id: fieldIds.password,
          title: userProfile.passwordTitle,
          valuesName: 'password',
          placeHolder: userProfile.passwordPlaceholder,
          keyboardType: 'default',
          fieldStatus: hidePassword,
          setFieldStatus: setHidePassword,
          icon: require('@root/assets/icons/invisibleInput.png'),
          changedIcon: require('@root/assets/icons/visibleInput.png'),
          webIcon: require('@root/web/assets/icons/hidePassword.svg'),
          webChangedIcon: require('@root/web/assets/icons/visiblePassword.svg'),
        },
        {
          id: fieldIds.passwordConfirmation,
          title: userProfile.passwordConfirmation,
          valuesName: 'passwordConfirmation',
          placeHolder: userProfile.passwordPlaceholder,
          keyboardType: 'default',
          fieldStatus: hideRepeatPassword,
          setFieldStatus: setHideRepeatPassword,
          icon: require('@root/assets/icons/invisibleInput.png'),
          changedIcon: require('@root/assets/icons/visibleInput.png'),
          webIcon: require('@root/web/assets/icons/hidePassword.svg'),
          webChangedIcon: require('@root/web/assets/icons/visiblePassword.svg'),
        },
      ]
    : [];

  const employerFieldFormData: FormikFieldFormDataType = [
    {
      id: fieldIds.name,
      title: userProfile.name,
      valuesName: 'name',
      placeHolder: userProfile.name,
      keyboardType: 'default',
    },
    ...registrationFields,
    {
      id: fieldIds.phone,
      title: userProfile.phoneTitle,
      valuesName: 'phone',
      placeHolder: userProfile.phonePlaceholder,
      keyboardType: 'phone-pad',
      mask: '[000]-[000]-[0000]',
      webMask: '999-999-9999',
    },
    {
      id: fieldIds.zipCode,
      title: userProfile.codeTitle,
      valuesName: 'zipCode',
      placeHolder: userProfile.codePlaceholder,
      keyboardType: 'number-pad',
      mask: '[00000]-[00000]',
      webMask: '99999-99999',
      sm: !isRegistration ? 12 : undefined,
    },
    {
      id: fieldIds.state,
      title: userProfile.state,
      valuesName: 'state',
      placeHolder: userProfile.state,
      onPress: onStatePress(setSelectedStateId, setSelectedCityId),
      options: states,
      autocomplete: true,
      icon: require('@root/assets/icons/arrowRight.png'),
      webIcon: require('@root/web/assets/icons/arrowRight.svg'),
      autoLength: true,
      setId: setSelectedStateId,
    },
    {
      id: fieldIds.city,
      title: userProfile.cityTitle,
      valuesName: 'city',
      placeHolder: userProfile.cityPlaceholder,
      onPress: onCityPress(selectedStateId, setSelectedCityId),
      options: cities,
      autocomplete: true,
      icon: require('@root/assets/icons/arrowRight.png'),
      webIcon: require('@root/web/assets/icons/arrowRight.svg'),
      autoLength: true,
      setId: setSelectedCityId,
      onChange: (value) => {
        setSearchValue(value);
      },
    },
    {
      id: fieldIds.fieldRep,
      title: userProfile.fieldRepTitle,
      valuesName: 'fieldRep',
      placeHolder: userProfile.fieldRepPlaceholder,
      onPress: () => setFieldRepModalVisible(!fieldRepModalVisible),
      icon: require('@root/assets/icons/arrowRight.png'),
      webIcon: require('@root/web/assets/icons/arrowRight.svg'),
      sm: 12,
    },
  ];

  const onlyLaborerFieldFormData: FormikFieldFormDataType = [
    {
      id: fieldIds.industry,
      title: userProfile.industry,
      valuesName: 'industry',
      placeHolder: userProfile.industry,
      onPress: () => setIndustryModalVisible(!industryModalVisible),
      icon: require('@root/assets/icons/arrowRight.png'),
      webIcon: require('@root/web/assets/icons/arrowRight.svg'),
      selectable: true,
      sm: 12,
    },
    {
      id: fieldIds.specifications,
      title: userProfile.specifications,
      valuesName: 'specifications',
      placeHolder: userProfile.specifications,
      onPress: () => {
        if (selectedIndustryIds.length) {
          setSpecificationsModalVisible(!specificationsModalVisible);
        } else {
          dispatch(showToastAction({ message: warnings.selectIndustry, type: ToastTypes.Error }));
        }
      },
      icon: require('@root/assets/icons/arrowRight.png'),
      webIcon: require('@root/web/assets/icons/arrowRight.svg'),
      selectable: true,
      sm: 12,
    },
    {
      id: fieldIds.rate,
      title: userProfile.salaryLabel,
      valuesName: 'rate',
      placeHolder: '0',
      keyboardType: 'decimal-pad',
      mask: '$[999990].[90]',
      sm: 12,
    },
    {
      id: fieldIds.description,
      title: userProfile.descriptionLabel,
      valuesName: 'description',
      placeHolder: userProfile.descriptionPlaceholder,
      keyboardType: 'default',
      multiline: true,
      sm: 12,
    },
  ];

  const isLaborer = currentRole === accountType.LABORER;
  const formFields = isLaborer ? [...employerFieldFormData, ...onlyLaborerFieldFormData] : employerFieldFormData;

  const handleSetFieldRepId = (fieldRepId: number) => {
    setSelectedFieldRepId(fieldRepId);
  };

  const formFieldsValidation = formFields.filter((formField) => formField.valuesName !== 'fieldRep');

  const getFieldRepId = () => {
    return fieldReps.find((fieldRep) => fieldRep.id === selectedFieldRepId)?.id || null;
  };

  const formHandleSubmit = (data: FormikValuesType, onSuccessCallback?: (token?: string) => void) => {
    const callback = (isSuccess: boolean, token?: string) => {
      isSuccess && onSuccessCallback && onSuccessCallback(token);
      setLoading(false);
    };

    setLoading(true);

    if (isRegistration) {
      const { name, email, password, passwordConfirmation, phone, zipCode, rate, description } = data;

      const employerData: EmployerSignUpData = {
        name,
        email,
        password,
        passwordConfirmation,
        phone,
        stateId: selectedStateId,
        cityId: selectedCityId,
        zipCode,
        roleId: currentRole,
        fieldRepId: selectedFieldRepId,
      };
      const laborerData: OnlyLaborerData = {
        rate: Number(rate.replace('$', '')),
        industriesIds: selectedIndustryIds,
        specificationsIds: selectedSpecificationIds,
        description: description.length > 0 ? description : undefined,
      };

      const signUpData = isLaborer ? { ...employerData, ...laborerData } : employerData;

      dispatch(signUpRequestAction({ signUpData, uniqueId, callback }));
    } else {
      const { name, phone, zipCode, rate, description } = data;

      const employerData: EmployerEditData = {
        name,
        phone,
        stateId: selectedStateId,
        cityId: selectedCityId,
        zipCode,
        roleId,
        fieldRepId: selectedFieldRepId,
      };

      const laborerData: OnlyLaborerData = {
        rate: Number(rate.replace('$', '')),
        industriesIds: selectedIndustryIds,
        specificationsIds: selectedSpecificationIds,
        description: description.length > 0 ? description : null,
      };

      const editUserData = isLaborer ? { ...employerData, ...laborerData } : employerData;

      dispatch(
        editUserAction({
          editUserData,
          deviceId: uniqueId,
          callback,
        }),
      );
    }
  };

  return [
    formFields,
    formInitialValues,
    formHandleSubmit,
    selectedIndustryIds,
    setSelectedIndustryIds,
    selectedSpecificationIds,
    setSelectedSpecificationIds,
    industryModalVisible,
    setIndustryModalVisible,
    specificationsModalVisible,
    setSpecificationsModalVisible,
    allIndustries,
    specificationsByIndustries,
    specificationsValidation,
    formikRef,
    isLoading,
    setCurrentRole,
    termsAndConditions,
    setTermsAndConditions,
    currentRole,
    setSelectedStateId,
    setSelectedCityId,
    selectedStateId,
    privacyPolicy,
    setPrivacyPolicy,
    fieldRepModalVisible,
    setFieldRepModalVisible,
    fieldReps,
    handleSetFieldRepId,
    formFieldsValidation,
    getFieldRepId,
  ];
};

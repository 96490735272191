import apisauce, { ApiErrorResponse, ApiOkResponse } from 'apisauce';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseKeys from 'camelcase-keys';

import { accountType, adScreenType, GENERAL_HEADERS, JOBS_STATUS, offersType, TIMEOUT } from '@root/general/consts';
import {
  ChangeEmailData,
  ChangePasswordData,
  EditJobData,
  EmployerEditData,
  EmployerSignUpData,
  FarmData,
  LaborerEditData,
  LaborerSignUpData,
  PostJobData,
  SignInData,
} from '@root/redux/reducers/types';
import {
  FetchCitiesFilterType,
  FetchDebtsFilterType,
  FetchJobsFilterType,
  FetchPaymentsFilterType,
} from '@root/redux/actions/types';
import {
  ApiResponseMeta,
  FarmType,
  MessagesResponse,
  ReferenceDataType,
  ReviewsArray,
  StateType,
} from '@root/general/types';
import { LaborersFilter } from '@root/redux/reducers/laborersReducer';
import { JobListingFilter } from '@root/redux/reducers/jobListingReducer';
import compareVersions from 'compare-versions';

export type Api = ReturnType<typeof create>;

export type CustomApiResponse<T, U = T> =
  | (ApiErrorResponse<U> & { meta?: ApiResponseMeta; messages?: MessagesResponse })
  | (ApiOkResponse<T> & { meta?: ApiResponseMeta; messages?: MessagesResponse });

export const create = (baseURL: string, appVersion: string) => {
  const api = apisauce.create({
    baseURL,
    headers: GENERAL_HEADERS,
    timeout: TIMEOUT,
  });

  const maxPerPage = 5000;

  const setHeader = (key: string, value: string) => {
    return api.setHeader(key, value);
  };

  const signUpRequest = (signUpData: LaborerSignUpData | EmployerSignUpData, deviceId: string) => {
    return api.post('registration', {
      ...signUpData,
      deviceId,
    });
  };

  const signOutRequest = () => {
    return api.get('/user/logout');
  };

  const signInRequest = (signInData: SignInData, deviceId: string) => {
    return api.post('login', {
      ...signInData,
      deviceId,
    });
  };

  const deleteAccountRequest = () => {
    return api.delete('user');
  };

  const confirmDeleteAccountRequest = (token: string) => {
    return api.delete(`user/${token}`);
  };

  const fetchUser = () => {
    return api.get('user', { count: 'unreadNotifications,debts' });
  };

  const restorePasswordRequest = (email: string) => {
    return api.post('password-token', { email });
  };

  const resetPasswordRequest = (token: string, password: string, passwordConfirmation: string) => {
    return api.post('password-reset', { token, password, passwordConfirmation });
  };

  const changePasswordRequest = (changePasswordData: ChangePasswordData) => {
    return api.put('user/password', changePasswordData);
  };

  const changeEmailRequest = (changeEmailData: ChangeEmailData) => {
    return api.put('user/email', changeEmailData);
  };

  const confirmEmailChange = (token: string) => {
    return api.put(`user/email/${token}`);
  };

  const editUserRequest = (editUserData: LaborerEditData | EmployerEditData) => {
    return api.put('user', editUserData);
  };

  const editUserRoleRequest = (roleId: number, deviceId: string) => {
    return api.put('user/roles', { roleId, deviceId });
  };

  const editAvatarRequest = (avatar: FormData | null) => {
    if (avatar) {
      return api.post('user/avatar', avatar, {
        headers: { 'content-type': 'multipart/form-data' },
        timeout: TIMEOUT * 5,
      });
    } else {
      return api.delete('user/avatar');
    }
  };

  const fetchRoles = () => {
    return api.get('roles');
  };

  const fetchUserById = (id: number | null) => {
    return api.get(`users/${id}`);
  };

  const fetchLaborersByIndustry = (industryId: number, filter: LaborersFilter, searchValue: any, page: number) => {
    const { specificationId, state, city, radius } = filter;

    const selectedStateIds = (state: StateType[] | null) => {
      return state?.length ? state.map((state) => state.id).join(',') : '';
    };

    const roleFilter = { 'filter[roles.role_id]': accountType.LABORER };
    const industryFilter = industryId ? { 'filter[industries.industry_id]': industryId } : {};
    // const rateFilter = rate ? { 'filter[rate][less_or_equal]': rate } : {};
    const specificationFilter = specificationId ? { 'filter[specifications.specification_id]': specificationId } : {};
    const stateFilter = selectedStateIds(state) && !radius ? { 'filter[state_id]': selectedStateIds(state) } : {};
    const cityFilter = city?.id && !radius ? { 'filter[city_id]': city.id } : {};
    // const ratingFilter = rating ? { 'filter[rating.average][greater_or_equal]': rating } : {};
    const radiusFilter =
      city?.latitude && city?.longitude && radius
        ? { 'filter[city.radius][less_or_equal]': `${city.latitude},${city.longitude},${radius}` }
        : {};
    const valueFilter = searchValue ? { 'filter[name][like]': `${searchValue}%` } : {};

    const resultFilter = {
      ...roleFilter,
      ...industryFilter,
      ...specificationFilter,
      // ...rateFilter,
      ...stateFilter,
      ...cityFilter,
      ...radiusFilter,
      // ...ratingFilter,
      ...valueFilter,
    };

    return api.get('users', {
      ...resultFilter,
      page,
    });
  };

  const fetchUserOffersByJobIds = (userId: number | null, jobIdsArray: Array<number | null>) => {
    const jobIds = jobIdsArray.join(',');

    return api.get(`users/${userId}/offers`, {
      'filter[job_id]': jobIds,
    });
  };

  const fetchStates = () => {
    return api.get('states', { per_page: maxPerPage });
  };

  const fetchCityById = (id = 1) => {
    return api.get(`cities/${id}`);
  };

  const searchCitiesByState = (stateId: number | null, value = '') => {
    const stateFilter = stateId ? { 'filter[state_id]': stateId } : null;

    return api.get('cities', {
      'filter[name][like]': value + '%',
      ...stateFilter,
    });
  };

  const fetchIndustries = () => {
    return api.get('/industries', {
      per_page: maxPerPage,
      count: 'rateTypes,specifications',
    });
  };

  const fetchSpecifications = () => {
    return api.get('/specifications', { per_page: maxPerPage });
  };

  const fetchFarms = (id: number, page: number) => {
    return api.get('user/farms', { page });
  };

  const createFarm = (farmData: FarmData) => {
    return api.post('user/farms', { ...farmData });
  };

  const editFarm = (farmData: FarmType) => {
    return api.put(`user/farms/${farmData.id}`, { ...farmData });
  };

  const deleteFarm = (id: number | null) => {
    return api.delete(`user/farms/${id}`);
  };

  const fetchUserJobs = (jobStatusId: number, page: number = 1) => {
    const statusFilter = jobStatusId !== 0 ? { 'filter[status]': jobStatusId } : {};

    return api.get('user/jobs', {
      count: 'offers',
      ...statusFilter,
      page,
    });
  };

  const fetchJobListings = (specificationId: number, filter: JobListingFilter, page: number) => {
    const { state = [], city, radius } = filter;

    const selectedStateIds = (state: StateType[] | null) => {
      return state?.length ? state.map((state) => state.id).join(',') : '';
    };

    const specificationFilter = specificationId ? { 'filter[specification_id]': specificationId } : {};
    const stateFilter = selectedStateIds(state) && !radius ? { 'filter[farm.state_id]': selectedStateIds(state) } : {};
    const cityFilter = city?.id && !radius ? { 'filter[farm.city_id]': city.id } : {};
    const radiusFilter =
      city?.latitude && city?.longitude && radius
        ? { 'filter[farm.city.radius][less_or_equal]': `${city.latitude},${city.longitude},${radius}` }
        : {};

    const resultFilter = {
      ...specificationFilter,
      ...stateFilter,
      ...cityFilter,
      ...radiusFilter,
    };

    return api.get('jobs', {
      'filter[status]': JOBS_STATUS.OFFERED.id,
      ...resultFilter,
      page,
    });
  };

  const fetchJob = (id: number | null) => {
    return api.get(`jobs/${id}`, { count: 'offers,reviews' });
  };

  const createNewJob = (job: PostJobData) => {
    return api.post('jobs', { ...job });
  };

  const editPostedJob = (job: EditJobData) => {
    return api.put(`jobs/${job.id}`, { ...job }, { params: { count: 'offers,reviews' } });
  };

  const deletePostedJob = (jobId: number | null) => {
    return api.delete(`jobs/${jobId}`);
  };

  const createOffer = (jobId: number | null, userId: number | null) => {
    return api.post('offers', { jobId, userId });
  };

  const updateOffer = (offerId: number | null, status: number | null) => {
    return api.put(`offers/${offerId}`, { status });
  };

  const fetchUserOffersRequest = (
    offerType: offersType,
    filter: { offerStatusId?: number; industryId?: number; jobId?: number | null },
    page: number,
  ) => {
    const { offerStatusId, industryId, jobId } = filter;

    const stateFilter = offerStatusId ? { 'filter[status]': offerStatusId } : {};
    const industryFilter = industryId ? { 'filter[job.industry_id]': industryId } : {};
    const jobFilter = jobId ? { 'filter[job_id]': jobId } : {};

    return api.get(`user/${offerType}`, {
      ...stateFilter,
      ...industryFilter,
      ...jobFilter,
      page,
    });
  };

  const fetchAds = (screenType: adScreenType) => {
    return api.get('/ads', { 'filter[params->screen]': screenType });
  };

  const addReviews = (reviews: ReviewsArray) => {
    return api.post('/reviews', { items: reviews });
  };

  const fetchNotifications = (page: number) => {
    return api.get('/user/notifications', { page });
  };

  const editNotification = (notificationId: number) => {
    return api.put(`user/notifications/${notificationId}`);
  };

  const fetchCitiesOnMap = (filter: FetchCitiesFilterType) => {
    const { latitude, longitude, radius, specificationIds } = filter;

    return api.get('cities', {
      'filter[radius][less_or_equal]': `${latitude},${longitude},${radius}`,
      'filter[jobs.specification_id]': specificationIds.join(','),
      'filter[jobs.status]': JOBS_STATUS.OFFERED.id,
      count: 'jobs',
      per_page: maxPerPage,
    });
  };

  const fetchJobsOnMap = (filter: FetchJobsFilterType, page: number) => {
    const { cityIds, specificationIds } = filter;

    return api.get('jobs', {
      'filter[specification_id]': specificationIds.join(','),
      'filter[status]': JOBS_STATUS.OFFERED.id,
      'filter[farm.city_id]': cityIds.join(','),
      page,
    });
  };

  const fetchPaymentCards = () => {
    return api.get('user/cards');
  };

  const payForJob = (jobId: number | null) => {
    return api.post('user/payments', { jobId });
  };

  const fetchSetupSecret = () => {
    return api.get('user/stripe-setup-intent');
  };

  const fetchPaymentSecret = (paymentId: number) => {
    return api.get(`user/stripe-payment-intent/${paymentId}`);
  };

  const setDefaultPaymentCard = (cardId: string) => {
    return api.put(`user/cards/${cardId}`);
  };

  const deletePaymentCard = (cardId: string) => {
    return api.delete(`user/cards/${cardId}`);
  };

  const fetchPayment = (paymentId: number) => {
    return api.get(`user/payments/${paymentId}`);
  };

  const fetchPayments = (filter: FetchPaymentsFilterType) => {
    const { page, offerId } = filter;

    const offerFilter = offerId ? { 'filter[offers.id]': offerId } : {};

    return api.get('user/payments', {
      ...offerFilter,
      page,
    });
  };

  const fetchDebts = (filter: FetchDebtsFilterType) => {
    const { page } = filter;

    return api.get('user/debts', { page });
  };

  const addReference = (reference: ReferenceDataType) => {
    return api.post('user/references', { ...reference });
  };

  const updateReference = (id: number | null, reference: ReferenceDataType) => {
    return api.put(`user/references/${id}`, { ...reference });
  };

  const deleteReference = (id: number | null) => {
    return api.delete(`user/references/${id}`);
  };

  const fetchReferences = (page: number) => {
    return api.get('user/references', { page });
  };

  const fetchFieldReps = () => {
    return api.get('field-reps');
  };

  api.addRequestTransform((request) => {
    if (request.data && !(request.data instanceof FormData)) {
      request.data = snakeCaseKeys(request.data, { deep: true });
    }
  });

  api.addResponseTransform((response: CustomApiResponse<any>) => {
    if (response.data) {
      response.data = camelCaseKeys(response.data, { deep: true });
      if (response.data.messages) {
        response.messages = [...response.data.messages];
      }
      if (response.data.meta) {
        response.meta = { ...response.data.meta };
      }
      if (response.data.data) {
        response.data = Array.isArray(response.data.data) ? [...response.data.data] : { ...response.data.data };
      }
    }
  });

  api.addMonitor((response) => {
    if (appVersion) {
      //@ts-ignore
      const minimalAvailableVersion = response.headers['minimal-recommended-app-version'] || '';

      if (response.status === 200 && compareVersions.compare(appVersion, minimalAvailableVersion, '<')) {
        // setting response.ok to false to catch it in commonErrorHandler and run HANDLE_MINIMAL_AVAILABLE_VERSION saga
        response.ok = false;
      }
    }
  });

  return {
    setHeader,
    signInRequest,
    signUpRequest,
    signOutRequest,
    restorePasswordRequest,
    resetPasswordRequest,
    fetchUser,
    fetchUserById,
    editUserRequest,
    editUserRoleRequest,
    editAvatarRequest,
    fetchRoles,
    fetchStates,
    fetchCityById,
    fetchFarms,
    createFarm,
    editFarm,
    deleteFarm,
    searchCitiesByState,
    fetchIndustries,
    fetchSpecifications,
    fetchJob,
    createNewJob,
    editPostedJob,
    deletePostedJob,
    fetchUserJobs,
    fetchJobListings,
    changePasswordRequest,
    fetchLaborersByIndustry,
    createOffer,
    fetchUserOffersRequest,
    updateOffer,
    fetchUserOffersByJobIds,
    fetchAds,
    addReviews,
    fetchCitiesOnMap,
    fetchJobsOnMap,
    fetchPaymentCards,
    fetchSetupSecret,
    fetchPaymentSecret,
    setDefaultPaymentCard,
    deletePaymentCard,
    payForJob,
    fetchPayment,
    fetchPayments,
    fetchDebts,
    fetchNotifications,
    editNotification,
    addReference,
    updateReference,
    deleteReference,
    fetchReferences,
    fetchFieldReps,
    changeEmailRequest,
    confirmEmailChange,
    deleteAccountRequest,
    confirmDeleteAccountRequest,
  };
};

import React from 'react';
import { SocialNetworks } from '@root/web/components/SocialNetworks';
import { useStyles } from './styles';
import { strings } from '@root/strings';
import clsx from 'clsx';

const { agFooter: string } = strings;

type AuthFooterProps = {
  className?: string;
};

export const AuthFooter = (props: AuthFooterProps) => {
  const styles = useStyles();

  return (
    <div className={clsx(styles.footer, props.className)}>
      <p className={styles.copyright}>{string.copyright}</p>
      <SocialNetworks />
    </div>
  );
};

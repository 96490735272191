import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: () => {
    return {
      backgroundColor: colors.lightGrey,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0px 30px',
      borderWidth: '0px 0px 1px 0px',
      borderStyle: 'solid',
      borderColor: colors.athensGray,
      ...theme.mixins.toolbar,
    };
  },
  buttonsContainer: {
    display: 'flex',
  },
  headerTitleText: {
    fontSize: 24,
    fontFamily: fonts.SFUiDisplayBold,
    lineHeight: '20px',
    letterSpacing: -0.24,
  },
  closeImageContainer: {
    marginRight: -7,
    borderRadius: 50,
    padding: 0,
    border: 0,
    '&:hover': {
      backgroundColor: colors.white,
    },
    minWidth: 45,
    minHeight: 45,
  },
  closeImage: {
    padding: 6,
    transform: 'rotate(45deg)',
    width: 28,
    height: 28,
    filter:
      'brightness(0) saturate(100%) invert(78%) sepia(10%) saturate(190%) hue-rotate(169deg) brightness(95%) contrast(88%);',
    borderRadius: 50,
    '&:hover': {
      filter: 'none',
      backgroundColor: colors.white,
    },
  },
  jobActionTabButton: {
    minWidth: 90,
  },
  jobActionTabButtonIcon: {
    filter:
      'brightness(0) saturate(100%) invert(27%) sepia(71%) saturate(2791%) hue-rotate(348deg) brightness(86%) contrast(87%);',
    height: 18,
  },
}));

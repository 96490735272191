import { ArrayIndustries, Industry } from '@root/general/types';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { getIndustries } from '@root/selectors';
import { arrayToString } from '@root/utils/arrayToString';

type UseCustomModalReturnType = [
  (itemId: number) => boolean,
  (item: Industry) => () => void,
  () => void,
  () => void,
  () => void,
];

export const useCustomModal = (
  selectedItemIds: number[],
  setSelectedItemIds: Dispatch<SetStateAction<number[]>>,
  setModalVisible: Dispatch<SetStateAction<boolean>>,
  isModalVisible: boolean,
  setDependentValue: (e: string | ChangeEvent<any>) => void,
  dependentItem: Array<number>,
  setDependentItem: Dispatch<SetStateAction<number[]>>,
  setValue: (e: string | ChangeEvent<any>) => void,
  data: ArrayIndustries,
): UseCustomModalReturnType => {
  const [itemIds, setItemIds] = useState(selectedItemIds);
  const { allSpecifications, allIndustries } = useSelector(getIndustries);

  const isSelected = (itemId: number) => {
    return itemIds.some((selectedId) => selectedId === itemId);
  };

  const onPress = (item: Industry) => () => {
    const result = isSelected(item.id) ? itemIds.filter((selectedId) => selectedId !== item.id) : [...itemIds, item.id];

    setItemIds(result);
  };

  const onPressAll = () => {
    const result = data.map((item) => item.id);

    setItemIds(result);
  };

  const onPressDone = () => {
    setModalVisible(!isModalVisible);
    const selectedNames = data.filter((item) => isSelected(item.id)).map((item) => item.name);

    const specificationsBySelectedIndustryIds = allSpecifications.filter((specification) => {
      return itemIds.some((id) => id === specification.industryId);
    });

    const unchangedSpecifications = specificationsBySelectedIndustryIds.filter((specification) => {
      return dependentItem.some((id) => id === specification.id);
    });

    const unchangedSpecificationsIds = unchangedSpecifications.map((specification) => specification.id);

    const unchangedSpecificationsNames = unchangedSpecifications.map((specification) => {
      if (specification.name.toUpperCase() === 'OTHER') {
        const industryName = allIndustries.find((industry) => industry.id === specification.industryId)?.name ?? '';
        return industryName + ' ' + specification.name;
      }

      return specification.name;
    });

    setSelectedItemIds(itemIds);
    setDependentValue(arrayToString(unchangedSpecificationsNames));
    setDependentItem(unchangedSpecificationsIds);
    setValue(arrayToString(selectedNames));
  };

  const closeModal = () => {
    setModalVisible(false);
    setItemIds(selectedItemIds);
  };

  return [isSelected, onPress, onPressDone, closeModal, onPressAll];
};

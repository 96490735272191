import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles(() => ({
  gridContainer: {
    position: 'relative',
    padding: '10px 10px 0px 30px',
  },
  scrollbarContainer: {
    height: '100%',
  },
  scrollbarContent: {
    backgroundColor: colors.white,
    padding: '20px 0px',
    borderColor: colors.athensGray,
    borderWidth: '0px 1px 1px',
    borderStyle: 'solid',
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  editButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0px 30px',
  },
  containerPadding: {
    paddingRight: 20,
  },
}));

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
  image: {
    borderRadius: 50,
  },
  defaultImage: {
    width: 22,
    height: 22,
    borderRadius: 50,
    padding: 6,
    filter:
      'brightness(0) saturate(100%) invert(27%) sepia(71%) saturate(2791%) hue-rotate(348deg) brightness(86%) contrast(87%);',
  },
}));

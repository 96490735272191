import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '20px 30px',
  },
  submitButtonContainer: {
    padding: '20px 30px',
    marginTop: 'auto',
  },
}));

import {
  DELETE_PAYMENT_CARD_SUCCESS,
  FETCH_PAYMENT_CARD_SUCCESS,
  UPDATE_DEFAULT_PAYMENT_CARD_SUCCESS,
  CLEAR_USER_DATA_STATE,
  FETCH_PAYMENTS_SUCCESS,
  SET_SUCCEED_PAYMENT,
} from '@root/general/consts/actions';
import { IActionType } from '@root/redux/types';
import { PaymentsArray, PaymentCardsArray } from '@root/general/types';
import { cardDefaultType } from '@root/general/consts';
import { transactionHistoryTab } from '@root/mobile/navigators/TransactionHistoryTopNavigator/types';

export type PaymentCardsReducer = {
  cards: PaymentCardsArray;
  payments: {
    [K in transactionHistoryTab]: {
      payments: PaymentsArray;
      lastPage: number;
    };
  };
};

const INITIAL_STATE: PaymentCardsReducer = {
  cards: [],
  payments: {
    [transactionHistoryTab.ALL]: {
      payments: [],
      lastPage: 1,
    },
    [transactionHistoryTab.OPEN]: {
      payments: [],
      lastPage: 1,
    },
  },
};

export const paymentCardsReducer = (state = INITIAL_STATE, action: IActionType) => {
  switch (action.type) {
    case FETCH_PAYMENT_CARD_SUCCESS:
      const { cards } = action.payload;
      return { ...state, cards };
    case FETCH_PAYMENTS_SUCCESS:
      const { paymentType, payments, lastPage } = action.payload;
      return { ...state, payments: { ...state.payments, [paymentType]: { payments, lastPage } } };
    case DELETE_PAYMENT_CARD_SUCCESS:
      return { ...state, cards: state.cards.filter((item) => item.id !== action.payload) };
    case UPDATE_DEFAULT_PAYMENT_CARD_SUCCESS:
      const formatArray = state.cards.map((item) => {
        if (item.id === action.payload) {
          return {
            ...item,
            isDefault: cardDefaultType.SELECTED,
          };
        } else {
          return {
            ...item,
            isDefault: cardDefaultType.NOT_SELECTED,
          };
        }
      });
      return { ...state, cards: formatArray };
    case SET_SUCCEED_PAYMENT: {
      const { succeedPayment } = action.payload;
      const { payments, lastPage } = state.payments.ALL;

      return {
        ...state,
        payments: {
          ...state.payments,
          [transactionHistoryTab.ALL]: {
            lastPage,
            payments: [succeedPayment, ...payments],
          },
        },
      };
    }
    case CLEAR_USER_DATA_STATE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
};

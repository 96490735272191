import { Api, CustomApiResponse } from '@root/services/api';
import {
  ChangeEmailAction,
  ChangePasswordAction,
  ConfirmChangeEmailAction,
  EditUserAction,
  EditUserAvatarAction,
  EditUserRoleAction,
  FetchUserAction,
  FetchUserByIdAction,
} from '@root/redux/actions/types';
import { call, put, select } from 'redux-saga/effects';
import { getUser } from '@root/selectors';
import { ErrorResponse, GeneralUser, Job, Offer } from '@root/general/types';
import { commonNotificationHandler } from '@root/redux/sagas/helpers';
import {
  CALL_COMMON_ERROR_HANDLER,
  CALL_UNEXPECTED_ERROR_HANDLER,
  EDIT_USER_ROLE_REQUEST,
  EDIT_USER_ROLE_SUCCESS,
  EDIT_USER_SUCCESS,
  FETCH_JOB_REQUEST,
  FETCH_USER_SUCCESS,
  SET_AUTH_HEADER,
  SET_TOKEN,
} from '@root/general/consts/actions';
import { fetchUserAction } from '@root/redux/actions';

export function* fetchUser(api: Api, action: FetchUserAction) {
  const callback = action?.payload?.callback;

  try {
    const response: CustomApiResponse<GeneralUser, ErrorResponse> = yield call(api.fetchUser);

    if (response.ok && response.data) {
      yield put({ type: FETCH_USER_SUCCESS, payload: response.data });
      yield callback && call(callback, true);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* fetchUserById(api: Api, action: FetchUserByIdAction) {
  const { userId, jobId, callback } = action.payload;
  try {
    const response: CustomApiResponse<GeneralUser, ErrorResponse> = yield call(api.fetchUserById, userId);

    if (response.ok && response.data) {
      if (jobId) {
        const setJobCallback = (isSuccess: boolean, job?: Job, offer?: Offer) => {
          if (isSuccess) {
            callback && callback(true, response.data, job, offer);
          } else {
            callback && callback(false);
          }
        };

        yield put({ type: FETCH_JOB_REQUEST, payload: { jobId, userId, callback: setJobCallback } });
      } else {
        yield callback && call(callback, true, response.data);
      }
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* editUser(api: Api, action: EditUserAction) {
  const { editUserData, deviceId, callback } = action.payload;

  try {
    const { roleId } = yield select(getUser);

    const response: CustomApiResponse<GeneralUser, ErrorResponse> = yield call(api.editUserRequest, editUserData);

    if (response.ok) {
      const { roleId: newRoleId } = editUserData;

      yield put({ type: EDIT_USER_SUCCESS, payload: response.data });

      if (newRoleId !== roleId && newRoleId) {
        yield put({ type: EDIT_USER_ROLE_REQUEST, payload: { roleId: newRoleId, deviceId, callback } });
      } else {
        yield callback && call(callback, true);
        yield commonNotificationHandler(response.messages);
      }
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* editUserRole(api: Api, action: EditUserRoleAction) {
  const { roleId, deviceId, callback } = action.payload;

  try {
    const response: CustomApiResponse<{ token: string }, ErrorResponse> = yield call(
      api.editUserRoleRequest,
      roleId,
      deviceId,
    );

    if (response.ok && response.data) {
      const { token } = response.data;
      yield put({ type: SET_AUTH_HEADER, payload: token });
      yield put({ type: SET_TOKEN, payload: token });
      yield put({ type: EDIT_USER_ROLE_SUCCESS, payload: { roleId } });
      yield put(fetchUserAction({ callback }));
      yield commonNotificationHandler(response.messages);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* editAvatar(api: Api, action: EditUserAvatarAction) {
  const { callback } = action.payload;
  const avatar = action.payload?.avatar ?? null;

  try {
    const response: CustomApiResponse<GeneralUser, ErrorResponse> = yield call(api.editAvatarRequest, avatar);

    if (response.ok && response.data) {
      yield put(
        fetchUserAction({
          callback: (isSuccess) => {
            if (isSuccess) {
              return commonNotificationHandler(response.messages);
            }
          },
        }),
      );
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }

    yield callback && call(callback, response.ok);
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* changePassword(api: Api, action: ChangePasswordAction) {
  const { changePasswordData, callback } = action.payload;

  try {
    const response: CustomApiResponse<Object, ErrorResponse> = yield call(
      api.changePasswordRequest,
      changePasswordData,
    );

    if (response.ok) {
      yield commonNotificationHandler(response.messages);
      yield callback && call(callback, true);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* changeEmail(api: Api, action: ChangeEmailAction) {
  const { changeEmailData, callback } = action.payload;

  try {
    const response: CustomApiResponse<any, ErrorResponse> = yield call(api.changeEmailRequest, changeEmailData);

    if (response.ok) {
      yield put(fetchUserAction({ callback }));
      yield commonNotificationHandler(response.messages);
      yield callback && call(callback, true);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

export function* confirmChangeEmail(api: Api, action: ConfirmChangeEmailAction) {
  const { token, callback } = action.payload;

  try {
    const response: CustomApiResponse<any, ErrorResponse> = yield call(api.confirmEmailChange, token);

    if (response.ok) {
      yield put(fetchUserAction({ callback }));
      yield commonNotificationHandler(response.messages);
      yield callback && call(callback, true);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

import { makeStyles } from '@material-ui/core';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

const DEFAULT_BORDER = `1px solid ${colors.athensGray}`;

export const useStyles = makeStyles({
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    outline: 0,
    backgroundColor: colors.white,
    padding: 1,
    borderRadius: 5,
    borderColor: colors.athensGray,
    borderWidth: 1,
    borderStyle: 'solid',
    minWidth: 320,
    width: 395,
    minHeight: 590,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginRight: 20,
    marginLeft: 20,
  },
  paymentItemContainer: {
    height: 57,
    padding: '24px 24px 0 24px',
    borderTop: DEFAULT_BORDER,
  },
  nameAndPriceContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  name: {
    color: colors.black,
    fontSize: 17,
    fontFamily: fonts.SFUiDisplayMedium,
  },
  price: {
    color: colors.red,
    fontSize: 17,
    fontFamily: fonts.IBMPlexMonoMedium,
  },
  date: {
    color: colors.grey,
    fontSize: 12,
    fontFamily: fonts.SFUiDisplayMedium,
  },
  modalItemContainer: {
    borderBottom: DEFAULT_BORDER,
  },
  jobNameContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 42,
    paddingLeft: 22,
    backgroundColor: colors.lightGrey,
  },
  jobName: {
    color: colors.black,
    fontSize: 15,
    fontWeight: 700,
    letterSpacing: -0.24,
    fontFamily: fonts.SFUiDisplayMedium,
  },
  searchBarContainer: {
    height: 36,
    padding: '18px 24px',
    borderBottom: DEFAULT_BORDER,
  },
  paymentsContainer: {
    maxHeight: 470,
    overflowY: 'auto',
  },
  emptyList: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '18px 24px',
    flex: 1,
  },
  emptyListText: {
    color: colors.black,
    fontSize: 15,
    fontWeight: 700,
    letterSpacing: -0.24,
    fontFamily: fonts.SFUiDisplayMedium,
  },
  closeButtonContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
});

import { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '@root/selectors';
import { random } from 'lodash';
import { OnCityPressType, OnStatePressType } from '@root/hooks/useUserProfileScreen';
import { createFarmAction, deleteFarmAction, editFarmAction } from '@root/redux/actions';
import { FormikFieldFormDataType, FormikHandleChangeType, FormikValuesType } from '@root/mobile/types/inputTypes';
import { FarmType } from '@root/general/types';
import { strings } from '@root/strings';
import { defaultCity, defaultState } from '@root/general/consts';

const { farmActionModalScreen: string } = strings;

type UseFarmActionModalScreenType = [
  FormikValuesType,
  FormikFieldFormDataType,
  (values: FormikValuesType) => void,
  boolean,
  (values: FormikValuesType, handleChange: FormikHandleChangeType, validateForm: any) => () => void,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  () => void,
  string,
  Dispatch<SetStateAction<number | null>>,
  Dispatch<SetStateAction<number | null>>,
  number | null,
  number,
];

enum fieldIds {
  farmName = 14,
  contactName = 15,
  email = 16,
  phone = 17,
  state = 18,
  city = 19,
  zipCode = 20,
}

export const useFarmActionModalScreen = (
  isEditFarmModalScreen: boolean,
  onStatePress: OnStatePressType = () => () => {},
  onCityPress: OnCityPressType = () => () => {},
  deleteCallback: () => void,
  goBackCallback: () => void,
  setFarmCallback: (editedFarm: FarmType) => void = () => {},
  farmInfo: FarmType | null = null,
): UseFarmActionModalScreenType => {
  const dispatch = useDispatch();

  const {
    id: userId,
    name: userName,
    email: userEmail = '',
    phone: userPhone = '',
    zipCode: userZipCode,
    state: userState,
    city: userCity,
    stateId: userStateId,
    cityId: userCityId,
  } = useSelector(getUser);

  const [isDeleteWarningVisible, setDeleteWarningModalVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [keyFillGeneralInfo, setKeyFillGeneralInfo] = useState(0);

  const farmContactName = farmInfo?.contactName ?? '';
  const farmState = farmInfo?.state ?? defaultState;
  const farmCreatedAt = farmInfo?.createdAt ?? '';
  const farmUpdatedAt = farmInfo?.updatedAt ?? '';
  const farmCity = farmInfo?.city ?? defaultCity;
  const farmZipCode = farmInfo?.zipCode ?? '';
  const farmEmail = farmInfo?.email ?? '';
  const farmPhone = farmInfo?.phone ?? '';
  const farmName = farmInfo?.name ?? '';
  const farmId = farmInfo?.id ?? 0;

  const stateName = farmState?.name ?? '';
  const cityName = farmCity?.name ?? '';
  const stateId = farmState?.id ?? 0;
  const cityId = farmCity?.id ?? 0;

  const userStateName = userState.name;
  const userCityName = userCity.name;

  const [selectedStateId, setSelectedStateId] = useState<number | null>(stateId);
  const [selectedCityId, setSelectedCityId] = useState<number | null>(cityId);

  const farmActionCallback = (isSuccess: boolean, editedFarm?: FarmType) => {
    if (isSuccess) {
      goBackCallback();
      editedFarm && setFarmCallback(editedFarm);
    }

    setLoading(false);
  };

  const farmDeleteCallback = (isSuccess: boolean) => {
    isSuccess && deleteCallback();
    setLoading(false);
  };

  const onPressDeleteFarm = () => {
    dispatch(
      deleteFarmAction({
        farmId,
        callback: farmDeleteCallback,
      }),
    );
  };

  const initialValues = {
    farmName,
    contactName: farmContactName,
    email: farmEmail,
    phone: farmPhone,
    city: cityName,
    state: stateName,
    zipCode: farmZipCode,
  } as FormikValuesType;

  const generalInfoData: FormikFieldFormDataType = [
    {
      id: fieldIds.farmName,
      title: string.locationTitle,
      valuesName: 'farmName',
      placeHolder: string.locationPlaceholder,
      keyboardType: 'default',
    },
    {
      id: fieldIds.contactName,
      title: string.contactNameTitle,
      valuesName: 'contactName',
      placeHolder: string.contactNamePlaceholder,
      keyboardType: 'default',
    },
    {
      id: fieldIds.email,
      title: string.emailTitle,
      valuesName: 'email',
      placeHolder: string.emailPlaceholder,
      keyboardType: 'email-address',
    },
    {
      id: fieldIds.phone,
      title: string.cellPhoneTitle,
      valuesName: 'phone',
      placeHolder: string.cellPhonePlaceholder,
      keyboardType: 'phone-pad',
      mask: '[000]-[000]-[0000]',
      webMask: '999-999-9999',
    },
    {
      id: fieldIds.state,
      title: string.stateTitle,
      valuesName: 'state',
      placeHolder: string.statePlaceholder,
      onPress: onStatePress(setSelectedStateId, setSelectedCityId),
      icon: require('@root/assets/icons/arrowRight.png'),
      webIcon: require('@root/web/assets/icons/arrowRight.svg'),
      autoLength: true,
    },
    {
      id: fieldIds.city,
      title: string.cityTitle,
      valuesName: 'city',
      placeHolder: string.cityPlaceholder,
      onPress: onCityPress(selectedStateId, setSelectedCityId),
      icon: require('@root/assets/icons/arrowRight.png'),
      webIcon: require('@root/web/assets/icons/arrowRight.svg'),
      autoLength: true,
    },
    {
      id: fieldIds.zipCode,
      title: string.zipCodeTitle,
      valuesName: 'zipCode',
      placeHolder: string.zipCodePlaceholder,
      keyboardType: 'number-pad',
      mask: '[00000]-[00000]',
      webMask: '99999-99999',
    },
  ];

  const onPressFarmAction = (values: FormikValuesType) => {
    const { farmName, contactName, email, phone, zipCode } = values;

    const farm = {
      userId,
      contactName,
      email,
      phone,
      zipCode,
      name: farmName,
      stateId: selectedStateId,
      cityId: selectedCityId,
      state: farmState,
      city: farmCity,
      createdAt: farmCreatedAt,
      updatedAt: farmUpdatedAt,
    };

    const editedFarm = { ...farm, id: farmId };
    const createFarm = { ...farm };

    setLoading(true);

    if (isEditFarmModalScreen) {
      dispatch(
        editFarmAction({
          data: editedFarm,
          callback: farmActionCallback,
        }),
      );
    } else {
      dispatch(
        createFarmAction({
          data: createFarm,
          callback: farmActionCallback,
        }),
      );
    }
  };

  const fillInfo = (values: FormikValuesType, handleChange: FormikHandleChangeType, validateForm: any) => () => {
    const key = random(1, 20);
    const uniqKey = key === keyFillGeneralInfo ? key + 1 : key;
    setKeyFillGeneralInfo(uniqKey);
    handleChange((values.contactName = userName));
    handleChange((values.zipCode = userZipCode));
    handleChange((values.state = userStateName));
    handleChange((values.city = userCityName));
    handleChange((values.email = userEmail));
    handleChange((values.phone = userPhone));
    setSelectedStateId(userStateId);
    setSelectedCityId(userCityId);
    validateForm();
  };

  return [
    initialValues,
    generalInfoData,
    onPressFarmAction,
    isLoading,
    fillInfo,
    isDeleteWarningVisible,
    setDeleteWarningModalVisible,
    onPressDeleteFarm,
    farmName,
    setSelectedStateId,
    setSelectedCityId,
    selectedStateId,
    keyFillGeneralInfo,
  ];
};

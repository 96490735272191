export const CLEAR_USER_DATA_STATE = 'CLEAR_USER_DATA_STATE',
  AUTH_SIGN_OUT = 'AUTH_SIGN_OUT',
  AUTH_INITIALISE = 'AUTH_INITIALISE',
  SIGN_IN_REQUEST = 'SIGN_IN_REQUEST',
  SIGN_UP_REQUEST = 'SIGN_UP_REQUEST',
  SET_AUTH_HEADER = 'SET_AUTH_HEADER',
  SET_AUTH_USER = 'SET_AUTH_USER',
  SET_TOKEN = 'SET_TOKEN',
  FETCH_USER_REQUEST = 'FETCH_USER_REQUEST',
  FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS',
  FETCH_USER_BY_ID_REQUEST = 'FETCH_USER_BY_ID_REQUEST',
  EDIT_USER_REQUEST = 'EDIT_USER_REQUEST',
  EDIT_USER_ROLE_REQUEST = 'EDIT_USER_ROLE_REQUEST',
  EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS',
  EDIT_USER_ROLE_SUCCESS = 'EDIT_USER_ROLE_SUCCESS',
  EDIT_USER_AVATAR_REQUEST = 'EDIT_USER_AVATAR_REQUEST',
  FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS',
  REDUCE_UNREAD_NOTIFICATION = 'REDUCE_UNREAD_NOTIFICATION',
  HANDLE_MINIMAL_AVAILABLE_VERSION = 'HANDLE_MINIMAL_AVAILABLE_VERSION',
  RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
  RESTORE_PASSWORD_REQUEST = 'RESTORE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST',
  CHANGE_EMAIL_REQUEST = 'CHANGE_EMAIL_REQUEST',
  DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST',
  CONFIRM_CHANGE_EMAIL_REQUEST = 'CONFIRM_CHANGE_EMAIL_REQUEST',
  CONFIRM_DELETE_ACCOUNT_REQUEST = 'CONFIRM_DELETE_ACCOUNT_REQUEST',
  SHOW_TOAST = 'SHOW_TOAST',
  FETCH_FARMS_REQUEST = 'FETCH_FARMS_REQUEST',
  FETCH_FARMS_SUCCESS = 'FETCH_FARMS_SUCCESS',
  REFRESH_FARMS_REQUEST = 'REFRESH_FARMS_REQUEST',
  FETCH_ADDITIONAL_FARMS_REQUEST = 'FETCH_ADDITIONAL_FARMS_REQUEST',
  CREATE_FARM_REQUEST = 'CREATE_FARM_REQUEST',
  CREATE_FARM_SUCCESS = 'CREATE_FARM_SUCCESS',
  DELETE_FARM_REQUEST = 'DELETE_FARM_REQUEST',
  DELETE_FARM_SUCCESS = 'DELETE_FARM_SUCCESS',
  EDIT_FARM_REQUEST = 'EDIT_FARM_REQUEST',
  EDIT_FARM_SUCCESS = 'EDIT_FARM_SUCCESS',
  FETCH_STATES_REQUEST = 'FETCH_STATES_REQUEST',
  FETCH_STATES_SUCCESS = 'FETCH_STATES_SUCCESS',
  SEARCH_CITIES = 'SEARCH_CITIES',
  FETCH_CITIES_ON_MAP_REQUEST = 'FETCH_CITIES_ON_MAP_REQUEST',
  FETCH_JOBS_ON_MAP_REQUEST = 'FETCH_JOBS_ON_MAP_REQUEST',
  GET_CITY_BY_ID = 'GET_CITY_BY_ID',
  SET_LOCATION_LOADING = 'SET_LOCATION_LOADING',
  FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST',
  EDIT_NOTIFICATION_REQUEST = 'EDIT_NOTIFICATION_REQUEST',
  // Posted jobs
  FETCH_POSTED_JOBS_REQUEST = 'FETCH_POSTED_JOBS_REQUEST',
  FETCH_POSTED_JOBS_SUCCESS = 'FETCH_POSTED_JOBS_SUCCESS',
  REFRESH_POSTED_JOBS_REQUEST = 'REFRESH_POSTED_JOBS_REQUEST',
  FETCH_ADDITIONAL_POSTED_JOBS_REQUEST = 'FETCH_ADDITIONAL_POSTED_JOBS_REQUEST',
  CREATE_NEW_JOB_REQUEST = 'CREATE_NEW_JOB_REQUEST',
  EDIT_POSTED_JOB_REQUEST = 'EDIT_POSTED_JOB_REQUEST',
  EDIT_POSTED_JOB_SUCCESS = 'EDIT_POSTED_JOB_SUCCESS',
  DELETE_POSTED_JOB_REQUEST = 'DELETE_POSTED_JOB_REQUEST',
  DELETE_POSTED_JOB_SUCCESS = 'DELETE_POSTED_JOB_SUCCESS',
  FETCH_JOB_REQUEST = 'FETCH_JOB_REQUEST',
  SET_NEW_JOB = 'SET_NEW_JOB',
  SET_INDUSTRIES_LOADING = 'SET_INDUSTRIES_LOADING',
  // Job listing
  SET_JOB_LISTING_FILTER = 'SET_JOB_LISTING_FILTER',
  CLEAR_JOB_LISTING_FILTER = 'CLEAR_JOB_LISTING_FILTER',
  FETCH_JOB_LISTING_REQUEST = 'FETCH_JOB_LISTING_REQUEST',
  FETCH_JOB_LISTING_SUCCESS = 'FETCH_JOB_LISTING_SUCCESS',
  REFRESH_JOB_LISTING_REQUEST = 'REFRESH_JOB_LISTING_REQUEST',
  FETCH_ADDITIONAL_JOB_LISTING_REQUEST = 'FETCH_ADDITIONAL_JOB_LISTING_REQUEST',
  SET_MAP_FILTER = 'SET_MAP_FILTER',
  FETCH_SPECIFICATIONS_SUCCESS = 'FETCH_SPECIFICATIONS_SUCCESS',
  FETCH_SPECIFICATIONS_REQUEST = 'FETCH_SPECIFICATIONS_REQUEST',
  FETCH_INDUSTRIES_SUCCESS = 'FETCH_INDUSTRIES_SUCCESS',
  FETCH_INDUSTRIES_REQUEST = 'FETCH_INDUSTRIES_REQUEST',
  FETCH_INDUSTRIES_WITH_SPECIFICATIONS_REQUEST = 'FETCH_INDUSTRIES_WITH_SPECIFICATIONS_REQUEST',
  FETCH_INDUSTRIES_WITH_SPECIFICATIONS_SUCCESS = 'FETCH_INDUSTRIES_WITH_SPECIFICATIONS_SUCCESS',
  SET_CURRENT_LABORERS_INDUSTRY_ID = 'SET_CURRENT_LABORERS_INDUSTRY_ID',
  SET_LABORERS_FILTER = 'SET_LABORERS_FILTER',
  CLEAR_LABORERS_FILTER = 'CLEAR_LABORERS_FILTER',
  FETCH_LABORERS_BY_INDUSTRY_REQUEST = 'FETCH_LABORERS_BY_INDUSTRY_REQUEST',
  FETCH_LABORERS_BY_INDUSTRY_SUCCESS = 'FETCH_LABORERS_BY_INDUSTRY_SUCCESS',
  REFRESH_LABORERS_BY_INDUSTRY_REQUEST = 'REFRESH_LABORERS_BY_INDUSTRY_REQUEST',
  FETCH_ADDITIONAL_LABORERS_BY_INDUSTRY_REQUEST = 'FETCH_ADDITIONAL_LABORERS_BY_INDUSTRY_REQUEST',
  CALL_COMMON_ERROR_HANDLER = 'CALL_COMMON_ERROR_HANDLER',
  CALL_UNEXPECTED_ERROR_HANDLER = 'CALL_UNEXPECTED_ERROR_HANDLER',
  FETCH_JOB_REQUESTS_BY_INDUSTRY_REQUEST = 'FETCH_JOB_REQUESTS_BY_INDUSTRY_REQUEST',
  FETCH_JOB_REQUESTS_BY_INDUSTRY_SUCCESS = 'FETCH_JOB_REQUESTS_BY_INDUSTRY_SUCCESS',
  REFRESH_JOB_REQUESTS_BY_INDUSTRY_REQUEST = 'REFRESH__FETCH_JOB_REQUESTS_BY_INDUSTRY_REQUEST',
  FETCH_ADDITIONAL_JOB_REQUESTS_BY_INDUSTRY_REQUEST = 'FETCH_ADDITIONAL_JOB_REQUESTS_BY_INDUSTRY_REQUEST',
  FETCH_PROPOSALS_REQUEST = 'FETCH_PROPOSALS_REQUEST',
  FETCH_PROPOSALS_SUCCESS = 'FETCH_PROPOSALS_SUCCESS',
  REFRESH_PROPOSALS_REQUEST = 'REFRESH_PROPOSALS_REQUEST',
  FETCH_ADDITIONAL_PROPOSALS_REQUEST = 'FETCH_ADDITIONAL_PROPOSALS_REQUEST',
  FETCH_OFFERS_REQUEST = 'FETCH_OFFERS_REQUEST',
  FETCH_OFFERS_SUCCESS = 'FETCH_OFFERS_SUCCESS',
  REFRESH_OFFERS_REQUEST = 'REFRESH_OFFERS_REQUEST',
  FETCH_ADDITIONAL_OFFERS_REQUEST = 'FETCH_ADDITIONAL_OFFERS_REQUEST',
  CREATE_OFFER_REQUEST = 'CREATE_OFFER_REQUEST',
  UPDATE_OFFER_REQUEST = 'UPDATE_OFFER_REQUEST',
  FETCH_ADS_REQUEST = 'FETCH_ADS_REQUEST',
  ADD_REVIEWS_REQUEST = 'ADD_REVIEWS_REQUEST',
  FETCH_JOBS_BY_OFFERS = 'FETCH_JOBS_BY_OFFERS',
  //payments actions
  PAY_FOR_JOB_REQUEST = 'PAY_FOR_JOB_REQUEST',
  FETCH_SETUP_SECRET_REQUEST = 'FETCH_SETUP_SECRET_REQUEST',
  FETCH_PAYMENT_REQUEST = 'FETCH_PAYMENT_REQUEST',
  FETCH_PAYMENT_SECRET_REQUEST = 'FETCH_PAYMENT_SECRET_REQUEST',
  FETCH_PAYMENT_CARDS_REQUEST = 'FETCH_PAYMENT_CARDS_REQUEST',
  FETCH_PAYMENT_CARD_SUCCESS = 'FETCH_PAYMENT_CARD_SUCCESS',
  DELETE_PAYMENT_CARD_REQUEST = 'DELETE_PAYMENT_CARD_REQUEST',
  DELETE_PAYMENT_CARD_SUCCESS = 'DELETE_PAYMENT_CARD_SUCCESS',
  UPDATE_DEFAULT_PAYMENT_CARD_REQUEST = 'UPDATE_DEFAULT_PAYMENT_CARD_REQUEST',
  UPDATE_DEFAULT_PAYMENT_CARD_SUCCESS = 'UPDATE_DEFAULT_PAYMENT_CARD_SUCCESS',
  //payments actions
  FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS',
  FETCH_PAYMENTS_REQUEST = 'FETCH_PAYMENTS_REQUEST',
  REFRESH_PAYMENTS_REQUEST = 'REFRESH_PAYMENTS_REQUEST',
  FETCH_ADDITIONAL_PAYMENTS_REQUEST = 'FETCH_ADDITIONAL_PAYMENTS_REQUEST',
  SET_SUCCEED_PAYMENT = 'SET_SUCCEED_PAYMENT',
  // References
  ADD_REFERENCE_REQUEST = 'ADD_REFERENCE_REQUEST',
  ADD_REFERENCE_SUCCESS = 'ADD_REFERENCE_SUCCESS',
  EDIT_REFERENCE_REQUEST = 'EDIT_REFERENCE_REQUEST',
  DELETE_REFERENCE_REQUEST = 'DELETE_REFERENCE_REQUEST',
  FETCH_REFERENCES_REQUEST = 'FETCH_REFERENCES_REQUEST',
  FETCH_REFERENCES_SUCCESS = 'FETCH_REFERENCES_SUCCESS',
  REFRESH_REFERENCES_REQUEST = 'REFRESH_REFERENCES_REQUEST',
  FETCH_ADDITIONAL_REFERENCES_REQUEST = 'FETCH_ADDITIONAL_REFERENCES_REQUEST',
  //navigation bar actions
  SET_NAVIGATION_BAR_WIDTH = 'SET_NAVIGATION_BAR_WIDTH',
  //field reps
  FETCH_FIELD_REPS_REQUEST = 'FETCH_FIELD_REPS_REQUEST',
  FETCH_FIELD_REPS_SUCCESS = 'FETCH_FIELD_REPS_SUCCESS';

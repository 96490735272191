import React, { useRef, useState } from 'react';
import { laborersFilterFieldFormData, useLaborersFilterModalScreen } from '@root/hooks/useLaborersFilterModalScreen';
import { showToastAction, ToastTypes } from '@root/redux/actions/commonActions';
import { useDispatch } from 'react-redux';
import { strings } from '@root/strings';
import { StateType } from '@root/general/types';
import { ChooseCityModal } from '@root/web/components/ChooseCityModal';
import { ChooseStateModal } from '@root/web/components/ChooseStateModal';
import { PickerMenu } from '@root/web/components/PickerMenu';
import { RADIUS, RATING } from '@root/general/consts';
import { CustomInput } from '@root/web/components/CustomInput';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { CustomTabHeader } from '@root/web/components/CustomTabHeader';
import { useStyles } from './../styles';
import Scrollbars from 'react-custom-scrollbars';
import { CustomTabContainer } from '@root/web/components/CustomTabContainer';
import { StatesInput } from '@root/web/components/StatesInput';

const { warnings, buttons, laborersFilterModalScreen: string } = strings;

type LaborersFilterModalProps = {
  onTabClose: () => void;
};

export const LaborersFilterModal = (props: LaborersFilterModalProps) => {
  const { onTabClose } = props;
  const styles = useStyles();
  const dispatch = useDispatch();
  const ref = useRef<any>();

  const [isStateModalVisible, setStateModalVisible] = useState(false);
  const [isCityModalVisible, setCityModalVisible] = useState(false);

  const onStatePress = () => () => {
    setStateModalVisible(true);
  };

  const onCityPress = (filterState: StateType[] | null) => () => {
    if (filterState?.length === 1) {
      setCityModalVisible(true);
    } else if (filterState?.length === 0) {
      dispatch(showToastAction({ message: warnings.selectState, type: ToastTypes.Error }));
    }
  };

  const [
    filterFieldFormData,
    resetFilter,
    onSave,
    ,
    setJobSpecificationId,
    jobSpecificationModalVisible,
    setJobSpecificationModalVisible,
    specifications,
    setFilterRating,
    ratingModalVisible,
    setRatingModalVisible,
    setFilterRadius,
    radiusModalVisible,
    setRadiusModalVisible,
    filterState,
    setFilterState,
    setFilterCity,
    jobSpecificationId,
    filterRating,
    filterRadius,
    keyResetFilter,
  ] = useLaborersFilterModalScreen(onStatePress, onCityPress, onTabClose);

  const renderInputForms = () => {
    return filterFieldFormData.map((item) => {
      const { id: fieldId, title, onPress } = item;

      switch (fieldId) {
        case laborersFilterFieldFormData.radius: {
          return (
            <PickerMenu
              key={fieldId}
              title={title}
              data={RADIUS}
              value={filterRadius}
              setValue={setFilterRadius}
              isOpen={radiusModalVisible}
              onOpen={onPress}
              onClose={() => setRadiusModalVisible(false)}
              disabled={!!(filterState && filterState.length > 1)}
            />
          );
        }
        case laborersFilterFieldFormData.rating: {
          return (
            <PickerMenu
              key={fieldId}
              title={title}
              data={RATING}
              value={filterRating}
              setValue={setFilterRating}
              isOpen={ratingModalVisible}
              onOpen={onPress}
              onClose={() => setRatingModalVisible(false)}
            />
          );
        }
        case laborersFilterFieldFormData.specification: {
          return (
            <PickerMenu
              key={fieldId}
              title={title}
              data={specifications}
              value={jobSpecificationId || ''}
              setValue={setJobSpecificationId}
              isOpen={jobSpecificationModalVisible}
              onOpen={onPress}
              onClose={() => setJobSpecificationModalVisible(false)}
            />
          );
        }
        case laborersFilterFieldFormData.rate: {
          return <CustomInput key={`${fieldId}-${keyResetFilter}`} item={item} />;
        }
        case laborersFilterFieldFormData.state: {
          return <StatesInput key={fieldId} setFilterState={setFilterState} defaultValue={filterState} />;
        }
        default:
          return <CustomInput key={fieldId} item={item} />;
      }
    });
  };

  return (
    <CustomTabContainer ref={ref} tabPositionFixed>
      <CustomTabHeader title={string.screenTitle} onTabHeaderClose={onTabClose} />
      <Scrollbars>
        <div className={styles.rightTabContent}>
          {renderInputForms()}
          <CustomButton type={customButtonType.TEXT} title={string.resetFilter} onPress={resetFilter} />
        </div>
      </Scrollbars>
      <div className={styles.submitButtonContainer}>
        <CustomButton type={customButtonType.RED} title={buttons.save} onPress={onSave} />
      </div>
      {isCityModalVisible && filterState?.length === 1 && (
        <ChooseCityModal
          isModalVisible={isCityModalVisible}
          setModalVisible={setCityModalVisible}
          selectedStateId={filterState[0].id ?? null}
          setCity={setFilterCity}
          ref={ref}
        />
      )}
      {isStateModalVisible && (
        <ChooseStateModal
          isModalVisible={isStateModalVisible}
          setModalVisible={setStateModalVisible}
          setState={setFilterState}
          setCity={setFilterCity}
          states={filterState}
          ref={ref}
        />
      )}
    </CustomTabContainer>
  );
};

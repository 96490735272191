import { ArraySpecifications, GeneralUser } from '@root/general/types';
import { LaborersFilter } from '@root/redux/reducers/laborersReducer';
import { Dispatch, RefObject, SetStateAction, useCallback, useRef, useState } from 'react';
import { TextInput } from 'react-native';
import { ActionType, AdditionActionType } from '@root/hooks/useFlatListPagination';
import { IActionType } from '@root/redux/types';
import { useSelector } from 'react-redux';
import { getLaborersByIndustryId, getLaborersFilterById, getSpecificationsByIndustryId } from '@root/selectors';
import {
  fetchAdditionalLaborersByIndustryAction,
  fetchLaborersByIndustryAction,
  refreshLaborersByIndustryAction,
} from '@root/redux/actions';
import { debounce } from 'lodash';
import { FETCH_DEBOUNCE_TIME } from '@root/general/consts';

type UseLaborersScreenReturnType = [
  ArraySpecifications,
  LaborersFilter,
  RefObject<TextInput>,
  string,
  Dispatch<SetStateAction<string>>,
  Array<GeneralUser>,
  number,
  ActionType,
  ActionType,
  AdditionActionType<GeneralUser>,
  (dispatchFetchAction: () => IActionType) => void,
];

export const useLaborersScreen = (industryId: number): UseLaborersScreenReturnType => {
  const filter = useSelector(getLaborersFilterById(industryId));
  const allSpecifications = useSelector(getSpecificationsByIndustryId(industryId));
  const industryLaborers = useSelector(getLaborersByIndustryId(industryId));
  const { laborers, lastPage } = industryLaborers;
  const searchRef = useRef<TextInput>(null);
  const [searchValue, setSearchValue] = useState('');

  const fetchAction = useCallback(
    (fetchCallback) => {
      return fetchLaborersByIndustryAction({
        industryId,
        filter,
        searchValue,
        callback: fetchCallback,
      });
    },
    [industryId, filter, searchValue],
  );

  const refreshAction = useCallback(
    (refreshCallback: () => void) => {
      return refreshLaborersByIndustryAction({
        industryId,
        filter,
        searchValue,
        callback: refreshCallback,
      });
    },
    [industryId, filter, searchValue],
  );

  const fetchAdditionAction = useCallback(
    (page: number, fetchAdditionCallback: (isSuccess: boolean, additionalItems: any) => void) => {
      return fetchAdditionalLaborersByIndustryAction({
        industryId,
        page,
        filter,
        searchValue,
        callback: fetchAdditionCallback,
      });
    },
    [industryId, filter, searchValue],
  );

  const debounceDispatchFetchAction = (dispatchFetchAction: () => IActionType) => dispatchFetchAction();

  const debounceFetchAction = useCallback(debounce(debounceDispatchFetchAction, FETCH_DEBOUNCE_TIME), []);

  return [
    allSpecifications,
    filter,
    searchRef,
    searchValue,
    setSearchValue,
    laborers,
    lastPage,
    fetchAction,
    refreshAction,
    fetchAdditionAction,
    debounceFetchAction,
  ];
};

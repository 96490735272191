import React from 'react';
import { Grid } from '@material-ui/core';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { strings } from '@root/strings';
import { useStyles } from './styles';
import { WarningModal } from '@root/web/components/WarningModal';
import { useDeleteAccountScreen } from '@root/hooks/useDeleteAccountScreen';
import { CustomLoader } from '@root/web/components/CustomLoader';

const { buttons, profileScreen: string } = strings;

export const DeleteAccount = () => {
  const styles = useStyles();

  const { onDeleteAccountPress, isLoading, isModalVisible, setIsModalVisible } = useDeleteAccountScreen();

  const handlePress = () => setIsModalVisible(true);
  const onApply = () => onDeleteAccountPress();

  return (
    <Grid container className={styles.container}>
      <Grid container direction={'column'}>
        <span className={styles.warnText}>{string.deleteAccountWarnPart1}</span>
        <span className={styles.warnText}>{string.deleteAccountWarnPart2}</span>
        <Grid container justify={'center'}>
          <CustomButton
            type={customButtonType.RED}
            marginTop={15}
            title={buttons.delete}
            onPress={handlePress}
            disabled={isLoading}
            maxWidth={140}
          />
        </Grid>
      </Grid>
      {isLoading && <CustomLoader />}
      {isModalVisible && (
        <WarningModal
          expression={string.deleteAccount}
          isModalVisible={isModalVisible}
          setModalVisible={setIsModalVisible}
          onApply={onApply}
          closeButtonIsCancel
        />
      )}
    </Grid>
  );
};

import React, { Dispatch, SetStateAction, useState } from 'react';
import { useStyles } from './styles';
import { profileTabsMode } from '@root/web/screens/ProfileScreen/types';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import { referenceSchema } from '@root/mobile/utils/validationSchema';
import { CustomTabHeader } from '@root/web/components/CustomTabHeader';
import { useReferencesActionModalScreen } from '@root/hooks/useReferencesActionModalScreen';
import { strings } from '@root/strings';
import Scrollbars from 'react-custom-scrollbars';
import { FormikHandleChangeType, FormikSetFieldTouchedType, FormikValuesType } from '@root/mobile/types/inputTypes';
import { CustomInputAdapter } from '@root/web/components/CustomInput/customInputAdapter';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { CustomLoader } from '@root/web/components/CustomLoader';
import { referencesActionModalMode } from '@root/general/consts';
import { Reference } from '@root/general/types';
import { WarningModal } from '@root/web/components/WarningModal';
import { useHistory } from 'react-router-dom';
import { route } from '@root/web/consts';
import { ReferenceActionCallbackProps } from '@root/mobile/screens/ReferencesModalScreen/types';
import { CustomTabContainer } from '@root/web/components/CustomTabContainer';

const { referencesActionModalScreen: string, buttons } = strings;

type ReferenceActionScreenProps = {
  mode: referencesActionModalMode;
  setTabMode: Dispatch<SetStateAction<profileTabsMode | null>>;
  reference?: Reference;
  referenceActionCallback?: (referenceActionCallbackProps: ReferenceActionCallbackProps) => void;
};

export const ReferenceActionScreen = (props: ReferenceActionScreenProps) => {
  const { mode, setTabMode, reference, referenceActionCallback } = props;
  const styles = useStyles();
  const history = useHistory();

  const isEditMode = mode === referencesActionModalMode.EDIT;

  const [isDeleteWarningVisible, setDeleteWarningModalVisible] = useState(false);

  const goBack = () => {
    setTabMode(null);
    history.push(route.REFERENCES);
  };

  const [formInitialValues, referenceFields, onSubmit, isLoading, onDelete] = useReferencesActionModalScreen(
    mode,
    goBack,
    () => {},
    referenceActionCallback,
    reference,
  );

  const renderHeader = () => {
    if (isEditMode) {
      return (
        <CustomTabHeader
          title={string.screenTitleEdit}
          onTabHeaderClose={goBack}
          buttonIcon={require('@root/web/assets/icons/delete.svg')}
          buttonTitle={buttons.delete}
          buttonPress={() => setDeleteWarningModalVisible(true)}
        />
      );
    }

    return <CustomTabHeader title={string.screenTitleAdd} onTabHeaderClose={goBack} />;
  };

  const renderInputForms = (
    handleChange: FormikHandleChangeType,
    values: FormikValuesType,
    errors: FormikErrors<FormikValuesType>,
    touched: FormikTouched<FormikValuesType>,
    setFieldTouched: FormikSetFieldTouchedType,
  ) => {
    return referenceFields.map((item) => {
      return (
        <CustomInputAdapter
          key={`${item.id}`}
          item={{ ...item, handleChange, values, errors, touched, setFieldTouched }}
        />
      );
    });
  };

  return (
    <Formik
      initialValues={formInitialValues}
      onSubmit={onSubmit}
      validationSchema={referenceSchema()}
      validateOnMount
      enableReinitialize
    >
      {({ handleChange, handleSubmit, values, errors, touched, setFieldTouched, isValid }) => (
        <CustomTabContainer tabPositionFixed>
          {renderHeader()}
          <div className={styles.contentContainer}>
            <Scrollbars>
              <div className={styles.content}>
                {renderInputForms(handleChange, values, errors, touched, setFieldTouched)}
              </div>
            </Scrollbars>
          </div>
          <div className={styles.submitButtonContainer}>
            <CustomButton
              type={customButtonType.RED}
              title={isEditMode ? buttons.save : buttons.add}
              onPress={handleSubmit}
              disabled={isLoading || !isValid}
            />
          </div>
          {isLoading && <CustomLoader />}
          <WarningModal
            expression={`delete ${reference?.name ?? ''}`}
            isModalVisible={isDeleteWarningVisible}
            setModalVisible={setDeleteWarningModalVisible}
            onApply={onDelete}
          />
        </CustomTabContainer>
      )}
    </Formik>
  );
};

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.lightGrey,
    borderRadius: 5,
    padding: '8px 10px',
    marginTop: 8,
  },
  approvedText: {
    whiteSpace: 'pre-wrap',
    color: colors.grey,
    letterSpacing: -0.24,
    fontSize: 10,
    fontFamily: fonts.SFUiDisplayMedium,
    marginLeft: 8,
  },
}));

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { fonts } from '@root/general/fonts';

export const useStyles = makeStyles({
  pageContainer: {
    display: 'flex',
    flex: 1,
  },
  navigationContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  container: {
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    backgroundColor: colors.lightGrey,
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filterValues: {
    display: 'flex',
    paddingTop: 20,
  },
  chip: {
    backgroundColor: colors.athensGray,
    fontSize: 14,
    fontFamily: fonts.SFUiDisplayBold,
    letterSpacing: -0.24,
    marginRight: 5,
    borderRadius: 3,
  },
  chipDeleteIcon: {
    width: 15,
    height: 15,
  },
  search: {
    width: 350,
    marginRight: 20,
  },
  rightTabContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '20px 30px',
  },
  submitButtonContainer: {
    padding: '20px 30px',
    marginTop: 'auto',
  },
});

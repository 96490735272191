import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  overrides: {
    MuiBadge: {
      dot: {
        width: 6,
        height: 6,
        minWidth: 6,
        minHeight: 6,
        borderRadius: '50%',
      },
      colorPrimary: {
        backgroundColor: colors.red,
      },
      badge: {
        zIndex: 0,
      },
      anchorOriginTopRightRectangle: {
        top: 13,
        right: 15,
      },
    },
  },
});

export const popoverTheme = createMuiTheme({
  overrides: {
    MuiPopover: {
      paper: {
        height: '60%',
      },
    },
  },
});

export const useStyles = makeStyles((theme: Theme) => ({
  container: () => {
    return {
      backgroundColor: colors.white,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0px 30px',
      borderWidth: '0px 0px 1px',
      borderStyle: 'solid',
      borderColor: colors.athensGray,
      ...theme.mixins.toolbar,
    };
  },
  leftContainer: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    flexGrow: 1,
  },
  badge: {
    display: 'flex',
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.athensGray,
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.5,
    },
  },
  popover: {
    overflow: 'hidden',
  },
}));

import { useState } from 'react';
import { FormikFieldFormDataType, FormikValuesType } from '@root/mobile/types/inputTypes';
import { strings } from '@root/strings';
import { useDispatch } from 'react-redux';
import { resetPasswordAction } from '@root/redux/actions';

const { resetPasswordModalScreen: string } = strings;

enum ResetPasswordIds {
  newPassword = 1,
  newPasswordConfirmation = 2,
}

type useResetPasswordScreenReturnType = [
  FormikValuesType,
  FormikFieldFormDataType,
  (data: FormikValuesType) => void,
  boolean,
];

export const useResetPasswordScreen = (
  token: string,
  goToLoginScreen: () => void,
): useResetPasswordScreenReturnType => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [hideNewPassword, setHideNewPassword] = useState(true);
  const [hideNewPasswordConfirmation, setHideNewPasswordConfirmation] = useState(true);

  const initialValues = { newPassword: '', newPasswordConfirmation: '' } as FormikValuesType;

  const resetPasswordData: FormikFieldFormDataType = [
    {
      id: ResetPasswordIds.newPassword,
      title: string.newPasswordTitle,
      valuesName: 'newPassword',
      placeHolder: '********',
      fieldStatus: hideNewPassword,
      setFieldStatus: setHideNewPassword,
      icon: require('@root/assets/icons/invisibleInput.png'),
      changedIcon: require('@root/assets/icons/visibleInput.png'),
    },
    {
      id: ResetPasswordIds.newPasswordConfirmation,
      title: string.repeatPasswordTitle,
      valuesName: 'newPasswordConfirmation',
      placeHolder: '********',
      fieldStatus: hideNewPasswordConfirmation,
      setFieldStatus: setHideNewPasswordConfirmation,
      icon: require('@root/assets/icons/invisibleInput.png'),
      changedIcon: require('@root/assets/icons/visibleInput.png'),
    },
  ];

  const onSubmit = (data: FormikValuesType) => {
    const callback = (isSuccess: boolean) => {
      isSuccess && goToLoginScreen();
      setLoading(false);
    };

    dispatch(
      resetPasswordAction({
        token,
        password: data.newPassword,
        passwordConfirmation: data.newPasswordConfirmation,
        callback,
      }),
    );
  };

  return [initialValues, resetPasswordData, onSubmit, loading];
};

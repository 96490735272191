import { Reference, ReferencesArray } from '@root/general/types';
import { ActionType, AdditionActionType } from '@root/hooks/useFlatListPagination';
import { useSelector } from 'react-redux';
import { getReferences } from '@root/selectors';
import { useCallback } from 'react';
import { fetchAdditionalReferencesAction, fetchReferencesAction, refreshReferencesAction } from '@root/redux/actions';

type UseReferencesModalScreenReturnType = [
  ReferencesArray,
  number,
  ActionType,
  ActionType,
  AdditionActionType<Reference>,
];

export const useReferencesModalScreen = (): UseReferencesModalScreenReturnType => {
  const { references, lastPage } = useSelector(getReferences);

  const fetchAction = useCallback((fetchCallback) => {
    return fetchReferencesAction({
      callback: fetchCallback,
    });
  }, []);

  const refreshAction = useCallback((refreshCallback) => {
    return refreshReferencesAction({
      callback: refreshCallback,
    });
  }, []);

  const fetchAdditionalAction = useCallback(
    (page: number, fetchAdditionCallback: (isSuccess: boolean, additionalItems: any) => void) => {
      return fetchAdditionalReferencesAction({
        page,
        callback: fetchAdditionCallback,
      });
    },
    [],
  );

  return [references, lastPage, fetchAction, refreshAction, fetchAdditionalAction];
};

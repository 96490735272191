import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';

export const useStyles = makeStyles(() => ({
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '60%',
    width: 1000,
    marginRight: 20,
    marginLeft: 20,
    outline: 0,
    backgroundColor: colors.white,
    minWidth: 320,
    borderRadius: 5,
    borderColor: colors.athensGray,
    borderWidth: 1,
    borderStyle: 'solid',
  },
  content: {
    width: '100%',
    height: '100%',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 30px',
    borderTopWidth: 1,
    borderTopColor: colors.athensGray,
    borderTopStyle: 'solid',
    minHeight: 48,
  },
}));

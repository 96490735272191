import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@root/general/colors';
import { StripeCardElementOptions } from '@stripe/stripe-js';

export const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
  style: {
    base: {
      '::placeholder': {
        color: colors.darkGreyOpacity5,
      },
      color: colors.darkGrey,
      fontSize: '17px',
      fontFamily: 'Open Sans, sans-serif',
      lineHeight: '23px',
      letterSpacing: '-0.175px',
    },
    invalid: { color: colors.red, iconColor: colors.red },
  },
};

export const useStyles = makeStyles(() => ({
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    outline: 0,
    backgroundColor: colors.white,
    padding: 1,
    borderRadius: 5,
    borderColor: colors.athensGray,
    borderWidth: 1,
    borderStyle: 'solid',
    minWidth: 320,
    width: 540,
    minHeight: 70,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    position: 'relative',
    marginRight: 20,
    marginLeft: 20,
  },
  cardImage: {
    margin: 20,
  },
  cardElementsContainer: {
    padding: 20,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: colors.athensGray,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  cardElement: {
    backgroundColor: colors.greyOpacity2,
    borderRadius: 4,
    padding: '22px 25px',
  },
  cardNumber: {
    width: '100%',
    marginBottom: 20,
  },
  cardMonth: {
    flex: 1,
    marginRight: 20,
  },
  cardCVC: {
    flex: 1,
  },
}));

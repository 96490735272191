import React from 'react';
import { useLoginScreen } from '@root/hooks/useLoginScreen';
import { CustomInputAdapter } from '@root/web/components/CustomInput/customInputAdapter';
import { Formik, FormikErrors, FormikTouched } from 'formik';
import { validationSchema } from '@root/mobile/utils/validationSchema';
import { FormikHandleChangeType, FormikSetFieldTouchedType, FormikValuesType } from '@root/mobile/types/inputTypes';
import { useOnLoginSuccessWeb } from '@root/web/hooks/useOnLoginSuccessWeb';
import { strings } from '@root/strings';
import { WEB_NOTIFICATION_TIMEOUT } from '@root/general/consts';
import { ToastContainer } from 'react-toastify';
import { useStyles } from './styles';
import { RightImageContainer } from '@root/web/components/RightImageContainer';
import { AuthHeader } from '@root/web/components/AuthHeader';
import { AuthFooter } from '@root/web/components/AuthFooter';
import { CustomButton, customButtonType } from '@root/web/components/CustomButton';
import { useHistory } from 'react-router-dom';
import { route } from '@root/web/consts';

const { roleScreen: role, loginScreen: string, buttons: button } = strings;

export const LoginScreen = () => {
  let history = useHistory();
  const styles = useStyles();
  const [onLoginSuccess] = useOnLoginSuccessWeb();

  const [signInSubmit, loginData, initialValue, loading] = useLoginScreen();

  const goToRestorePassword = () => {
    history.push(route.RESTORE_PASSWORD);
  };

  const renderInputForms = (
    handleChange: FormikHandleChangeType,
    values: FormikValuesType,
    errors: FormikErrors<FormikValuesType>,
    touched: FormikTouched<FormikValuesType>,
    setFieldTouched: FormikSetFieldTouchedType,
  ) => {
    return loginData.map((item) => (
      <CustomInputAdapter key={item.id} item={{ ...item, handleChange, values, errors, touched, setFieldTouched }} />
    ));
  };

  const DevButtons = () => (
    <div className={styles.devBtnContainer}>
      <CustomButton
        type={customButtonType.RED}
        title={role.laborer}
        // @ts-ignore
        onPress={() => signInSubmit({ email: 'laborer3000@laborer.com', password: '123456789' }, onLoginSuccess)}
      />
      <CustomButton
        type={customButtonType.RED}
        title={role.employer}
        // @ts-ignore
        onPress={() => signInSubmit({ email: 'employer3000@employer.com', password: '123456789' }, onLoginSuccess)}
      />
    </div>
  );

  return (
    <div className={styles.container}>
      {process.env.NODE_ENV === 'development' && <DevButtons />}
      <div className={styles.leftContainer}>
        <AuthHeader />
        <Formik
          initialValues={initialValue}
          onSubmit={(data) => signInSubmit(data, onLoginSuccess)}
          validationSchema={validationSchema(loginData)}
          validateOnMount={true}
        >
          {({ handleChange, handleSubmit, values, errors, isValid, touched, setFieldTouched }) => (
            <div className={styles.main}>
              <span className={styles.title}>{string.screenTitle}</span>
              <div className={styles.formContainer}>
                {renderInputForms(handleChange, values, errors, touched, setFieldTouched)}
              </div>
              <CustomButton
                type={customButtonType.RED}
                title={button.login}
                onPress={handleSubmit}
                maxWidth={350}
                disabled={loading || !isValid}
              />
              <div>
                <span>{string.forgotPassword}</span>
                <CustomButton
                  type={customButtonType.TEXT}
                  title={string.restorePassword}
                  onPress={goToRestorePassword}
                />
              </div>
            </div>
          )}
        </Formik>
        <AuthFooter />
      </div>
      <ToastContainer autoClose={WEB_NOTIFICATION_TIMEOUT} />
      <RightImageContainer />
    </div>
  );
};

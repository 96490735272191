import { makeStyles, Theme } from '@material-ui/core/styles';
import { CustomScrollComponentProps } from '@root/web/components/CustomScrollComponent/index';

export const useStyles = makeStyles((theme: Theme) => ({
  container: (props: CustomScrollComponentProps) => {
    const { footer } = props;
    return {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: !footer ? '20px 0px' : '20px 0px 0px',
      boxSizing: 'border-box',
    };
  },
  headerContainer: {
    width: '100%',
    padding: '0px 30px 20px',
    boxSizing: 'border-box',
  },
  footerContainer: {
    padding: '30px 30px 20px',
    [theme.breakpoints.down('md')]: {
      padding: '10px 30px',
    },
  },
  gridContainer: {
    width: '100%',
    padding: '0px 18px',
    boxSizing: 'border-box',
    margin: 0,
  },
  horizontalScroll: {
    display: 'none',
  },
}));

import React from 'react';
import { Industry, Job } from '@root/general/types';
import { formattedWorkPeriod } from '@root/mobile/utils/formattedWorkPeriod';
import { colors } from '@root/general/colors';
import { getLabelColorByOfferStatus } from '@root/mobile/utils/getLabelColorByOfferStatus';
import { getRateTypeName } from '@root/mobile/utils/getRateTypeName';
import { useStyles } from './styles';
import { getJobStatusByStatusId } from '@root/mobile/utils/getJobStatusByStatusId';
import { accountType, OFFERS_BY_STATUS } from '@root/general/consts';
import { getLocationAddress } from '@root/utils/getLocationAddress';
import { CustomCardContainer } from '@root/web/components/CustomCardContainer';
import { useSelector } from 'react-redux';
import { getUser } from '@root/selectors';
import { strings } from '@root/strings';

export type JobCardProps = {
  job: Job;
  onPress: () => void;
  industry?: Industry;
  title?: string | null;
  offerStatusId?: number | null;
  jobStatus?: number | null;
  isSelected?: boolean;
};

const { paymentTypes } = strings;

export const JobCard = (props: JobCardProps) => {
  const styles = useStyles(props);
  const { roleId } = useSelector(getUser);

  const { job, onPress, offerStatusId, jobStatus, title, industry, isSelected } = props;
  const { name, rate, rateTypeId, startDate, endDate, isOfferFromUs, state, city, paymentType } = job;
  const { instantPayment, monthlyPayment } = paymentTypes;
  const paymentMethod = paymentType === 0 ? instantPayment : monthlyPayment;
  const isEmployer = roleId === accountType.EMPLOYER;

  const RenderStatusFlag = () => {
    const { name: statusName, color: statusColor } = jobStatus
      ? getJobStatusByStatusId(jobStatus)
      : getLabelColorByOfferStatus(offerStatusId, isOfferFromUs, false);

    const [textColor, statusContainerBorder] =
      statusName === OFFERS_BY_STATUS.NEW.name || statusName === OFFERS_BY_STATUS.RECEIVED.name
        ? [colors.black, { borderColor: colors.black, borderWidth: 1 }]
        : [colors.white, {}];

    return (
      <span
        className={styles.statusText}
        style={{
          backgroundColor: statusColor,
          borderColor: textColor,
          color: textColor,
          ...statusContainerBorder,
        }}
      >
        {statusName}
      </span>
    );
  };

  return (
    <CustomCardContainer isSelected={isSelected} onPress={onPress}>
      <div>
        {title && <span className={styles.titleText}>{title}</span>}
        <div className={styles.containerTop}>
          <div className={styles.workPeriodContainer}>
            <span className={styles.nameText}>{name}</span>
            <span className={styles.workPeriodText}>{formattedWorkPeriod(startDate, endDate)}</span>
          </div>
          <div className={styles.rateContainer}>
            {paymentMethod && isEmployer && <span className={styles.paymentMethodTitle}>{paymentMethod}</span>}
            <span className={styles.salaryText}>{`$${rate}`}</span>
            <span className={styles.rateTypeText}>{getRateTypeName(rateTypeId, industry)}</span>
          </div>
        </div>
      </div>
      <div className={styles.containerBottom}>
        <div className={styles.locationContainer}>
          <img src={require('@root/web/assets/icons/location.svg')} alt='' className={styles.locationIcon} />
          <span className={styles.farmAddressText}>{getLocationAddress(state, city)}</span>
        </div>
        {(jobStatus || offerStatusId) && <RenderStatusFlag />}
      </div>
    </CustomCardContainer>
  );
};

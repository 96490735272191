import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, ThemeProvider } from '@material-ui/core';
import { useStyles } from './styles';
import { theme } from '@root/web/components/CustomInput/styles';
import { capitalize } from '@root/utils/capitalize';

export type PickerMenuProps = {
  title: string;
  data: Array<{ id: number | null; name: string }>;
  disabled?: boolean;
  fullWidth?: boolean;
  customStyles?: string;
  value: any;
  setValue: (value: any) => void;
  isOpen: boolean;
  onOpen?: (() => void) | null;
  onClose?: (() => void) | null;
  handleChange?: (value: string) => void;
  isDefaultFont?: boolean;
};

export const PickerMenu = (props: PickerMenuProps) => {
  const {
    title,
    data,
    disabled,
    fullWidth,
    setValue,
    value,
    isOpen,
    isDefaultFont,
    onOpen,
    onClose,
    handleChange,
  } = props;

  const styles = useStyles({ isDefaultFont });

  const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value as number);
    handleChange && handleChange(String(event.target.value));
  };

  return (
    <ThemeProvider theme={theme}>
      <FormControl variant={'outlined'} className={styles.container} fullWidth={fullWidth}>
        <InputLabel className={styles.selectLabel}>{title}</InputLabel>
        <Select
          disabled={disabled}
          className={styles.selectContainer}
          classes={{ icon: styles.selectIcon }}
          value={value || ''}
          onChange={onChange}
          open={isOpen}
          onOpen={() => onOpen && onOpen()}
          onClose={() => onClose && onClose()}
        >
          {data.map((item) => {
            return (
              <MenuItem key={item.id || 0} value={item.id || 0} className={styles.itemText}>
                {capitalize(item.name)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};

import { Api, CustomApiResponse } from '@root/services/api';
import { call, put } from 'redux-saga/effects';
import {
  CALL_COMMON_ERROR_HANDLER,
  CALL_UNEXPECTED_ERROR_HANDLER,
  FETCH_JOB_REQUESTS_BY_INDUSTRY_SUCCESS,
} from '@root/general/consts/actions';
import {
  FetchAdditionalJobRequestsByIndustryAction,
  FetchJobRequestsByIndustryAction,
} from '@root/redux/actions/types';
import { ErrorResponse, OfferArray } from '@root/general/types';
import { OFFER_STATUS, offersType } from '@root/general/consts';

const DEFAULT_PAGE = 1;

export function* fetchJobRequestsByIndustry(api: Api, action: FetchJobRequestsByIndustryAction) {
  const { industryId, callback } = action.payload;

  try {
    const response: CustomApiResponse<OfferArray, ErrorResponse> = yield call(
      api.fetchUserOffersRequest,
      offersType.incoming,
      { industryId, offerStatusId: OFFER_STATUS.SENT.id },
      DEFAULT_PAGE,
    );

    if (response.ok && response.data) {
      yield put({
        type: FETCH_JOB_REQUESTS_BY_INDUSTRY_SUCCESS,
        payload: { industryId, jobRequests: response.data, lastPage: response.meta?.lastPage ?? DEFAULT_PAGE },
      });
    }

    if (!response.ok) {
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  } finally {
    yield callback && call(callback);
  }
}

export function* fetchAdditionalJobRequestsByIndustry(api: Api, action: FetchAdditionalJobRequestsByIndustryAction) {
  const { industryId, page, callback } = action.payload;
  try {
    const response: CustomApiResponse<OfferArray, ErrorResponse> = yield call(
      api.fetchUserOffersRequest,
      offersType.incoming,
      { industryId, offerStatusId: OFFER_STATUS.SENT.id },
      page,
    );

    if (response.ok && response.data) {
      yield callback && call(callback, true, response.data);
    }

    if (!response.ok) {
      yield callback && call(callback, false);
      yield put({ type: CALL_COMMON_ERROR_HANDLER, payload: response });
    }
  } catch (error) {
    yield callback && call(callback, false);
    yield put({ type: CALL_UNEXPECTED_ERROR_HANDLER, payload: error });
  }
}

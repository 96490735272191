import React from 'react';
import { IndustryTopNavigator } from '@root/web/navigators/IndustryTopNavigator';
import { useJobRequestsScreen } from '@root/hooks/useJobRequestsScreen';
import { useFlatListPagination } from '@root/hooks/useFlatListPagination';
import { Offer } from '@root/general/types';
import { Grid } from '@material-ui/core';
import { MainHeader } from '@root/web/components/MainHeader';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useStyles } from './styles';
import { defaultGeneralUser, defaultJob, WEB_NOTIFICATION_TIMEOUT } from '@root/general/consts';
import { LaborerCard } from '@root/web/components/LaborerCard';
import { useSelector } from 'react-redux';
import { getIndustries, getIndustryById } from '@root/selectors';
import { route } from '@root/web/consts';
import { MainFooter } from '@root/web/components/MainFooter';
import { ToastContainer } from 'react-toastify';
import { UserInfoTab } from '@root/web/components/UserInfoTab';
import { jobRequestFeedbackCallback } from '@root/utils/jobRequestsFeedbackCallbacl';
import { CustomScrollComponent } from '@root/web/components/CustomScrollComponent';
import { CustomTabContainer } from '@root/web/components/CustomTabContainer';
import { strings } from '@root/strings';
import { useReadNotification } from '@root/web/hooks/useReadNotification';

const { jobRequestsScreen: string } = strings;

type locationType = {
  notificationId?: number | null;
};

export const JobRequestsScreen = (props: { path: string }) => {
  const styles = useStyles();

  const location = useLocation<locationType>();
  const notificationId = location?.state?.notificationId ?? null;

  useReadNotification(notificationId);

  const { jobId = '', laborerId = '' } = useParams<{ jobId?: string; laborerId?: string }>();
  const numberJobId = Number(jobId);
  const numberLaborerId = Number(laborerId);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.navigationContainer}>
        <MainHeader />
        <IndustryTopNavigator {...props}>
          <JobRequestsView industryId={1} jobId={numberJobId} laborerId={numberLaborerId} />
        </IndustryTopNavigator>
      </div>
      {!!numberJobId && <CustomTabContainer isCarcass />}
    </div>
  );
};

type JobRequestsViewProps = {
  industryId: number;
  jobId: number;
  laborerId: number;
};

const JobRequestsView = (props: JobRequestsViewProps) => {
  const { industryId, jobId, laborerId } = props;

  const styles = useStyles();

  const history = useHistory();

  const { allIndustries } = useSelector(getIndustries);
  const currentIndustry = useSelector(getIndustryById(industryId)) || allIndustries[0];
  const currentIndustryName = currentIndustry.name.toLowerCase();

  const [jobRequests, lastPage, fetchAction, refreshAction, fetchAdditionAction] = useJobRequestsScreen(industryId);

  const [
    jobRequestsByIndustry,
    isFlatListLoading,
    ,
    ,
    ,
    onEndReached,
    setJobRequestsByIndustry,
    hasMore,
  ] = useFlatListPagination<Offer>(jobRequests, lastPage, fetchAction, refreshAction, fetchAdditionAction);

  const onTabClose = () => {
    history.push(`${route.REQUESTS}/${currentIndustryName}`);
  };

  const goToLaborerInfo = (laborerId: number | null, jobId: number | null) => () => {
    history.push(`${route.REQUESTS}/${currentIndustryName}/${laborerId}/${jobId}`);
  };

  const renderJobRequests = (jobRequest: Offer) => {
    const { id: offerId, owner = defaultGeneralUser, job = defaultJob } = jobRequest;
    const { name, rate, rating, id: ownerId } = owner;
    const { name: jobName } = job;
    return (
      <Grid
        key={offerId}
        item
        xs={12}
        md={laborerId && jobId ? 12 : 6}
        lg={laborerId && jobId ? 6 : 4}
        xl={laborerId && jobId ? 4 : 3}
      >
        <LaborerCard
          onPress={goToLaborerInfo(ownerId, job.id)}
          name={name}
          jobName={jobName}
          rate={rate}
          rating={rating}
          isSelected={laborerId === ownerId && jobId === job.id}
        />
      </Grid>
    );
  };

  return (
    <>
      <CustomScrollComponent
        data={jobRequestsByIndustry}
        renderItem={renderJobRequests}
        isFlatListLoading={isFlatListLoading}
        onEndReached={onEndReached}
        hasMore={hasMore}
        footer={<MainFooter />}
        defaultPrimaryText={string.defaultPrimaryText}
        containerStyle={styles.container}
      />
      {!!(laborerId && jobId) && (
        <UserInfoTab
          userId={laborerId}
          jobId={jobId}
          goBackCallback={onTabClose}
          jobRequestFeedbackCallback={jobRequestFeedbackCallback(jobRequestsByIndustry, setJobRequestsByIndustry)}
          tabPositionFixed
        />
      )}
      <ToastContainer autoClose={WEB_NOTIFICATION_TIMEOUT} />
    </>
  );
};
